const csvLabels = [
  {
    label: 'First Name',
    fieldName: 'firstName',
  },
  {
    label: 'Last Name',
    fieldName: 'lastName',
  },
  {
    label: 'Email',
    fieldName: 'email',
  },
  {
    label: 'Phone',
    fieldName: 'phone',
  },
  {
    label: 'Status',
    fieldName: 'status',
  },
  {
    label: 'Role',
    fieldName: 'role',
  },
  {
    label: 'Group',
    fieldName: 'group',
  },
  {
    label: 'Start Address',
    fieldName: 'startAddress',
  },
  {
    label: 'From Address',
    fieldName: 'fromAddress',
  },
  {
    label: 'Journey Start',
    fieldName: 'journeyStartTs',
  },
  {
    label: 'Journey End',
    fieldName: 'journeyEndTs',
  },
  {
    label: 'Reimbursement Amount',
    fieldName: 'reimbursementAmount',
  },
  {
    label: 'Reimbursement Currency',
    fieldName: 'reimbursementCurrency',
  },
  {
    label: 'Receipt Status',
    fieldName: 'receiptStatus',
  },
  {
    label: 'Total Amount',
    fieldName: 'totalAmount',
  },
  {
    label: 'Currency',
    fieldName: 'currency',
  },
];

export const userImportCsvHeaders = csvLabels.map(config => {
  return {
    label: config.label,
    key: config.fieldName,
  };
});
