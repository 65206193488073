import React, { useEffect, useState } from 'react';
import { Prompt } from 'react-router-dom';

import useModal from '../../hooks/useModal';
import ConfirmModal from '../Modal/ConfirmModal';

const UnloadWarningModal = props => {
  const { loading, when, history, location, message } = props;

  const [isModalOpen, openModal, closeModal] = useModal();
  const [shouldUnload, setShouldUnload] = useState(false);

  const [lastLocation, setLastLocation] = useState(location);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  const hideModal = () => {
    closeModal();
    setShouldUnload(false);
  };

  const showModal = nextLocation => {
    openModal();
    setLastLocation(nextLocation);
  };

  const handleBlockedRoute = nextLocation => {
    if (!confirmedNavigation && when) {
      showModal(nextLocation);
      return false;
    }

    return true;
  };

  const handleConfirmNavigationClick = () => {
    hideModal();
    setConfirmedNavigation(true);
  };

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      // Navigate to the previous blocked location with your navigate function
      setShouldUnload(true);
      history.push(lastLocation.pathname);
    }
  }, [confirmedNavigation, lastLocation, history]);

  // Block non-react routes
  useEffect(() => {
    const unload = event => {
      if (when && !shouldUnload) {
        event.returnValue = message;
      }
      if (shouldUnload) {
        event.returnValue = '';
      }
    };
    window.addEventListener('beforeunload', unload);

    return () => window.removeEventListener('beforeunload', unload);
  }, [when, message, shouldUnload]);

  return (
    <>
      <Prompt when={when} message={handleBlockedRoute} />

      <ConfirmModal
        loading={loading}
        visible={isModalOpen}
        message={message}
        onOk={handleConfirmNavigationClick}
        onCancel={hideModal}
        okText="Yes"
        cancelText="No"
      />
    </>
  );
};

export default UnloadWarningModal;
