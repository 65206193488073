import React from 'react';

import { hasSystemAdminRole } from '../../../utils/roles';
import FeatureFormItem from './FeatureFormItem';
import SettingSectionWrapper from './SettingSectionWrapper';

const NotificationSettingsFormItems = props => {
  const { t, authUser, companySettings, onFeatureChange, disabled } = props;

  if (!hasSystemAdminRole(authUser)) return null;

  return (
    <SettingSectionWrapper title={t('notificationSettings')}>
      <FeatureFormItem
        label={t('favrAlerts')}
        value={companySettings.favrAlerts}
        onChange={checked => onFeatureChange({ favrAlerts: checked })}
      />

      <FeatureFormItem
        label={t('sendNextPaymentPeriodPushNotification')}
        value={companySettings.sendNextPaymentPeriodPushNotification}
        onChange={checked => onFeatureChange({ sendNextPaymentPeriodPushNotification: checked })}
        disabled={disabled}
      />

      <FeatureFormItem
        label={t('autoTrackingMisconfigurationNotifications')}
        value={companySettings.autoTrackingMisconfigurationNotifications}
        onChange={checked =>
          onFeatureChange({
            autoTrackingMisconfigurationNotifications: checked,
          })
        }
      />
    </SettingSectionWrapper>
  );
};

export default NotificationSettingsFormItems;
