import { handleApiCalls } from './axiosInstance';

export const ADMIN_API = {
  /**
   * @param {string} email
   * @param {object} ticketInfo
   * @param {string} ticketInfo.subject
   * @param {string} ticketInfo.description
   */
  createTicket: async (email, ticketInfo = {}) => {
    const url = `${process.env.REACT_APP_HOST_API}admin/access-requests`;
    await handleApiCalls('post', url, { email, ...ticketInfo });
  },
};
