import { Col, Row } from 'antd';
import { get } from 'lodash';
import React from 'react';
import { Popup } from 'react-mapbox-gl';

import { IMAGES } from '../../enum';
import { getUserFullName, getUserInitials, momentFormat } from '../../utils/common';
import { formatAddressIntoString } from '../../utils/trips';
import Avatar from '../Avatar';
// import Select from '../Select';
import Text from '../Text';
import FadedText from '../Text/FadedText';
import classNames from './styles.module.scss';

const UserInsightMapPopup = props => {
  const { coordinates, user, t } = props;

  return (
    <Popup coordinates={coordinates} offset={[0, -35]} style={{ width: 300 }}>
      <Row gutter={10} align="middle" className={classNames.userTopAvatarRow}>
        <Col>
          <Avatar src={user.profilePicture} initials={getUserInitials(user)} />
        </Col>
        <Col flex={1}>
          <Text size="xs" variant="strong">
            {getUserFullName(user)}
          </Text>
        </Col>
      </Row>

      <div className={classNames.contentSection}>
        <div style={{ marginBottom: 5 }}>
          <Text size="xs" variant="strong">
            {t('lastTripDestination')}
          </Text>
        </div>

        {user.lastTripEndTs && (
          <>
            <div className={classNames.rowWithIcon}>
              <img width="20px" src={IMAGES.CALENDAR_ICON} alt="map" />
              <FadedText size="xs">{momentFormat(user.lastTripEndTs, 'MMM DD, YYYY')}</FadedText>
            </div>

            <div className={classNames.rowWithIcon}>
              <img width="20px" src={IMAGES.TIME_CLOCK_CIRCLE_GRAY} alt="clock" />
              <FadedText size="xs">{momentFormat(user.lastTripEndTs, 'hh:mm a')} Arrival</FadedText>
            </div>
          </>
        )}

        <div className={classNames.rowWithIcon}>
          <img width="20px" src={IMAGES.MAP_PIN_ICON} alt="map" />
          <FadedText size="xs">
            {get(user, 'lastLocation.formattedAddress', formatAddressIntoString(user.lastLocation))}
          </FadedText>
        </div>
      </div>

      {/* <Form.Item labelCol={{ span: 24 }} label="Assign event" className={classNames.assignEventRow}>
        <Select options={[]} placeholder="Select" />
      </Form.Item> */}
    </Popup>
  );
};

export default UserInsightMapPopup;
