import moment from 'moment-timezone';
import React, { useMemo } from 'react';

import { STATUS_LIST } from '../../../enum';
import useDebouncedState from '../../../hooks/useDebouncedState';
import { checkIfStringContainsValue, getUserFullName, momentTimezone } from '../../../utils/common';
import {
  sortColumnByDateString,
  sortColumnByMomentTime,
  sortColumnByStatus,
} from '../../../utils/tables';
import { CustomTable } from '../..';
import ProfileNamePicture from '../../shared-ui/ProfileNamePicture';
import StatusTag from '../../Tag/StatusTag';
import Text from '../../Text';

const ReceiptStatusHistoryTable = props => {
  const { t, dataSource } = props;

  const [debouncedSearchTerm, setSearchTerm] = useDebouncedState();

  const TIME_FORMAT = 'hh:mm a';
  const DATE_FORMAT = 'MM-DD-YYYY';
  const TIMEZONE_FORMAT = 'zz';

  const filteredTable = useMemo(() => {
    let array = dataSource;

    if (debouncedSearchTerm) {
      array = array.filter(data => {
        let userName;

        if (typeof data.user === 'string') {
          userName = data.user;
        } else {
          userName = getUserFullName(data.user);
        }

        const newStatus = data.newStatus
          ? STATUS_LIST.StatusTagColorConfig[data.newStatus].label
          : 'N/A';
        const oldStatus = data.oldStatus
          ? STATUS_LIST.StatusTagColorConfig[data.oldStatus].label
          : 'N/A';

        return (
          checkIfStringContainsValue(userName, debouncedSearchTerm) ||
          checkIfStringContainsValue(newStatus, debouncedSearchTerm) ||
          checkIfStringContainsValue(oldStatus, debouncedSearchTerm) ||
          checkIfStringContainsValue(
            momentTimezone(data.timestamp, null).format(TIME_FORMAT),
            debouncedSearchTerm,
          ) ||
          checkIfStringContainsValue(
            momentTimezone(data.timestamp, null).format(DATE_FORMAT),
            debouncedSearchTerm,
          )
        );
      });
    }

    return array;
  }, [debouncedSearchTerm, dataSource]);

  const COLUMNS = [
    {
      width: 120,
      key: 'date',
      title: 'Date',
      ...sortColumnByDateString('timestamp'),
      render: (actions, data) => moment(data.timestamp, null).tz('UTC').format(DATE_FORMAT),
    },
    {
      key: 'time',
      title: 'Time',
      ...sortColumnByMomentTime('timestamp', TIME_FORMAT),
      render: (actions, data) => moment(data.timestamp, null).tz('UTC').format(TIME_FORMAT),
    },
    {
      key: 'timezone',
      title: 'Timezone',
      sorter: false,
      render: (actions, data) => moment(data.timestamp, null).tz('UTC').format(TIMEZONE_FORMAT),
    },
    {
      width: 200,
      key: 'user',
      title: 'User',
      sorter: (a, b) => {
        const userNameA = typeof a.user === 'string' ? a.user : getUserFullName(a.user);
        const userNameB = typeof b.user === 'string' ? b.user : getUserFullName(b.user);

        return userNameA.toLowerCase().localeCompare(userNameB.toLowerCase());
      },
      render: (actions, data) => {
        if (typeof data.user === 'string') {
          return (
            <Text variant="strong" size="sm">
              {data.user}
            </Text>
          );
        }

        return <ProfileNamePicture t={t} user={data.user} />;
      },
    },
    {
      key: 'oldStatus',
      title: 'Previous Status',
      render: (actions, data) => (data.oldStatus ? <StatusTag status={data.oldStatus} /> : 'N/A'),
      ...sortColumnByStatus('oldStatus'),
    },
    {
      key: 'newStatus',
      title: 'Current Status',
      render: (actions, data) => <StatusTag status={data.newStatus} />,
      ...sortColumnByStatus('newStatus'),
    },
  ];

  return (
    <CustomTable
      scroll={{ x: 900 }}
      columns={COLUMNS}
      dataSource={filteredTable}
      onSearchTermChange={setSearchTerm}
      defaultSearchTerm={debouncedSearchTerm}
    />
  );
};

ReceiptStatusHistoryTable.defaultProps = {
  dataSource: [],
};

export default ReceiptStatusHistoryTable;
