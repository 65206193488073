import './style.scss';

import { Divider, Row, Space, Tag, Upload } from 'antd';
import { Button } from 'components';
import { Button as CustomButton, CustomTable } from 'components';
import Spinner from 'components/Spinner';
import Toast from 'components/Toast';
import PageContainer from 'containers/PageContainer';
import { IMAGES } from 'enum';
import { INTERNAL_LINKS } from 'enum';
import useDebouncedState from 'hooks/useDebouncedState';
import moment from 'moment-timezone';
import React, { useMemo, useState } from 'react';
import { CSVLink } from 'react-csv';
import { Helmet } from 'react-helmet';
import { withNamespaces } from 'react-i18next';
import { useSelector } from 'react-redux';

import { handleApiErrors, performApiCallIfCompanySubIsActive } from '../../api/axiosInstance';
import { USER_API } from '../../api/user';
import { ConfirmModal } from '../../components';
import Text from '../../components/Text';
import withAuthentication from '../../hocs/withAuthentication';
import { checkIfStringContainsValue, formatPageTitle } from '../../utils/common';
import { selectStoreCompanyGroupByID } from '../../utils/storeSelectors';

const CSV_TEMPLATE = t => [
  {
    label: 'First Name',
    fieldName: 'firstName',
  },
  {
    label: 'Last Name',
    fieldName: 'lastName',
  },
  {
    label: 'Email',
    fieldName: 'email',
  },
  {
    label: 'Employee Number',
    fieldName: 'employeeNumber',
  },
  {
    label: 'Group',
    fieldName: 'group',
  },
  {
    label: 'Role',
    fieldName: 'role',
  },
  {
    label: 'Send Invite Email on Future Date (optional)',
    fieldName: 'sendInviteEmailOnFutureDateOptional',
  },
  {
    label: 'Send Invite on Future Time (optional)',
    fieldName: 'sendInviteOnFutureTimeOptional',
  },
  { label: t('costCenter_optional'), fieldName: 'costCenterOptional' },
  { label: t('vehicleMake_optional'), fieldName: 'vehicleMakeOptional' },
  { label: t('vehicleModel_optional'), fieldName: 'vehicleModelOptional' },
  { label: t('vehicleYear_optional'), fieldName: 'vehicleYearOptional' },
  { label: t('homeAddressLine1_optional'), fieldName: 'homeAddressLine1Optional' },
  { label: t('homeAddressLine2_optional'), fieldName: 'homeAddressLine2Optional' },
  { label: t('homeAddressCity_optional'), fieldName: 'homeAddressCityOptional' },
  { label: t('homeAddressState_optional'), fieldName: 'homeAddressStateOptional' },
  { label: t('homeAddressZipCode_optional'), fieldName: 'homeAddressZipCodeOptional' },
  { label: t('homeAddressCountry_optional'), fieldName: 'homeAddressCountryOptional' },
];

const renderTag = (columnVal, row, columnName, minWidth) => {
  return (
    <>
      <span>{columnVal}</span>
      {row.errors ? (
        <div style={{ minWidth }}>
          {row.errors.map((tag, key) => {
            return (
              tag.param === columnName && (
                <Tag
                  color="volcano"
                  key={key}
                  style={{ whiteSpace: 'normal', textTransform: 'capitalize' }}
                >
                  {tag.msg === 'Invalid role.'
                    ? `Role is either 'User', 'Manager', or 'Admin'`
                    : tag.msg}
                </Tag>
              )
            );
          })}
        </div>
      ) : null}
    </>
  );
};

const Columns = t => [
  {
    title: 'Errors',
    key: 'errors',
    dataIndex: 'errors',
  },
  {
    title: 'First Name',
    dataIndex: 'firstName',
    key: 'firstName',
    sorter: {
      compare: (a, b) => a.firstName.localeCompare(b.firstName),
    },
    checked: true,
    render: (role, row) => renderTag(role, row, 'firstName'),
  },
  {
    title: 'Last Name',
    dataIndex: 'lastName',
    key: 'lastName',
    sorter: {
      compare: (a, b) => a.lastName.localeCompare(b.lastName),
    },
    checked: true,
    render: (role, row) => renderTag(role, row, 'lastName'),
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    sorter: {
      compare: (a, b) => a.email.localeCompare(b.email),
    },
    checked: true,
    render: (role, row) => renderTag(role, row, 'email'),
  },
  {
    title: 'Employee Number',
    dataIndex: 'employeeNumber',
    key: 'employeeNumber',
    sorter: {
      compare: (a, b) => {
        const numberA = a.employeeNumber ? `${a.employeeNumber}` : '';
        const numberB = b.employeeNumber ? `${b.employeeNumber}` : '';

        return numberA.localeCompare(numberB);
      },
    },
    checked: true,
    render: (role, row) => renderTag(role, row, 'employeeNumber'),
  },
  {
    title: 'Group',
    dataIndex: 'group',
    key: 'group',
    sorter: {
      compare: (a, b) => a.group.localeCompare(b.group),
    },
    checked: true,
    render: (role, row) => renderTag(role, row, 'group'),
  },
  {
    title: 'Role',
    dataIndex: 'role',
    key: 'role',
    sorter: {
      compare: (a, b) => a.role.localeCompare(b.role),
    },
    checked: true,
    render: (role, row) => renderTag(role, row, 'role'),
  },
  {
    title: 'Send Activation Email Date',
    key: 'sendActivationEmailDate',
    checked: true,
    render: ({ sendInviteEmailOnFutureDateOptional, sendInviteOnFutureTimeOptional }, row) => {
      if (!sendInviteEmailOnFutureDateOptional && !sendInviteOnFutureTimeOptional) {
        return 'N/A';
      }
      return (
        <div>
          <div>
            {renderTag(
              sendInviteEmailOnFutureDateOptional,
              row,
              'sendInviteEmailOnFutureDateOptional',
            )}
          </div>
          <div>
            {renderTag(sendInviteOnFutureTimeOptional, row, 'sendInviteOnFutureTimeOptional')}
          </div>
        </div>
      );
    },
  },
  {
    title: t('Cost Center'),
    key: 'costCenterOptional',
    dataIndex: 'costCenterOptional',
    render: (role, row) => renderTag(role, row, 'costCenterOptional'),
  },
  {
    title: t('vehicleMake'),
    key: 'vehicleMakeOptional',
    dataIndex: 'vehicleMakeOptional',
    render: (role, row) => renderTag(role, row, 'vehicleMakeOptional'),
  },
  {
    title: t('vehicleModel'),
    key: 'vehicleModelOptional',
    dataIndex: 'vehicleModelOptional',
    render: (role, row) => renderTag(role, row, 'vehicleModelOptional'),
  },
  {
    title: t('vehicleYear'),
    key: 'vehicleYearOptional',
    dataIndex: 'vehicleYearOptional',
    render: (role, row) => renderTag(role, row, 'vehicleYearOptional'),
  },
  {
    title: t('homeAddressLine1'),
    key: 'homeAddressLine1Optional',
    dataIndex: 'homeAddressLine1Optional',
    render: (role, row) => renderTag(role, row, 'homeAddressLine1Optional', '300px'),
  },
  {
    title: t('homeAddressLine2'),
    key: 'homeAddressLine2Optional',
    dataIndex: 'homeAddressLine2Optional',
    render: (role, row) => renderTag(role, row, 'homeAddressLine2Optional', '300px'),
  },
  {
    title: t('homeAddressCity'),
    key: 'homeAddressCityOptional',
    dataIndex: 'homeAddressCityOptional',
    render: (role, row) => renderTag(role, row, 'homeAddressCityOptional', '300px'),
  },
  {
    title: t('homeAddressState'),
    key: 'homeAddressStateOptional',
    dataIndex: 'homeAddressStateOptional',
    render: (role, row) => renderTag(role, row, 'homeAddressStateOptional', '300px'),
  },
  {
    title: t('homeAddressZipCode'),
    key: 'homeAddressZipCodeOptional',
    dataIndex: 'homeAddressZipCodeOptional',
    render: (role, row) => renderTag(role, row, 'homeAddressZipCodeOptional', '300px'),
  },
  {
    title: t('homeAddressCountry'),
    key: 'homeAddressCountryOptional',
    dataIndex: 'homeAddressCountryOptional',
    render: (role, row) => renderTag(role, row, 'homeAddressCountryOptional', '300px'),
  },
];

const MAX_FILE_SIZE = 2000000;

const CSVInvite = props => {
  const currentCompany = useSelector(state => state.common.currentCompany);
  const groupList = useSelector(state => state.group.groupList);

  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [invalidUsers, setInvalidUsers] = useState([]);
  const [validUsers, setValidUsers] = useState([]);
  const emptyData = { firstName: '', lastName: '', email: '', group: '', role: '' };
  const csvHeaders = CSV_TEMPLATE(props.t).map(config => {
    return {
      label: config.label,
      key: config.fieldName,
    };
  });

  const addFullGroupToUser = (usersDetails, invitedUsers) => {
    invitedUsers.forEach(user => {
      const userIndex = usersDetails.findIndex(userDetails => user.email === userDetails.email);
      if (usersDetails[userIndex]) {
        const group = selectStoreCompanyGroupByID(user.groupId);

        if (group) {
          usersDetails[userIndex].group = { ...group };

          if (usersDetails[userIndex].group && usersDetails[userIndex].group.groupManager._id) {
            usersDetails[userIndex].group.groupManager =
              usersDetails[userIndex].group.groupManager._id;
          }

          if (usersDetails[userIndex].group.company && usersDetails[userIndex].group.company._id) {
            usersDetails[userIndex].group.company = usersDetails[userIndex].group.company._id;
          }
        }
      }
    });
  };

  // Ignore invalid user and send invite
  const continueInvite = async () => {
    setIsModalVisible(false);
    setLoading(true);

    // adjusted roles to use /api/user/invite endpoint
    const invitedUsers = await validUsers.map(user => {
      user.role = user.role.toLowerCase();
      if (user.role === 'admin' || user.role === 'manager') {
        user.role = 'company-' + user.role;
      }
      const sendActivationEmailDate =
        user.sendInviteEmailOnFutureDateOptional && user.sendInviteOnFutureTimeOptional
          ? moment(
              `${user.sendInviteEmailOnFutureDateOptional} ${user.sendInviteOnFutureTimeOptional}`,
              'MM/DD/YYYY HH:mm',
            ).toISOString()
          : undefined;

      let homeAddress;
      if (
        !!user.homeAddressLine1Optional ||
        !!user.homeAddressLine1Optiona2 ||
        !!user.homeAddressCityOptional ||
        !!user.homeAddressStateOptional ||
        !!user.homeAddressZipCodeOptional ||
        !!user.homeAddressCountryOptional
      ) {
        homeAddress = {
          streetOne: user.homeAddressLine1Optional,
          streetTwo: user.homeAddressLine2Optional ? user.homeAddressLine2Optional : undefined,
          city: user.homeAddressCityOptional,
          state: user.homeAddressStateOptional,
          postalCode: user.homeAddressZipCodeOptional,
          country: user.homeAddressCountryOptional,
        };
      }

      let vehicle;
      if (!!user.vehicleMakeOptional && !!user.vehicleModelOptional && !!user.vehicleYearOptional) {
        vehicle = {
          make: user.vehicleMakeOptional,
          model: user.vehicleModelOptional,
          year: user.vehicleYearOptional,
        };
      }

      return {
        email: user.email,
        firstName: user.firstName,
        group: user.group,
        groupId: user.groupId,
        groupName: user.groupName,
        lastName: user.lastName,
        role: user.role,
        employeeId: user.employeeNumber,
        sendActivationEmailDate,
        department: user.costCenterOptional,
        homeAddress,
        vehicle,
      };
    });

    invitedUsers.map(user => {
      const group = groupList.find(grp => user.group === grp.name);
      user.groupId = group._id;
      user.groupName = group.name;
      return user;
    });

    try {
      const result = await USER_API.inviteUsers(invitedUsers, currentCompany._id);

      if (result.data.success) {
        addFullGroupToUser(result.data.usersDetails, invitedUsers);
        Toast({
          type: 'success',
          message: 'Invitation sent',
        });
        setLoading(false);
        props.history.push(INTERNAL_LINKS.USER_MANAGER);
      }
    } catch (error) {}
    setLoading(false);
  };

  const [debouncedSearchValid, setSearchValid] = useDebouncedState();
  const [debouncedSearchInvalid, setSearchInvalid] = useDebouncedState();

  const filteredValid = useMemo(() => {
    let array = validUsers;

    if (debouncedSearchValid) {
      array = array.filter(user => {
        return (
          user.firstName.toLowerCase().includes(debouncedSearchValid.toLowerCase()) ||
          user.lastName.toLowerCase().includes(debouncedSearchValid.toLowerCase()) ||
          user.email.toLowerCase().includes(debouncedSearchValid.toLowerCase()) ||
          checkIfStringContainsValue(user.employeeNumber, debouncedSearchValid)
        );
      });
    }

    return array;
  }, [debouncedSearchValid, validUsers]);

  const filteredInvalid = useMemo(() => {
    let array = invalidUsers;

    if (debouncedSearchInvalid) {
      array = array.filter(user => {
        return (
          user.firstName.toLowerCase().includes(debouncedSearchInvalid.toLowerCase()) ||
          user.lastName.toLowerCase().includes(debouncedSearchInvalid.toLowerCase()) ||
          user.email.toLowerCase().includes(debouncedSearchInvalid.toLowerCase()) ||
          checkIfStringContainsValue(user.employeeNumber, debouncedSearchInvalid)
        );
      });
    }

    return array;
  }, [debouncedSearchInvalid, invalidUsers]);

  const getInvalidTableTitle = (invalid, valid) => {
    if (invalid >= 1 && valid === 0) {
      return `${invalid} ${
        invalid === 1 ? 'user' : 'users'
      } could not be created due to validation error(s). Please correct and re-upload a CSV file.`;
    } else if (invalid >= 1 && valid >= 0) {
      return `${invalid} of ${invalid + valid} ${
        invalid === 0 ? 'user has validation error(s)' : 'users have validation errors'
      } and will not be created. Please correct the data in your CSV file to create ${
        invalid === 1 ? 'this user' : 'these users'
      }`;
    }
  };

  const csvProps = {
    fileList: [],
    beforeUpload: file => {
      if (file.type !== 'text/csv') {
        Toast({
          type: 'error',
          message: 'File Upload Failed.',
          description: `Only CSV file is allowed.`,
        });

        return false;
      }
      if (file.size > MAX_FILE_SIZE) {
        Toast({
          type: 'error',
          message: 'CSV Upload Failed.',
          description: `Your CSV file is too large. Maximum file size is ${(
            MAX_FILE_SIZE / 1048576
          ).toFixed(0)}MB.
      `,
        });
        return false;
      }
      return file.type === 'text/csv';
    },
    customRequest: async options => {
      const { file } = options;
      const url = `${process.env.REACT_APP_HOST_API}user/import-users`;
      setInvalidUsers([]);
      setValidUsers([]);
      setLoading(true);
      let csvData = new FormData();
      let filteredUsers = [];
      csvData.append('file', file);

      try {
        const result = await performApiCallIfCompanySubIsActive('post', url, csvData, {
          'Content-Type': 'multipart/form-data',
          Accept: 'text/csv',
        });

        if (result.data.message.validUsers) {
          await setValidUsers(result.data.message.validUsers);
        }
        if (result.data.message.invalidUsers) {
          filteredUsers = await result.data.message.invalidUsers.filter(
            res =>
              res.email !== '' || res.firstName !== '' || res.lastName !== '' || res.role !== '',
          );
          await setInvalidUsers(filteredUsers);
        }

        if (!result.data.message.validUsers.length) {
          setLoading(false);
          return Toast({
            type: 'error',
            message: props.t('importUserCsvError'),
            description: props.t('usersNotCreatedInvalidMissingCsvValues'),
          });
        }
      } catch (error) {
        handleApiErrors(error.response);
      }
      setLoading(false);
    },
  };
  return (
    <PageContainer title="Bulk Create Users" className="csv-invite">
      <Helmet>
        <title>{formatPageTitle('Bulk Create Users')}</title>
      </Helmet>

      <Row>
        {props.t('Step 1')}: {props.t('Download this CSV template and add your users')}:
        <CSVLink
          target="_self"
          filename="Bulk_Create_User_template.csv"
          headers={csvHeaders}
          data={[emptyData]}
        >
          &nbsp; Bulk_Create_User_template.csv
        </CSVLink>
      </Row>
      <Divider />
      <Row>
        {props.t('Step 2')}: {props.t('Upload your file')}. &nbsp;
        <Upload showUploadList={false} listType="file" accept=".csv" {...csvProps}>
          <Button
            className="with-icon upload-button"
            type="secondary"
            size="sm"
            key="upload-btn"
            icon={<img className="download-button" src={IMAGES.UPLOAD_BOX_ICON} alt="upload csv" />}
            text="Upload CSV"
          />
        </Upload>
      </Row>

      {invalidUsers.length > 0 && (
        <>
          <Row>
            <CustomTable
              className="error-table"
              dataSource={filteredInvalid}
              columns={Columns(props.t)}
              hiddenColumns={['errors']}
              customTitle={getInvalidTableTitle(invalidUsers.length, validUsers.length)}
              onSearchTermChange={setSearchInvalid}
              scroll={{ x: 1200 }}
            />
          </Row>
        </>
      )}

      {validUsers.length > 0 && (
        <>
          <Row>
            <CustomTable
              rowKey={record => record.email}
              className="valid-table"
              dataSource={filteredValid}
              columns={Columns(props.t).filter(col => col.dataIndex !== 'errors')}
              customTitle={`${validUsers.length} ${
                invalidUsers.length ? `of ${invalidUsers.length + validUsers.length}` : ''
              } user(s) can be invited.`}
              hiddenColumns={['errors']}
              onSearchTermChange={setSearchValid}
              scroll={{ x: 1200 }}
            />
          </Row>

          <br />

          <Row>
            <Space direction="horizontal" size="small" wrap>
              <CustomButton
                block
                size="large"
                type="secondary"
                text="Cancel"
                onClick={() => {
                  setInvalidUsers([]);
                  setValidUsers([]);
                }}
              />
              <CustomButton
                block
                type="primary"
                size="large"
                onClick={() => setIsModalVisible(true)}
                text={`Create Users`}
              />
            </Space>
          </Row>
        </>
      )}

      <ConfirmModal
        className="address-modal"
        title="Confirm Create Users"
        visible={isModalVisible}
        okText="Yes"
        onCancel={() => setIsModalVisible(false)}
        onOk={() => continueInvite()}
        message={
          <div>
            <Text variant="p" size="sm" style={{ marginBottom: 5 }}>
              {validUsers.length} {' of '} {validUsers.length + invalidUsers.length}
              {validUsers.length === 1 ? ' user' : ' users'}
              {' will be created.'}
            </Text>

            <Text variant="p" size="sm">
              Are you sure you want to continue?
            </Text>
          </div>
        }
      />

      {loading && (
        <div className="loading-container">
          <Spinner />
        </div>
      )}
    </PageContainer>
  );
};

export default withNamespaces()(withAuthentication(CSVInvite));
