import { Col, Row } from 'antd';
import React, { useMemo } from 'react';

import { STATUS_LIST } from '../../../enum';
import useDebouncedState from '../../../hooks/useDebouncedState';
import useDidUpdateEffect from '../../../hooks/useDidUpdateEffect';
import { checkIfStringContainsValue, momentTimezone } from '../../../utils/common';
import Button from '../../Button';
import CustomTable from '../../Table';
import StatusTag from '../../Tag/StatusTag';
import LinkText from '../../Text/LinkText';

const CountryRatesTable = props => {
  const {
    t,
    dataSource,
    defaultSearchTerm,
    onRateHistoryView,
    onEditRateClick,
    onDeleteRateClick,
    ...rest
  } = props;

  const [debouncedSearchTerm, setSearchTerm] = useDebouncedState(defaultSearchTerm);

  useDidUpdateEffect(() => {
    setSearchTerm(defaultSearchTerm);
  }, [defaultSearchTerm]);

  const filteredTable = useMemo(() => {
    let array = dataSource;

    if (debouncedSearchTerm) {
      array = dataSource.filter(countryRate => {
        return (
          checkIfStringContainsValue(countryRate.country, debouncedSearchTerm) ||
          checkIfStringContainsValue(countryRate.name, debouncedSearchTerm) ||
          checkIfStringContainsValue(countryRate.currency, debouncedSearchTerm) ||
          checkIfStringContainsValue(countryRate.effectiveStartDate, debouncedSearchTerm) ||
          checkIfStringContainsValue(countryRate.effectiveEndDate, debouncedSearchTerm) ||
          checkIfStringContainsValue(
            STATUS_LIST.StatusTagColorConfig[countryRate.status].label,
            debouncedSearchTerm,
          )
        );
      });
    }

    return array;
  }, [debouncedSearchTerm, dataSource]);

  const COLUMNS = [
    {
      key: 'country',
      title: t('Country'),
      dataIndex: 'country',
      render: country => t(country),
    },
    {
      key: 'name',
      title: t('Name'),
      dataIndex: 'name',
    },
    {
      key: 'currency',
      title: t('unit_plural'),
      dataIndex: 'currency',
      render: currency => t(currency),
    },
    {
      key: 'effectiveDate',
      title: t('Effective Date'),
      render: (actions, data) =>
        [
          momentTimezone(data.effectiveStartDate).format('YYYY-MM-DD'),
          momentTimezone(data.effectiveEndDate).format('YYYY-MM-DD'),
        ].join(' - '),
    },
    {
      key: 'status',
      title: t('status'),
      dataIndex: 'status',
      render: status => <StatusTag status={status} />,
    },
    {
      key: 'history',
      title: t('history'),
      render: (actions, data) => (
        <LinkText onClick={() => onRateHistoryView(data._id)}>{t('view')}</LinkText>
      ),
    },
    {
      key: 'edit',
      title: t('actions'),
      render: (actions, data) => (
        <Row gutter={[8, 8]}>
          <Col flex={1}>
            <Button block size="xs" onClick={() => onEditRateClick(data._id)}>
              {t('Edit')}
            </Button>
          </Col>
          <Col flex={1}>
            <Button block type="secondary" size="xs" onClick={() => onDeleteRateClick(data._id)}>
              {t('Delete')}
            </Button>
          </Col>
        </Row>
      ),
    },
  ];

  return (
    <CustomTable
      {...rest}
      rowKey={data => data._id}
      scroll={{ x: 900 }}
      columns={COLUMNS}
      dataSource={filteredTable}
      onSearchTermChange={setSearchTerm}
      defaultSearchTerm={debouncedSearchTerm}
    />
  );
};

export default CountryRatesTable;
