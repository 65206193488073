import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';

import { CustomTable, CustomCheckbox, RequiredInput } from 'components';

import './style.scss';

class InsurancePolicyConfView extends React.Component {
  insurance_columns = [
    {
      title: () => (
        <div className="d-flex justify-content-center align-items-center f-dir-column">
          <span className="mb-2">Required</span>
          <CustomCheckbox
            disabled={this.props.readOnly}
            type="secondary"
            onChange={this.selectAllRequired}
          />
        </div>
      ),
      dataIndex: ['required', 'key'],
      key: 'required',
      width: 50,
      render: (actions, data) => (
        <CustomCheckbox
          disabled={this.props.readOnly}
          type="secondary"
          checked={data.required}
          onChange={() => this.changeRequired(data.key)}
        />
      ),
      align: 'center',
    },
    {
      title: () => <span className="d-flex justify-content-center">Field</span>,
      dataIndex: 'field',
      key: 'field',
    },
    {
      title: () => (
        <div className="d-flex f-dir-column">
          <span>{'Per Person'}</span>
          <span>{'(minimum)'}</span>
        </div>
      ),
      dataIndex: ['perperson', 'stateMinimum', 'key'],
      key: 'perperson',
      render: (actions, data) => (
        <RequiredInput
          required={!this.props.readOnly && !data.stateMinimum}
          dirty={this.props.dirty}
          disabled={this.props.readOnly || data.stateMinimum}
          defaultValue={data.perperson}
          onChange={e => this.onChange(data.key, 'perperson', e.target.value)}
        />
      ),
      align: 'center',
    },
    {
      title: () => (
        <div className="d-flex f-dir-column">
          <span>{'Per Accident'}</span>
          <span>{'(minimum)'}</span>
        </div>
      ),
      dataIndex: ['peraccident', 'stateMinimum', 'key'],
      key: 'peraccident',
      render: (actions, data) => (
        <RequiredInput
          required={!this.props.readOnly && !data.stateMinimum}
          dirty={this.props.dirty}
          disabled={this.props.readOnly || data.stateMinimum}
          defaultValue={data.peraccident}
          onChange={e => this.onChange(data.key, 'peraccident', e.target.value)}
        />
      ),
      align: 'center',
    },
    {
      title: () => (
        <div className="d-flex justify-content-center align-items-center f-dir-column">
          <span className="mb-2">Use State Minimum</span>
          <CustomCheckbox
            disabled={this.props.readOnly}
            type="secondary"
            onChange={this.selectAllStateMinimum}
          />
        </div>
      ),
      dataIndex: ['stateMinimum', 'key'],
      key: 'stateMinimum',
      render: (actions, data) => (
        <CustomCheckbox
          disabled={this.props.readOnly}
          type="secondary"
          checked={data.stateMinimum}
          onChange={() => this.changeStateMinimum(data.key)}
        />
      ),
      align: 'center',
    },
  ];

  insurance_data = [
    {
      key: 1,
      required: false,
      field: 'Bodily Injury to Others',
      perperson: '',
      peraccident: '',
      stateMinimum: false,
    },
    {
      key: 2,
      required: false,
      field: "Damage to Someone Else' Property",
      perperson: '',
      peraccident: '',
      stateMinimum: false,
    },
    {
      key: 3,
      required: false,
      field: 'Personal Injury Protection',
      perperson: '',
      peraccident: '',
      stateMinimum: false,
    },
    {
      key: 4,
      required: false,
      field: 'Bodily Injury Caused by Uninsured Auto',
      perperson: '',
      peraccident: '',
      stateMinimum: false,
    },
    {
      key: 5,
      required: false,
      field: 'Optional Bodily Injury to Others',
      perperson: '',
      peraccident: '',
      stateMinimum: false,
    },
    {
      key: 6,
      required: false,
      field: 'Medical Payments',
      perperson: '',
      peraccident: '',
      stateMinimum: false,
    },
    {
      key: 7,
      required: false,
      field: 'Collision',
      perperson: '',
      peraccident: '',
      stateMinimum: false,
    },
    {
      key: 8,
      required: false,
      field: 'Comprehensive',
      perperson: '',
      peraccident: '',
      stateMinimum: false,
    },
    {
      key: 9,
      required: false,
      field: 'Bodily Injury Caused by an Underinsured Auto',
      perperson: '',
      peraccident: '',
      stateMinimum: false,
    },
  ];

  constructor(props) {
    super(props);

    const { creating } = this.props.policy;
    let data = this.insurance_data;
    if (creating && creating.data && creating.data.length) {
      data = creating.data;
    }

    this.state = {
      insuranceColumns: this.insurance_columns,
      insuranceData: data,
    };
  }

  componentDidMount() {
    this.props.onChange(this.state.insuranceData);
  }

  changeRequired = key => {
    let { insuranceData } = this.state;
    const index = insuranceData.findIndex(item => item.key === key);
    if (index >= 0) {
      insuranceData[index].required = !insuranceData[index].required;
    }
    this.setState({ insuranceData });
    this.props.onChange(insuranceData);
  };

  changeStateMinimum = key => {
    let { insuranceData } = this.state;
    const index = insuranceData.findIndex(item => item.key === key);
    if (index >= 0) {
      insuranceData[index].stateMinimum = !insuranceData[index].stateMinimum;
    }
    this.setState({ insuranceData });
    this.props.onChange(insuranceData);
  };

  selectAllStateMinimum = e => {
    if (e) {
      const { checked } = e.target;
      let { insuranceData } = this.state;
      insuranceData.map(item => (item.stateMinimum = checked));
      this.setState({ insuranceData });
      this.props.onChange(insuranceData);
    }
  };

  selectAllRequired = e => {
    if (e) {
      const { checked } = e.target;
      let { insuranceData } = this.state;
      insuranceData.map(item => (item.required = checked));
      this.setState({ insuranceData });
      this.props.onChange(insuranceData);
    }
  };

  onChange = (key, field, value) => {
    let { insuranceData } = this.state;
    const index = insuranceData.findIndex(item => item.key === key);
    if (index >= 0) {
      insuranceData[index][field] = value;
    }
    this.setState({ insuranceData });
    this.props.onChange(insuranceData);
  };

  render() {
    const { insuranceData, insuranceColumns } = this.state;

    return (
      <div className="insurance-policy-view">
        <CustomTable
          pagination={false}
          bordered
          dataSource={insuranceData}
          columns={insuranceColumns}
        />
      </div>
    );
  }
}

InsurancePolicyConfView.propTypes = {
  data: PropTypes.array,
  readOnly: PropTypes.bool,
  onChange: PropTypes.func,
};

InsurancePolicyConfView.defaultProps = {
  data: [],
  readOnly: false,
  onChange: () => {},
};

const mapStateToProps = (state, ownProps) => {
  return {
    policy: state.policy,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withNamespaces()(InsurancePolicyConfView));
