import { get } from 'lodash';
import { useQuery } from 'react-query';

import { handleApiErrors } from '../../api/axiosInstance';
import useDidUpdateEffect from '../useDidUpdateEffect';
import useTablePagination from '../useTablePagination';

/**
 *
 * @param {import('react-query').UseQueryOptions} queryOptions
 * @param {object} paginationOptions
 * @param {any[]} paginationOptions.resetPageQueryKey
 * @param {number} paginationOptions.initialPage
 * @param {number} paginationOptions.initialPageSize
 * @param {boolean} paginationOptions.useQueryParams
 * @param {string} countKey
 */
const usePaginatedFiltersQuery = (
  queryOptions = {},
  paginationOptions = { resetPageQueryKey: [] },
  countKey = 'totalCount',
) => {
  const {
    paginationConfig,
    handlePageChange,
    handlePageSizeChange,
    handleTotalUpdate,
  } = useTablePagination(paginationOptions);

  useDidUpdateEffect(() => {
    if (paginationConfig.current !== 1) {
      handlePageChange(1);
    }
    // eslint-disable-next-line
  }, get(paginationOptions, 'resetPageQueryKey', []));

  const query = useQuery({
    onError: error => handleApiErrors(error.response),
    ...queryOptions,
    queryKey: [...queryOptions.queryKey, paginationConfig.current, paginationConfig.pageSize],
    onSuccess: data => {
      handleTotalUpdate(data[countKey]);

      if (typeof queryOptions.onSuccess === 'function') {
        queryOptions.onSuccess(data);
      }
    },
  });

  useDidUpdateEffect(() => {
    if (query.status === 'success' && paginationConfig.total !== query.data[countKey]) {
      handleTotalUpdate(query.data[countKey]);
    }
  }, [paginationConfig.total, query.status, query.data, countKey, handleTotalUpdate]);

  return {
    query,
    paginationConfig,
    handlePageChange,
    handlePageSizeChange,
  };
};

export default usePaginatedFiltersQuery;
