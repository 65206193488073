import { Col, Form, List, Row } from 'antd';
import React from 'react';

import { HelpIcon } from '../../../components';
import Radio from '../../../components/Radio';
import SectionStepWrapper from '../../../components/SectionStepWrapper';
import { ERROR_MESSAGE } from '../../../utils/constants';
import CommuteDeductionSettingsSection from './CommuteDeductionSettingsSection';

const CompanyCommuteSettingsSection = props => {
  const {
    t,
    isSubmitting,
    companyCommuteSettings,
    onCommuteSettingsChange,
    isCommuteSettingCustom,
    isCommuteSettingSingleLocation,
    isCommuteSettingNotHaveOffice,
    isNoOfficeMaxDeduction,
    isNoOfficeIgnoreFirstAndLast,
    isHomeAddressMaxCommuteDeduction,
    distanceUnit,
  } = props;

  return (
    <section>
      <SectionStepWrapper
        step="1"
        title={t('selectHowCommuteDeductionsApply')}
        titleProps={{ variant: 'b', size: 'sm', style: { marginTop: 2.5, marginBottom: 10 } }}
        showContent={companyCommuteSettings?.enabled}
      >
        <Form.Item rules={[{ required: true, message: ERROR_MESSAGE.BLANK_FIELD }]}>
          <Radio.Group
            name="level"
            value={companyCommuteSettings?.level}
            onChange={e => onCommuteSettingsChange({ level: e.target.value })}
          >
            <Radio disabled={!companyCommuteSettings.enabled || isSubmitting} value="company">
              {t('sameCommuteDeductionAllUser')}
            </Radio>

            <Row wrap={false} align="middle">
              <Col>
                <Radio disabled={!companyCommuteSettings.enabled || isSubmitting} value="group">
                  {t('differentCommuteByGroup')}
                </Radio>
              </Col>

              <Col>
                <HelpIcon
                  hint={
                    <List
                      size="small"
                      renderItem={item => <List.Item>{item}</List.Item>}
                      dataSource={[
                        t('allowsToApplyCommuteDifferentGroups'),
                        t('belowSettingsWillBeUsedByDefault'),
                        t('commuteSettingsManagedOnGroupsPage'),
                      ]}
                    ></List>
                  }
                />
              </Col>
            </Row>
          </Radio.Group>
        </Form.Item>
      </SectionStepWrapper>

      <CommuteDeductionSettingsSection
        t={t}
        isSubmitting={isSubmitting}
        commuteSettings={companyCommuteSettings}
        onCommuteSettingsChange={onCommuteSettingsChange}
        isCommuteSettingCustom={isCommuteSettingCustom}
        isCommuteSettingSingleLocation={isCommuteSettingSingleLocation}
        isCommuteSettingNotHaveOffice={isCommuteSettingNotHaveOffice}
        isNoOfficeMaxDeduction={isNoOfficeMaxDeduction}
        isNoOfficeIgnoreFirstAndLast={isNoOfficeIgnoreFirstAndLast}
        isHomeAddressMaxCommuteDeduction={isHomeAddressMaxCommuteDeduction}
        distanceUnit={distanceUnit}
      />
    </section>
  );
};

export default CompanyCommuteSettingsSection;
