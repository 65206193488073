import { Col, Form, Row } from 'antd';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';

import { analyseAddressAutoCompleteString } from '../../utils/address';
import { ERROR_MESSAGE } from '../../utils/constants';
import { findFullSupportedCountryData } from '../../utils/countries';
import { selectStoreCountryByCode, selectStoreCountryByName } from '../../utils/storeSelectors';
import {
  DEFAULT_COUNTRY_ADDRESS_FIELD_ORDER,
  DEFAULT_COUNTRY_ADDRESS_REQUIRED_FIELDS,
} from '../Forms/CountryForm/country-enums';
import LocationInput from '../LocationInput';
import PostalCodeInput from '../PostalCodeInput';
import Select from '../Select';
import SpaceSpinner from '../SpaceSpinner';
import TextInput from '../TextInput';
import Toast from '../Toast';

/**
 * Form with set of fields to update the user's Address
 */
const AddressForm = props => {
  const {
    t,
    companyAddress,
    formDisabled,
    onValueChange,
    values,
    countries,
    allFieldsOptional,
    formInstance,
    fieldNames,
    hiddenFields,
  } = props;

  const [addressFieldsOrder, setAddressFieldsOrder] = useState();
  const [requiredAddressFields, setRequiredAddressFields] = useState();

  const companyCountry = useMemo(() => companyAddress?.country, [companyAddress]);

  const COUNTRY = useMemo(() => {
    if (values.country || companyCountry) {
      const fullCountry = findFullSupportedCountryData(values.country || companyCountry);
      setAddressFieldsOrder(fullCountry?.addressFieldsOrder || DEFAULT_COUNTRY_ADDRESS_FIELD_ORDER);
      setRequiredAddressFields(
        fullCountry?.requiredAddressFields || DEFAULT_COUNTRY_ADDRESS_REQUIRED_FIELDS,
      );

      return fullCountry;
    } else {
      setAddressFieldsOrder(DEFAULT_COUNTRY_ADDRESS_FIELD_ORDER);
      setRequiredAddressFields(DEFAULT_COUNTRY_ADDRESS_REQUIRED_FIELDS);
      return countries.find(country => country.code === 'US');
    }
  }, [values.country, countries, companyCountry]);

  const STATES = useMemo(() => {
    let countryStates;

    if (values.country || companyCountry) {
      const country = countries.find(
        country => country.code === (values.country || companyCountry),
      );

      if (country) {
        countryStates = country.states;
      }
    }

    return countryStates;
    // eslint-disable-next-line
  }, [values.country, countries, companyCountry]);

  const handleStreetOneSelect = (address, option) => {
    const analysedString = analyseAddressAutoCompleteString(address);
    const { streetOne, streetTwo, city, state, country, postalCode } = analysedString;
    const countryInStore = selectStoreCountryByCode(country) || selectStoreCountryByName(country);
    if (countryInStore) {
      const updatedAddress = {
        streetOne,
        streetTwo,
        city: option?.city || city,
        state: option?.stateCode || state,
        country: option?.countryCode || country,
        postalCode: option?.postalCode || postalCode,
      };
      onValueChange(updatedAddress);
      if (formInstance) {
        formInstance.setFieldsValue({
          [fieldNames.streetOne || 'streetOne']: streetOne,
          [fieldNames.streetTwo || 'streetTwo']: streetTwo,
          [fieldNames.city || 'city']: option?.city || city,
          [fieldNames.state || 'state']: option?.stateCode || state,
          [fieldNames.country || 'country']: option?.countryCode || country,
          [fieldNames.postalCode || 'postalCode']: option?.postalCode || postalCode,
        });
      }
    } else {
      const updatedAddress = { streetOne: values?.streetOne };
      onValueChange({ streetOne: values?.streetOne });
      if (formInstance) formInstance.setFieldsValue(updatedAddress);

      Toast({
        type: 'error',
        message: t('unsupportedCountryOnAddress'),
      });
    }
  };

  if (
    (!COUNTRY && !hiddenFields.includes('country')) ||
    !Array.isArray(addressFieldsOrder) ||
    !Array.isArray(requiredAddressFields)
  ) {
    return <SpaceSpinner />;
  }

  const ADDRESS_FIELD_COMPONENTS = {
    streetOne: () => (
      <Form.Item
        name={fieldNames.streetOne || 'streetOne'}
        required={requiredAddressFields.includes('streetOne') && !allFieldsOptional}
        label={t('Address 1')}
        rules={[
          {
            required: requiredAddressFields.includes('streetOne') && !allFieldsOptional,
            message: ERROR_MESSAGE.BLANK_FIELD,
          },
        ]}
      >
        <LocationInput
          disabled={formDisabled}
          defaultValue={values.streetOne}
          onChange={streetOne => onValueChange({ streetOne })}
          onSelect={handleStreetOneSelect}
          limitedToCountryCode={values.country}
          autoComplete="new-password"
        />
      </Form.Item>
    ),
    streetTwo: () => (
      <Form.Item
        name={fieldNames.streetTwo || 'streetTwo'}
        required={requiredAddressFields.includes('streetTwo') && !allFieldsOptional}
        label={t('Address 2')}
        rules={[
          {
            required: requiredAddressFields.includes('streetTwo') && !allFieldsOptional,
            message: ERROR_MESSAGE.BLANK_FIELD,
          },
        ]}
      >
        <TextInput
          autoComplete="address-line2"
          name={fieldNames.streetTwo || 'streetTwo'}
          disabled={formDisabled}
          value={values.streetTwo}
          onChange={e => onValueChange({ streetTwo: e.target.value })}
        />
      </Form.Item>
    ),
    country: () => (
      <Form.Item
        name={fieldNames.country || 'country'}
        required={requiredAddressFields.includes('country') && !allFieldsOptional}
        label={t('Country')}
        rules={[
          {
            required: requiredAddressFields.includes('country') && !allFieldsOptional,
            message: ERROR_MESSAGE.BLANK_FIELD,
          },
        ]}
      >
        <Select
          name={fieldNames.country || 'country'}
          disabled={formDisabled}
          autoComplete="country"
          placeholder={t('Select a country')}
          value={values.country || (companyAddress && companyAddress.country)}
          onChange={country => onValueChange({ country, state: undefined })}
          options={countries.map(c => ({
            label: c.name,
            value: c.code,
          }))}
        />
      </Form.Item>
    ),
    state: ({ states }) => (
      <Form.Item
        name={fieldNames.state || 'state'}
        required={requiredAddressFields.includes('state') && !allFieldsOptional}
        label={t('State')}
        rules={[
          {
            required: requiredAddressFields.includes('state') && !allFieldsOptional,
            message: ERROR_MESSAGE.BLANK_FIELD,
          },
        ]}
      >
        {Array.isArray(states) && !!states.length ? (
          <Select
            name={fieldNames.state || 'state'}
            disabled={formDisabled}
            placeholder={t('Select a state')}
            options={STATES}
            value={values.state}
            onChange={state => onValueChange({ state })}
          />
        ) : (
          <TextInput
            required={requiredAddressFields.includes('state') && !allFieldsOptional}
            name={fieldNames.state || 'state'}
            value={values.state}
            autoComplete="address-level1"
            disabled={formDisabled || !values.country}
            placeholder={values.country ? 'Type the name of your state' : undefined}
            onChange={e => onValueChange({ state: e.target.value })}
          />
        )}
      </Form.Item>
    ),
    city: () => (
      <Form.Item
        name={fieldNames.city || 'city'}
        required={requiredAddressFields.includes('city') && !allFieldsOptional}
        label={t('City')}
        rules={[
          {
            required: requiredAddressFields.includes('city') && !allFieldsOptional,
            message: ERROR_MESSAGE.BLANK_FIELD,
          },
        ]}
      >
        <TextInput
          name={fieldNames.city || 'city'}
          value={values.city}
          autoComplete="address-level2"
          disabled={formDisabled}
          onChange={e => onValueChange({ city: e.target.value })}
        />
      </Form.Item>
    ),
    postalCode: () => (
      <PostalCodeInput
        required={requiredAddressFields.includes('postalCode') && !allFieldsOptional}
        size="small"
        country={values.country || companyAddress.country}
        label={values.country === 'FR' ? t('postalCode') : t('zipCode')}
        name={fieldNames.postalCode || 'postalCode'}
        defaultValue={values.postalCode}
        autoComplete="postal-code"
        disabled={formDisabled}
        onChange={e => onValueChange({ postalCode: e.target.value })}
      />
    ),
  };

  return (
    <Form labelCol={{ span: 24 }} form={formInstance} initialValues={values}>
      <Row gutter={16}>
        {addressFieldsOrder.map(
          addressType =>
            !hiddenFields.includes(addressType) && (
              <Col xs={24} lg={12} key={addressType}>
                {ADDRESS_FIELD_COMPONENTS[addressType]({ states: STATES })}
              </Col>
            ),
        )}
      </Row>
    </Form>
  );
};

AddressForm.propTypes = {
  values: PropTypes.shape({}),
  onValueChange: PropTypes.func.isRequired,
  countries: PropTypes.arrayOf(PropTypes.shape({})),
  fieldNames: PropTypes.shape({}),
  hiddenFields: PropTypes.arrayOf(
    PropTypes.oneOf(['streetOne', 'streetTwo', 'country', 'state', 'city', 'postalCode']),
  ),
};

AddressForm.defaultProps = {
  values: {},
  countries: [],
  fieldNames: {},
  hiddenFields: [],
};

export default AddressForm;
