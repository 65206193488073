import { get } from 'lodash';
import React from 'react';

import { IMAGES, STATUS_LIST } from '../enum';
import { dateCompare, getUserFullName, momentFormat } from './common';

const getSorterFieldValues = (a, b, fieldName, defaultValue) => {
  let aField = fieldName ? get(a, fieldName, defaultValue) : a;
  let bField = fieldName ? get(b, fieldName, defaultValue) : b;
  return { aField, bField };
};

export const sortColumnByStringField = fieldName => {
  return {
    sorter: (a, b) => {
      const { aField, bField } = getSorterFieldValues(a, b, fieldName);
      if (typeof aField !== 'string' || typeof bField !== 'string') return 1;
      return aField
        .toLowerCase()
        .localeCompare(bField.toLowerCase(), undefined, { numeric: true, sensitivity: 'base' });
    },
  };
};

export const sortColumnByBoolean = fieldName => {
  return {
    sorter: (a, b) => {
      const { aField, bField } = getSorterFieldValues(a, b, fieldName);
      return aField === bField ? 0 : aField ? -1 : 1;
    },
  };
};

export const sortColumnByMomentDate = (fieldName, dateFormat) => {
  return {
    sorter: (a, b) => {
      const { aField, bField } = getSorterFieldValues(a, b, fieldName);
      if (!aField) return 1;
      if (!bField) return -1;
      return dateCompare(aField, bField, dateFormat);
    },
  };
};

export const sortColumnByMomentTime = (fieldName, timeFormat = 'hh:mm a') => {
  return {
    sorter: (a, b) => {
      const { aField, bField } = getSorterFieldValues(a, b, fieldName);
      if (!aField) return 1;
      if (!bField) return -1;

      return (
        // Using same date here just to force the calculation to account for time only
        new Date('1970/01/01 ' + momentFormat(aField, timeFormat)) -
        new Date('1970/01/01 ' + momentFormat(bField, timeFormat))
      );
    },
  };
};

export const sortColumnByDateString = fieldName => {
  return {
    sorter: (a, b) => {
      let { aField, bField } = getSorterFieldValues(a, b, fieldName);

      if (aField === null || bField === null) {
        aField = aField || '-';
        bField = bField || '-';
        return aField.toLowerCase().localeCompare(bField.toLowerCase());
      }

      if (!aField) return 1;
      if (!bField) return -1;
      return new Date(aField) - new Date(bField);
    },
  };
};

export const sortColumnByNumber = fieldName => {
  return {
    sorter: (a, b) => {
      const { aField, bField } = getSorterFieldValues(a, b, fieldName);
      if (typeof aField !== 'number' || typeof bField !== 'number') return 1;
      return parseFloat(aField) - parseFloat(bField);
    },
  };
};

export const sortColumnByStatus = fieldName => {
  return {
    sorter: (a, b) => {
      const { aField, bField } = getSorterFieldValues(a, b, fieldName);
      if (!aField) return 1;
      if (!bField) return -1;
      const aStatusLabel = STATUS_LIST.StatusTagColorConfig[aField.toLocaleLowerCase()];
      const bStatusLabel = STATUS_LIST.StatusTagColorConfig[bField.toLocaleLowerCase()];

      if (!aStatusLabel) return 1;
      if (!bStatusLabel) return -1;

      return aStatusLabel.label
        .toLocaleLowerCase()
        .localeCompare(bStatusLabel.label.toLocaleLowerCase());
    },
  };
};

export const renderCheckmarkFromBoolean = (bool = false) => {
  return bool ? (
    <img width="15px" title="Yes" alt="checked" src={IMAGES.CIRCLE_CHECK_MARK_ICON} />
  ) : (
    <img width="15px" title="No" alt="not checked" src={IMAGES.CIRCLE_REMOVE_ICON} />
  );
};

/**
 * Renders a checkmark or an X icon depending on the the field boolean value
 *
 * @param {string} fieldName Column field value
 */
export const renderBooleanCheckmarks = fieldName => {
  return {
    render: record => {
      let isTrue = false;

      if (typeof record[fieldName] === 'string' && record[fieldName].toLowerCase() === 'yes') {
        isTrue = true;
      } else if (typeof record[fieldName] === 'boolean') {
        isTrue = record[fieldName];
      }

      return renderCheckmarkFromBoolean(isTrue);
    },
  };
};

export const sortColumnByUserFullName = field => {
  return {
    sorter: (a, b) => {
      const aFullName = getUserFullName(field ? get(a, field) : a);
      const bFullName = getUserFullName(field ? get(b, field) : b);

      return aFullName.toLowerCase().localeCompare(bFullName.toLowerCase());
    },
  };
};
