import { Col, Form, Row } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { Trans } from 'react-i18next';

import { STATUS_LIST } from '../../../enum';
import { ERROR_MESSAGE } from '../../../utils/constants';
import { Button } from '../..';
import Select from '../../Select';
import SubmitCancelButtonGroup from '../../SubmitCancelButtonGroup';
import Switch from '../../Switch';
import TextInput from '../../TextInput';
import { COUNTRY_LIST } from './country-enums';

const CountryForm = props => {
  const {
    t,
    isUpdate,
    isSubmitting,
    supportedCountries,
    initialValues,
    onSubmit,
    onDelete,
    onCancel,
  } = props;

  const [selectedCountry, setSelectedCountry] = useState();
  const [availableLocales, setAvailableLocales] = useState([]);

  const [form] = Form.useForm();

  const LOWERCASE_COUNTRY_NAMES = useMemo(() => supportedCountries.map(c => c.name.toLowerCase()), [
    supportedCountries,
  ]);

  useEffect(() => {
    if (selectedCountry) {
      const country = COUNTRY_LIST.find(c => c.name === selectedCountry);

      if (country) {
        setAvailableLocales(country.locales);
        form.setFields([
          {
            name: 'code',
            value: country.countryCode,
          },
          {
            name: 'locale',
            value: country.defaultLocale,
          },
          {
            name: 'currencyCode',
            value: country.currencyCode,
          },
          {
            name: 'currencyName',
            value: country.currencyName,
          },
          {
            name: 'fuel',
            value: country.fuel,
          },
          {
            name: 'distanceLong',
            value: country.distanceUnitLong,
          },
          {
            name: 'distanceShort',
            value: country.distanceUnitShort,
          },
          {
            name: 'countryPhoneCode',
            value: country.countryPhoneCode,
          },
        ]);
      }
    }
    // eslint-disable-next-line
  }, [selectedCountry]);

  return (
    <Form
      form={form}
      labelCol={{ span: 24 }}
      onFinish={({ active, ...values }) =>
        onSubmit({
          ...values,
          status: active ? STATUS_LIST.Status.ACTIVE : STATUS_LIST.Status.INACTIVE,
        })
      }
      initialValues={
        initialValues
          ? {
              ...initialValues,
              active: initialValues.status === STATUS_LIST.Status.ACTIVE,
            }
          : { active: true }
      }
    >
      <Row gutter={16}>
        <Col span={7}>
          <Form.Item required name="name" label={t('countryName')}>
            <Select
              showSearch
              rules={[{ required: true, message: ERROR_MESSAGE.BLANK_FIELD }]}
              disabled={isUpdate || isSubmitting}
              onChange={c => setSelectedCountry(c)}
              options={COUNTRY_LIST.map(c => ({
                value: c.name,
                label: c.name,
                disabled: LOWERCASE_COUNTRY_NAMES.includes(c.name.toLowerCase()),
              }))}
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item required name="code" label={t('countryCode')}>
            <TextInput disabled />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item required name="locale" label={t('locale')}>
            <Select
              showSearch
              disabled={isSubmitting}
              options={availableLocales.map(locale => ({
                value: locale,
                label: locale,
              }))}
            />
          </Form.Item>
        </Col>

        <Col span={3}>
          <Form.Item required name="active" label={t('active')}>
            <Switch
              disabled={isSubmitting}
              defaultChecked={
                initialValues ? initialValues.status === STATUS_LIST.Status.ACTIVE : true
              }
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={7}>
          <Form.Item required name="currencyName" label={t('currencyName')}>
            <TextInput disabled />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item required name="currencyCode" label={t('currency')}>
            <TextInput disabled />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item
            name="currencySymbol"
            label={t('currencySymbol')}
            rules={[{ required: true, message: ERROR_MESSAGE.BLANK_FIELD }]}
            extra={
              <Trans
                t={t}
                i18nKey="findHTMLCodeHere"
                components={[
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.toptal.com/designers/htmlarrows/currency/"
                  >
                    DummyText
                  </a>,
                ]}
              />
            }
          >
            <TextInput disabled={isSubmitting} />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={7}>
          <Form.Item required name="distanceLong" label={t('distanceUnitOfMeasure')}>
            <TextInput disabled />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item required name="distanceShort" label={t('distanceAbbreviation')}>
            <TextInput disabled />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={7}>
          <Form.Item
            required
            name="fuel"
            label={t('fuel')}
            rules={[{ required: true, message: ERROR_MESSAGE.BLANK_FIELD }]}
          >
            <TextInput disabled={isSubmitting} />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={7}>
          <Form.Item
            required
            name="countryPhoneCode"
            label={`${t('countryCode')} (${t('phone')})`}
            rules={[{ required: true, message: ERROR_MESSAGE.BLANK_FIELD }]}
          >
            <TextInput prefix="+" step="any" type="number" disabled={isSubmitting} />
          </Form.Item>
        </Col>
      </Row>

      <Row justify="end" gutter={30}>
        {isUpdate && (
          <Col>
            <Button onClick={onDelete}>{t('deleteCountry')}</Button>
          </Col>
        )}
        <Col>
          <SubmitCancelButtonGroup
            isForm
            loading={isSubmitting}
            onCancel={onCancel}
            submitText={isUpdate ? t('update') : t('create')}
          />
        </Col>
      </Row>
    </Form>
  );
};

export default CountryForm;
