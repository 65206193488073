import { Col, Row } from 'antd';
import moment from 'moment-timezone';
import React from 'react';
import { Trans } from 'react-i18next';

import Button from '../../../../components/Button';
import HelpIcon from '../../../../components/HelpIcon';
import RowButton from '../../../../components/RowButton';
import StatusTag from '../../../../components/Tag/StatusTag';
import Text from '../../../../components/Text';
import FadedText from '../../../../components/Text/FadedText';
import LinkText from '../../../../components/Text/LinkText';
import { IMAGES, STATUS_LIST } from '../../../../enum';
import { formatBytes, momentFormat, momentTimezone } from '../../../../utils/common';

const DownloadRowButton = props => {
  const {
    t,
    request,
    isAdmin,
    loggedInUserID,
    downloadingRequestID,
    onDownload,
    onViewUsers,
  } = props;

  const displayDynamicUserNameList = () => {
    if (request.status === STATUS_LIST.Status.PENDING) {
      if (request.userIds.length <= 3) {
        return (
          <Text size="sm" variant="strong">
            (
            {t('anonymize_user_list', {
              count: request.userIds.length,
              names: request.userIds.map(r => r.name).join(', '),
            })}
            )
          </Text>
        );
      }

      if (typeof onViewUsers !== 'function') return null;

      return (
        <LinkText size="sm" onClick={() => onViewUsers(request.userIds)}>
          {t('viewUsers')}
        </LinkText>
      );
    }

    const additionalInfo = [];
    if (request.requestSubtype !== 'export') {
      additionalInfo.push(t('users_count', { count: request.userIds.length }));
    }

    if (request.status !== STATUS_LIST.Status.GENERATING_FILE) {
      additionalInfo.push(formatBytes(request.fileSize));
    }

    return (
      <Text size="sm" variant="strong">
        <span style={{ whiteSpace: 'nowrap' }}>
          {!!additionalInfo.length && `(${additionalInfo.join(', ')})`}
        </span>{' '}
        {typeof onViewUsers === 'function' && (
          <LinkText
            size="sm"
            style={{ whiteSpace: 'nowrap' }}
            onClick={() => onViewUsers(request.userIds)}
          >
            {t('viewUsers')}
          </LinkText>
        )}
      </Text>
    );
  };

  const downloadRowTitle = () => {
    if (request.requestSubtype === 'export') {
      if (/unsubmitted_trips/gi.test(request.filename)) return t('exportedUnsubmittedTripsData');
      if (/trips/gi.test(request.filename)) return t('exportedTripsData');
      if (/receipt/gi.test(request.filename)) return t('exportedReceiptsData');
      if (/reimbursement/gi.test(request.filename)) return t('exportedReimbursementsData');
      if (/tax_report/gi.test(request.filename)) {
        const year = request.filename.split('_')[2] || 'N/A';
        return t('exportedTaxReportsData__year', { year });
      }
      return 'Exported data';
    }

    if (isAdmin) return t('downloadUserData');
    return t('downloadMyData');
  };

  return (
    <RowButton
      key={request._id}
      clickable={false}
      icon={IMAGES.CSV_FILE_ICON}
      content={
        <>
          {isAdmin ? (
            <>
              <Text variant="strong" size="sm">
                {downloadRowTitle()} {displayDynamicUserNameList(request)}
              </Text>
              {request?.downloadOptions?.fromDate && request?.downloadOptions?.toDate && (
                <Text size="sm">
                  <span style={{ whiteSpace: 'nowrap' }}>
                    {momentTimezone(
                      new Date(request.downloadOptions.fromDate),
                      'DD MMM, YYYY',
                    ).format('MMM Do, YYYY')}
                  </span>
                  {' - '}
                  <span style={{ whiteSpace: 'nowrap' }}>
                    {momentTimezone(
                      new Date(request.downloadOptions.toDate),
                      'DD MMM, YYYY',
                    ).format('MMM Do, YYYY zz')}
                  </span>
                </Text>
              )}
            </>
          ) : (
            <Text variant="strong" size="sm">
              {downloadRowTitle()}{' '}
              {request.status !== STATUS_LIST.Status.GENERATING_FILE &&
                `(${formatBytes(request.fileSize)})`}
            </Text>
          )}

          <Text size="sm">
            {[request.downloadOptions.profile ? 'Profile' : undefined].filter(Boolean).join(', ')}
          </Text>

          <FadedText size="sm">
            <Trans
              t={t}
              i18nKey={isAdmin ? 'requestOnDateBy' : 'requestOnDate'}
              values={{
                date: momentFormat(new Date(request.created), 'LL'),
                time: momentFormat(new Date(request.created), 'hh:mm A zz'),
                name: request.requestingUser.name,
              }}
              components={[<span style={{ whiteSpace: 'nowrap' }}>DummyText</span>]}
            />
          </FadedText>
        </>
      }
      sideContent={
        <Row justify="start" align="middle" gutter={17}>
          {request.status === STATUS_LIST.Status.GENERATING_FILE && (
            <Col>
              <Row gutter={5} style={{ flexWrap: 'nowrap' }}>
                <Col>
                  <StatusTag status={request.status} />
                </Col>
                {request.requestSubtype !== 'export' && (
                  <Col>
                    <HelpIcon
                      hint={<Text style={{ width: 250 }}>{t('userDataExportBeingGenerated')}</Text>}
                    />
                  </Col>
                )}
              </Row>
            </Col>
          )}

          {loggedInUserID === request.requestingUser._id &&
            request.status !== STATUS_LIST.Status.GENERATING_FILE && (
              <Col>
                <Row justify="end">
                  <Button
                    size="sm"
                    type="primary"
                    disabled={
                      !!downloadingRequestID || moment().isAfter(new Date(request.deleteDate))
                    }
                    loading={request._id === downloadingRequestID}
                    onClick={() => onDownload(request._id, request)}
                  >
                    {t('download')}
                  </Button>
                </Row>
                <FadedText size="sm" textAlign="right" style={{ marginTop: 5 }}>
                  <Trans
                    i18nKey="fileAvailableUntil"
                    values={{
                      date: momentFormat(request.deleteDate, 'LL'),
                    }}
                    components={[<span style={{ whiteSpace: 'nowrap' }}>DummyText</span>]}
                  />
                </FadedText>
              </Col>
            )}
        </Row>
      }
    />
  );
};

export default DownloadRowButton;
