import { Row } from 'antd';
import React from 'react';

import Box from '../../../../components/Box';
import AnimatedCheckmark from '../../../../components/Lottie/AnimatedCheckmark';
import Text from '../../../../components/Text';
import className from './styles.module.scss';

const HomeAddressSetSuccessBox = props => {
  const { t } = props;

  return (
    <Box className={className.card}>
      <Row justify="center">
        <AnimatedCheckmark width="60px" height="60px" />
      </Row>

      <br />

      <Text textAlign="center" variant="h4">
        {t('Home Address Set')}
      </Text>

      <Text textAlign="center">
        {t('To view / edit your home address, access your profile (upper right). ')}
      </Text>
    </Box>
  );
};

export default HomeAddressSetSuccessBox;
