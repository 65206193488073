import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import ChainedBackend from 'i18next-chained-backend';
import HttpBackend from 'i18next-http-backend';
import LocalStorageBackend from 'i18next-localstorage-backend';
import { reactI18nextModule } from 'react-i18next';

import { getEnvironmentName } from './utils/window';

const supportedLanguages = ['en'];

const isDebugMode = getEnvironmentName() === 'local_dev';
const isProd = getEnvironmentName() === 'prod';

i18n
  .use(ChainedBackend)
  .use(detector)
  .use(reactI18nextModule) // passes i18n down to react-i18next
  .init({
    lng: supportedLanguages[0],
    fallbackLng: supportedLanguages[0],
    ns: ['default'],
    defaultNS: 'default',
    whitelist: supportedLanguages,

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safe from xss
    },
    backend: {
      backends: [LocalStorageBackend, HttpBackend],
      backendOptions: [
        {
          prefix: `trans_${process.env.REACT_APP_VERSION}_`,
          defaultVersion: process.env.REACT_APP_VERSION,
          expirationTime: !isProd ? 0 : 24 * 60 * 60 * 1000 * 7, // 7 days
        },
        {
          loadPath: `/locales/{{lng}}/{{ns}}.json`,
          queryStringParams: {
            version: process.env.REACT_APP_VERSION,
          },
        },
      ],
      debug: isDebugMode,
    },
    react: {
      wait: true,
      useSuspense: false,
    },
  });

i18n.on('languageChanged', () => {
  document.documentElement.lang = i18n.language;
});

export default i18n;
