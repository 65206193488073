import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import _ from 'lodash';
import { Button } from 'components';

import './style.scss';

class CompanyPolicyConfView extends React.Component {
  constructor(props) {
    super(props);

    const { creating } = this.props.policy;
    let data = [];
    if (creating && creating.data && creating.data.length) {
      data = creating.data;
    }

    this.state = {
      selectedFile: data,
    };
  }

  componentDidMount() {
    this.props.onChange(this.state.fileList);
  }

  onBrowser = () => {
    if (this.fileInput) {
      this.fileInput.click();
    }
  };

  onFileChange = event => {
    const files = event.target.files;
    if (this.maxSelectFile(event)) {
      this.setState(
        state => {
          state.selectedFile = files;
          return state;
        },
        () => {
          this.props.onChange(this.state.selectedFile);
        },
      );
    }
  };

  maxSelectFile = event => {
    let files = event.target.files; // create file object
    if (files.length > 10) {
      const msg = 'Only 10 files can be uploaded at a time';
      event.target.value = null; // discard selected file
      console.log(msg);
      return false;
    }
    return true;
  };

  fileRemove = index => {
    if (this.state.selectedFile) {
      this.setState(
        state => {
          let newFiles = {};
          let newIndex = 0;
          Object.keys(state.selectedFile).forEach(key => {
            if (key !== index) {
              newFiles[newIndex] = state.selectedFile[key];
              newIndex++;
            }
          });
          state.selectedFile = newFiles;
          return state;
        },
        () => {
          this.props.onChange(this.state.selectedFile);
        },
      );
    }
  };

  render() {
    return (
      <div className="cpv">
        <h6 className="cpv-title">
          {
            'Upload a PDF of your company policy. Users will need to review and accept the company policy.'
          }
        </h6>
        <div className="cpv-upload">
          <h6 className="ge-label">Upload file(s):</h6>
          <h6 className="ge-label">{!!this.state.selectedFile}</h6>
          {!this.props.readOnly && (
            <input
              className="cpv-upload-input"
              type="file"
              multiple
              onChange={this.onFileChange}
              ref={fileInput => (this.fileInput = fileInput)}
            />
          )}
          {!this.props.readOnly && (
            <Button icon="fa fa-upload" text="Browser" onClick={this.onBrowser} />
          )}
          {this.state.selectedFile &&
            Object.values(this.state.selectedFile).map((file, index) => (
              <div key={index} className="file-item">
                {!this.props.readOnly && (
                  <i
                    className="fa fa-times file-item-icon"
                    onClick={() => this.fileRemove(index)}
                  />
                )}
                {this.props.readOnly && <i className="fa fa-file file-item-icon" />}
                <span className="ge-label">{file.name}</span>
              </div>
            ))}
          {(!this.state.selectedFile || _.isEmpty(this.state.selectedFile)) && (
            <span className="ge-label mt-2">Choose the Policy Document</span>
          )}
        </div>
      </div>
    );
  }
}

CompanyPolicyConfView.propTypes = {
  data: PropTypes.array,
  readOnly: PropTypes.bool,
  onChange: PropTypes.func,
};

CompanyPolicyConfView.defaultProps = {
  data: [],
  readOnly: false,
  onChange: () => {},
};

const mapStateToProps = (state, ownProps) => {
  return {
    policy: state.policy,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withNamespaces()(CompanyPolicyConfView));
