import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';

import { INTERNAL_LINKS, STATUS_LIST } from '../../../enum';
import useDebouncedState from '../../../hooks/useDebouncedState';
import { checkIfStringContainsValue, formatDate, momentFormat } from '../../../utils/common';
import { formatCurrencyToCompanyLocale, formatNumberToLocale } from '../../../utils/numbers';
import {
  sortColumnByNumber,
  sortColumnByStatus,
  sortColumnByStringField,
} from '../../../utils/tables';
import { CustomTable } from '../..';
import StatusTag from '../../Tag/StatusTag';
import LinkText from '../../Text/LinkText';

const formatBillingPeriod = invoice =>
  [
    momentFormat(new Date(invoice.periodStart * 1000), 'MMM DD'),
    momentFormat(new Date(invoice.periodEnd * 1000), 'MMM DD, YYYY'),
  ].join(' - ');

const formatInvoiceDate = date => (date ? formatDate(new Date(date * 1000), 'MMM DD, YYYY') : '-');

const InvoiceTable = props => {
  const { t, dataSource, selectedYear, ...rest } = props;

  const [searchTerm, setSearchTerm] = useDebouncedState();

  const filteredDataSource = useMemo(() => {
    let array = [...dataSource];

    if (searchTerm) {
      array = array.filter(invoice => {
        return (
          checkIfStringContainsValue(invoice.number, searchTerm) ||
          checkIfStringContainsValue(formatBillingPeriod(invoice), searchTerm) ||
          checkIfStringContainsValue(formatInvoiceDate(invoice.created), searchTerm) ||
          checkIfStringContainsValue(formatInvoiceDate(invoice.dueDate), searchTerm) ||
          checkIfStringContainsValue(formatCurrencyToCompanyLocale(invoice.total), searchTerm) ||
          checkIfStringContainsValue(
            STATUS_LIST.StatusTagColorConfig[invoice.status].label,
            searchTerm,
          )
        );
      });
    }

    return array;
  }, [dataSource, searchTerm]);

  const COLUMNS = useMemo(
    () => [
      {
        title: t('billingPeriod'),
        key: 'periodStart',
        ...sortColumnByNumber('periodStart'),
        render: (_, data) => formatBillingPeriod(data),
      },
      {
        title: t('invoiceDate'),
        dataIndex: 'created',
        key: 'created',
        ...sortColumnByNumber('created'),
        render: created => formatInvoiceDate(created),
      },
      {
        title: t('dueDate'),
        dataIndex: 'dueDate',
        key: 'dueDate',
        ...sortColumnByNumber('dueDate'),
        render: dueDate => formatInvoiceDate(dueDate),
      },
      {
        title: t('invoiceNumber'),
        dataIndex: 'number',
        key: 'number',
        ...sortColumnByStringField('number'),
        render: (number, data) => (
          <a href={data.hostedInvoiceUrl} rel="noopener noreferrer" target="_blank">
            <LinkText>{number}</LinkText>
          </a>
        ),
      },
      {
        title: t('Amount'),
        dataIndex: 'total',
        key: 'total',
        ...sortColumnByNumber('total'),
        render: total => formatCurrencyToCompanyLocale(total),
      },
      {
        title: t('Users'),
        dataIndex: 'numberOfUsers',
        key: 'numberOfUsers',
        ...sortColumnByNumber('numberOfUsers'),
        render: (numberOfUsers, data) => (
          <Link
            to={
              data.billingInformationId
                ? INTERNAL_LINKS.INVOICE_BILLED_USERS(data.billingInformationId, selectedYear)
                : '#'
            }
          >
            <LinkText>{formatNumberToLocale(numberOfUsers, 0)}</LinkText>
          </Link>
        ),
      },
      {
        title: t('status'),
        dataIndex: 'status',
        key: 'status',
        ...sortColumnByStatus('status'),
        render: status => <StatusTag status={status} />,
      },
    ],
    // eslint-disable-next-line
    [selectedYear],
  );

  return (
    <CustomTable
      // Search props
      onSearchTermChange={setSearchTerm}
      // Table props
      rowKey={data => data.created}
      {...rest}
      dataSource={filteredDataSource}
      columns={COLUMNS}
    />
  );
};

export default InvoiceTable;
