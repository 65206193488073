import moment from 'moment-timezone';

import { checkEmailAvailability } from '../api/auth';
import { EMAIL_SPECIAL_CHAR_VALIDATION_REGEXP, isValidPassword } from './validate';

const NAME_MAX_CHARACTER = 30;
const ADDRESS_MAX_CHARACTER = 30;

const ERROR_MESSAGE = {
  NUMBER_BETWEEN: (min, max) => `Must be a number between ${min} and ${max}`,
  MIN_NUMBER: minNumber => `Must be higher or equal to ${minNumber}`,
  MAX_NUMBER: maxNumber => `Must be lower or equal to ${maxNumber}`,
  STRING_MIN_LENGTH: minNumber => `Field must have at least ${minNumber} characters`,
  STRING_MAX_LENGTH: maxNumber => `Field cannot be longer than ${maxNumber} characters`,
  CONFIRM_EMAIL: 'A problem was encountered while resending email confirmation link.',
  UPDATE_COMPANY: 'A problem was encountered while updating your company information.',
  BLANK_FIELD: "This field can't be blank",
  NUMBER_FIELD: 'This field must have a valid number',
  VALID_EMAIL: 'Please enter a valid email address.',
  EMAIL_INVALID_CHARACTERS: 'This email contains invalid characters',
  INVALID_PASSWORD:
    'Your password needs to be at least 8 characters, contain at least one: upper case letter, special character, and number.',
  EMAIL_ALREADY_USED: 'Email used by another user account.',
  MUST_ACCEPT_TOS: 'You must accept our terms of service and privacy policy',
  MUST_BE_X_YEARS_OLD: age => `Must be at least ${age} years old`,
  INVALID_GPS_COORDINATES: 'Can’t find GPS coordinate',
  ONLY_NUMBERS_ALLOWED: 'Only numbers are allowed.',
  BLOCKED_EMAIL_DOMAIN:
    'The domain name of your email address is not allowed. Please use a different email address to register with Kliks.',
  MAX_PASSWORD_LENGTH: `Password is limited to ${NAME_MAX_CHARACTER} characters`,
  PASSWORDS_DONT_MATCH: 'The passwords do not match.',
  TEXT_MATCH_DELETE_COMPANY: 'Text must match DELETE COMPANY',
};

const EMAIL_UPDATE_FORM_RULES = [
  {
    validateTrigger: ['onChange', 'onBlur'],
    type: 'email',
    message: ERROR_MESSAGE.VALID_EMAIL,
  },
  {
    validateTrigger: ['onChange', 'onBlur'],
    required: true,
    message: 'Please enter an email address.',
  },
];

export const EMPLOYEE_NUMBER_FORM_RULES = [
  {
    required: true,
    message: ERROR_MESSAGE.BLANK_FIELD,
  },
  {
    validateTrigger: 'onSubmit',
    type: 'string',
    min: 4,
    message: ERROR_MESSAGE.STRING_MIN_LENGTH(4),
  },
  {
    validateTrigger: 'onSubmit',
    type: 'string',
    max: 255,
    message: ERROR_MESSAGE.STRING_MAX_LENGTH(255),
  },
];

const EMAIL_VALIDATION_FORM_RULES = [
  ...EMAIL_UPDATE_FORM_RULES,
  {
    validateTrigger: 'onSubmit',
    validator: async (rules, email = '', callback) => {
      if (!email) return;
      try {
        if (
          /@{2,}/.test(email) ||
          // eslint-disable-next-line no-useless-escape
          EMAIL_SPECIAL_CHAR_VALIDATION_REGEXP.test(email.split('@')[0])
        ) {
          return Promise.reject(ERROR_MESSAGE.EMAIL_INVALID_CHARACTERS);
        }

        const isAvailable = await checkEmailAvailability(email);

        if (isAvailable) {
          return Promise.resolve(email);
        } else {
          return Promise.reject(ERROR_MESSAGE.EMAIL_ALREADY_USED);
        }
      } catch (error) {
        return Promise.reject('Error while validating email.');
      }
    },
  },
];

const FORMAT_FORM_RULE = formatRegExp => [
  {
    validateTrigger: ['onBlur', 'onSubmit'],
    validator: (_, value) => {
      if (!value) return Promise.resolve();
      if (!formatRegExp.test(value)) {
        return Promise.reject('Invalid format');
      }

      return Promise.resolve();
    },
  },
];

const DATE_OF_BIRTH_FORM_RULE = minAge => [
  {
    validator: (_, value) => {
      if (!value) return Promise.resolve();

      if (moment().diff(value, 'years') < minAge) {
        return Promise.reject(ERROR_MESSAGE.MUST_BE_X_YEARS_OLD(minAge));
      }

      return Promise.resolve();
    },
  },
];

const NEW_PASSWORD_FORM_RULE = [
  { required: true, message: ERROR_MESSAGE.BLANK_FIELD },
  () => ({
    validator(rule, value) {
      if (!value) return Promise.resolve();
      if (isValidPassword(value) > 0) {
        return Promise.reject(ERROR_MESSAGE.INVALID_PASSWORD);
      }
      if (value.length > 30) {
        return Promise.reject(ERROR_MESSAGE.MAX_PASSWORD_LENGTH);
      }
      return Promise.resolve();
    },
  }),
];

const CONFIRM_PASSWORD_FORM_RULE = formInstance => [
  { required: true, message: ERROR_MESSAGE.BLANK_FIELD },
  () => ({
    validator(rule, value) {
      if (value !== formInstance.getFieldValue('password')) {
        return Promise.reject(ERROR_MESSAGE.PASSWORDS_DONT_MATCH);
      }
      return Promise.resolve();
    },
    validateTrigger: 'onBlur',
  }),
];

const MIN_NUMBER_FORM_RULE = (num = 0) => [
  () => ({
    validator: (_, value) => {
      if (!value) return Promise.resolve();

      if (value < num) {
        return Promise.reject(ERROR_MESSAGE.MIN_NUMBER(num));
      }

      return Promise.resolve();
    },
  }),
];

export {
  ADDRESS_MAX_CHARACTER,
  CONFIRM_PASSWORD_FORM_RULE,
  DATE_OF_BIRTH_FORM_RULE,
  EMAIL_UPDATE_FORM_RULES,
  EMAIL_VALIDATION_FORM_RULES,
  ERROR_MESSAGE,
  FORMAT_FORM_RULE,
  MIN_NUMBER_FORM_RULE,
  NAME_MAX_CHARACTER,
  NEW_PASSWORD_FORM_RULE,
};
