import { Col, Form, Row } from 'antd';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';

import { ERROR_MESSAGE } from '../../../utils/constants';
import { CustomCheckbox, Modal } from '../..';
import FormItem from '../../Form/FormItem';
import SubmitCancelButtonGroup from '../../SubmitCancelButtonGroup';
import Switch from '../../Switch';
import Text from '../../Text';
import TimePicker from '../../TimePicker';
import Toast from '../../Toast';

const TripRecordingDayEditModal = props => {
  const WINDOW_WIDTH = window.innerWidth - 50;
  const MODAL_WIDTH = WINDOW_WIDTH > 400 ? 400 : WINDOW_WIDTH;

  const [form] = Form.useForm();

  const { t, day, visible, onOk, onCancel, isSubmitting, initialValues } = props;

  const [is24HourSelected, setIs24HourSelected] = useState(initialValues?.is24HourSelected);
  const [startTime, setStartTime] = useState(initialValues?.startTime);
  const [endTime, setEndTime] = useState(initialValues?.endTime);

  const handleSubmit = async v => {
    if (!startTime) {
      Toast({
        type: 'error',
        message: t('startTime'),
        description: ERROR_MESSAGE.BLANK_FIELD,
      });
    } else if (!endTime) {
      Toast({
        type: 'error',
        message: t('endTime'),
        description: ERROR_MESSAGE.BLANK_FIELD,
      });
    } else {
      onOk({
        ...v,
        canEdit: true,
        is24HourSelected,
        startTime: startTime.format('HHmm'),
        endTime: endTime.format('HHmm'),
      });
    }
  };

  useEffect(() => {
    if (visible) {
      setStartTime(initialValues?.startTime);
      setEndTime(initialValues?.endTime);
      setIs24HourSelected(initialValues?.is24HourSelected);
    }
    // eslint-disable-next-line
  }, [visible]);

  return (
    <Modal
      width={MODAL_WIDTH}
      centered
      destroyOnClose
      className="trip-update-modal"
      title={day}
      visible={visible}
      onCancel={onCancel}
      footer={null}
    >
      <Form
        key={day}
        form={form}
        onFinish={handleSubmit}
        initialValues={{
          ...initialValues,
          allDaysWithSameData: false,
        }}
      >
        <FormItem>
          <Row gutter={16} wrap={false} align="middle">
            <Col>
              <Text>{t('enableFor24Hours')}</Text>
            </Col>
            <Col>
              <FormItem
                name="is24HourSelected"
                valuePropName="checked"
                className="no-margin-bottom"
              >
                <Switch
                  disabled={isSubmitting}
                  onChange={checked => {
                    setIs24HourSelected(checked);
                    if (checked) {
                      form.setFieldsValue({
                        is24HourSelected: true,
                        startTime: moment().set('hours', 24).set('minutes', 0),
                        endTime: moment().set('hours', 23).set('minutes', 59),
                      });

                      setStartTime(moment().set('hours', 24).set('minutes', 0));
                      setEndTime(moment().set('hours', 23).set('minutes', 59));
                    } else {
                      form.setFieldsValue({ is24HourSelected: true });
                    }
                  }}
                />
              </FormItem>
            </Col>
          </Row>
        </FormItem>

        <FormItem label={t('startTime')} key={is24HourSelected ? '24-hour-start' : '12-hour-start'}>
          <TimePicker
            value={startTime}
            disabled={isSubmitting || is24HourSelected}
            onChange={time => setStartTime(time.clone())}
          />
        </FormItem>

        <FormItem label={t('endTime')} key={is24HourSelected ? '24-hour-end' : '12-hour-end'}>
          <TimePicker
            value={endTime}
            disabled={isSubmitting || is24HourSelected}
            onChange={time => setEndTime(time.clone())}
          />
        </FormItem>

        <FormItem name="allDaysWithSameData" valuePropName="checked">
          <CustomCheckbox disabled={isSubmitting}>
            {t('updateAllDaysWithTheSameDate')}
          </CustomCheckbox>
        </FormItem>

        <SubmitCancelButtonGroup
          isForm
          loading={isSubmitting}
          disabled={isSubmitting}
          onCancel={onCancel}
          submitText={t('Save')}
        />
      </Form>
    </Modal>
  );
};

export default TripRecordingDayEditModal;
