import { Col, Row } from 'antd';
import { get } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Trans, withNamespaces } from 'react-i18next';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';

import { PAYMENT_SCHEDULES_API } from '../../api/paymentSchedule';
import Alert from '../../components/Alert';
import Box from '../../components/Box';
import Button from '../../components/Button';
import AchAddFundsModal from '../../components/Modal/AchAddFundsModal';
import GenericModal from '../../components/Modal/GenericModal';
import UsersWithoutBankAccountsModal from '../../components/Modal/UsersWithoutBankAccountsModal';
import SpaceSpinner from '../../components/SpaceSpinner';
import AccountReimbursementHistoryTable from '../../components/Table/AccountReimbursementHistoryTable';
import Text from '../../components/Text';
import LinkText from '../../components/Text/LinkText';
import TextWithExternalLink from '../../components/TextWithExternalLink';
import PageContainer from '../../containers/PageContainer';
import { EXTERNAL_LINKS, INTERNAL_LINKS } from '../../enum';
import { MONTHLY_SCHEDULE_TYPE } from '../../enum/ScheduleTypes';
import usePaginatedDwollaResourcesQuery from '../../hooks/queries/usePaginatedDwollaResourcesQuery';
import useDwollaAccountBalance from '../../hooks/useDwollaAccountBalance';
import useDwollaCompanyInfo from '../../hooks/useDwollaCompanyInfo';
import useModal from '../../hooks/useModal';
import usePaymentSchedules from '../../hooks/usePaymentSchedules';
import {
  formatPageTitle,
  momentCompanyTimezone,
  momentTimezone,
  setModalFullscreenWidth,
} from '../../utils/common';
import HolidaysSection from '../userdetails/ach-bank-info/HolidaysSection';
import AccountBalanceSummaryBox from './components/AccountBalanceSummaryBox';
import ReimbursementBalanceTimeline from './components/ReimbursementBalanceTimeline';

const PageHelmet = () => (
  <Helmet>
    <title>{formatPageTitle('Account Balance')}</title>
  </Helmet>
);

const AccountBalancePage = props => {
  const { t } = props;

  const [isAddFundsModalVisible, openAddFundsModal, closeAddFundsModal] = useModal();
  const [currentPaymentSchedule, setCurrentPaymentSchedule] = useState();
  const [isHolidayModalVisible, openHolidayModal, closeHolidayModal] = useModal();
  const [
    isUsersWithoutBankModalVisible,
    openUsersWithoutBankModal,
    closeUsersWithoutBankModal,
    usersWithoutBankModalProps,
  ] = useModal();

  const {
    isFetchingPaymentSchedules,
    paymentSchedules,
    paymentScheduleOptions,
  } = usePaymentSchedules(undefined, undefined, true);
  const { companyOtherFundingSource } = useDwollaCompanyInfo();

  const {
    isLoadingAccountBalance,
    pendingAccountBalance,
    dwollaAccountBalance,
    addAmountToBalance,
  } = useDwollaAccountBalance(t, { onAddAmountSuccess: closeAddFundsModal });

  const { isFetching: isLoadingPaymentScheduleBalance, data: paymentScheduleBalances } = useQuery({
    enabled: !!currentPaymentSchedule?._id,
    staleTime: 1000 * 60 * 5, // 5 minutes
    queryKey: ['payment-schedules', 'balance', currentPaymentSchedule?._id],
    queryFn: () => PAYMENT_SCHEDULES_API.getPaymentScheduleBalance(currentPaymentSchedule?._id),
  });

  const [currentPeriod, paymentDate, nextPaymentDate] = useMemo(() => {
    let achPaymentDate;
    let nextAchPaymentDate;
    const currentPaymentPeriod = get(currentPaymentSchedule, 'currentPeriod');
    const previousPaymentPeriod = get(currentPaymentSchedule, 'previousPeriod');
    const previousPeriodPaymentDate = get(previousPaymentPeriod, 'achPaymentDate');

    if (momentTimezone().isBefore(momentTimezone(previousPeriodPaymentDate))) {
      achPaymentDate = previousPeriodPaymentDate;
      nextAchPaymentDate = get(currentPaymentPeriod, 'achPaymentDate');
    } else {
      achPaymentDate = get(currentPaymentPeriod, 'achPaymentDate');
    }

    return [currentPaymentPeriod, achPaymentDate, nextAchPaymentDate];
  }, [currentPaymentSchedule]);

  useEffect(() => {
    if (!!paymentSchedules.length && !currentPaymentSchedule) {
      setCurrentPaymentSchedule(paymentSchedules[0]);
    }
  }, [paymentSchedules, currentPaymentSchedule]);

  const {
    paginationConfig,
    handlePageChange,
    handlePageSizeChange,
    query: { isFetching: isLoadingReimbursementTransactions, data: reimbursementTransactions },
  } = usePaginatedDwollaResourcesQuery(['mass-payment']);

  if (isFetchingPaymentSchedules || isLoadingAccountBalance || isLoadingPaymentScheduleBalance) {
    return (
      <>
        <PageHelmet />
        <SpaceSpinner />
      </>
    );
  }

  return (
    <PageContainer
      title={t('yourKliksAccountBalance')}
      sideActionComponent={
        <Row gutter={[16, 16]}>
          <Col flex={1}>
            <Link to={INTERNAL_LINKS.MANAGE_ACCOUNT_BALANCE}>
              <Button>{t('manageAccountBalance')}</Button>
            </Link>
          </Col>
        </Row>
      }
    >
      <PageHelmet />

      <Row gutter={[16, 16]} justify="space-between">
        <Col>
          <AccountBalanceSummaryBox
            t={t}
            isLoading={isLoadingAccountBalance}
            pendingBalanceAmount={pendingAccountBalance}
            balanceAmount={dwollaAccountBalance?.balance?.value}
            paymentDate={paymentDate}
            nextPaymentDate={nextPaymentDate}
            pendingReimbursementsWithBank={paymentScheduleBalances?.sumOfUsersWithBankAccount}
            pendingReimbursementsWithoutBank={paymentScheduleBalances?.sumOfUsersWithoutBankAccount}
            paymentScheduleOptions={paymentScheduleOptions}
            currentPaymentScheduleId={currentPaymentSchedule?._id}
            onPaymentScheduleChange={id =>
              setCurrentPaymentSchedule(paymentSchedules.find(schedule => schedule._id === id))
            }
          />
        </Col>

        <Col>
          <Alert
            type="info"
            closable={false}
            title={
              <LinkText variant="b" onClick={openHolidayModal}>
                {t('howBankHolidaysImpactACHPayments')}
              </LinkText>
            }
            message={
              <TextWithExternalLink
                text={t('usBankHolidays')}
                textProps={{
                  variant: 'b',
                  size: 'm',
                  renderAs: 'a',
                  color: 'primary',
                  rel: 'noopener noreferrer',
                  target: '_blank',
                  href: EXTERNAL_LINKS.US_HOLIDAYS,
                }}
                linkProps={{
                  href: EXTERNAL_LINKS.US_HOLIDAYS,
                }}
              />
            }
          />
        </Col>
      </Row>

      {currentPaymentSchedule?.scheduleType === MONTHLY_SCHEDULE_TYPE && (
        <>
          <br />

          <Row>
            <Col flex={1}>
              <Box padding="0px">
                <ReimbursementBalanceTimeline
                  t={t}
                  onAddFunds={openAddFundsModal}
                  timeline={[
                    {
                      type: 'balance',
                      finalBalance:
                        (dwollaAccountBalance?.balance?.value || 0) -
                        (paymentScheduleBalances?.sumOfUsersWithBankAccount || 0),
                      date: momentCompanyTimezone(currentPeriod?.paymentPeriodStart).toDate(),
                    },
                    {
                      type: 'deadline',
                      date: momentCompanyTimezone(currentPeriod?.achFundingDeadlineDate).toDate(),
                    },
                    {
                      type: 'payment-date',
                      date: momentCompanyTimezone(currentPeriod?.achPaymentDate).toDate(),
                    },
                  ]}
                />
              </Box>
            </Col>
          </Row>

          <br />

          <Text>
            <Trans
              t={t}
              i18nKey="achTransfersNotice_withLink"
              components={[
                <Link to={INTERNAL_LINKS.ACH_TRANSFERS_HOW_IT_WORKS}>
                  <LinkText>DummyText</LinkText>
                </Link>,
              ]}
            />
          </Text>
        </>
      )}

      <br />
      <br />

      <Row>
        <Text variant="h4">{t('reimbursementPeriodsPaidACH')}</Text>

        <AccountReimbursementHistoryTable
          t={t}
          loading={isLoadingReimbursementTransactions}
          onTotalPaidInfoClick={openUsersWithoutBankModal}
          dataSource={reimbursementTransactions.documents}
          onChange={({ current }) => handlePageChange(current)}
          pagination={{
            pageSize: paginationConfig.pageSize,
            total: paginationConfig.total,
            current: paginationConfig.current,
            onShowSizeChange: handlePageSizeChange,
          }}
        />
      </Row>

      <AchAddFundsModal
        t={t}
        visible={isAddFundsModalVisible}
        isSubmitting={addAmountToBalance.isLoading}
        currentBalance={dwollaAccountBalance?.balance?.value}
        onCancel={closeAddFundsModal}
        onSubmit={({ amount }) =>
          addAmountToBalance.mutate({
            fundingSourceId: companyOtherFundingSource.fundingSourceId,
            amount,
          })
        }
      />

      <GenericModal
        visible={isHolidayModalVisible}
        onCancel={closeHolidayModal}
        width={setModalFullscreenWidth(900)}
        footer={null}
      >
        <HolidaysSection t={t} />
      </GenericModal>

      <UsersWithoutBankAccountsModal
        t={t}
        visible={isUsersWithoutBankModalVisible}
        onCancel={closeUsersWithoutBankModal}
        {...usersWithoutBankModalProps}
      />
    </PageContainer>
  );
};

export default withNamespaces(['default', 'dwolla'])(AccountBalancePage);
