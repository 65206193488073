import { STATUS_LIST, USER_ROLES } from '../../enum';
import { hasCompanyManagerOrAdminRole } from '../../utils/roles';
import {
  selectStoreCompanySettings,
  selectStoreCurrentCompany,
  selectStoreLoggedInUser,
} from '../../utils/storeSelectors';

export const canSubmitForApproval = userData => {
  if (!userData) return false;

  const company = selectStoreCurrentCompany();

  if (!company) return false;
  if (
    company.companySettingId &&
    company.companySettingId.settings &&
    !company.companySettingId.settings.createReceipt
  )
    return false;

  return [USER_ROLES.COMPANY_MANAGER, USER_ROLES.USER].includes(userData.profile.role);
};

export const canCreateTripManually = userData => {
  if (!userData) return false;

  return [USER_ROLES.COMPANY_MANAGER, USER_ROLES.USER, USER_ROLES.COMPANY_ADMIN].includes(
    userData.profile.role,
  );
};

export const canUploadTripViaCSV = userData => {
  if (!userData) return false;

  const settings = selectStoreCompanySettings();
  if (!settings) return false;

  return !!settings.importTripsViaCsv;
};

export const canUpdateTripsStatus = (userData, receipt) => {
  if (receipt && [STATUS_LIST.Status.APPROVED, STATUS_LIST.Status.PAID].includes(receipt.status)) {
    return false;
  }

  return hasCompanyManagerOrAdminRole(userData);
};

export const canDeleteTrip = trip => {
  const loggedInUser = selectStoreLoggedInUser();
  if (!loggedInUser) return false;
  if (!trip) return false;
  if (!trip.driverID) return false;

  return trip.driverID === loggedInUser._id;
};

export const canAddCommentOnTrip = trip => {
  if (!trip) return false;

  return [STATUS_LIST.Status.MORE_INFO].includes(trip.status);
};
