import { Col, Row, Spin } from 'antd';
import { isNull, isUndefined, uniqBy } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import useInfiniteAllCompanyUsersQuery from '../../hooks/queries/useInfiniteAllCompanyUsersQuery';
import useDebouncedState from '../../hooks/useDebouncedState';
import { checkIfElementIsScrolledToBottom } from '../../utils/common';
import { StorageUtils } from '../../utils/sessionStorage';
import { selectStoreCurrentCompany } from '../../utils/storeSelectors';
import { convertUserListIntoValidSelectOptions } from '../../utils/users';
import Select from '../Select';

const CompanyUsersLookupSelect = props => {
  const {
    t,
    showAllOption,
    groupId,
    disabled,
    value,
    onChange,
    optionsFilter,
    notFoundContent,
    additionalQueryParams,
    ...rest
  } = props;

  const [searchTerm, setSearchTerm] = useDebouncedState('', 500);
  const [initialCount, setInitialCount] = useState();

  const currentCompany = useSelector(selectStoreCurrentCompany);
  const allUsersQuery = useInfiniteAllCompanyUsersQuery(
    currentCompany._id,
    { groupId, searchTerm, ...(additionalQueryParams || {}) },
    {
      staleTime: Infinity,
      onSuccess: ({ pages }) => {
        if (
          Array.isArray(pages) &&
          typeof pages[0]?.totalCount !== 'undefined' &&
          typeof initialCount === 'undefined'
        ) {
          setInitialCount(pages[0]?.totalCount);
        }
      },
    },
  );

  const flatUserPages = useMemo(() => {
    let optionsArray = [];

    if (Array.isArray(allUsersQuery?.data?.pages)) {
      allUsersQuery.data.pages.forEach(({ users }) => {
        optionsArray = optionsArray.concat(users);
      });
    }

    if (typeof optionsFilter === 'function') {
      optionsArray = optionsArray.filter(optionsFilter);
    }

    return optionsArray;
  }, [allUsersQuery, optionsFilter]);

  const options = useMemo(() => {
    let optionsArray = convertUserListIntoValidSelectOptions(flatUserPages);

    if (showAllOption) {
      optionsArray.unshift({ value: null, label: t('All') });
    }

    return optionsArray;
  }, [t, showAllOption, flatUserPages]);

  const optionsWithLastLookup = useMemo(() => {
    const optionsArray = [...options];

    if (!optionsArray.find(user => user.value === value)) {
      const lastUserLookupSelection = StorageUtils.lastUserLookupSelection.get();

      if (lastUserLookupSelection) {
        optionsArray.splice(1, 0, JSON.parse(lastUserLookupSelection));
      } else if (typeof onChange === 'function' && !isNull(value) && !isUndefined(value)) {
        onChange(null);
      }
    }

    return uniqBy(optionsArray, 'value');
  }, [options, value, onChange]);

  const optionsWithoutNullValues = useMemo(() => {
    return optionsWithLastLookup.filter(opt => !!opt.value);
  }, [optionsWithLastLookup]);

  const handleOptionsScroll = useCallback(
    e => {
      const bottom = checkIfElementIsScrolledToBottom(e.target);

      if (bottom && !allUsersQuery.isFetchingNextPage && allUsersQuery.hasNextPage) {
        allUsersQuery.fetchNextPage();
      }
    },
    [allUsersQuery],
  );

  const handleChange = useCallback(
    (value, option) => {
      if (typeof onChange === 'function') {
        const fullSelectedUser = flatUserPages.find(user => user._id === value);
        onChange(value, fullSelectedUser);
        if (typeof option === 'undefined') {
          StorageUtils.lastUserLookupSelection.remove();
        } else {
          StorageUtils.lastUserLookupSelection.set(option);
        }
      }
      setSearchTerm();
    },
    [flatUserPages, setSearchTerm, onChange],
  );

  useEffect(() => {
    return () => {
      StorageUtils.lastUserLookupSelection.remove();
    };
  }, []);

  return (
    <Select
      {...rest}
      resetOnNoMatch={false}
      onChange={handleChange}
      value={value && !optionsWithoutNullValues.length ? undefined : value}
      fullWidth
      showSearch
      allowClear={false}
      disabled={disabled}
      loading={allUsersQuery.isFetchingNextPage}
      options={optionsWithLastLookup}
      onSearch={setSearchTerm}
      placeholder={t('user')}
      onPopupScroll={handleOptionsScroll}
      notFoundContent={
        allUsersQuery.isFetching ? (
          <Row justify="center" align="middle">
            <Col>
              <Spin size="default" />
            </Col>
          </Row>
        ) : initialCount === 0 ? (
          /* message to display only if the initial search has not results,
           hence any other search term queries will be return the same */
          notFoundContent
        ) : undefined
      }
    />
  );
};

export default CompanyUsersLookupSelect;
