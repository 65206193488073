import { Form, Row } from 'antd';
import React from 'react';

import useDidUpdateEffect from '../../../hooks/useDidUpdateEffect';
import { ERROR_MESSAGE } from '../../../utils/constants';
import { ConfirmModal } from '../..';
import FormItem from '../../Form/FormItem';
import Text from '../../Text';
import TextInput from '../../TextInput';

const CompanyDeleteConfirmationModal = props => {
  const { t, visible, isDeletingCompany, onDelete, onCancel } = props;

  const [form] = Form.useForm();

  useDidUpdateEffect(() => {
    if (visible) {
      form.resetFields();
    }
  }, [visible]);

  return (
    <ConfirmModal
      closable={!isDeletingCompany}
      loading={isDeletingCompany}
      visible={visible}
      title={t('deleteCompany?')}
      onOk={() => {
        form.validateFields().then(onDelete);
      }}
      onCancel={onCancel}
      okText={t('yesHardDelete')}
      message={
        <div style={{ display: 'block' }}>
          <Row style={{ marginBottom: 15 }}>
            <Text size="sm" renderAs="p">
              {t('sureYouWantToDeleteCompany')}
            </Text>
          </Row>

          <Row style={{ marginBottom: 15 }}>
            <Text size="sm" renderAs="p">
              {t('deletingCompanyWillDeleteEverything')}
            </Text>
          </Row>

          <Row style={{ marginBottom: 15 }}>
            <Text size="sm" renderAs="p">
              {t('deletetingCompanyIsNotRecoverable')}
            </Text>
          </Row>

          <Row>
            <Form form={form}>
              <FormItem
                name="text"
                label={t('typeDeleteCompanyToHardDelete')}
                rules={[
                  { required: true, message: ERROR_MESSAGE.BLANK_FIELD },
                  {
                    validateTrigger: 'onSubmit',
                    validator: async (_, text = '') => {
                      if (!text) return;

                      if (!/DELETE COMPANY/i.test(text)) {
                        return Promise.reject(ERROR_MESSAGE.TEXT_MATCH_DELETE_COMPANY);
                      }

                      return Promise.resolve(text);
                    },
                  },
                ]}
              >
                <TextInput />
              </FormItem>
            </Form>
          </Row>
        </div>
      }
    />
  );
};

export default CompanyDeleteConfirmationModal;
