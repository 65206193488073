import { Col, Row, Space } from 'antd';
import React, { useMemo } from 'react';

import Checkbox from '../../../components/Checkbox';
import RedAsterisk from '../../../components/RedAsterisk';
import Select from '../../../components/Select';
import Text from '../../../components/Text';
import { getNumberRage } from '../../../utils/common';

const SelectMonthlyParameters = props => {
  const {
    isAchEnabled,
    paymentDayOfMonth,
    achTransferDayOfMonth,
    paymentDateUseBusinessDays,
    tripsSubmissionDeadline,
    tripsSubmissionDeadlineUseBusinessDays,
    managerApprovalDeadline,
    managerApprovalDeadlineUseBusinessDays,
    onSettingChange,
    disabled,
  } = props;

  const OPTIONS = useMemo(() => {
    return [
      {
        key: 'paymentDayOfMonth',
        businessDayKey: 'paymentDateUseBusinessDays',
        label: 'Select Payment Period End Date',
        suffix: 'th day of every month',
        value: paymentDayOfMonth,
        onlyBusinessDays: paymentDateUseBusinessDays,
        showBusinessDaysCheckbox: true,
        checkboxDisabled: disabled,
        disabled,
      },
      {
        key: 'achTransferDayOfMonth',
        businessDayKey: 'achTransferDayOfMonthUseBusinessDays',
        label: 'Select Payment Date for ACH payments (optional)',
        subLabel: '(Kliks will submit ACH transactions 3 days prior to the Payment Date)',
        suffix: 'th day of every month',
        value: achTransferDayOfMonth,
        onlyBusinessDays: true,
        showBusinessDaysCheckbox: true,
        checkboxDisabled: true,
        disabled,
        optional: true,
        hidden: !isAchEnabled,
      },
      {
        key: 'tripsSubmissionDeadline',
        label: 'Select Trips Submission Deadline',
        suffix: 'days prior to Payment Period End Date',
        value: tripsSubmissionDeadline,
        onlyBusinessDays: tripsSubmissionDeadlineUseBusinessDays,
        showBusinessDaysCheckbox: true,
        checkboxDisabled: disabled,
        disabled,
      },
      {
        key: 'managerApprovalDeadline',
        label: 'Select Manager Approval Deadline',
        suffix: 'days prior to Payment Period End Date',
        value: managerApprovalDeadline,
        onlyBusinessDays: managerApprovalDeadlineUseBusinessDays,
        showBusinessDaysCheckbox: true,
        checkboxDisabled: disabled,
        disabled,
        options: getNumberRage(0, 31).map(n => {
          return {
            label: n,
            value: n,
          };
        }),
      },
    ];
  }, [
    disabled,
    paymentDayOfMonth,
    paymentDateUseBusinessDays,
    achTransferDayOfMonth,
    isAchEnabled,
    tripsSubmissionDeadline,
    tripsSubmissionDeadlineUseBusinessDays,
    managerApprovalDeadline,
    managerApprovalDeadlineUseBusinessDays,
  ]);

  return (
    <div>
      {OPTIONS.map(
        (option, index) =>
          !option.hidden && (
            <Row key={index} style={{ marginBottom: 20 }}>
              <Col flex="100%">
                <Space direction="horizontal" size={3} wrap={false} align="center">
                  {!option.optional && <RedAsterisk />}
                  <Text style={{ marginTop: 8, marginBottom: 8 }} variant="p" size="sm">
                    {option.label}
                  </Text>
                </Space>
              </Col>

              <Col flex={1}>
                <Row gutter={5}>
                  <Col flex="100px">
                    <Select
                      disabled={option.disabled}
                      placeholder="Day"
                      value={option.value}
                      style={{ width: '100%', marginBottom: 5 }}
                      onChange={value => onSettingChange({ [option.key]: value }, option.key)}
                      options={
                        option.options ||
                        getNumberRage(1, 31).map(n => ({
                          label: n,
                          value: n,
                        }))
                      }
                    />
                  </Col>
                  {option.suffix && (
                    <Col>
                      <Text variant="p" size="sm" style={{ marginTop: 8 }}>
                        {option.suffix}
                      </Text>
                    </Col>
                  )}
                </Row>

                {option.subLabel && (
                  <Text style={{ marginTop: 8, marginBottom: 8 }} variant="p" size="sm">
                    {option.subLabel}
                  </Text>
                )}

                {option.showBusinessDaysCheckbox && (
                  <Checkbox
                    disabled={option.checkboxDisabled}
                    checked={option.onlyBusinessDays}
                    onChange={e => {
                      if (!option.checkboxDisabled) {
                        onSettingChange({
                          [option.businessDayKey || `${option.key}UseBusinessDays`]: e.target
                            .checked,
                        });
                      }
                    }}
                  >
                    <p>Only use business days </p>
                    {(index === 0 || (isAchEnabled && index === 1)) && (
                      <p>
                        (System will default to prev/next business day if the selected day is a
                        weekend or a holiday)
                      </p>
                    )}
                  </Checkbox>
                )}
              </Col>
            </Row>
          ),
      )}
    </div>
  );
};

export default SelectMonthlyParameters;
