import { LineChartOutlined } from '@ant-design/icons';
import { Button, Col, Drawer, Row } from 'antd';
import _, { get } from 'lodash';
import React from 'react';
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { normalizeTripsSchema } from 'utils/trips';

import { handleApiCalls, handleApiErrors } from '../../../../api/axiosInstance';
import { createAdjustment, deleteAdjustment, updateAdjustment } from '../../../../api/receipt';
import MetabaseMonthlyMileageChart from '../../../../components/MetabaseMonthlyMileageChart';
import AdjustmentHistoryModal from '../../../../components/Modal/AdjustmentHistoryModal';
import FullScreenTripModal from '../../../../components/Modal/FullScreenTripModal';
import ReimbursementAdjustmentModal from '../../../../components/Modal/ReimbursementAdjustmentModal';
import ReimbursementsSummaryCard from '../../../../components/Reimbursements/ReimbursementsSummaryCard';
import ReimbursementReceiptsTable from '../../../../components/Table/ReimbursementReceiptsTable';
import TripsTable from '../../../../components/Table/TripsTable';
import StatusTag from '../../../../components/Tag/StatusTag';
import Text from '../../../../components/Text';
import Toast from '../../../../components/Toast';
import { INTERNAL_LINKS } from '../../../../enum';
import { getUrlQueryParameter, setModalFullscreenWidth } from '../../../../utils/common';
import { hasCompanyManagerOrAdminRole } from '../../../../utils/roles';
import { canAdjustReceipt, canUpdateReceiptStatus } from '../../../receipt/receipt-permissions';
import ReimbursementDetailsPageContainer from '../../ReimbursementDetailsPageContainer';

class ReimbursementDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      adjustments: [],
      defaultAdjustmentHistorySearch: '',
      isAdjustmentHistVisible: false,
      isAddingAdjustment: false,
      receiptIdToAdjust: undefined,
      isTripDetailsVisible: false,
      tripMapConfig: {},
      tripReimbursement: undefined,
      tripReceipts: [],
      selectedRowKeys: [],
      rawTripData: [],
      isFetchingReceiptsData: false,
      selectedReceiptID: getUrlQueryParameter('receipt') || undefined,
      selectedReceiptData: undefined,
      selectedReceiptTrips: undefined,
      receiptAdjustments: {},
      selectedAdjustment: undefined,
      isMonthlyChartVisible: false,
    };
  }

  componentDidMount() {
    if (this.props.myProfile) {
      const { id } = this.props.match.params;
      if (id) this.loadReimbursementDetails(id);
    }

    if (
      this.state.selectedReceiptID &&
      !this.state.selectedReceiptData &&
      !this.state.isFetchingReceiptsData
    ) {
      this.getReceiptDetails(this.state.selectedReceiptID);
    }
  }

  componentDidUpdate(preProps, prevState, snapshot) {
    const receiptID = getUrlQueryParameter('receipt');

    if (!preProps.myProfile && this.props.myProfile) {
      const { id } = this.props.match.params;
      this.loadReimbursementDetails(id);
    }

    if (receiptID && !this.state.selectedReceiptData && !this.state.isFetchingReceiptsData) {
      this.getReceiptDetails(receiptID);
    } else if (!receiptID && this.state.selectedReceiptData) {
      this.setState({
        selectedReceiptID: undefined,
        selectedReceiptData: undefined,
        selectedReceiptTrips: undefined,
      });
    }
  }

  toggleAdjustmentHistoryModal = defaultTerm => {
    this.setState(state => ({
      isAdjustmentHistVisible: !state.isAdjustmentHistVisible,
      defaultAdjustmentHistorySearch: defaultTerm,
    }));
  };

  updateReceiptStatus = (id, status) => {
    const data = this.state.tripReceipts;
    const matchingDataIndex = data.findIndex(d => d._id === id);
    data[matchingDataIndex].status = status;

    this.setState(prevState => ({
      tripReceipts: data,
      selectedRowKeys: prevState.selectedRowKeys.filter(k => k !== id),
    }));
  };

  adjustReceipt = async receiptAdjustment => {
    if (this.state.selectedAdjustment) {
      if (
        this.state.selectedAdjustment.adjustmentType === receiptAdjustment.type &&
        this.state.selectedAdjustment.amount === receiptAdjustment.amount &&
        this.state.selectedAdjustment.note === receiptAdjustment.note
      ) {
        this.setState({
          receiptIdToAdjust: undefined,
          isAddingAdjustment: false,
        });
        return;
      }
    }

    try {
      if (this.state.selectedAdjustment) {
        await updateAdjustment(this.state.receiptIdToAdjust, receiptAdjustment);
      } else {
        await createAdjustment(this.state.receiptIdToAdjust, receiptAdjustment);
      }

      const { id } = this.props.match.params;
      await this.loadReimbursementDetails(id);

      this.setState({ receiptIdToAdjust: undefined, isAddingAdjustment: false });
    } catch (error) {
      handleApiErrors(error.response, () => {
        Toast({
          type: 'error',
          message: 'Unable to create adjustment',
        });
      });
    }
  };

  removeAdjustment = async () => {
    try {
      await deleteAdjustment(this.state.receiptIdToAdjust);
      const { id } = this.props.match.params;
      await this.loadReimbursementDetails(id);

      this.setState({ receiptIdToAdjust: undefined, isAddingAdjustment: false });
    } catch (error) {
      handleApiErrors(error.response, () => {
        Toast({
          type: 'error',
          message: 'Error while removing adjustment',
        });
      });
    }

    this.setState(prevState => ({
      ...prevState,
      receiptAdjustments: {
        ...prevState.receiptAdjustments,
        [this.state.receiptIdToAdjust]: undefined,
      },
      receiptIdToAdjust: undefined,
      isAddingAdjustment: false,
    }));
  };

  getReceiptDetails = async id => {
    this.setState({ isFetchingReceiptsData: true });
    const url = `${process.env.REACT_APP_HOST_API}trip-receipt/${id}`;
    const result = await handleApiCalls('get', url);
    if (result.status === 200) {
      this.setState({
        selectedReceiptID: id,
        selectedReceiptData: result.data.data,
        selectedReceiptTrips: Array.isArray(result.data.data.trips)
          ? result.data.data.trips.map(trip =>
              normalizeTripsSchema(trip, result.data.data.submittedBy),
            )
          : [],
      });
    } else {
      Toast({
        type: 'error',
        message: 'Error',
        description: 'Unable to fetch receipt details',
      });
    }
    this.setState({ isFetchingReceiptsData: false });
  };

  loadReimbursementDetails = async id => {
    this.setState({ loading: true });
    try {
      const url = `${process.env.REACT_APP_HOST_API}reimbursements/${id}`;
      const result = await handleApiCalls('get', url);
      if (result.status === 200) {
        const reimbursement = result.data.reimbursement;
        let tripReimbursement = {
          ...reimbursement,
          unit: result.data.unit,
        };

        const reimbursementReceipts = Array.isArray(reimbursement.receipt)
          ? reimbursement.receipt
          : [reimbursement.receipt].filter(Boolean);

        let adjustments = [];
        reimbursementReceipts.forEach(receipt => {
          if (Array.isArray(receipt.adjustments) && !!receipt.adjustments.length) {
            const adjustmentsWithReceiptSeqID = receipt.adjustments.map(adj => {
              return {
                ...adj,
                receiptSeqId: receipt.seqId,
              };
            });
            adjustments = [...adjustments, ...adjustmentsWithReceiptSeqID];
          }
        });

        this.setState({
          adjustments: _.orderBy(adjustments, a => new Date(a.created), 'desc'),
          tripReimbursement,
          tripReceipts: reimbursementReceipts,
          rawTripData: reimbursement.trips,
        });
      }
    } catch (err) {
      Toast({
        type: 'error',
        message: 'Error loading reimbursement data',
      });
    }
    this.setState({ loading: false });
  };

  onSelectedRowKeysChange = selectedRowKeys => {
    this.setState({ selectedRowKeys });
  };

  goToReceiptDetails = receipt => {
    this.setState(
      { selectedReceiptID: receipt, selectedReceiptData: undefined, selectedRowKeys: [] },
      () => {
        this.props.history.push({
          pathname: this.props.history.location.pathname,
          search: '?receipt=' + receipt,
        });
      },
    );
  };

  render() {
    const { t, myProfile, companySettings } = this.props;
    const { tripReimbursement, selectedReceiptID, tripReceipts, selectedRowKeys } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectedRowKeysChange,
    };

    const reimbursementSeqID = tripReimbursement ? tripReimbursement.seqId : '';
    const receiptID = this.state.selectedReceiptData ? this.state.selectedReceiptData.seqId : '';

    return (
      <ReimbursementDetailsPageContainer
        t={t}
        history={this.props.history}
        companySettings={companySettings}
        breadcrumbs={[
          reimbursementSeqID && tripReimbursement
            ? {
                onClick: async () => {
                  await this.props.history.push(
                    `${INTERNAL_LINKS.REIMBURSEMENT}/${tripReimbursement._id}`,
                  );
                  this.setState({
                    selectedReceiptID: undefined,
                    selectedReceiptData: undefined,
                  });
                },
                label: (
                  <Row align="middle" gutter={10} style={{ display: 'inline-flex' }}>
                    <Col>{reimbursementSeqID}</Col>
                    {!receiptID && (
                      <Col>
                        <StatusTag status={tripReimbursement.payment.status} />
                      </Col>
                    )}
                  </Row>
                ),
              }
            : undefined,
          receiptID
            ? {
                label: (
                  <Row align="middle" gutter={10} style={{ display: 'inline-flex' }}>
                    <Col>{receiptID}</Col>

                    <Col>
                      <StatusTag status={this.state.selectedReceiptData.status} />
                    </Col>
                  </Row>
                ),
              }
            : undefined,
        ]}
      >
        <Row style={{ marginBottom: 32, width: '100%' }}>
          <Col flex={1}>
            <ReimbursementsSummaryCard
              t={t}
              receipts={tripReceipts}
              summary={tripReimbursement}
              company={this.props.currentCompany}
              hasAdjustments={!!this.state.adjustments.length}
              onAdjustmentView={() =>
                this.toggleAdjustmentHistoryModal(receiptID ? receiptID : undefined)
              }
            />
          </Col>
        </Row>

        <Row style={{ marginBottom: 43 }} justify="space-between" gutter={[16, 16]}>
          <Col flex={1}>
            <Text variant="h4">
              {selectedReceiptID ? t('Trips in this Receipt') : t('Receipts in this Reimbursement')}
            </Text>
          </Col>

          {companySettings?.settings?.mileageCap && hasCompanyManagerOrAdminRole(myProfile) && (
            <Col>
              <Button
                size="sm"
                type="secondary"
                onClick={() => this.setState({ isMonthlyChartVisible: true })}
                icon={<LineChartOutlined style={{ fontSize: '16px' }} />}
              >
                {t('viewHistoricalMileage')}
              </Button>
            </Col>
          )}
        </Row>

        {selectedReceiptID ? (
          <TripsTable
            t={t}
            authUserID={myProfile.profile._id}
            companyCountry={this.props.currentCompany.address.country}
            loading={!this.state.selectedReceiptData && !this.state.selectedReceiptTrips}
            dataSource={this.state.selectedReceiptTrips ? this.state.selectedReceiptTrips : []}
            handleViewFullScreen={(trp, activeRoutes, mapCenter) => {
              this.setState({
                isTripDetailsVisible: true,
                tripMapConfig: {
                  tripId: trp._id,
                  isManual: !trp.gpsVerified || trp.gpsVerified === 'No',
                  toLoc: trp.to_loc,
                  fromLoc: trp.from_loc,
                  routes: activeRoutes,
                  center: mapCenter,
                },
              });
            }}
          />
        ) : (
          <ReimbursementReceiptsTable
            t={t}
            scroll={{ x: 970 }}
            canUpdateStatus={canUpdateReceiptStatus(myProfile, undefined, true)}
            canAdjustReceipt={canAdjustReceipt(myProfile)}
            loading={this.state.loading}
            dataSource={tripReceipts}
            rowSelection={rowSelection}
            onReceiptIDClick={id => this.goToReceiptDetails(id)}
            summary={tripReimbursement}
            updateStatus={this.updateReceiptStatus}
            addAdjustment={(receiptID, adjustment) => {
              this.setState({
                receiptIdToAdjust: receiptID,
                selectedAdjustment: adjustment,
                isAddingAdjustment: true,
              });
            }}
          />
        )}

        <ReimbursementAdjustmentModal
          t={t}
          currency={tripReimbursement?.reimbursementCurrency}
          adjustmentsList={this.state.adjustments}
          isEdit={!!this.state.receiptAdjustments[this.state.receiptIdToAdjust]}
          visible={this.state.isAddingAdjustment}
          addAdjustment={this.adjustReceipt}
          onRemove={this.removeAdjustment}
          onCancel={() =>
            this.setState({
              isAddingAdjustment: false,
              receiptIdToAdjust: undefined,
              selectedAdjustment: undefined,
            })
          }
        />

        <FullScreenTripModal
          visible={this.state.isTripDetailsVisible}
          mapConfig={this.state.tripMapConfig}
          onCancel={() => {
            this.setState({
              isTripDetailsVisible: false,
              tripMapConfig: {},
            });
          }}
        />

        <AdjustmentHistoryModal
          t={t}
          currency={tripReimbursement?.reimbursementCurrency}
          defaultSearchTerm={this.state.defaultAdjustmentHistorySearch}
          visible={this.state.isAdjustmentHistVisible}
          adjustmentsList={this.state.adjustments}
          onCancel={() => this.toggleAdjustmentHistoryModal()}
        />

        {companySettings?.settings?.mileageCap && (
          <Drawer
            width={setModalFullscreenWidth(848)}
            placement="right"
            open={this.state.isMonthlyChartVisible}
            onClose={() => this.setState({ isMonthlyChartVisible: false })}
          >
            <MetabaseMonthlyMileageChart
              userId={get(tripReimbursement, 'submittedBy._id')}
              companyId={this.props.currentCompany._id}
            />
          </Drawer>
        )}
      </ReimbursementDetailsPageContainer>
    );
  }
}

ReimbursementDetails.propTypes = {};

ReimbursementDetails.defaultProps = {};

const mapStateToProps = (state, ownProps) => {
  return {
    myProfile: state.profile,
    currentCompany: state.common.currentCompany,
    companySettings: state.common.currentCompany.companySettingId,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(ReimbursementDetails));
