import { get } from 'lodash';
import queryString from 'querystring';
import { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';

/**
 * @param {object} params
 * @param {number} params.initialPage
 * @param {number} params.initialPageSize
 * @param {boolean} params.useQueryParams
 */
const useTablePagination = (params = {}) => {
  const history = useHistory();

  const [paginationConfig, setPaginationConfig] = useState({
    total: 0,
    pageSize: parseInt(get(params, 'initialPageSize', 25), 10),
    current: parseInt(get(params, 'initialPage', 1), 10),
  });

  const handlePageChange = useCallback(
    currentPage => {
      setPaginationConfig(state => ({
        ...state,
        current: currentPage,
      }));

      if (params.useQueryParams) {
        history.replace({
          search: queryString.stringify({
            ...queryString.parse(window.location.search.replace('?', '')),
            page: currentPage,
          }),
        });
      }
    },
    [history, params.useQueryParams],
  );

  const handlePageSizeChange = useCallback(
    (current, size) => {
      setPaginationConfig(state => ({
        ...state,
        pageSize: size,
      }));

      if (params.useQueryParams) {
        history.replace({
          search: queryString.stringify({
            ...queryString.parse(window.location.search.replace('?', '')),
            pageSize: size,
          }),
        });
      }
    },
    [history, params.useQueryParams],
  );

  const handleTotalUpdate = useCallback(total => {
    setPaginationConfig(state => ({
      ...state,
      total,
    }));
  }, []);

  return { paginationConfig, handlePageChange, handlePageSizeChange, handleTotalUpdate };
};

export default useTablePagination;
