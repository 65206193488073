import { Col, Row } from 'antd';
import { get, isNull } from 'lodash';
import moment from 'moment-timezone';
import React, { useState } from 'react';
import { withNamespaces } from 'react-i18next';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';

import { USER_API } from '../../../api/user';
import { Button } from '../../../components';
import CompanyUsersLookupSelect from '../../../components/CompanyUsersLookupSelect';
import DownloadIcon from '../../../components/DownloadIcon';
import GroupPeriodLookupSelect from '../../../components/GroupPeriodLookupSelect';
import ReceiptDeleteConfirmationModal from '../../../components/Modal/ReceiptDeleteConfirmationModal';
import ReimbursementApproveConfirmation from '../../../components/Modal/ReimbursementApproveConfirmation';
import ReimbursementRejectConfirmation from '../../../components/Modal/ReimbursementRejectConfirmation';
import SubmitReimbursementsForApprovalConfirmation from '../../../components/Modal/SubmitReimbursementsForApprovalConfirmation';
import PageFiltersRenderer from '../../../components/shared-ui/PageFiltersRenderer';
import ReimbursementsWithInsightsTable from '../../../components/Table/ReimbursementsWithInsightsTable';
import LinkText from '../../../components/Text/LinkText';
import UserGroupsLookupSelect from '../../../components/UserGroupsLookupSelect';
import { RECEIPT_STATUS } from '../../../enum';
import useUserGroupsQuery from '../../../hooks/queries/useUserGroupsQuery';
import useLocationSearchQueryParser from '../../../hooks/useLocationSearchQueryParser';
import { getMinutesAsMilliseconds } from '../../../utils/numbers';
import {
  hasCompanyAdminRole,
  hasCompanyManagerOrAdminRole,
  hasUserRole,
} from '../../../utils/roles';
import { canUpdateReceiptStatus } from '../../receipt/receipt-permissions';
import ReimbursementPageContainer from '../ReimbursementPageContainer';
import { canMarkReimbursementAsPaid } from '../reimbursements/reimbursements-permissions';
import useLimitOneReceiptReimbursementsState from './useLimitOneReceiptReimbursementsState';

const COMMON_RESPONSE_FILTER_SETTINGS = {
  xs: 24,
  md: 12,
  lg: 4,
};

const LimitedOneReceiptReimbursementsView = props => {
  const { t, history, location } = props;

  const initialFilters = useLocationSearchQueryParser(location);

  const authUser = useSelector(store => store.profile);
  const companySettings = useSelector(store => store.common.currentCompany.companySettingId);

  const userGroupsQuery = useUserGroupsQuery(authUser?.profile?._id);
  const dashboardDataQuery = useQuery({
    placeholderData: {},
    staleTime: getMinutesAsMilliseconds(5),
    queryKey: ['fetchDashboardData', authUser?.profile?._id, authUser?.profile?.group?._id, '', ''],
    enabled: hasUserRole(authUser),
    queryFn: () =>
      USER_API.fetchDashboardData({
        groupId: authUser?.profile?.group?._id,
      }),
  });

  const {
    // Booleans
    isLoadingData,
    isApprovalConfirmationVisible,
    isRejectConfirmationVisible,
    isResubmitConfirmationVisible,
    isApprovingReceipt,
    isRejectingReceipt,
    isResubmittingReceiptsForApproval,
    isInsightsEnabled,
    isDeleteReceiptModalVisible,
    // General
    receipts,
    selectedReceipts,
    selectedReceiptsWithMoreInfoTrips,
    overdueReceiptsCount,
    reimbursementPaginationConfig,
    reimbursementFilters,
    // Handlers
    handleTableSort,
    handleReceiptSelection,
    handleApprove,
    handleDeny,
    handleResubmitForApproval,
    handleFiltersChange,
    markReceiptAsPaidMutation,
    exportReceiptMutation,
    openApprovalConfirmation,
    closeApprovalConfirmation,
    openRejectionConfirmation,
    closeRejectionConfirmation,
    openResubmitConfirmation,
    closeResubmitConfirmation,
    handleReimbursementPageChange,
    handleReimbursementPageSizeChange,
    handleOverdueAlertClick,
    toggleReceiptsInsights,
    setReceiptIdToDelete,
    openDeleteReceiptModal,
    closeDeleteReceiptModal,
    deleteReceiptMutation,
  } = useLimitOneReceiptReimbursementsState({
    t,
    history,
    initialFilters: {
      ...initialFilters,
      fromDate: isNull(initialFilters?.fromDate)
        ? null
        : initialFilters?.fromDate
        ? moment(initialFilters.fromDate)
        : hasUserRole(authUser) && dashboardDataQuery.data?.oldestPendingReimbursementCreatedDate
        ? moment(dashboardDataQuery.data?.oldestPendingReimbursementCreatedDate)
        : null,
      toDate: isNull(initialFilters?.toDate)
        ? null
        : initialFilters?.toDate
        ? moment(initialFilters.toDate)
        : null,
      groupId:
        typeof initialFilters.groupId !== 'undefined' && initialFilters.groupId !== ''
          ? initialFilters.groupId
          : Array.isArray(userGroupsQuery.data)
          ? get(userGroupsQuery, 'data[0]._id', null)
          : null,
    },
  });

  const [currentGroupHasPeriods, setCurrentGroupHasPeriods] = useState(true);
  const [filterPeriodWithDatePicker, setFilterPeriodWithDatePicker] = useState(
    !hasCompanyManagerOrAdminRole(authUser) ||
      !currentGroupHasPeriods ||
      !!initialFilters.fromDate ||
      !!initialFilters.toDate,
  );

  return (
    <ReimbursementPageContainer
      t={t}
      canViewReimbursementAlert={hasCompanyManagerOrAdminRole(authUser)}
      overdueCount={overdueReceiptsCount}
      onOverdueAlertClick={!isLoadingData ? handleOverdueAlertClick : null}
      stickyHeader={canUpdateReceiptStatus(authUser, undefined, true)}
      companySettings={companySettings}
      sideActionComponent={
        <Row gutter={[16, 16]} justify="end" align="middle">
          {canUpdateReceiptStatus(authUser, undefined, true) &&
            hasCompanyManagerOrAdminRole(authUser) && (
              <>
                <Col>
                  <Button
                    size="sm"
                    type="secondary"
                    onClick={openRejectionConfirmation}
                    disabled={!receipts.length || isLoadingData || !selectedReceipts.length}
                  >
                    {t('rejectSelected', { count: selectedReceipts.length })}
                  </Button>
                </Col>

                <Col>
                  <Button
                    size="sm"
                    onClick={openApprovalConfirmation}
                    disabled={!receipts.length || isLoadingData || !selectedReceipts.length}
                  >
                    {t('approveSelected', { count: selectedReceipts.length })}
                  </Button>
                </Col>
              </>
            )}

          <Col>
            <Button
              size="sm"
              type="primary"
              loading={isResubmittingReceiptsForApproval}
              disabled={!receipts.length || isLoadingData || !selectedReceipts.length}
              onClick={openResubmitConfirmation}
            >
              {t('submitApproval')}
            </Button>
          </Col>
        </Row>
      }
    >
      <Row gutter={16} align="middle" justify="space-between" wrap={false}>
        <Col flex={1}>
          <PageFiltersRenderer
            t={t}
            history={history}
            loading={isLoadingData}
            onFiltersChange={handleFiltersChange}
            filters={[
              {
                hidden: !hasCompanyManagerOrAdminRole(authUser),
                componentType: 'custom',
                label: t('user'),
                responsive: COMMON_RESPONSE_FILTER_SETTINGS,
                defaultValue: initialFilters.userId || null,
                render: ({ updateFilterValues, disabled, loading }) => (
                  <CompanyUsersLookupSelect
                    t={t}
                    showAllOption
                    disabled={disabled || loading}
                    value={reimbursementFilters?.userId}
                    defaultValue={initialFilters.userId || null}
                    onChange={userId => updateFilterValues({ userId })}
                  />
                ),
              },
              {
                hidden: !hasCompanyManagerOrAdminRole(authUser),
                componentType: 'custom',
                label: t('Group'),
                name: 'groupId',
                responsive: COMMON_RESPONSE_FILTER_SETTINGS,
                defaultValue:
                  typeof initialFilters.groupId !== 'undefined' && initialFilters.groupId !== ''
                    ? initialFilters.groupId
                    : undefined,
                render: ({ updateFilterValues, disabled, loading }) => (
                  <UserGroupsLookupSelect
                    t={t}
                    showAllOption
                    hideFormItem
                    userId={authUser.profile._id}
                    disabled={disabled || loading}
                    value={reimbursementFilters?.groupId}
                    onChange={groupId => {
                      updateFilterValues({ groupId, period: undefined });
                      if (groupId) {
                        setFilterPeriodWithDatePicker(false);
                        setCurrentGroupHasPeriods(true);
                      } else {
                        setFilterPeriodWithDatePicker(true);
                        setCurrentGroupHasPeriods(false);
                      }
                    }}
                    defaultValue={
                      typeof initialFilters.groupId !== 'undefined' && initialFilters.groupId !== ''
                        ? initialFilters.groupId
                        : undefined
                    }
                  />
                ),
              },
              {
                hidden: !(
                  !hasCompanyManagerOrAdminRole(authUser) ||
                  !currentGroupHasPeriods ||
                  !reimbursementFilters.groupId ||
                  filterPeriodWithDatePicker
                ),
                label: (
                  <Row gutter={6} wrap={false} justify="space-between">
                    <Col flex={1}>{t('date')}</Col>
                    {!(
                      !hasCompanyManagerOrAdminRole(authUser) ||
                      !currentGroupHasPeriods ||
                      !reimbursementFilters.groupId
                    ) && (
                      <Col>
                        <LinkText
                          size="xs"
                          onClick={() => {
                            setFilterPeriodWithDatePicker(!filterPeriodWithDatePicker);
                            handleFiltersChange({
                              ...reimbursementFilters,
                              period: undefined,
                              fromDate: null,
                              toDate: null,
                            });
                          }}
                        >
                          (filter by period)
                        </LinkText>
                      </Col>
                    )}
                  </Row>
                ),
                allowClear: false,
                componentType: 'dateRange',
                name: ['fromDate', 'toDate'],
                defaultValue:
                  reimbursementFilters.fromDate && reimbursementFilters.toDate
                    ? [reimbursementFilters.fromDate, reimbursementFilters.toDate]
                    : null,
                value:
                  reimbursementFilters.fromDate && reimbursementFilters.toDate
                    ? [reimbursementFilters.fromDate, reimbursementFilters.toDate]
                    : null,
                responsive: {
                  xs: 24,
                  md: 12,
                  lg: 6,
                },
              },
              {
                hidden:
                  !hasCompanyManagerOrAdminRole(authUser) ||
                  !currentGroupHasPeriods ||
                  !reimbursementFilters.groupId ||
                  filterPeriodWithDatePicker,
                label: (
                  <Row gutter={6} wrap={false} justify="space-between">
                    <Col flex={1}>{t('period')}</Col>
                    <Col>
                      <LinkText
                        size="xs"
                        onClick={() => {
                          setFilterPeriodWithDatePicker(!filterPeriodWithDatePicker);
                          handleFiltersChange({
                            ...reimbursementFilters,
                            period: null,
                            fromDate: null,
                            toDate: null,
                          });
                        }}
                      >
                        (filter by date range)
                      </LinkText>
                    </Col>
                  </Row>
                ),
                componentType: 'custom',
                name: 'period',
                defaultValue: initialFilters.period || undefined,
                value: reimbursementFilters.period,
                responsive: {
                  xs: 24,
                  md: 12,
                  lg: 6,
                },
                render: ({ updateFilterValues }) => (
                  <GroupPeriodLookupSelect
                    t={t}
                    hideFormItem
                    showAllOption
                    allowClear={false}
                    groupId={reimbursementFilters.groupId}
                    value={reimbursementFilters.period}
                    disabled={isLoadingData}
                    onChange={period => {
                      updateFilterValues({
                        groupId: reimbursementFilters.groupId,
                        userId: reimbursementFilters.userId,
                        status: reimbursementFilters.status,
                        period: period === '' ? initialFilters.period : period,
                        toDate: undefined,
                        fromDate: undefined,
                      });
                    }}
                    onEmptyPeriods={() => {
                      setFilterPeriodWithDatePicker(true);
                      setCurrentGroupHasPeriods(false);
                    }}
                  />
                ),
              },
              {
                componentType: 'select',
                label: t('status'),
                name: 'status',
                defaultValue: initialFilters.status || null,
                value: reimbursementFilters.status,
                options: [
                  { label: t('All'), value: null },
                  ...(companySettings.settings.ach_enabled
                    ? RECEIPT_STATUS.SelectionOptionsWithACH
                    : RECEIPT_STATUS.SelectOptions),
                ],
                responsive: COMMON_RESPONSE_FILTER_SETTINGS,
              },
              {
                componentType: 'select',
                label: t('payableViaACH'),
                name: 'canBeUpdatedManually',
                responsive: COMMON_RESPONSE_FILTER_SETTINGS,
                defaultValue: initialFilters.canBeUpdatedManually || null,
                value: reimbursementFilters.canBeUpdatedManually,
                hidden: !companySettings.settings.ach_enabled,
                options: [
                  { label: t('All'), value: null },
                  { label: t('yes'), value: false },
                  { label: t('no'), value: true },
                ],
              },
            ]}
            search={{
              defaultValue: initialFilters.searchTerm,
            }}
          />
        </Col>

        <DownloadIcon
          colWidth="65px"
          text={t('exportToCSV')}
          loading={exportReceiptMutation.isLoading || isLoadingData}
          onClick={exportReceiptMutation.mutateAsync}
          disabled={
            !reimbursementPaginationConfig.total ||
            reimbursementPaginationConfig.total === 0 ||
            exportReceiptMutation.isLoading ||
            isLoadingData
          }
        />
      </Row>

      <br />

      <Row style={{ marginBottom: 24 }}>
        <Col flex={1}>
          <ReimbursementsWithInsightsTable
            t={t}
            asyncSort
            onViewInsights={toggleReceiptsInsights}
            isInsightsEnabled={isInsightsEnabled}
            loading={isLoadingData}
            dataSource={receipts}
            rowSelection={{
              selectedRowKeys: selectedReceipts,
              onChange: handleReceiptSelection,
              // getCheckboxProps: item => ({
              //   disabled: !item.canBeUpdatedManually,
              // }),
            }}
            pagination={{
              pageSize: reimbursementPaginationConfig.pageSize,
              total: reimbursementPaginationConfig.total,
              current: reimbursementPaginationConfig.current,
              onShowSizeChange: handleReimbursementPageSizeChange,
            }}
            onChange={({ current }, filters, sorters) => {
              handleReimbursementPageChange(current);
              handleReceiptSelection([]);
              handleTableSort(sorters?.columnKey, sorters?.order);
            }}
            onDeleteReceipt={
              hasCompanyAdminRole(authUser)
                ? receiptId => {
                    openDeleteReceiptModal();
                    setReceiptIdToDelete(receiptId);
                  }
                : undefined
            }
          />
        </Col>
      </Row>

      {hasCompanyManagerOrAdminRole(authUser) && (
        <ReimbursementApproveConfirmation
          t={t}
          visible={isApprovalConfirmationVisible}
          isApproving={isApprovingReceipt}
          onCancel={closeApprovalConfirmation}
          onConfirm={() => handleApprove()}
          reimbursementCount={selectedReceipts.length}
          reimbursementWithMoreInfoTrips={selectedReceiptsWithMoreInfoTrips}
        />
      )}

      {hasCompanyManagerOrAdminRole(authUser) && (
        <ReimbursementRejectConfirmation
          t={t}
          visible={isRejectConfirmationVisible}
          isRejecting={isRejectingReceipt}
          onCancel={closeRejectionConfirmation}
          onConfirm={() => handleDeny()}
        />
      )}

      <SubmitReimbursementsForApprovalConfirmation
        t={t}
        count={selectedReceipts.length}
        visible={isResubmitConfirmationVisible}
        isSubmitting={isResubmittingReceiptsForApproval}
        onCancel={closeResubmitConfirmation}
        onConfirm={() => handleResubmitForApproval()}
      />

      {canMarkReimbursementAsPaid(authUser) && (
        <Row gutter={17} align="middle">
          <Col>
            <Button
              disabled={
                !selectedReceipts.length || isLoadingData || markReceiptAsPaidMutation.isLoading
              }
              loading={markReceiptAsPaidMutation.isLoading}
              type="primary"
              onClick={markReceiptAsPaidMutation.mutateAsync}
            >
              {t(!!selectedReceipts.length ? 'markAsPaid__count' : 'markAsPaid', {
                count: selectedReceipts.length,
              })}
            </Button>
          </Col>
        </Row>
      )}

      {hasCompanyAdminRole(authUser) && (
        <ReceiptDeleteConfirmationModal
          t={t}
          isReimbursement
          visible={isDeleteReceiptModalVisible}
          loading={deleteReceiptMutation.isLoading}
          onOk={deleteReceiptMutation.mutateAsync}
          onCancel={() => {
            closeDeleteReceiptModal();
            setReceiptIdToDelete();
          }}
        />
      )}
    </ReimbursementPageContainer>
  );
};

export default withNamespaces()(LimitedOneReceiptReimbursementsView);
