import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { withNamespaces } from 'react-i18next';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';

import { PAYMENT_SCHEDULES_API } from '../../api/paymentSchedule';
import PaymentSchedulesTable from '../../components/Table/PaymentSchedulesTable';
import PageContainer from '../../containers/PageContainer';
import { INTERNAL_LINKS } from '../../enum';
import withAuthentication from '../../hocs/withAuthentication';
import { formatPageTitle } from '../../utils/common';
import { selectStoreCurrentCompany } from '../../utils/storeSelectors';

const PaymentSchedulesView = props => {
  const { t, history } = props;

  const [pageSize, setPageSize] = useState(25);
  const [currentPage, setCurrentPage] = useState(1);

  const currentCompany = useSelector(selectStoreCurrentCompany);

  const paymentSchedulesQuery = useQuery({
    staleTime: Infinity,
    placeholderData: [],
    queryKey: ['fetchCompanyPaymentSchedules', currentCompany._id, true],
    queryFn: () =>
      PAYMENT_SCHEDULES_API.fetchCompanyPaymentSchedules(currentCompany._id, { getUsers: true }),
  });

  return (
    <PageContainer
      stickyHeader
      title="Approval / Payment Schedules"
      pageActionText={t('createPaymentSchedule')}
      onPageActionClick={() => history.push(INTERNAL_LINKS.PAYMENT_SCHEDULE_SETUP)}
    >
      <Helmet>
        <title>{formatPageTitle('Approval / Payment Schedules')}</title>
      </Helmet>

      <PaymentSchedulesTable
        t={t}
        loading={paymentSchedulesQuery.isFetching}
        dataSource={paymentSchedulesQuery.data}
        companyCountry={currentCompany.address.country}
        onChange={({ current }) => setCurrentPage(current)}
        pagination={{
          pageSize,
          total: paymentSchedulesQuery.data?.length || 0,
          current: currentPage,
          onShowSizeChange: (current, size) => {
            setPageSize(size);
          },
        }}
      />
    </PageContainer>
  );
};

export default withNamespaces()(withAuthentication(PaymentSchedulesView));
