import * as Sentry from '@sentry/react';
import { Col, Row } from 'antd';
import React from 'react';
import { withNamespaces } from 'react-i18next';
import { withRouter } from 'react-router-dom';

import { IMAGES } from '../../enum';
import Button from '../Button';
import Text from '../Text';
import classNames from './styles.module.scss';

const ErrorBoundary = props => {
  const { t, children, fallback } = props;

  const renderFallback = ({ error }) => {
    let errorInfo = {};

    /*
      Handle [ ChunkLoadError ].
      This error is caused when there's a new release/update on live code
      and a cached javascript chunk/file name changes.

      Throwing an error on the browser as it is unable to load the code.
      Refreshing the page is the only "fix".
      */
    if (/ChunkLoadError/i.test(error.message) || /chunk/i.test(error.message)) {
      errorInfo = {
        error: error,
        clientMessage: t('newSiteUpdatePleseRefresh'),
        clientCallbackText: t('refreshPage'),
        clientCallback: () => window.location.reload(),
      };
    } else {
      if (process.env.NODE_ENV === 'development') console.error(error);

      errorInfo = {
        error: error,
        errorInfo: errorInfo,
        clientMessage: t('Unexpected error on component'),
      };
    }

    if (fallback) return fallback;

    return (
      <Row justify="center" align="middle" className={classNames.infoRow}>
        <Col>
          <Row gutter={15} justify="center" align="middle" className={classNames.infoText}>
            <Col>
              <img src={IMAGES.INFORMATION_CIRCLE} alt="info" />
            </Col>

            <Col>
              <Text size="l">{errorInfo.clientMessage}</Text>
            </Col>
          </Row>

          {errorInfo.clientCallback && errorInfo.clientCallbackText && (
            <Row justify="center">
              <Col>
                <Button onClick={errorInfo.clientCallback}>{errorInfo.clientCallbackText}</Button>
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    );
  };

  return <Sentry.ErrorBoundary fallback={renderFallback}>{children}</Sentry.ErrorBoundary>;
};

export default withNamespaces()(withRouter(ErrorBoundary));
