import { Col, Row } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { withNamespaces } from 'react-i18next';
import { useSelector } from 'react-redux';

import { handleApiErrors } from '../../api/axiosInstance';
import {
  confirmImportRequest,
  fetchImportRequestByID,
  retryImportRequest,
} from '../../api/import-requests';
import Box from '../../components/Box';
import Button from '../../components/Button';
import PageBreadcrumbs from '../../components/PageBreadcrumbs';
import Steps from '../../components/Steps';
import TripsCsvErrorsTable from '../../components/Table/TripsCsvErrorsTable';
import TripsCsvTable from '../../components/Table/TripsCsvTable';
import StatusTag from '../../components/Tag/StatusTag';
import Text from '../../components/Text';
import Toast from '../../components/Toast';
import PageContainer from '../../containers/PageContainer';
import { INTERNAL_LINKS, STATUS_LIST } from '../../enum';
import withAuthentication from '../../hocs/withAuthentication';
import { formatPageTitle } from '../../utils/common';
import { checkForCreateErrorsOnImportRequest } from '../../utils/importRequest';
import { selectStoreCurrentAuthUser, selectStoreCurrentCompany } from '../../utils/storeSelectors';
import ImportRequestSummaryCard from '../locations/ImportRequestSummaryCard';
import { canUploadTripViaCSV } from '../trips/trips-permissions';

const REVIEW_VALID_STEP = 0;
const REVIEW_INVALID_STEP = 1;
const REVIEW_CONFIRM_STEP = 3;

const PageHelmet = () => (
  <Helmet>
    <title>{formatPageTitle('Trips CSV Details')}</title>
  </Helmet>
);

const TripsUploadedViaCsvDetails = props => {
  const { t, match, history } = props;

  const authUser = useSelector(selectStoreCurrentAuthUser);
  const currentCompany = useSelector(selectStoreCurrentCompany);

  const [isConfirmingRequest, setIsConfirmingRequest] = useState(false);

  const [isLoadingDetails, setIsLoadingDetails] = useState(false);
  const [importRequest, setImportRequest] = useState();
  const [currentStep, setCurrentStep] = useState(REVIEW_VALID_STEP);

  const [validTrips, invalidTrips] = useMemo(() => {
    let valid = [];
    let invalid = [];

    if (importRequest && Array.isArray(importRequest.validation)) {
      importRequest.validation.forEach((request, index) => {
        if (request.result === 'valid' || request.result === 'update') valid.push(request);
        if (request.result === 'invalid' || request.result === 'error')
          invalid.push({
            ...request,
            expandable: true,
            rowNumber: index + 2,
          });
      });
    }

    return [valid, invalid];
  }, [importRequest]);

  const AVAILABLE_STEPS = useMemo(() => {
    const steps = [REVIEW_VALID_STEP];

    if (invalidTrips.length !== 0) {
      steps.push(REVIEW_INVALID_STEP);
    }

    steps.push(REVIEW_CONFIRM_STEP);

    return steps;
  }, [invalidTrips]);

  const handleConfirm = async () => {
    setIsConfirmingRequest(true);
    try {
      await confirmImportRequest(importRequest._id);
      Toast({
        type: 'success',
        message: t('tripsConfirmSuccess'),
      });
      history.push(INTERNAL_LINKS.MANAGE_UPLOADED_TRIPS);
    } catch (error) {
      handleApiErrors(error.response, () => {
        Toast({
          type: 'error',
          message: t('tripsConfirmError'),
        });
      });
    }
    setIsConfirmingRequest(false);
  };

  const handleRetry = async () => {
    setIsConfirmingRequest(true);
    try {
      await retryImportRequest(importRequest._id);
      Toast({
        type: 'success',
        message: t('tripsCsvRetrySuccess'),
      });
      history.push(INTERNAL_LINKS.MANAGE_UPLOADED_TRIPS);
    } catch (error) {
      handleApiErrors(error.response, () => {
        Toast({
          type: 'error',
          message: t('tripsCsvRetryError'),
        });
      });
    }
    setIsConfirmingRequest(false);
  };

  const handleNextStep = () => {
    const currentStepIndex = AVAILABLE_STEPS.findIndex(stepNumber => stepNumber === currentStep);

    if (currentStep !== REVIEW_CONFIRM_STEP) {
      setCurrentStep(AVAILABLE_STEPS[currentStepIndex + 1]);
    } else {
      handleConfirm();
    }
  };

  const handleBackStep = () => {
    const currentStepIndex = AVAILABLE_STEPS.findIndex(stepNumber => stepNumber === currentStep);
    setCurrentStep(AVAILABLE_STEPS[currentStepIndex - 1]);
  };

  const handleGoBackToManageTrips = () =>
    props.history.push({
      pathname: INTERNAL_LINKS.MANAGE_UPLOADED_TRIPS,
      search: 'activeTab=import-status',
    });

  const loadImportedTripDetails = async () => {
    const { id } = match.params;
    setIsLoadingDetails(true);
    const request = await fetchImportRequestByID(id);
    setImportRequest(request);
    setIsLoadingDetails(false);
  };

  useEffect(() => {
    if (!canUploadTripViaCSV(authUser)) {
      history.push(INTERNAL_LINKS.DASHBOARD);
    }
    // eslint-disable-next-line
  }, [authUser]);

  useEffect(() => {
    if (canUploadTripViaCSV(authUser) && !isLoadingDetails && !importRequest) {
      loadImportedTripDetails();
    }
    // eslint-disable-next-line
  }, [authUser, currentCompany]);

  const HAS_CREATE_ERRORS =
    importRequest &&
    [STATUS_LIST.Status.COMPLETED].includes(importRequest.status) &&
    checkForCreateErrorsOnImportRequest(importRequest);

  if (HAS_CREATE_ERRORS) {
    return (
      <PageContainer
        loading={isLoadingDetails || !importRequest}
        title={
          <>
            <PageHelmet />
            <PageBreadcrumbs
              items={[
                {
                  label: t('manageTrips'),
                  onClick: handleGoBackToManageTrips,
                },
                {
                  label: (
                    <Row align="middle" gutter={10} style={{ display: 'inline-flex' }}>
                      <Col>{t('csvImportDetails')}</Col>
                      {importRequest && (
                        <Col>
                          {HAS_CREATE_ERRORS ? (
                            <StatusTag status={STATUS_LIST.Status.COMPLETED_WITH_ERRORS} />
                          ) : (
                            <StatusTag status={importRequest.status} />
                          )}
                        </Col>
                      )}
                    </Row>
                  ),
                },
              ]}
            />
          </>
        }
      >
        <Row style={{ marginBottom: 20 }}>
          <Col flex={1}>
            <ImportRequestSummaryCard t={t} importRequest={importRequest} />
          </Col>
        </Row>

        <Row>
          <Col flex={1}>
            <Box>
              <Text variant="h4">{t('tripsWithErrors')}</Text>

              <TripsCsvErrorsTable t={t} dataSource={invalidTrips} />

              <br />

              <Row justify="end">
                <Col>
                  <Row gutter={17} justify="end" align="middle">
                    <Col>
                      <Button
                        onClick={handleRetry}
                        loading={isConfirmingRequest}
                        disabled={isConfirmingRequest}
                      >
                        {t('retry')}
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Box>
          </Col>
        </Row>
      </PageContainer>
    );
  }

  return (
    <PageContainer
      loading={isLoadingDetails || !importRequest}
      title={
        <>
          <PageHelmet />
          <PageBreadcrumbs
            items={[
              {
                label: t('manageTrips'),
                onClick: handleGoBackToManageTrips,
              },
              {
                label: (
                  <Row align="middle" gutter={10} style={{ display: 'inline-flex' }}>
                    <Col>{t('csvImportDetails')}</Col>
                    {importRequest && (
                      <Col>
                        {HAS_CREATE_ERRORS ? (
                          <StatusTag status={STATUS_LIST.Status.HAS_ERRORS} />
                        ) : (
                          <StatusTag status={importRequest.status} />
                        )}
                      </Col>
                    )}
                  </Row>
                ),
              },
            ]}
          />
        </>
      }
    >
      <Row style={{ marginBottom: 20 }}>
        <Col flex={1}>
          <ImportRequestSummaryCard t={t} importRequest={importRequest} />
        </Col>
      </Row>

      <Row>
        <Col flex={1}>
          <Box>
            <Steps size="small" current={currentStep}>
              <Steps.Step
                title={t('reviewValidTripsCount', {
                  count: validTrips.length,
                })}
                description={t('newOrUpdatedValidTrips')}
              />
              <Steps.Step
                title={t('reviewInvalidTripsCount', {
                  count: invalidTrips.length,
                })}
                description={t('tripssNotVerifiedNotImported')}
              />
              <Steps.Step title={t('Confirm')} />
            </Steps>

            <br />

            <Row>
              <Col flex={1}>
                {currentStep === REVIEW_VALID_STEP && (
                  <TripsCsvTable t={t} loading={!importRequest} dataSource={validTrips} />
                )}

                {currentStep === REVIEW_INVALID_STEP && (
                  <TripsCsvErrorsTable t={t} dataSource={invalidTrips} />
                )}

                {currentStep === REVIEW_CONFIRM_STEP && (
                  <Row justify="center" style={{ minHeight: 300, alignItems: 'center' }}>
                    <Col flex="300px">
                      <br />
                      <Text>
                        {t('tripsToBeImported')}: {validTrips.length}
                      </Text>
                      <br />
                      <Text>
                        {t('invalidTrips')}: {invalidTrips.length}
                      </Text>
                      <br />
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>

            <br />

            <Row justify="space-between">
              <Col>
                <Row gutter={17} justify="end" align="middle">
                  {currentStep !== REVIEW_VALID_STEP && (
                    <Col>
                      <Button
                        type="secondary"
                        onClick={handleBackStep}
                        disabled={isConfirmingRequest}
                      >
                        {t('Back')}
                      </Button>
                    </Col>
                  )}

                  {importRequest &&
                    (importRequest.status === STATUS_LIST.Status.AWAITING_CONFIRMATION ||
                      HAS_CREATE_ERRORS) && (
                      <Col>
                        <Button
                          onClick={handleNextStep}
                          loading={isConfirmingRequest}
                          disabled={isConfirmingRequest}
                        >
                          {currentStep !== REVIEW_CONFIRM_STEP ? t('Next') : t('Confirm')}
                        </Button>
                      </Col>
                    )}
                </Row>
              </Col>
            </Row>
          </Box>
        </Col>
      </Row>
    </PageContainer>
  );
};

export default withNamespaces()(withAuthentication(TripsUploadedViaCsvDetails));
