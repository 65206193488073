import './style.scss';

import { Form, Input, Select } from 'antd';
import { CustomTable } from 'components';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useRef, useState } from 'react';

const EditableContext = React.createContext();

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  type,
  controlType,
  options,
  optional,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef();
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };

  const save = async e => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      if (errInfo && errInfo.errorFields) {
        let values = {};
        errInfo.errorFields.forEach(field => {
          if (errInfo.values) {
            values = { ...values, [field.name[0]]: errInfo.values[field.name[0]] };
          }
        });
        handleSave({ ...record, ...values });
      }
    }
  };

  let childNode = children;

  if (editable && record && record.editable) {
    let inputNode;
    switch (controlType) {
      case 'input':
        inputNode = (
          <Input
            type={type || 'text'}
            ref={inputRef}
            onPressEnter={save}
            onBlur={save}
            placeholder={title}
          />
        );
        break;
      case 'select':
        inputNode = (
          <Select placeholder={title} onChange={save} ref={inputRef}>
            {options.map((opt, index) => (
              <Select.Option value={opt.value} key={index}>
                {opt.label}
              </Select.Option>
            ))}
          </Select>
        );
        break;
      default:
        inputNode = (
          <Input
            type={type || 'text'}
            ref={inputRef}
            onPressEnter={save}
            onBlur={save}
            placeholder={title}
          />
        );
        break;
    }
    if (editing) {
      childNode = (
        <Form.Item
          style={{
            margin: 0,
          }}
          name={dataIndex}
          rules={[
            {
              required: !optional,
              type,
              message:
                type === 'email' ? 'Please enter a valid email address.' : `${title} is required.`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      );
    } else {
      let selectValue;
      if (controlType === 'select') {
        selectValue = options.find(x => x.value === record[dataIndex]);
      }
      childNode = (
        <div
          className="editable-cell-value-wrap"
          style={{
            paddingRight: 24,
          }}
          onClick={toggleEdit}
        >
          {controlType === 'select' && selectValue ? selectValue.label : children}
        </div>
      );
    }
  }

  return <td {...restProps}>{childNode}</td>;
};

class EditableTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      columns: [...props.columns],
      dataSource: [...props.dataSource],
      count: props.dataSource.length,
    };
  }

  componentDidUpdate(preProps) {
    if (!_.isEqual(this.state.dataSource, this.props.dataSource)) {
      this.setState({
        dataSource: [...this.props.dataSource],
        count: this.props.dataSource.length,
      });
    }
  }

  handleDelete = key => {
    const dataSource = [...this.state.dataSource];
    this.setState(
      state => {
        state.dataSource = dataSource.filter(item => item.key !== key);
        return state;
      },
      () => {
        this.props.onDataChange([...this.state.dataSource]);
      },
    );
  };

  handleAdd = () => {
    const { count, dataSource } = this.state;
    const newItem = {
      ...this.props.newItem,
      key: count,
    };

    this.setState(
      state => {
        state.dataSource = [...dataSource, newItem];
        state.count = count + 1;
        return state;
      },
      () => {
        this.props.onDataChange([...this.state.dataSource]);
      },
    );
  };

  handleSave = row => {
    const newData = [...this.state.dataSource];
    const index = newData.findIndex(item => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, { ...item, ...row });

    this.setState(
      state => {
        state.dataSource = newData;
        return state;
      },
      () => {
        this.props.onDataChange([...this.state.dataSource]);
      },
    );
  };

  render() {
    const { dataSource } = this.state;
    const components = {
      body: {
        row: EditableRow,
        cell: EditableCell,
      },
    };
    const columns = this.state.columns.map(col => {
      if (!col.editable) {
        return col;
      }

      return {
        ...col,
        onCell: record => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          type: col.type || '',
          controlType: col.controlType,
          options: col.options || [],
          handleSave: this.handleSave,
          optional: col.optional,
        }),
      };
    });

    return (
      <div className="editable-table">
        <CustomTable
          {...this.props}
          components={components}
          rowClassName={() => 'editable-row'}
          bordered
          dataSource={dataSource}
          columns={columns}
        />
      </div>
    );
  }
}

EditableTable.propTypes = {
  addButtonLabel: PropTypes.string,
  columns: PropTypes.array,
  dataSource: PropTypes.array,
  newItem: PropTypes.object,
  onDataChange: PropTypes.func,
};

EditableTable.defaultProps = {
  addButtonLabel: 'Add a row',
  columns: () => [],
  dataSource: () => [],
  newItem: () => {},
  onDataChange: () => {},
};

export default EditableTable;
