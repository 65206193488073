import React from 'react';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import storage from 'store';

import SpaceSpinner from '../components/SpaceSpinner';
import Toast from '../components/Toast';
import { INTERNAL_LINKS } from '../enum';
import { emitNavigateTo } from '../stores/actions/router';
import { getRedirectUri } from '../utils/auth';
import { hasCompanyManagerOrAdminRole, hasCompanyManagerRole, hasUserRole } from '../utils/roles';
import { selectStoreCurrentAuthUser, selectStoreCurrentCompany } from '../utils/storeSelectors';
import { hasSkippedHomeAddressVerification, hasVerifiedHomeAddress } from '../utils/users';

const withAuthentication = WrappedComponent => {
  return props => {
    const { location } = props;
    const dispatch = useDispatch();
    const authUser = useSelector(selectStoreCurrentAuthUser);
    const currentCompany = useSelector(selectStoreCurrentCompany);

    const IS_BILLING_PRICING_PAGE = [
      INTERNAL_LINKS.PRICE_PLANS,
      INTERNAL_LINKS.BILLING_METHOD,
    ].includes(location.pathname);

    const IS_IN_COMPANY_ONBOARDING = new RegExp(INTERNAL_LINKS.COMPANY_ONBOARDING).test(
      location.pathname,
    );

    const IS_IN_USER_ONBOARDING = new RegExp(INTERNAL_LINKS.USER_ONBOARDING).test(
      location.pathname,
    );

    const IS_KLIKS_APP_USER = useMemo(
      () => !!authUser && (hasCompanyManagerOrAdminRole(authUser) || hasUserRole(authUser)),
      [authUser],
    );

    const USER_REQUIRES_HOME_ADDRESS = useMemo(
      () => !!authUser && (hasCompanyManagerRole(authUser) || hasUserRole(authUser)),
      [authUser],
    );

    if (!authUser && !storage.get('accessToken')) {
      Toast({
        type: 'error',
        message: 'You must be logged in to access this page',
      });

      dispatch(emitNavigateTo(`${INTERNAL_LINKS.LOGIN}?redirect_uri=${getRedirectUri()}`));

      return <SpaceSpinner />;
    }

    if (!authUser || (IS_KLIKS_APP_USER && !currentCompany?.name)) {
      return <SpaceSpinner />;
    }

    if (IS_KLIKS_APP_USER) {
      if (currentCompany && !currentCompany.regCompleted && !IS_IN_COMPANY_ONBOARDING) {
        return <Redirect to={INTERNAL_LINKS.COMPANY_ONBOARDING} />;
      }

      if (
        currentCompany?.companySettingId?.commute?.enabled &&
        !IS_BILLING_PRICING_PAGE &&
        USER_REQUIRES_HOME_ADDRESS &&
        !(
          hasVerifiedHomeAddress(authUser.profile) ||
          hasSkippedHomeAddressVerification(authUser.profile)
        ) &&
        !IS_IN_USER_ONBOARDING
      ) {
        return <Redirect to={INTERNAL_LINKS.USER_ONBOARDING} />;
      }

      if (
        currentCompany?.companySettingId?.settings?.realtorFeatures &&
        !IS_BILLING_PRICING_PAGE &&
        !hasVerifiedHomeAddress(authUser.profile)
      ) {
        return <Redirect to={INTERNAL_LINKS.USER_ONBOARDING} />;
      }
    }

    return <WrappedComponent {...props} />;
  };
};

export default withAuthentication;
