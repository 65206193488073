import './style.scss';

import { Badge, Form, Tabs } from 'antd';
import { CustomTable, DataModal, EditableTable } from 'components';
import { INTERNAL_LINKS } from 'enum';
import React from 'react';
import { Helmet } from 'react-helmet';
import { withNamespaces } from 'react-i18next';
import { useSelector } from 'react-redux';

import FormItem from '../../../components/Form/FormItem';
import InviteNewUsersModal from '../../../components/Modal/InviteNewUsersModal';
import PageBreadcrumbs from '../../../components/PageBreadcrumbs';
import Select from '../../../components/Select';
import ProfileNamePicture from '../../../components/shared-ui/ProfileNamePicture';
import SubmitCancelButtonGroup from '../../../components/SubmitCancelButtonGroup';
import StatusTag from '../../../components/Tag/StatusTag';
import Text from '../../../components/Text';
import UnloadWarningModal from '../../../components/UnloadWarningModal';
import PageContainer from '../../../containers/PageContainer';
import withAuthentication from '../../../hocs/withAuthentication';
import useDebouncedState from '../../../hooks/useDebouncedState';
import { formatPageTitle } from '../../../utils/common';
import {
  selectStoreCompanySettings,
  selectStoreCurrentCompany,
} from '../../../utils/storeSelectors';
import { getUserRoleLabel } from '../../../utils/users';
import GroupDetailsFormBox from './GroupDetailsFormBox';
import useGroupDetails from './useGroupDetails';

const { TabPane } = Tabs;

const AllUsersColumns = t => [
  {
    width: 100,
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: status => <StatusTag status={status} />,
    checked: true,
    sorter: true,
  },
  {
    defaultSortOrder: 'ascend',
    wdith: 200,
    key: ['firstName', 'lastName'].join(','),
    title: 'Name',
    ellipsis: true,
    checked: true,
    editable: true,
    render: (actions, data) => <ProfileNamePicture t={t} showStatus={false} user={data} />,
    sorter: true,
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    sorter: true,
    checked: true,
    editable: true,
    controlType: 'input',
  },
  {
    title: 'Group',
    dataIndex: ['group', 'name'],
    key: 'group.name',
    sorter: true,
    checked: true,
    render: (action, data) => data.group?.name || '-',
  },
  {
    width: 100,
    title: 'Role',
    dataIndex: 'role',
    key: 'role',
    sorter: true,
    checked: true,
    render: (actions, data) => {
      return getUserRoleLabel(data.role);
    },
  },
];

const badgeIcon = selectedRowKeysInAllTable => {
  const count = selectedRowKeysInAllTable.length;
  return count ? (
    <Badge count={count} className="users-count">
      All Users
    </Badge>
  ) : (
    'All Users'
  );
};

const GroupDetails = props => {
  const { t, match, history, location } = props;

  const [allUsersSearchTerm, setAllUsersSearchTerm] = useDebouncedState();

  const {
    dirty,
    formInstance,
    commuteState,
    isSubmitting,
    model,
    initialModel,
    newItem,
    allUsersList,
    allUsersQuery,
    allUsersTablePagination,
    allUsersHandleTableSort,
    groupManagersLists,
    groupUserList,
    groupUsersQuery,
    groupUsersTablePagination,
    groupUsersQueryHandleTableSort,
    invitedUserList,
    isInviteUserModalVisible,
    showDefaultGroupSetModal,
    defaultGroup,
    selectedRowKeysInAllTable,
    pageSubtitle,
    newInvitedUsers,

    // Handlers
    goToGroupManagementPage,
    inviteUserChanged,
    changeDefaultGroup,
    setDefaultGroup,
    setGroupToDefault,
    cancelSetDefaultGroup,
    changeSelectedGroupsInCurrentTable,
    changeSelectedGroupsInAllTable,
    openInviteUserModal,
    closeInviteUserModal,
    handleModelChange,
    handleGroupManagerChange,
    handleAdditionalManagerAdd,
    handleAdditionalManagerRemove,
    onFinish,
    formValuesChange,
  } = useGroupDetails({ match, history, allUsersSearchTerm });

  const groupsData = useSelector(store => store.group);
  const companySettings = useSelector(selectStoreCompanySettings);
  const currentCompany = useSelector(selectStoreCurrentCompany);

  const rowSelectionInCurrentGroup = {
    selectedRowKeys: invitedUserList.map(user => user._id || user.key),
    onChange: (selectedRowKeys, selectedRows) => {
      changeSelectedGroupsInCurrentTable(selectedRowKeys, selectedRows);
    },
  };

  const rowSelectionInAllGroups = {
    selectedRowKeys: selectedRowKeysInAllTable,
    onChange: (selectedRowKeys, selectedRows) => {
      changeSelectedGroupsInAllTable(selectedRowKeys, selectedRows);
    },
  };

  return (
    <PageContainer
      title={
        <PageBreadcrumbs
          items={[
            {
              label: t('Groups'),
              onClick: () => history.push(INTERNAL_LINKS.GROUP_MANAGEMENT),
            },
            model && model.name ? { label: model.name } : undefined,
          ]}
        />
      }
      caption={pageSubtitle}
      className="group-details"
      pageActionText={newInvitedUsers.length ? t('Edit Users to Invite') : t('Invite New User')}
      onPageActionClick={openInviteUserModal}
    >
      <Helmet>
        <title>{formatPageTitle(match.params.id ? 'Group Details' : 'Create Group')}</title>
      </Helmet>

      <div className="group-details-container">
        <Form
          form={formInstance}
          labelCol={{ span: 24 }}
          colon={false}
          onFinish={onFinish}
          onValuesChange={formValuesChange}
          initialValues={{ ...model, ...(model.commute || {}) }}
        >
          <GroupDetailsFormBox
            t={t}
            isUpdate={!!match.params.id}
            isSubmitting={isSubmitting}
            group={model}
            initialGroupValues={initialModel}
            groupMembers={groupUserList}
            defaultGroup={defaultGroup}
            groupManagerList={groupManagersLists}
            onManagerChange={handleGroupManagerChange}
            onChangeDefaultGroup={changeDefaultGroup}
            onAddAdditionalManager={handleAdditionalManagerAdd}
            onRemoveAdditionalManager={handleAdditionalManagerRemove}
            onModelChange={handleModelChange}
            commuteSettings={commuteState.commuteSettings}
            onCommuteSettingsChange={commuteState.handleCommuteSettingChange}
            isCommuteSettingCustom={commuteState.isCommuteSettingCustom}
            isCommuteSettingSingleLocation={commuteState.isCommuteSettingSingleLocation}
            isCommuteSettingNotHaveOffice={commuteState.isCommuteSettingNotHaveOffice}
            isNoOfficeMaxDeduction={commuteState.isNoOfficeMaxDeduction}
            isNoOfficeIgnoreFirstAndLast={commuteState.isNoOfficeIgnoreFirstAndLast}
            isHomeAddressMaxCommuteDeduction={commuteState.isHomeAddressMaxCommuteDeduction}
            commuteDistanceUnit={commuteState.commuteDistanceUnit}
          />

          {/* Tables */}
          <div className="users-list-table">
            <Text variant="h5">Add Existing Users to Group</Text>
            <Tabs className="users-list-table-tabs" defaultActiveKey="current">
              <TabPane tab="Users in Current Group" key="current">
                <EditableTable
                  asyncSort
                  loading={isSubmitting || groupUsersQuery.isFetching}
                  showSearchInput={false}
                  rowSelection={
                    newInvitedUsers.length
                      ? {
                          type: 'checkbox',
                          ...rowSelectionInCurrentGroup,
                        }
                      : false
                  }
                  bordered
                  rowKey={user => user._id}
                  dataSource={groupUserList}
                  columns={AllUsersColumns(t).filter(col => col.title !== 'Group')}
                  newItem={newItem}
                  addButtonLabel="Invite New User"
                  onDataChange={inviteUserChanged}
                  onChange={({ current }, _, sorters) => {
                    groupUsersTablePagination.handlePageChange(current);
                    groupUsersQueryHandleTableSort(sorters?.columnKey, sorters?.order);
                  }}
                  pagination={{
                    pageSize: groupUsersTablePagination.paginationConfig.pageSize,
                    total: groupUsersTablePagination.paginationConfig.total,
                    current: groupUsersTablePagination.paginationConfig.current,
                    onShowSizeChange: groupUsersTablePagination.handlePageSizeChange,
                  }}
                />
              </TabPane>
              <TabPane tab={badgeIcon(selectedRowKeysInAllTable)} key="all">
                <CustomTable
                  asyncSort
                  loading={isSubmitting || allUsersQuery.isFetching}
                  rowKey={user => user._id}
                  onSearchTermChange={setAllUsersSearchTerm}
                  rowSelection={{
                    type: 'checkbox',
                    ...rowSelectionInAllGroups,
                  }}
                  dataSource={allUsersList}
                  columns={AllUsersColumns(t)}
                  onChange={({ current }, _, sorters) => {
                    allUsersTablePagination.handlePageChange(current);
                    allUsersHandleTableSort(sorters?.columnKey, sorters?.order);
                  }}
                  pagination={{
                    pageSize: allUsersTablePagination.paginationConfig.pageSize,
                    total: allUsersTablePagination.paginationConfig.total,
                    current: allUsersTablePagination.paginationConfig.current,
                    onShowSizeChange: allUsersTablePagination.handlePageSizeChange,
                  }}
                />
              </TabPane>
            </Tabs>
          </div>
          <SubmitCancelButtonGroup
            isForm
            submitDisabled={!(dirty || commuteState.isCommuteSettingsDirty)}
            disabled={isSubmitting}
            loading={isSubmitting}
            onCancel={() => goToGroupManagementPage(true)}
            submitText={t('Save')}
          />
        </Form>
      </div>
      <DataModal
        className="defaultgroup-modal"
        visible={showDefaultGroupSetModal}
        title="Please select the group to be default."
        onOk={setDefaultGroup}
        onCancel={cancelSetDefaultGroup}
        destroyOnClose={true}
      >
        <FormItem label="Group">
          <Select
            placeholder="Please select a group to be default"
            onChange={setGroupToDefault}
            options={
              groupsData?.groupList
                ? groupsData.groupList
                    .filter(group => group._id !== match.params.id)
                    .map(group => ({
                      label: group.name,
                      value: group._id,
                    }))
                : []
            }
          />
        </FormItem>
      </DataModal>

      <InviteNewUsersModal
        existingUsers={allUsersList}
        defaultValues={invitedUserList}
        visible={isInviteUserModalVisible}
        onCancel={closeInviteUserModal}
        requireEmployeeID={companySettings?.requireEmployeeNumber}
        companyAddress={currentCompany?.address}
        onFinish={async usersToInvite => {
          inviteUserChanged(usersToInvite);
          closeInviteUserModal();
        }}
      />

      <UnloadWarningModal
        loading={isSubmitting}
        when={dirty}
        history={history}
        location={location}
        message={
          <div>
            <Text>{t('youMadeChangesToGroupSaveChanges?')}</Text>
          </div>
        }
      />
    </PageContainer>
  );
};

GroupDetails.propTypes = {};

GroupDetails.defaultProps = {};

export default withNamespaces()(withAuthentication(GroupDetails));
