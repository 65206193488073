import { Col, DatePicker, Form, Row } from 'antd';
import React, { useMemo, useState } from 'react';
import { useQuery } from 'react-query';

import { handleApiErrors } from '../../../../api/axiosInstance';
import { DWOLLA_API } from '../../../../api/dwolla';
import FormItem from '../../../../components/Form/FormItem';
import LocationInput from '../../../../components/LocationInput';
import Radio from '../../../../components/Radio';
import Select from '../../../../components/Select';
import SpaceSpinner from '../../../../components/SpaceSpinner';
import TextInput from '../../../../components/TextInput';
import Toast from '../../../../components/Toast';
import { DWOLLA_BUSINESS_TYPES } from '../../../../enum/Dwolla';
import { analyseAddressAutoCompleteString } from '../../../../utils/address';
import {
  DATE_OF_BIRTH_FORM_RULE,
  ERROR_MESSAGE,
  FORMAT_FORM_RULE,
} from '../../../../utils/constants';
import { selectStoreCountryByCode } from '../../../../utils/storeSelectors';

const BusinessInformationForm = props => {
  const {
    t,
    form,
    isUpdate,
    formDisabled,
    isSubmitting,
    initialValues,
    onFieldChange,
    fullSsnRequired,
  } = props;

  const UNITED_STATES = useMemo(() => selectStoreCountryByCode('US'), []);

  const [businessType, setBusinessType] = useState(initialValues?.businessType);
  const [citizenship, setCitizenship] = useState(
    initialValues?.citizenship || initialValues?.controllerCitizenship,
  );

  const { isFetching, data: fullListOfTypes } = useQuery({
    initialData: [],
    queryKey: ['dwollaBusinessClassificationList'],
    refetchOnMount: ({ state: { data } }) => !data?.length,
    queryFn: DWOLLA_API.fetchDwollaBusinessClassificationList,
    onError: error => {
      handleApiErrors(error.response, () => {
        Toast({
          type: 'error',
          message: t('businessClassificationFetchError'),
        });
      });
    },
  });

  const typeOptions = useMemo(() => {
    return [
      {
        label: t('soleProprietorship'),
        value: DWOLLA_BUSINESS_TYPES.SOLE_PROPRIETORSHIP,
      },
      {
        label: t('corporation'),
        value: DWOLLA_BUSINESS_TYPES.CORPORATION,
      },
      {
        label: t('llc'),
        value: DWOLLA_BUSINESS_TYPES.LLC,
      },
      {
        label: t('partnership'),
        value: DWOLLA_BUSINESS_TYPES.PARTNERSHIP,
      },
    ];
  }, [t]);

  const classificationOptions = useMemo(() => {
    const array = [];

    fullListOfTypes.forEach(classificationType => {
      array.push({
        label: classificationType.type,
        options: classificationType.classifications.map(classItem => {
          return {
            value: classItem.id,
            label: classItem.name,
          };
        }),
      });
    });

    return array;
  }, [fullListOfTypes]);

  const handleStreetOneSelect = address => {
    const analysedString = analyseAddressAutoCompleteString(address);
    const { streetOne, city, state, postalCode } = analysedString;

    if (form) {
      form.setFieldsValue({
        businessAddress1: streetOne,
        businessState: state,
        businessCity: city,
        businessPostalCode: postalCode,
      });
    }
  };

  if (isFetching) return <SpaceSpinner />;

  return (
    <Form form={form} initialValues={initialValues} onValuesChange={onFieldChange}>
      <FormItem
        required
        name="businessName"
        label={t('businessName')}
        rules={[{ required: true, message: ERROR_MESSAGE.BLANK_FIELD }]}
      >
        <TextInput disabled={isSubmitting || formDisabled || isUpdate} />
      </FormItem>

      <FormItem
        required
        name="businessType"
        label={t('businessType')}
        rules={[{ required: true, message: ERROR_MESSAGE.BLANK_FIELD }]}
      >
        <Select
          showSearch
          fullWidth
          optionFilterProp="children"
          disabled={isSubmitting || formDisabled || isUpdate}
          options={typeOptions}
          onChange={type => setBusinessType(type)}
        />
      </FormItem>

      <FormItem
        required
        name="businessClassification"
        label={t('businessClassification')}
        rules={[{ required: true, message: ERROR_MESSAGE.BLANK_FIELD }]}
      >
        <Select
          showSearch
          fullWidth
          optionFilterProp="children"
          disabled={isFetching || isSubmitting || formDisabled || isUpdate}
          loading={isFetching}
          options={classificationOptions}
        />
      </FormItem>

      <FormItem
        required
        name="ein"
        label={t('ein')}
        rules={[
          { required: businessType !== 'soleProprietorship', message: ERROR_MESSAGE.BLANK_FIELD },
          ...FORMAT_FORM_RULE(/[0-9]{2}-[0-9]{7}/),
        ]}
      >
        <TextInput
          masked
          disabled={isSubmitting || formDisabled || formDisabled || isUpdate}
          placeholder="00-0000000"
          mask="00-0000000"
        />
      </FormItem>

      <FormItem
        required
        name="businessAddress1"
        label={t('businessAddress1')}
        rules={[{ required: true, message: ERROR_MESSAGE.BLANK_FIELD }]}
      >
        <LocationInput
          disabled={isSubmitting || (formDisabled && !isUpdate)}
          autoComplete="new-password"
          limitedToCountryCode="US"
          onSelect={handleStreetOneSelect}
        />
      </FormItem>

      <FormItem name="businessAddress2" label={t('businessAddress2')}>
        <TextInput disabled={isSubmitting || (formDisabled && !isUpdate)} />
      </FormItem>

      <Row gutter={24}>
        <Col xs={24} md={8}>
          <FormItem
            required
            name="businessState"
            label={t('state')}
            rules={[{ required: true, message: ERROR_MESSAGE.BLANK_FIELD }]}
          >
            <Select
              fullWidth
              showSearch
              disabled={isSubmitting || (formDisabled && !isUpdate)}
              optionFilterProp="children"
              options={UNITED_STATES.states}
            />
          </FormItem>
        </Col>
        <Col xs={24} md={8}>
          <FormItem
            required
            name="businessCity"
            label={t('city')}
            rules={[{ required: true, message: ERROR_MESSAGE.BLANK_FIELD }]}
          >
            <TextInput disabled={isSubmitting || (formDisabled && !isUpdate)} />
          </FormItem>
        </Col>

        <Col xs={24} md={8}>
          <FormItem
            required
            name="businessPostalCode"
            label={t('postalCode')}
            rules={[{ required: true, message: ERROR_MESSAGE.BLANK_FIELD }]}
          >
            <TextInput disabled={isSubmitting || (formDisabled && !isUpdate)} />
          </FormItem>
        </Col>
      </Row>

      <Row gutter={24}>
        <Col xs={24} md={12}>
          <FormItem
            required
            name="businessOwnerFirstName"
            label={t('businessOwnerFirstName')}
            rules={[{ required: true, message: ERROR_MESSAGE.BLANK_FIELD }]}
          >
            <TextInput disabled={isSubmitting || formDisabled || isUpdate} />
          </FormItem>
        </Col>

        <Col xs={24} md={12}>
          <FormItem
            required
            name="businessOwnerLastName"
            label={t('businessOwnerLastName')}
            rules={[{ required: true, message: ERROR_MESSAGE.BLANK_FIELD }]}
          >
            <TextInput disabled={isSubmitting || formDisabled || isUpdate} />
          </FormItem>
        </Col>
      </Row>

      <FormItem
        required
        name="businessOwnerEmail"
        label={t('businessOwnerEmail')}
        rules={[{ required: true, type: 'email', message: ERROR_MESSAGE.VALID_EMAIL }]}
      >
        <TextInput type="email" disabled={isSubmitting || (formDisabled && !isUpdate)} />
      </FormItem>

      <FormItem
        required
        name="businessDateOfBirth"
        label={t('dateOfBirthBusinessOwner')}
        rules={[
          { required: true, message: ERROR_MESSAGE.BLANK_FIELD },
          ...DATE_OF_BIRTH_FORM_RULE(18),
        ]}
      >
        <DatePicker
          autoComplete="new-password"
          placeholder="YYYY-MM-DD"
          disabled={isSubmitting || formDisabled || isUpdate}
          style={{ width: '100%' }}
        />
      </FormItem>

      {(fullSsnRequired || initialValues?.businessType === 'soleProprietorship') && (
        <Row gutter={24}>
          <Col xs={24} md={8}>
            <FormItem
              required
              name="citizenship"
              label={t('citizenship')}
              rules={[{ required: true, message: ERROR_MESSAGE.BLANK_FIELD }]}
            >
              <Radio.Group
                disabled={isSubmitting || formDisabled || (isUpdate && !fullSsnRequired)}
                name="citizenship"
                onChange={e => {
                  setCitizenship(e.target.value);
                  form.setFieldsValue({ controllerSSN: undefined });
                }}
              >
                <Radio value="us">{t('usCitizen')}</Radio>
                <Radio value="non-us">{t('nonUsCitizen')}</Radio>
              </Radio.Group>
            </FormItem>
          </Col>
          <Col xs={24} md={16}>
            <FormItem
              required
              name="ssn"
              label={t(citizenship === 'us' && !fullSsnRequired ? 'ssnLast4' : 'ssnAllDigits')}
              rules={[
                { required: true, message: ERROR_MESSAGE.BLANK_FIELD },
                ...(citizenship === 'us' && !fullSsnRequired
                  ? [
                      { type: 'string', max: 4, message: ERROR_MESSAGE.STRING_MAX_LENGTH(4) },
                      { type: 'string', min: 4, message: ERROR_MESSAGE.STRING_MIN_LENGTH(4) },
                    ]
                  : [
                      { type: 'string', min: 9, message: ERROR_MESSAGE.STRING_MIN_LENGTH(9) },
                      ...FORMAT_FORM_RULE(/[0-9]{3}-[0-9]{2}-[0-9]{4}/),
                    ]),
              ]}
            >
              <TextInput
                pattern="\d*"
                disabled={
                  isSubmitting || formDisabled || formDisabled || (isUpdate && !fullSsnRequired)
                }
                minLength={4}
                maxLength={citizenship === 'us' && !fullSsnRequired ? 4 : undefined}
                masked={citizenship !== 'us' || fullSsnRequired}
                mask={citizenship === 'us' && !fullSsnRequired ? '0000' : '000-00-0000'}
                placeholder={citizenship === 'us' && !fullSsnRequired ? '0000' : '000-00-0000'}
              />
            </FormItem>
          </Col>
        </Row>
      )}
    </Form>
  );
};

export default BusinessInformationForm;
