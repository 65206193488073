import { Form } from 'antd';
import React from 'react';

import useDidUpdateEffect from '../../../hooks/useDidUpdateEffect';
import { getCompanyCurrency } from '../../../utils/company';
import { ERROR_MESSAGE } from '../../../utils/constants';
import FormItem from '../../Form/FormItem';
import SubmitCancelButtonGroup from '../../SubmitCancelButtonGroup';
import Text from '../../Text';
import TextInput from '../../TextInput';
import GenericModal from '../GenericModal';

const AchWithdrawFundsModal = props => {
  const { t, visible, isSubmitting, onCancel, onSubmit, currentBalance } = props;

  const [form] = Form.useForm();

  useDidUpdateEffect(() => {
    if (!visible) {
      form.resetFields();
    }
  }, [visible]);

  return (
    <GenericModal
      visible={visible}
      title={t('withdrawFunds')}
      closable={!isSubmitting}
      onCancel={onCancel}
      footer={
        <SubmitCancelButtonGroup
          onCancel={onCancel}
          loading={isSubmitting}
          disabled={isSubmitting}
          submitText={t('confirm&Transfer')}
          onSubmit={form.submit}
        />
      }
    >
      <Form form={form} onFinish={onSubmit}>
        <Text variant="h5">{t('amountToWithdrawFromKliksAccount')}</Text>
        <FormItem
          required
          name="amount"
          extra={t('amountGreaterThan10KWillBeSplit')}
          rules={[
            { required: true, message: ERROR_MESSAGE.BLANK_FIELD },
            {
              validateTrigger: 'onSubmit',
              validator: (_, value) => {
                if (!value) return Promise.resolve();

                if (value <= 0) {
                  return Promise.reject(ERROR_MESSAGE.MIN_NUMBER(1));
                } else if (value > currentBalance) {
                  return Promise.reject(t('amountCannotBeGreaterThanAccountBalance'));
                }

                return Promise.resolve();
              },
            },
          ]}
        >
          <TextInput
            min={0}
            pattern="\d*"
            type="number"
            step="any"
            disabled={isSubmitting}
            prefix={getCompanyCurrency()?.currencySymbol}
          />
        </FormItem>

        <Text>{t('allow45DBusinessaysForProcessing')}</Text>
      </Form>
    </GenericModal>
  );
};

AchWithdrawFundsModal.defaultProps = {
  currentBalance: 0,
};

export default AchWithdrawFundsModal;
