import { MinusCircleOutlined } from '@ant-design/icons';
import { Col, Form, Row } from 'antd';
import { find, findIndex, first, get, last, merge, pick } from 'lodash';
import React, { useCallback, useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { withNamespaces } from 'react-i18next';
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';

import { handleApiErrors } from '../../api/axiosInstance';
import { SETTINGS_API } from '../../api/settings';
import Box from '../../components/Box';
import FormItem from '../../components/Form/FormItem';
import PageBreadcrumbs from '../../components/PageBreadcrumbs';
import SpaceSpinner from '../../components/SpaceSpinner';
import Spinner from '../../components/Spinner';
import SubmitCancelButtonGroup from '../../components/SubmitCancelButtonGroup';
import HorizontalSwitch from '../../components/Switch/HorizontalSwitch';
import Text from '../../components/Text';
import TextInput from '../../components/TextInput';
import Toast from '../../components/Toast';
import PageContainer from '../../containers/PageContainer';
import { INTERNAL_LINKS } from '../../enum';
import withAuthentication from '../../hocs/withAuthentication';
import {
  emitFetchGlobalSettings,
  emitUpdateGlobalSettings,
} from '../../stores/actions/systemAdmin';
import { formatPageTitle } from '../../utils/common';
import { ERROR_MESSAGE } from '../../utils/constants';
import { normalizeGlobalSettings } from '../../utils/settings';

const MetabaseReportUpdateView = props => {
  const { t, match, history } = props;
  const dispatch = useDispatch();
  const { globalSettings } = useSelector(store => store.systemAdmin);

  const [form] = Form.useForm();

  useEffect(() => {
    if (!globalSettings) {
      dispatch(emitFetchGlobalSettings());
    }
    // eslint-disable-next-line
  }, []);

  const metabaseReport = useMemo(() => {
    const optional = [];
    const required = [];

    if (!globalSettings) {
      form.setFieldValue('required', []);
      form.setFieldValue('optional', []);
      return null;
    }

    if (!match.params.id) {
      form.setFieldValue('optional', [
        { name: 'companyId', keyName: 'companyId' },
        { name: 'groupId', keyName: 'groupId' },
        { name: 'userId', keyName: 'userId' },
      ]);
      return {};
    }

    const report = find(get(globalSettings, 'metabase.reports', []), { _id: match.params.id });

    if (!report && match.params.id) {
      history.replace(INTERNAL_LINKS.GLOBAL_SETTINGS);
      return null;
    }

    const reportKeys = Object.keys(report);
    Object.values(report).forEach((val, i) => {
      if (val?.hasOwnProperty('keyName')) {
        if (!!get(val, 'required')) {
          required.push({ name: reportKeys[i], keyName: get(val, 'keyName') || null });
        } else {
          optional.push({ name: reportKeys[i], keyName: get(val, 'keyName') || null });
        }
      }
    });

    form.setFieldValue('required', required);
    form.setFieldValue('optional', optional);

    return report;
  }, [form, globalSettings, history, match.params.id]);

  const renderParamsList = useCallback(
    (type, label, emptyText) => {
      return (
        <FormItem label={label}>
          <Form.List name={type}>
            {(fields, { remove }) => {
              if (!fields.length) {
                return <Text size="sm">{emptyText}</Text>;
              }

              return fields.map(({ key, name, ...restField }) => (
                <Row align="middle" gutter={[16, 16]} style={{ width: '430px' }}>
                  <Col flex="100px">
                    <Text size="sm" variant="b">
                      {form.getFieldValue([type, name])?.name}
                    </Text>
                  </Col>
                  <Col flex="300px">
                    <FormItem
                      noStyle
                      shouldUpdate={(prevValues, curValues) =>
                        prevValues.keyName !== curValues.keyName
                      }
                    >
                      <FormItem
                        {...restField}
                        label={t('keyName')}
                        name={[name, 'keyName']}
                        extra={type === 'optional' ? t('leaveKeyNameEmptyToIgnoreParam') : ''}
                        rules={[
                          { required: type === 'required', message: ERROR_MESSAGE.BLANK_FIELD },
                        ]}
                      >
                        <TextInput />
                      </FormItem>
                    </FormItem>
                  </Col>
                  <Col>
                    <MinusCircleOutlined
                      className="item-container-trash"
                      onClick={() => {
                        const oppositiveFieldType = type === 'required' ? 'optional' : 'required';
                        form.setFieldValue(oppositiveFieldType, [
                          ...form.getFieldValue(oppositiveFieldType),
                          {
                            name: form.getFieldValue([type, name])?.name,
                            keyName:
                              oppositiveFieldType === 'optional'
                                ? ''
                                : form.getFieldValue([type, name])?.keyName,
                          },
                        ]);
                        remove(name);
                      }}
                    />
                  </Col>
                </Row>
              ));
            }}
          </Form.List>
        </FormItem>
      );
    },
    [form, t],
  );

  const handleReportUpdateMutation = useMutation(
    values => {
      const reports = get(globalSettings, 'metabase.reports');
      const sharedValues = pick(values, [
        'name',
        'type',
        'visibleInReports',
        'dashboardId',
        'flagsmithName',
      ]);

      if (match.params.id) {
        const reportIndex = findIndex(reports, { _id: match.params.id });
        merge(reports[reportIndex], sharedValues);

        values.required.forEach(param => {
          reports[reportIndex][param.name].required = true;
          reports[reportIndex][param.name].keyName = param.keyName;
        });

        values.optional.forEach(param => {
          reports[reportIndex][param.name].required = false;
          reports[reportIndex][param.name].keyName = param.keyName;
        });
      } else {
        const newReport = merge({}, sharedValues);

        values.required.forEach(param => {
          newReport[param.name] = {};
          newReport[param.name].required = true;
          newReport[param.name].keyName = param.keyName;
        });

        values.optional.forEach(param => {
          newReport[param.name] = {};
          newReport[param.name].required = false;
          newReport[param.name].keyName = param.keyName;
        });

        reports.push(newReport);
      }

      return SETTINGS_API.updateGlobalSettings(globalSettings._id, { metabase: { reports } });
    },
    {
      onSuccess: settings => {
        Toast({
          type: 'open',
          message: match.params.id
            ? t('updateMetabaseReportSuccess')
            : t('createMetabaseReportSuccess'),
        });

        if (!match.params.id) {
          history.push(`${INTERNAL_LINKS.GLOBAL_SETTINGS}#metabaseReports`);
        }

        dispatch(emitUpdateGlobalSettings(normalizeGlobalSettings(settings)));
      },
      onError: error => handleApiErrors(error.response),
    },
  );

  useEffect(() => {
    const pageHeader = document.querySelector('.ant-breadcrumb');
    if (pageHeader) {
      pageHeader.scrollIntoView();
    }
  }, []);

  if (!globalSettings || (!metabaseReport && match.params.id)) {
    return <SpaceSpinner />;
  }

  return (
    <PageContainer
      title={
        <>
          <Helmet>
            <title>{formatPageTitle('Update Metabase Report')}</title>
          </Helmet>
          <PageBreadcrumbs
            items={[
              {
                label: t('globalSettings'),
                onClick: () => history.push(`${INTERNAL_LINKS.GLOBAL_SETTINGS}#metabaseReports`),
              },
              {
                label: t('metabaseReports'),
              },
              { label: metabaseReport?.name || t('createReport') },
            ]}
          />
        </>
      }
    >
      <Row justify="left">
        <Col flex="832px">
          <Box>
            <Spinner spinning={handleReportUpdateMutation.isLoading}>
              <Form
                disabled={handleReportUpdateMutation.isLoading}
                form={form}
                initialValues={{
                  ...metabaseReport,
                  required: [],
                  optional: [],
                }}
              >
                <section>
                  <FormItem name="visibleInReports" valuePropName="checked">
                    <HorizontalSwitch label={t('enabled')} size="medium" />
                  </FormItem>

                  <FormItem
                    label={t('metabaseReportName')}
                    name="name"
                    rules={[{ required: true, message: ERROR_MESSAGE.BLANK_FIELD }]}
                  >
                    <TextInput placeholder={t('copyNameHere')} />
                  </FormItem>

                  <FormItem
                    label={t('type')}
                    name="type"
                    rules={[
                      { required: true, message: ERROR_MESSAGE.BLANK_FIELD },
                      {
                        validateTrigger: ['onSubmit'],
                        validator: (_, value) => {
                          if (!value) return Promise.resolve();

                          const reports = get(globalSettings, 'metabase.reports', []);
                          const duplicateReport = find(reports, { type: value });
                          if (duplicateReport && duplicateReport._id !== match?.params?.id) {
                            return Promise.reject(t('reportExistWithSameType'));
                          }

                          return Promise.resolve();
                        },
                      },
                    ]}
                    extra={t('alphanumStringSplitByHyphens')}
                  >
                    <TextInput
                      placeholder="all-trips"
                      onKeyPress={event => {
                        if (!/[a-zA-Z0-9-]/gi.test(event.key)) {
                          event.preventDefault();

                          const currentValue = event?.target?.value || '';

                          if (event.key === ' ' && last(currentValue.split('')) !== '-') {
                            form.setFieldValue('type', currentValue + '-');
                          }
                        }
                      }}
                      onBlur={event => {
                        const currentValue = event?.target?.value || '';
                        const lastChar = last(currentValue.split(''));
                        const firstChar = first(currentValue.split(''));

                        if (lastChar === '-' || firstChar === '-') {
                          form.setFieldValue('type', currentValue.replace(/^-|-$/gi, ''));
                        }
                      }}
                    />
                  </FormItem>

                  <FormItem
                    label={t('metabaseReportId')}
                    name="dashboardId"
                    rules={[{ required: true, message: ERROR_MESSAGE.BLANK_FIELD }]}
                  >
                    <TextInput placeholder={t('copyIdHere')} />
                  </FormItem>

                  <FormItem label={t('flagsmithFlagName')} name="flagsmithName">
                    <TextInput />
                  </FormItem>
                </section>

                <section>
                  <Text variant="h5">{t('metabaseReportParams')}</Text>

                  {renderParamsList(
                    'required',
                    t('metabaseRequiredParams'),
                    t('allMetabaseParamsOptional'),
                  )}

                  {renderParamsList(
                    'optional',
                    t('metabaseOptionalParams'),
                    t('allMetabaseParamsRequired'),
                  )}
                </section>

                <SubmitCancelButtonGroup
                  hideCancel
                  submitText={t('update')}
                  onSubmit={() => {
                    form.validateFields().then(handleReportUpdateMutation.mutateAsync);
                  }}
                />
              </Form>
            </Spinner>
          </Box>
        </Col>
      </Row>
    </PageContainer>
  );
};

export default withNamespaces()(withAuthentication(MetabaseReportUpdateView));
