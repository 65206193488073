import { Col, Row } from 'antd';
import React from 'react';

import { STATUS_LIST } from '../../../enum';
import { momentTimezone } from '../../../utils/common';
import Button from '../../Button';
import Text from '../../Text';
import Tooltip from '../../Tooltip';
import classNames from './styles.module.scss';

const CompanyAdminPreviewTooltip = props => {
  const { t, user, children, onImpersonate, isRequestingImpersonation, ...rest } = props;

  return (
    <Tooltip
      {...rest}
      className={classNames.companyTooltip}
      placement="bottom"
      title={
        <Row gutter={16}>
          <Col flex={1}>
            <Row>
              <Col flex={1}>
                <Text variant="strong">
                  {t('status')}:{' '}
                  <Text renderAs="span">
                    {t(STATUS_LIST.StatusTagColorConfig[user.status]?.label)}
                  </Text>
                </Text>
              </Col>
            </Row>

            <Row>
              <Col>
                <Text variant="strong">
                  {t('Email')}: <Text renderAs="span">{user.email}</Text>
                </Text>
              </Col>
            </Row>

            <Row>
              <Col>
                <Text variant="strong">
                  {t('lastLogin')}:{' '}
                  <Text renderAs="span">{momentTimezone(user.lastLogin).format('LL')}</Text>
                </Text>
              </Col>
            </Row>
          </Col>

          <Col flex={1}>
            <Button
              size="xs"
              onClick={onImpersonate}
              disabled={isRequestingImpersonation}
              loading={isRequestingImpersonation}
            >
              {t('impersonate')}
            </Button>
          </Col>
        </Row>
      }
    >
      {children}
    </Tooltip>
  );
};

export default CompanyAdminPreviewTooltip;
