import React, { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { withNamespaces } from 'react-i18next';

import { handleApiErrors } from '../../api/axiosInstance';
import { STANDARD_VEHICLES_API } from '../../api/cars';
import StandardVehiclesTable from '../../components/Table/StandardVehiclesTable';
import PageContainer from '../../containers/PageContainer';
import { formatPageTitle } from '../../utils/common';

const StandardVehiclesView = props => {
  const { t } = props;

  const [isLoadingData, setIsLoadingData] = useState(false);
  const [standardVehicles, setStandardVehicles] = useState([]);

  const loadStandardVehicles = useCallback(async () => {
    setIsLoadingData(true);

    try {
      const vehicles = await STANDARD_VEHICLES_API.fetchAllStandardVehicles(2022);
      setStandardVehicles(vehicles.map(vehicle => JSON.parse(vehicle)));
    } catch (error) {
      handleApiErrors(error.response);
    }

    setIsLoadingData(false);
  }, []);

  useEffect(() => {
    loadStandardVehicles();
  }, [loadStandardVehicles]);

  return (
    <PageContainer title={t('standardVehicles')}>
      <Helmet>
        <title>{formatPageTitle('Standard Vehicles')}</title>
      </Helmet>

      <StandardVehiclesTable t={t} loading={isLoadingData} dataSource={standardVehicles} />
    </PageContainer>
  );
};

export default withNamespaces()(StandardVehiclesView);
