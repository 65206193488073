import React from 'react';

import Select from '../Select';
import {
  BUSINESS_COMMUTE_TRIP_TYPE,
  BUSINESS_TRIP_TYPE,
  COMMUTE_TRIP_TYPE,
  PERSONAL_TRIP_TYPE,
} from './trip-type-select-constants';
import { canSelectTripType } from './trip-type-select-permissions';

const TripTypeSelect = props => {
  const { trip, value, onChange, ...rest } = props;

  return (
    <Select
      {...rest}
      placeholder="Select a purpose"
      value={value}
      onChange={onChange}
      options={[
        {
          disabled: !canSelectTripType(BUSINESS_TRIP_TYPE, value, trip),
          value: BUSINESS_TRIP_TYPE,
          label: 'Business',
        },
        {
          disabled: !canSelectTripType(COMMUTE_TRIP_TYPE, value, trip),
          value: COMMUTE_TRIP_TYPE,
          label: 'Commute',
        },
        {
          disabled: !canSelectTripType(PERSONAL_TRIP_TYPE, value, trip),
          value: PERSONAL_TRIP_TYPE,
          label: 'Personal',
        },
        {
          disabled: true,
          value: BUSINESS_COMMUTE_TRIP_TYPE,
          label: 'Business-Commute',
        },
      ]}
    />
  );
};

export default TripTypeSelect;
