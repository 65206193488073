import { Col, Descriptions, List, Row, Space } from 'antd';
import { get } from 'lodash';
import React, { useMemo, useState } from 'react';

import { momentTimezone } from '../../../utils/common';
import { renderBooleanCheckmarks } from '../../../utils/tables';
import { renderCheckmarkFromBoolean } from '../../../utils/tables';
import { Button, CustomTable } from '../..';
import StatusTag from '../../Tag/StatusTag';
import Text from '../../Text';

const MeasureOneInsuranceItemsTable = props => {
  const { t, dataSource, canSelect, onSelect, isSelecting, onPreviewDocument, ...rest } = props;

  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  const toggleExpandedRow = row => {
    if (!expandedRowKeys.includes(row.createdAt)) {
      setExpandedRowKeys([row.createdAt]);
    } else {
      setExpandedRowKeys([]);
    }
  };

  const COLUMNS = useMemo(
    () => [
      {
        title: t('status'),
        dataIndex: 'processingStatus',
        render: processingStatus => <StatusTag status={processingStatus} />,
      },
      {
        title: t('reason'),
        dataIndex: 'reasonCodes',
        render: reasonCodes => get(reasonCodes, '[0].description'),
      },
      {
        width: '150px',
        title: t('date'),
        dataIndex: 'createdAt',
        render: date => momentTimezone(date).format('MM-DD-YYYY'),
      },
      {
        width: '150px',
        title: t('lastUpdated'),
        dataIndex: 'updatedAt',
        render: date => momentTimezone(date).format('MM-DD-YYYY'),
      },
      {
        align: 'center',
        title: t('duplicateDocument'),
        ...renderBooleanCheckmarks('isDuplicate'),
      },
      {
        align: 'center',
        title: '',
        render: data => {
          return (
            <Button
              size="xs"
              disabled={!data.id}
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                onPreviewDocument(data.id);
              }}
            >
              {t('previewDocument')}
            </Button>
          );
        },
      },
    ],
    [t, onPreviewDocument],
  );

  const dataSourceWithExpandable = useMemo(() => {
    return dataSource.map(item => ({
      ...item,
      expandable: !!get(item, 'data.coverages', [])?.length,
    }));
  }, [dataSource]);

  return (
    <CustomTable
      pagination={false}
      {...rest}
      withExpandableRows
      onExpand={(expanded, record) => {
        toggleExpandedRow(record);
      }}
      onRow={row => ({
        onClick: e => {
          e.preventDefault();
          e.stopPropagation();
          toggleExpandedRow(row);
        },
      })}
      showSearchInput={false}
      rowKey={data => data.createdAt}
      columns={COLUMNS}
      dataSource={dataSourceWithExpandable}
      scroll={{ x: 500 }}
      expandedRowStyle={{ padding: 0 }}
      expandedRowRender={record => {
        const coverages = get(record, 'data.coverages', []);

        return (
          <div style={{ padding: '20px', maxHeight: 200, overflowY: 'auto', overflowX: 'hidden' }}>
            <List
              dataSource={coverages}
              renderItem={coverage => {
                const vehicleInfo = get(coverage, 'details.vehicleInfo');

                return (
                  <Row gutter={[10, 10]} align="middle">
                    <Col flex={1}>
                      <List.Item key={coverage.createdAt}>
                        <Space style={{ width: '100%' }} direction="vertical">
                          <Descriptions size="small">
                            <Descriptions.Item label={t('isSelectedForComplianceCheck')}>
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  height: '100%',
                                }}
                              >
                                {renderCheckmarkFromBoolean(coverage.isSelectedForComplianceCheck)}
                              </div>
                            </Descriptions.Item>
                          </Descriptions>

                          {vehicleInfo && (
                            <div>
                              <Text variant="b">{t('vehicleInfo')}</Text>

                              <Descriptions size="small" column={{ sm: 2, xs: 1 }}>
                                <Descriptions.Item label={t('year')}>
                                  {get(vehicleInfo, 'year', '-')}
                                </Descriptions.Item>
                                <Descriptions.Item label={t('vin')}>
                                  {get(vehicleInfo, 'vin', '-')}
                                </Descriptions.Item>
                                <Descriptions.Item label={t('make')}>
                                  {get(vehicleInfo, 'make', '-')}
                                </Descriptions.Item>
                                <Descriptions.Item label={t('model')}>
                                  {get(vehicleInfo, 'model', '-')}
                                </Descriptions.Item>
                              </Descriptions>
                            </div>
                          )}
                        </Space>
                      </List.Item>
                    </Col>

                    {!coverage.isSelectedForComplianceCheck && canSelect && (
                      <Col>
                        <Button
                          size="xs"
                          loading={isSelecting}
                          disabled={isSelecting}
                          onClick={() =>
                            onSelect({
                              itemId: record.id,
                              coverageItemId: coverage.id,
                            })
                          }
                        >
                          {t('useThisDocument')}
                        </Button>
                      </Col>
                    )}
                  </Row>
                );
              }}
            />
          </div>
        );
      }}
    />
  );
};

MeasureOneInsuranceItemsTable.defaultProps = {
  dataSource: [],
};

export default MeasureOneInsuranceItemsTable;
