import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import storage from 'store';

import { INTERNAL_LINKS } from '../../enum';
import { getRedirectUri } from '../../utils/auth';
import Toast from '../Toast';

const PrivateRoute = props => {
  const { path, component: Component, render, ...rest } = props;

  return (
    <Route
      {...rest}
      path={path}
      render={routeProps => {
        if (!storage.get('accessToken')) {
          Toast({
            type: 'error',
            message: 'You must be logged in to access this page',
          });

          return <Redirect to={`${INTERNAL_LINKS.LOGIN}?redirect_uri=${getRedirectUri()}`} />;
        }

        if (typeof render === 'function') {
          return render(routeProps);
        }

        return <Component {...routeProps} />;
      }}
    />
  );
};

export default PrivateRoute;
