import React from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import _ from 'lodash';

import './style.scss';

class AdditionalInfo extends React.Component {

  render() {
    const { company } = this.props || {}
    let address = '', state = ''
    if (!_.isEmpty(company)) {
      address = `${company.address.city} in ${company.address.country}`
      state = company.address.state
    }
    return (
      <div className="additional-info">
        <h5>Additional Information:</h5>
        <div className="additional-info-section">
          <ul>
            <li>
              The default required insurance coverages and minimum values are based on the state your company is based.
            </li>
            <li>
              Your company is based in
              <span className="company-address">{` ${address} `}</span>
              You can change the state by
              <span>{` editing the company address`}</span>
              .
            </li>
            <li>
              You can configure required and minimum insurance values according to your company requirements as long as they meet the
              <span className="company-address">{` ${state} `}</span>
              state minimums
            </li>
            <li>
              If an employee is based in a different state than your company, Kliks will require the employee to at least have insurance coverage equal to the minimums as required by the state in which they reside.
            </li>
          </ul>
        </div>
      </div>
    )
  }
}

AdditionalInfo.propTypes = {
  company: PropTypes.object,
}

AdditionalInfo.defaultProps = {
  company: {}
}

export default withNamespaces()(AdditionalInfo);