import { Col, Row } from 'antd';
import { filter, merge, pick } from 'lodash';
import moment from 'moment-timezone';
import React, { useMemo, useState } from 'react';
import DotDotDot from 'react-dotdotdot';

import { IMAGES, STATUS_LIST } from '../../../enum';
import useDebouncedState from '../../../hooks/useDebouncedState';
import { checkIfStringContainsValue, getUserFullName, momentTimezone } from '../../../utils/common';
import { formatNumberWithCurrency } from '../../../utils/numbers';
import {
  renderBooleanCheckmarks,
  sortColumnByBoolean,
  sortColumnByMomentDate,
  sortColumnByNumber,
  sortColumnByStatus,
  sortColumnByStringField,
} from '../../../utils/tables';
import {
  getTripCompanyLocationFromAddressLabel,
  getTripCompanyLocationToAddressLabel,
  hasTripsWithDeductedCommuteMiles,
} from '../../../utils/trips';
import TripPurposeChip from '../../Chip/TripPurposeChip';
import ErrorBoundary from '../../ErrorBoundary';
import { Button, CustomTable } from '../../index';
import StatusTag from '../../Tag/StatusTag';
import Text from '../../Text';
import FadedText from '../../Text/FadedText';
import LinkText from '../../Text/LinkText';
import Tooltip from '../../Tooltip';
import TripTypeSelect from '../../TripTypeSelect';
import ExpandedTripsSubRow from './ExpandedTripsSubRow';
import classNames from './style.module.scss';

/**
 * Table with Trips data
 */
const TripsTableByPeriod = props => {
  const {
    t,
    onDeleteTrip,
    displayAwaitingStatusHelp,
    tripsToRemove,
    // onRemoveTrip,
    // onUndoTripRemove,
    // displayMoreInfoRemoveTrips,
    handleViewFullScreen,
    dataSource,
    companyCountry,
    // disableUpdateStatus,
    // onTripStatusUpdate,
    isRecalculatingAmount,
    recalculatingTripId,
    onAmountRecalculate,
    onTripPurposeChange,
    isRejectingTrip,
    canRejectTrip,
    onTripReject,
    onViewMoreComments,
    onFetchDetails,
    ...rest
  } = props;
  const [fetchedTripDetails, setFetchedTripDetails] = useState({});
  const [fetchingRowKeys, setFetchingRowKeys] = useState([]);
  const [expandedRowsKeys, setExpandedRowsKeys] = useState([]);

  const addFetchingRow = id => setFetchingRowKeys(array => [...array, id]);
  const removeFetchingRow = id => setFetchingRowKeys(array => filter(array, id));

  const [searchTerm, setSearchTerm] = useDebouncedState();

  const filteredDataSource = useMemo(() => {
    let array = [...dataSource];

    if (searchTerm) {
      array = array.filter(trip => {
        const groupName = Array.isArray(trip.group) ? trip.group[0].name : '-';

        return (
          // TODO: use label instead of type
          checkIfStringContainsValue(trip.tripType, searchTerm) ||
          checkIfStringContainsValue(getUserFullName(trip), searchTerm) ||
          checkIfStringContainsValue(trip.from_loc, searchTerm) ||
          checkIfStringContainsValue(trip.to_loc, searchTerm) ||
          checkIfStringContainsValue(trip.date, searchTerm) ||
          checkIfStringContainsValue(
            momentTimezone(trip.journeyStartTs).format('hh:mm'),
            searchTerm,
          ) ||
          checkIfStringContainsValue(
            momentTimezone(trip.journeyEndTs).format('hh:mm'),
            searchTerm,
          ) ||
          checkIfStringContainsValue(trip.journeyDistance, searchTerm) ||
          checkIfStringContainsValue(groupName, searchTerm) ||
          checkIfStringContainsValue(
            moment(trip.journeyEndTs).diff(moment(trip.journeyStartTs), 'minutes').toString(),
            searchTerm,
          ) ||
          checkIfStringContainsValue(
            formatNumberWithCurrency(trip.amount, trip?.currency),
            searchTerm,
          ) ||
          checkIfStringContainsValue(
            STATUS_LIST.StatusTagColorConfig[trip.status.toLowerCase()].label,
            searchTerm,
          )
        );
      });
    }

    return array;
  }, [dataSource, searchTerm]);

  const COLUMNS = [
    ...(canRejectTrip
      ? [
          {
            title: '',
            width: 90,
            render: (actions, data) => {
              return (
                <Button
                  size="sm"
                  variant="secondary"
                  disabled={isRejectingTrip}
                  onClick={e => {
                    e.stopPropagation();
                    onTripReject(data._id);
                  }}
                >
                  {t('reject')}
                </Button>
              );
            },
          },
        ]
      : []),
    {
      width: 100,
      key: 'date',
      title: 'Date',
      ...sortColumnByMomentDate('date', 'MM/DD/YYYY zz'),
      render: (actions, data) => {
        return (
          <p style={{ minWidth: 100, maxWidth: 'max-content' }}>
            {data.captureMode === 'Daily-Mileage' ? data.dateNoTimezone : data.date}
          </p>
        );
      },
    },
    {
      width: 140,
      key: 'time',
      title: 'Time',
      ...sortColumnByMomentDate('starttime', 'hh.mm.ss A'),
      render: (actions, data) => {
        if (data.captureMode === 'Daily-Mileage') {
          return '-';
        }

        return (
          <p style={{ minWidth: 140, maxWidth: 'max-content' }}>
            {[
              momentTimezone(data.journeyStartTs).format('hh:mm a'),
              momentTimezone(data.journeyEndTs).format('hh:mm a'),
            ].join(' - ')}
          </p>
        );
      },
    },
    {
      width: 195,
      key: 'purpose',
      title: 'Purpose',
      ...sortColumnByStringField('tripType'),
      render: (actions, data) => (
        <Row wrap={false} align="middle">
          {(!!data.notes || !!data.latestComment) && (
            <Col flex="40px">
              <img width="24px" height="24px" src={IMAGES.NOTES_PAPER_TEXT} alt="notes" />
            </Col>
          )}
          <Col flex={1}>
            {typeof onTripPurposeChange === 'function' &&
            [STATUS_LIST.Status.PENDING, STATUS_LIST.Status.DENIED].includes(data.status) ? (
              <TripTypeSelect
                trip={data}
                disabled={rest?.loading}
                style={{ width: '100%' }}
                defaultValue="Business"
                value={data.tripType}
                onChange={purpose => onTripPurposeChange(data._id, purpose)}
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              />
            ) : (
              <>
                {typeof data.tripType === 'string' ? (
                  data.tripType === 'business-commute' ? (
                    <Row gutter={[0, 3]}>
                      <Col xs={24}>
                        <TripPurposeChip purpose={'commute'} />
                      </Col>
                      <Col xs={24}>
                        <TripPurposeChip purpose={'business'} />
                      </Col>
                    </Row>
                  ) : (
                    <TripPurposeChip purpose={data.tripType} />
                  )
                ) : (
                  '-'
                )}
              </>
            )}
          </Col>
        </Row>
      ),
    },
    {
      width: 100,
      title: t('Distance'),
      align: 'right',
      key: 'journeyDistance',
      dataIndex: 'journeyDistance',
      ...sortColumnByNumber('journeyDistance'),
    },
    {
      width: 50,
      title: 'Minutes',
      align: 'right',
      key: 'duration',
      render: (actions, data) => {
        if (data.journeyStartTs && data.journeyEndTs) {
          return moment(data.journeyEndTs).diff(moment(data.journeyStartTs), 'minutes');
        }

        return '-';
      },
      sorter: (a, b) => {
        if (a.journeyStartTs && a.journeyEndTs && b.journeyStartTs && b.journeyEndTs) {
          const aDuration = moment(a.journeyEndTs).diff(moment(a.journeyStartTs), 'minutes');
          const bDuration = moment(b.journeyEndTs).diff(moment(b.journeyStartTs), 'minutes');
          return aDuration - bDuration;
        }

        return 0;
      },
    },
    {
      width: 200,
      title: 'From',
      key: 'from_loc',
      ...sortColumnByStringField('from_loc'),
      render: (actions, data) => {
        if (data.isFromAddressHome) {
          return (
            <Tooltip title={data.from_loc}>
              <LinkText>{t('home')}</LinkText>
            </Tooltip>
          );
        }

        const companyLocationLabel = getTripCompanyLocationFromAddressLabel(data);
        if (companyLocationLabel) {
          return (
            <Tooltip title={data.from_loc}>
              <LinkText>{companyLocationLabel}</LinkText>
            </Tooltip>
          );
        }

        return (
          <div>
            <DotDotDot clamp={2}>{data.from_loc}</DotDotDot>
          </div>
        );
      },
    },
    {
      width: 200,
      title: 'To',
      key: 'to_loc',
      sorter: (a, b) => a.to_loc.localeCompare(b.to_loc),
      ...sortColumnByStringField('to_loc'),
      render: (actions, data) => {
        if (data.isToAddressHome) {
          return (
            <Tooltip title={data.to_loc}>
              <LinkText>{t('home')}</LinkText>
            </Tooltip>
          );
        }

        const companyLocationLabel = getTripCompanyLocationToAddressLabel(data);
        if (companyLocationLabel) {
          return (
            <Tooltip title={data.to_loc}>
              <LinkText>{companyLocationLabel}</LinkText>
            </Tooltip>
          );
        }

        return (
          <div title={data.to_loc}>
            <DotDotDot clamp={2}>{data.to_loc}</DotDotDot>
          </div>
        );
      },
    },
    {
      width: 100,
      title: 'Amount',
      key: 'amount',
      align: 'right',
      ...sortColumnByNumber('amount'),
      render: (actions, data) => {
        const amount = formatNumberWithCurrency(data.amount, data.currency);

        const TOOLTIP_VISIBLE = isRecalculatingAmount && recalculatingTripId === data._id;
        const CALCULATING_DIFFERENT_TRIP =
          isRecalculatingAmount && recalculatingTripId !== data._id;

        if (typeof onAmountRecalculate === 'function' && parseFloat(data.amount) === 0) {
          return (
            <Tooltip
              {...(TOOLTIP_VISIBLE
                ? { visible: true }
                : CALCULATING_DIFFERENT_TRIP
                ? { visible: false }
                : {})}
              className={classNames.amountTooltip}
              title={
                <Row onClick={e => e.stopPropagation()}>
                  <Col>
                    <Row>
                      <Text variant="p" size="sm">
                        Kliks has not yet calculated an amount.
                      </Text>
                      <Text variant="p" size="sm">
                        Amounts are calculated on a nightly basis.
                      </Text>
                    </Row>

                    <Row style={{ margin: '15px 0' }}>
                      <Text variant="p" size="sm">
                        To calculate an amount now:
                      </Text>
                    </Row>

                    <Row justify="center">
                      <Button
                        size="xs"
                        text="Calculate Trip Amount"
                        loading={isRecalculatingAmount}
                        onClick={e => {
                          e.stopPropagation();
                          onAmountRecalculate(data._id);
                        }}
                      />
                    </Row>
                  </Col>
                </Row>
              }
            >
              <Text textAlign="right" variant="p" size="sm" color="primary" renderAs="span">
                {amount}
              </Text>
            </Tooltip>
          );
        }

        return amount;
      },
    },
    {
      width: 130,
      key: 'crmMatch',
      title: 'CRM Match',
      align: 'center',
      ...sortColumnByBoolean('isCRMTrip'),
      ...renderBooleanCheckmarks('isCRMTrip'),
    },
    {
      width: 50,
      title: 'GPS',
      align: 'center',
      ...renderBooleanCheckmarks('gps'),
      sorter: (a, b) => (a.gps !== b.gps ? 1 : -1),
    },
    {
      width: 160,
      title: 'Status',
      key: 'status',
      render: (actions, data) => {
        if (data.status) {
          return (
            <>
              <StatusTag status={data.status} />

              {displayAwaitingStatusHelp &&
                typeof t === 'function' &&
                data.status === STATUS_LIST.Status.SUBMITTED && (
                  <FadedText size="sm">{t('tripApprovedWhenReceiptApproved')}</FadedText>
                )}
            </>
          );
        } else {
          return '-';
        }
      },
      ...sortColumnByStatus('status'),
    },
  ];

  const toggleExpandedRow = row => {
    if (!expandedRowsKeys.includes(row.key)) {
      setExpandedRowsKeys([row.key]);
    } else {
      setExpandedRowsKeys([]);
    }
  };

  return (
    <CustomTable
      rowKey={data => data._id}
      showSearchInput={false}
      onSearchTermChange={setSearchTerm}
      {...rest}
      dataSource={filteredDataSource}
      className="trips-table"
      rowClassName={data => {
        if (Array.isArray(tripsToRemove) && tripsToRemove.includes(data._id)) {
          return classNames.blurredRow;
        }
      }}
      withExpandableRows
      columns={COLUMNS}
      scroll={{ x: 1400 }}
      onRow={row => ({
        className: 'trips-table-row',
        leftBorderColor: hasTripsWithDeductedCommuteMiles([row]) ? 'danger' : '',
        onClick: e => {
          e.preventDefault();
          e.stopPropagation();
          toggleExpandedRow(row);
        },
      })}
      expandedRowKeys={expandedRowsKeys}
      onExpand={(expanded, record) => {
        toggleExpandedRow(record);
        if (
          typeof onFetchDetails === 'function' &&
          expanded &&
          !Object.keys(fetchedTripDetails).includes(record._id) &&
          !fetchingRowKeys.includes(record._id)
        ) {
          addFetchingRow(record._id);
          onFetchDetails(record._id)
            .then(tripDetails => {
              setFetchedTripDetails(obj => {
                return merge(obj, {
                  [tripDetails._id]: merge(record, pick(tripDetails, ['_id', 'journeyGeoJson'])),
                });
              });
            })
            .finally(() => removeFetchingRow(record._id));
        }
      }}
      expandedRowRender={data => (
        <ErrorBoundary>
          <ExpandedTripsSubRow
            t={t}
            trip={data}
            onDeleteTrip={onDeleteTrip}
            companyCountry={companyCountry}
            onViewMoreComments={onViewMoreComments}
            onViewFullScreenClick={handleViewFullScreen}
          />
        </ErrorBoundary>
      )}
    />
  );
};

export default TripsTableByPeriod;
