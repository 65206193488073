import { Col, DatePicker, Row } from 'antd';
import React from 'react';
import { Helmet } from 'react-helmet';
import { Trans, withNamespaces } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import Box from '../../components/Box';
import Calendar from '../../components/Calendar';
import FormItem from '../../components/Form/FormItem';
import PaymentScheduleDeleteConfirmationModal from '../../components/Modal/PaymentScheduleDeleteConfirmationModal';
import PageBreadcrumbs from '../../components/PageBreadcrumbs';
import Spinner from '../../components/Spinner';
import SubmitCancelButtonGroup from '../../components/SubmitCancelButtonGroup';
import Text from '../../components/Text';
import FadedText from '../../components/Text/FadedText';
import LinkText from '../../components/Text/LinkText';
import TextInput from '../../components/TextInput';
import Toast from '../../components/Toast';
import PageContainer from '../../containers/PageContainer';
import { INTERNAL_LINKS } from '../../enum';
import {
  AD_HOC_SCHEDULE_TYPE,
  CUSTOM_SCHEDULE_TYPE,
  MONTHLY_SCHEDULE_TYPE,
  WEEKLY_SCHEDULE_TYPE,
} from '../../enum/ScheduleTypes';
import withAuthentication from '../../hocs/withAuthentication';
import { formatPageTitle, momentCompanyFormat, momentCompanyTimezone } from '../../utils/common';
import { selectStoreCompanySettings } from '../../utils/storeSelectors';
import { getTimezoneLabel } from '../../utils/timezone';
import SelectCustomParameters from './select-custom-params';
import SelectPaymentScheduleGroups from './select-group';
import SelectMonthlyParameters from './select-monthly-params';
import SelectPaymentSchedule from './select-payment-schedule';
import SelectWeeklyParameters from './select-weekly-params';
import classNames from './style.module.scss';
import usePaymentScheduleDetailsState from './usePaymentScheduleDetailsState';

const PageHelmet = () => (
  <Helmet>
    <title>{formatPageTitle('Payment Schedule Details')}</title>
  </Helmet>
);

const PaymentScheduleDetails = props => {
  const { t, history, match } = props;
  const companySettings = useSelector(selectStoreCompanySettings);

  const {
    // Booleans
    fullPaymentScheduleData,
    paymentSchedule,
    isUpdating,
    isLoadingFuturePayments,
    deleteScheduleModalVisible,
    isDeletingSchedule,
    futurePaymentDates,
    tripApprovalWorkflow,
    defaultGroup,
    // General
    currentCompany,
    companyGroups,
    selectedGroups,
    paymentScheduleName,
    groupsWithUsers,
    // Handlers
    handleGroupSelect,
    handlePaymentScheduleNameUpdate,
    handleDeleteScheduleModalToggle,
    handlePaymentScheduleStateUpdate,
    handleSubmit,
    paymentScheduleUpdater,
    handlePaymentScheduleDelete,
  } = usePaymentScheduleDetailsState({ t, history, match });

  if (match.params.id && !paymentSchedule) {
    return (
      <PageContainer>
        <PageHelmet />

        <Row style={{ minHeight: 300 }} justify="center" align="middle">
          <Col>
            <Spinner />
          </Col>
        </Row>
      </PageContainer>
    );
  }

  const settingsHelpText = (
    <Text variant="p" size="sm" className={classNames.formSection}>
      All dates of deadlines must be after current date
    </Text>
  );

  const PERIOD_START_DATE_SECTION =
    paymentSchedule &&
    [MONTHLY_SCHEDULE_TYPE, WEEKLY_SCHEDULE_TYPE, CUSTOM_SCHEDULE_TYPE].includes(
      paymentSchedule.scheduleType,
    ) ? (
      <section className={classNames.formSection}>
        <Text variant="h4">Payment Period Start Date</Text>

        <DatePicker
          disabled={!!match.params.id}
          defaultValue={
            paymentSchedule.paymentPeriodStart
              ? momentCompanyTimezone(paymentSchedule.paymentPeriodStart)
              : null
          }
          onChange={date => {
            handlePaymentScheduleStateUpdate({
              ...paymentSchedule,
              paymentPeriodStart: momentCompanyFormat(date, 'YYYY-MM-DD'),
            });
          }}
        />
      </section>
    ) : null;

  return (
    <PageContainer
      stickyHeader={!!match.params.id}
      pageActionText={match.params.id ? t('deleteSchedule') : undefined}
      onPageActionClick={() => {
        if (!!groupsWithUsers.length) {
          Toast({
            type: 'error',
            message:
              'You cannot delete a payment schedule that is assigned to one or more groups. Only payment schedules that are not assigned to any groups can be deleted.',
          });
        } else {
          handleDeleteScheduleModalToggle(true);
        }
      }}
      title={
        <PageBreadcrumbs
          items={[
            {
              label: t('paymentSchedules'),
              onClick: () => props.history.push(INTERNAL_LINKS.PAYMENT_SCHEDULES),
            },
            ...(paymentScheduleName
              ? [
                  {
                    label: paymentScheduleName,
                  },
                ]
              : []),
          ]}
        />
      }
    >
      <PageHelmet />

      <div className={classNames.pageContent}>
        <div>
          <Box>
            <section className={classNames.formSection}>
              <FormItem required label={t('name')}>
                <TextInput
                  defaultValue={paymentScheduleName}
                  onChange={e => {
                    handlePaymentScheduleNameUpdate(e.target.value);
                  }}
                />
              </FormItem>
            </section>

            <section className={classNames.formSection}>
              <Text variant="h4">Groups</Text>

              <SelectPaymentScheduleGroups
                groups={companyGroups}
                selectedGroups={selectedGroups}
                onGroupSelect={selected => handleGroupSelect(selected)}
              />
            </section>

            <section className={classNames.formSection}>
              <Text variant="h4">Schedule Type</Text>

              <SelectPaymentSchedule
                disabled={!!match.params.id}
                tripApprovalWorkflow={tripApprovalWorkflow}
                paymentFrequency={paymentSchedule?.scheduleType}
                onFrequencyChange={type => {
                  handlePaymentScheduleStateUpdate({
                    ...(paymentSchedule || {}),
                    scheduleType: type,
                  });
                }}
              />
            </section>

            {PERIOD_START_DATE_SECTION}

            <div>
              {paymentSchedule?.scheduleType === MONTHLY_SCHEDULE_TYPE && (
                <section className={classNames.formSection}>
                  <Text variant="h4">Schedule Monthly Settings</Text>

                  {settingsHelpText}

                  <SelectMonthlyParameters
                    {...paymentSchedule}
                    isAchEnabled={!!companySettings?.ach_enabled}
                    onSettingChange={paymentScheduleUpdater}
                    disabled={!!match.params.id}
                  />
                </section>
              )}

              {paymentSchedule?.scheduleType === WEEKLY_SCHEDULE_TYPE && (
                <section className={classNames.formSection}>
                  <Text variant="h4">Schedule Weekly Settings</Text>

                  {settingsHelpText}

                  <SelectWeeklyParameters
                    {...paymentSchedule}
                    onSettingChange={paymentScheduleUpdater}
                    disabled={!!match.params.id}
                  />
                </section>
              )}

              {paymentSchedule?.scheduleType === CUSTOM_SCHEDULE_TYPE && (
                <section className={classNames.formSection}>
                  <Text variant="h4">Schedule Custom Settings</Text>

                  {settingsHelpText}

                  <SelectCustomParameters
                    {...paymentSchedule}
                    onSettingChange={paymentScheduleUpdater}
                    disabled={!!match.params.id}
                  />
                </section>
              )}
            </div>
          </Box>
        </div>

        {paymentSchedule?.scheduleType && paymentSchedule?.scheduleType !== AD_HOC_SCHEDULE_TYPE && (
          <div>
            <div className={classNames.calendarWrapper}>
              {isLoadingFuturePayments ? (
                <Spinner />
              ) : (
                <>
                  <Calendar selectedDates={futurePaymentDates} />
                  <FadedText size="sm" className={classNames.calendarFooterTextWrapper}>
                    <Trans
                      t={t}
                      i18nKey="datesAreBasedOnCompanyTimezone"
                      values={{
                        timezone: getTimezoneLabel(currentCompany.preferredTimezone),
                      }}
                      components={[<b>DummyText</b>]}
                    />

                    <Link to={INTERNAL_LINKS.COMPANY}>
                      <LinkText size="sm">({t('edit')})</LinkText>
                    </Link>
                  </FadedText>
                </>
              )}
            </div>
          </div>
        )}
      </div>

      <SubmitCancelButtonGroup
        style={{ marginTop: 30 }}
        loading={isUpdating}
        submitText={match.params.id ? 'Update' : 'Create'}
        onSubmit={handleSubmit}
        onCancel={() => {
          props.history.push(INTERNAL_LINKS.PAYMENT_SCHEDULES);
        }}
      />

      {paymentSchedule && fullPaymentScheduleData && (
        <PaymentScheduleDeleteConfirmationModal
          t={t}
          isDeletingSchedule={isDeletingSchedule}
          visible={deleteScheduleModalVisible}
          group={fullPaymentScheduleData?._id}
          defaultGroup={defaultGroup}
          onDelete={handlePaymentScheduleDelete}
          onCancel={() => handleDeleteScheduleModalToggle(false)}
        />
      )}
    </PageContainer>
  );
};

export default withNamespaces()(withAuthentication(PaymentScheduleDetails));
