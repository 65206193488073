import { Col, Dropdown, Form, Row } from 'antd';
import React, { useState } from 'react';

import { STATUS_LIST, USER_ROLES } from '../../enum';
import useFileInput from '../../hooks/useFileInput';
import { getUserInitials } from '../../utils/common';
import {
  EMAIL_UPDATE_FORM_RULES,
  EMAIL_VALIDATION_FORM_RULES,
  ERROR_MESSAGE,
  NAME_MAX_CHARACTER,
} from '../../utils/constants';
import { isValidPassword, validateProfileImageSize } from '../../utils/validate';
import Avatar from '../Avatar';
import Select from '../Select';
import SubmitCancelButtonGroup from '../SubmitCancelButtonGroup';
import Switch from '../Switch';
import TextInput from '../TextInput';
import classNames from './style.module.scss';

const SystemAdminForm = props => {
  const {
    t,
    values,
    isUpdate,
    showProfilePicture,
    isSubmitting,
    profilePicture,
    onImageUpload,
    onUserStatusChange,
    onCancel,
    onSubmit,
  } = props;

  const [form] = Form.useForm();

  const [imageBase64, setImageBase64] = useState();
  const [statusActive, setStatusActive] = useState(values?.status === STATUS_LIST.Status.ACTIVE);

  const handleStatusUpdate = async status => {
    await onUserStatusChange(
      statusActive ? STATUS_LIST.Status.INACTIVE : STATUS_LIST.Status.ACTIVE,
    );
    setStatusActive(status);
  };

  const openImagePickerDlg = useFileInput(
    files => {
      if (files[0]) {
        validateProfileImageSize(files[0], (imageFile, base64) => {
          setImageBase64(base64);
          onImageUpload(imageFile);
        });
      }
    },
    { accept: 'image/png, image/jpeg', multiple: false },
  );

  const passwordValidator = (rule, value) => {
    if (isValidPassword(value) > 0) {
      return Promise.reject(ERROR_MESSAGE.INVALID_PASSWORD);
    }
    if (value.length > NAME_MAX_CHARACTER) {
      return Promise.reject(`Password is limited to ${NAME_MAX_CHARACTER} characters`);
    }
    return Promise.resolve();
  };

  const ROLE_OPTIONS = [
    { label: t('systemAdministrator'), value: USER_ROLES.SYSTEM_ADMIN },
    { label: t('backOfficeUser'), value: USER_ROLES.BACK_OFFICE_USER },
  ];

  return (
    <Form
      form={form}
      name="systemAdmin"
      labelCol={{ span: 24 }}
      onFinish={onSubmit}
      initialValues={values}
    >
      {showProfilePicture && (
        <Row justify="space-between" style={{ marginBottom: 57 }}>
          <Col>
            <div>
              <Dropdown
                trigger="hover"
                disabled={isSubmitting}
                menu={{
                  className: classNames.avatarMenu,
                  items: [
                    {
                      key: 'open-image-picker',
                      label: profilePicture ? t('changeImage') : t('uploadAnImage'),
                      onClick: openImagePickerDlg,
                    },
                  ],
                }}
              >
                <div>
                  <Avatar
                    size={96}
                    src={imageBase64 || profilePicture}
                    initials={getUserInitials({
                      firstName: values.firstName,
                      lastName: values.lastName,
                    })}
                  />
                </div>
              </Dropdown>
            </div>
          </Col>

          <Col flex="175px">
            <Form.Item>
              <Switch
                size="medium"
                name="status"
                label={t('Account {{ status }}', { status: statusActive ? 'Active' : 'Inactive' })}
                checked={statusActive}
                disabled={isSubmitting || !values?._id}
                onChange={st => handleStatusUpdate(st)}
              />
            </Form.Item>
          </Col>
        </Row>
      )}

      <Form.Item
        required
        name="firstName"
        label={t('firstName')}
        rules={[{ required: true, message: ERROR_MESSAGE.BLANK_FIELD }]}
      >
        <TextInput disabled={isSubmitting} />
      </Form.Item>

      <Form.Item
        required
        name="lastName"
        label={t('lastName')}
        rules={[{ required: true, message: ERROR_MESSAGE.BLANK_FIELD }]}
      >
        <TextInput disabled={isSubmitting} />
      </Form.Item>

      <Form.Item
        required
        name="email"
        label={t('Email')}
        rules={isUpdate ? EMAIL_UPDATE_FORM_RULES : EMAIL_VALIDATION_FORM_RULES}
      >
        <TextInput type="email" disabled={isSubmitting} />
      </Form.Item>

      <Form.Item
        required
        name="password"
        label={t('Password')}
        validateTrigger={['onSubmit', 'onBlur']}
        rules={[
          { required: true, message: ERROR_MESSAGE.BLANK_FIELD },
          () => ({
            validator(rule, value) {
              return passwordValidator(rule, value);
            },
          }),
        ]}
      >
        <TextInput password disabled={isSubmitting} autoComplete="new-password" />
      </Form.Item>

      <Form.Item
        name="confirmPassword"
        label={t('confirmPassword')}
        rules={[
          { required: true, message: ERROR_MESSAGE.BLANK_FIELD },
          () => ({
            validator(rule, value) {
              if (value !== form.getFieldValue('password')) {
                return Promise.reject(t('passwordNotMatch'));
              }
              return Promise.resolve();
            },
            validateTrigger: 'onBlur',
          }),
        ]}
      >
        <TextInput password disabled={isSubmitting} autoComplete="new-password" />
      </Form.Item>

      <Form.Item
        required
        name="role"
        label={t('role')}
        rules={[{ required: true, message: ERROR_MESSAGE.BLANK_FIELD }]}
      >
        <Select options={ROLE_OPTIONS} disabled={isSubmitting} />
      </Form.Item>

      <SubmitCancelButtonGroup
        isForm
        onCancel={onCancel}
        loading={isSubmitting}
        submitText={t(isUpdate ? 'update' : 'create')}
      />
    </Form>
  );
};

SystemAdminForm.defaultProps = {
  values: {
    role: USER_ROLES.SYSTEM_ADMIN,
  },
  profilePicture: undefined,
  isSubmitting: false,
  showProfilePicture: true,
  isUpdate: false,
};

export default SystemAdminForm;
