import { Collapse } from 'antd';
import { isNull } from 'lodash';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';

import { IMAGES, INTERNAL_LINKS, STATUS_LIST } from '../../../enum';
import {
  checkIfStringContainsValue,
  getUserFullName,
  momentFormat,
  momentTimezone,
} from '../../../utils/common';
import { formatCurrencyToCompanyLocale } from '../../../utils/numbers';
import { sortColumnByUserFullName } from '../../../utils/tables';
import { scrollAppContainerToTop } from '../../../utils/window';
import { CustomTable } from '../..';
import ComplianceItemsList from '../../ComplianceItemsList';
import ProfileNamePicture from '../../shared-ui/ProfileNamePicture';
import StatusTag from '../../Tag/StatusTag';
import AmountTextReverseColors from '../../Text/AmountTextReverseColors';

const ComplianceStatusTable = props => {
  const { t, dataSource, searchTerm, ...rest } = props;

  const filteredDataSource = useMemo(() => {
    let array = [...dataSource];

    if (searchTerm) {
      array = array.filter(item => {
        return checkIfStringContainsValue(getUserFullName(item), searchTerm);
      });
    }

    return array;
  }, [dataSource, searchTerm]);

  const COLUMNS = [
    {
      key: 'name',
      title: t('name'),
      render: (_, data) => (
        <ProfileNamePicture user={data} redirectTo={INTERNAL_LINKS.COMPLIANCE_DETAILS(data._id)} />
      ),
      ...sortColumnByUserFullName(),
    },
    {
      key: 'startDate',
      title: t('startDate'),
      dataIndex: 'startDate',
      render: startDate => (startDate ? momentFormat(startDate, 'DD MMM, YYYY') : '-'),
    },
    {
      key: 'complianceStatus',
      title: t('complianceStatus'),
      dataIndex: 'complianceStatus',
      render: (status, data) => <StatusTag status={status} />,
    },
    {
      width: 400,
      key: 'nonComplianceDetails',
      title: t('nonComplianceDetails'),
      dataIndex: 'documents',
      render: complianceDocuments => {
        const hasInvalidDocuments =
          Array.isArray(complianceDocuments) &&
          complianceDocuments.some(document => {
            return [STATUS_LIST.Status.DENIED, STATUS_LIST.Status.INVALID].includes(
              document.status,
            );
          });

        if (hasInvalidDocuments) {
          return complianceDocuments.map(document => {
            if (
              ![STATUS_LIST.Status.DENIED, STATUS_LIST.Status.INVALID].includes(document.status)
            ) {
              return null;
            }

            return (
              <Collapse
                ghost
                expandIcon={({ isActive }) => (
                  <img
                    src={IMAGES.GREEN_CHEVRON_DOWN_ICON}
                    alt="expand icon"
                    style={{ transform: `translateY(-3px) rotate(${isActive ? 0 : 180}deg)` }}
                  />
                )}
              >
                <Collapse.Panel header={t(document.type)} key={document.type}>
                  <ComplianceItemsList
                    t={t}
                    type={document.type}
                    complianceComponents={document.complianceComponents}
                  />
                </Collapse.Panel>
              </Collapse>
            );
          });
        }

        return '-';
      },
    },
    {
      key: 'gracePeriod',
      title: t('gracePeriod'),
      dataIndex: 'endOfGracePeriodDate',
      render: endOfGracePeriodDate =>
        endOfGracePeriodDate
          ? `${momentFormat(endOfGracePeriodDate, 'DD MMM, YYYY')} (${momentTimezone(
              endOfGracePeriodDate,
            ).fromNow()})`
          : '-',
    },
    {
      key: 'lastAction',
      title: t('lastAction'),
      dataIndex: ['lastAction', 'reason'],
      render: (reason, data) => (reason ? t(`${data.lastAction.type}-${reason}`) : '-'),
    },
    {
      key: 'lastActionDate',
      title: t('lastActionDate'),
      dataIndex: ['lastAction', 'created'],
      render: created =>
        created
          ? `${momentFormat(created, 'DD MMM, YYYY')} (${momentTimezone(created).fromNow()})`
          : '-',
    },
    {
      key: 'taxDue',
      title: t('taxDue'),
      dataIndex: 'taxDue',
      render: (taxDue, data) =>
        isNull(taxDue) ? (
          '-'
        ) : (
          <Link to={INTERNAL_LINKS.USER_TAX_REPORT(data._id)} onClick={scrollAppContainerToTop}>
            <AmountTextReverseColors variant="b" amount={formatCurrencyToCompanyLocale(taxDue)} />
          </Link>
        ),
    },
  ];

  return (
    <CustomTable
      {...rest}
      columns={COLUMNS}
      showSearchInput={false}
      dataSource={filteredDataSource}
      scroll={{ x: 1100 }}
    />
  );
};

export default ComplianceStatusTable;
