import { STATUS_LIST, USER_ROLES } from '../../../enum';
import { momentEST } from '../../../utils/common';
import { checkUserRole, hasCompanyManagerOrAdminRole } from '../../../utils/roles';

export const canUpdateEmployeeNumber = userData => {
  return checkUserRole(userData, [USER_ROLES.COMPANY_ADMIN]);
};

export const canSendActivationLink = (userData, userTarget) => {
  if (!userTarget || !userData) return false;
  if (userTarget.status === STATUS_LIST.Status.DELETED) return false;
  if (userData.profile._id === userTarget._id) return false;

  return (
    hasCompanyManagerOrAdminRole(userData) &&
    userTarget.status === STATUS_LIST.Status.PENDING &&
    (!userTarget.sendActivationEmailDate ||
      momentEST().isAfter(momentEST(userTarget.sendActivationEmailDate)))
  );
};

export const canUpdateUserActiveStatus = userData => {
  return checkUserRole(userData, [
    USER_ROLES.SYSTEM_ADMIN,
    USER_ROLES.COMPANY_ADMIN,
    USER_ROLES.COMPANY_MANAGER,
  ]);
};

export const canUpdateUserNames = userData => {
  return checkUserRole(userData, [USER_ROLES.USER]);
};
