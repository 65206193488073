import { Col, DatePicker, Radio, Row, Space } from 'antd';
import moment from 'moment-timezone';
import React, { useMemo } from 'react';

import { CustomRadio } from '../../../components';
import Checkbox from '../../../components/Checkbox';
import RedAsterisk from '../../../components/RedAsterisk';
import Select from '../../../components/Select';
import Text from '../../../components/Text';
import { getNumberRage } from '../../../utils/common';

const SelectCustomParameters = props => {
  const {
    paymentRecurrenceNumber,
    paymentRecurrencePeriod,

    dateForPayment,
    paymentDateUseBusinessDays,
    tripsSubmissionDeadline,
    tripsSubmissionDeadlineUseBusinessDays,
    managerApprovalDeadline,
    managerApprovalDeadlineUseBusinessDays,
    onSettingChange,
    disabled,
  } = props;

  const OPTIONS = useMemo(() => {
    return [
      {
        key: 'dateForPayment',
        businessDayKey: 'paymentDateUseBusinessDays',
        type: 'datepicker',
        label: 'Select Payment Date',
        suffix: '',
        value: dateForPayment,
        onlyBusinessDays: paymentDateUseBusinessDays,
        options: moment.weekdays().map(day => ({
          label: day,
          value: day,
        })),
        disabled,
        checkboxDisabled: disabled,
      },
      {
        key: 'paymentRecurrance',
        label: 'Select Recurrence',
        disabled,
        checkboxDisabled: disabled,
        render: option => (
          <Row gutter={5} align="middle">
            <Col>
              <Text variant="p" size="sm">
                Every
              </Text>
            </Col>
            <Col flex="120px">
              <Select
                disabled={option.disabled}
                placeholder={paymentRecurrencePeriod === 'days' ? 'Day' : 'Week'}
                value={paymentRecurrenceNumber}
                style={{ width: '100%', marginBottom: 5 }}
                onChange={value => onSettingChange({ paymentRecurrenceNumber: value }, option.key)}
                options={
                  option.options ||
                  getNumberRage(paymentRecurrencePeriod === 'days' ? 6 : 1, 31).map(n => ({
                    label: n,
                    value: n,
                  }))
                }
              />
            </Col>
            <Col flex={1}>
              <Radio.Group
                disabled={option.disabled}
                value={paymentRecurrencePeriod}
                onChange={e =>
                  onSettingChange({ paymentRecurrencePeriod: e.target.value }, option.key)
                }
              >
                <Row>
                  <Col>
                    <CustomRadio style={{ marginBottom: 5 }} value="days">
                      Days
                    </CustomRadio>
                  </Col>
                  <Col>
                    <CustomRadio style={{ marginBottom: 5 }} value="weeks">
                      Weeks
                    </CustomRadio>
                  </Col>
                </Row>
              </Radio.Group>
            </Col>
          </Row>
        ),
      },
      {
        key: 'tripsSubmissionDeadline',
        label: 'Select Trips Submission Deadline',
        suffix: 'days prior to Payment date',
        value: tripsSubmissionDeadline,
        onlyBusinessDays: tripsSubmissionDeadlineUseBusinessDays,
        disabled,
        checkboxDisabled: disabled,
      },
      {
        key: 'managerApprovalDeadline',
        label: 'Select Manager Approval Deadline',
        suffix: 'days prior to Payment date',
        value: managerApprovalDeadline,
        onlyBusinessDays: managerApprovalDeadlineUseBusinessDays,
        disabled,
        checkboxDisabled: disabled,
        options: getNumberRage(0, 31).map(n => {
          return {
            label: n,
            value: n,
          };
        }),
      },
    ];
  }, [
    disabled,
    dateForPayment,
    onSettingChange,
    paymentDateUseBusinessDays,
    paymentRecurrencePeriod,
    paymentRecurrenceNumber,
    tripsSubmissionDeadline,
    tripsSubmissionDeadlineUseBusinessDays,
    managerApprovalDeadline,
    managerApprovalDeadlineUseBusinessDays,
  ]);

  return (
    <div>
      {OPTIONS.map(
        (option, index) =>
          !option.hidden && (
            <Row key={index} style={{ marginBottom: 20 }}>
              <Col flex="100%">
                <Space direction="horizontal" size={3} wrap={false} align="center">
                  {!option.optional && <RedAsterisk />}
                  <Text style={{ marginTop: 8, marginBottom: 8 }} variant="p" size="sm">
                    {option.label}
                  </Text>
                </Space>
              </Col>

              <Col flex={1}>
                {typeof option.render === 'function' ? (
                  option.render(option)
                ) : (
                  <>
                    <Row gutter={5}>
                      <Col flex="180px">
                        {option.type === 'datepicker' ? (
                          <DatePicker
                            allowClear={false}
                            disabled={option.disabled}
                            defaultValue={dateForPayment ? moment(dateForPayment) : undefined}
                            style={{ width: '100%', marginBottom: 5 }}
                            onChange={momentDate =>
                              onSettingChange({ dateForPayment: momentDate.toDate() })
                            }
                          />
                        ) : (
                          <Select
                            disabled={option.disabled}
                            placeholder={paymentRecurrencePeriod === 'days' ? 'Day' : 'Week'}
                            value={option.value}
                            style={{ width: '100%', marginBottom: 5 }}
                            onChange={value => onSettingChange({ [option.key]: value }, option.key)}
                            options={
                              option.options ||
                              getNumberRage(1, 31).map(n => ({
                                label: n,
                                value: n,
                              }))
                            }
                          />
                        )}
                      </Col>
                      {option.suffix && (
                        <Col>
                          <Text variant="p" size="sm" style={{ marginTop: 8 }}>
                            {option.suffix}
                          </Text>
                        </Col>
                      )}
                    </Row>
                    <Checkbox
                      disabled={option.checkboxDisabled}
                      checked={option.onlyBusinessDays}
                      onChange={e =>
                        onSettingChange({ [`${option.key}UseBusinessDays`]: e.target.checked })
                      }
                    >
                      <p>Only use business days </p>
                      {index === 0 && (
                        <p>
                          (System will default to prev/next business day if the selected day is a
                          weekend or a holiday)
                        </p>
                      )}
                    </Checkbox>
                  </>
                )}
              </Col>
            </Row>
          ),
      )}
    </div>
  );
};

export default SelectCustomParameters;
