import React, { useState } from 'react';
import { Col, Row } from 'antd';

import Box from '../Box';
import Text from '../Text';
import Button from '../Button';

import { getUserFullName } from '../../utils/common';

const ImpersonationWarningBar = props => {
  const { t, impersonatedProfile, onStopImpersonating } = props;

  const [isStoppingImpersonation, setIsStoppingImpersonation] = useState(false);

  const handleStopImpersonation = async () => {
    setIsStoppingImpersonation(true);
    await onStopImpersonating();
    setIsStoppingImpersonation(false);
  };

  return (
    <Box noShadow padding={'12px 24px'} variant="gray" style={{ borderRadius: 0 }}>
      <Row gutter={17} justify="space-between" align="middle">
        <Col>
          <Text variant="p" size="m" color="red">
            {t('You are impersonating as')}:{' '}
            <Text variant="strong" size="m" color="red">
              {getUserFullName(impersonatedProfile)}
            </Text>
            {' | '}
            <Text variant="strong" size="m" color="red">
              {impersonatedProfile.email}
            </Text>
          </Text>
        </Col>
        <Col>
          <Button
            size="sm"
            loading={isStoppingImpersonation}
            disabled={isStoppingImpersonation}
            onClick={handleStopImpersonation}
          >
            Stop Impersonation
          </Button>
        </Col>
      </Row>
    </Box>
  );
};

export default ImpersonationWarningBar;
