import { Col, DatePicker, Row } from 'antd';
import { get } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { handleApiErrors } from '../../../api/axiosInstance';
import { fetchLatestCompanySubscription } from '../../../api/company';
import { createCompanyFreeTrial, extendCompanyFreeTrial } from '../../../api/subscription';
import { Button } from '../../../components';
import FormItem from '../../../components/Form/FormItem';
import Select from '../../../components/Select';
import SubmitCancelButtonGroup from '../../../components/SubmitCancelButtonGroup';
import Text from '../../../components/Text';
import Toast from '../../../components/Toast';
import { STATUS_LIST } from '../../../enum';
import { saveCompany } from '../../../stores/actions/common';
import { emitFetchCustomPricesData, loadPricesData } from '../../../stores/actions/prices';
import { momentFormat, momentTimezone } from '../../../utils/common';
import { getMomentDateWithoutTimezone } from '../../../utils/datetime';
import { useStoreSelector } from '../../../utils/storeSelectors';

const EditFreeTrialSection = props => {
  const { t, currentCompany } = props;

  const dispatch = useDispatch();
  const pricesStore = useStoreSelector('prices');
  const [isEditingFreeTrial, setIsEditingFreeTrial] = useState(false);
  const [isSavingDates, setIsSavingDates] = useState(false);
  const [freeTrialEndDate, setFreeTrialEndDate] = useState();
  const [latestActivePlan, setLatestActivePlan] = useState();
  const [planToActivate, setPlanToActivate] = useState();

  const HAS_FREE_TRIAL = [STATUS_LIST.Status.ACTIVE_TRIAL, STATUS_LIST.Status.TRIAL_END].includes(
    currentCompany.status,
  );

  const FreeTrialTextPeriod = useMemo(() => {
    const period = [];
    const dates = [];

    if (currentCompany?.freeTrialInfo?.trialStartDate) {
      period.push(momentFormat(currentCompany.freeTrialInfo.trialStartDate, 'MMMM DD, YYYY'));
      dates.push(momentTimezone(currentCompany.freeTrialInfo.trialStartDate));
    }

    if (currentCompany?.freeTrialInfo?.trialEndDate) {
      period.push(momentFormat(currentCompany.freeTrialInfo.trialEndDate, 'MMMM DD, YYYY'));
      setFreeTrialEndDate(momentTimezone(currentCompany.freeTrialInfo.trialEndDate));
    }

    return period.join(' - ');
  }, [currentCompany]);

  const toggleEditFreeTrial = () => setIsEditingFreeTrial(state => !state);

  const handleSaveFreeTrialDates = async () => {
    setIsSavingDates(true);

    let updatedCompany;
    try {
      if (currentCompany.status === STATUS_LIST.Status.ACTIVE_TRIAL) {
        updatedCompany = await extendCompanyFreeTrial({
          companyId: currentCompany._id,
          endDate: getMomentDateWithoutTimezone(freeTrialEndDate).format('YYYY-MM-DD'),
        });
      } else if (currentCompany.status === STATUS_LIST.Status.TRIAL_END) {
        updatedCompany = await createCompanyFreeTrial({
          companyId: currentCompany._id,
          planId:
            planToActivate ||
            get(latestActivePlan, 'planId') ||
            get(latestActivePlan, 'details.plan.id'),
          endDate: getMomentDateWithoutTimezone(freeTrialEndDate).format('YYYY-MM-DD'),
        });
      }

      dispatch(saveCompany({ freeTrialInfo: updatedCompany.freeTrialInfo }));
      toggleEditFreeTrial();

      Toast({
        type: 'open',
        message: t('freeTrialExtendSuccess'),
      });
    } catch (error) {
      handleApiErrors(error.response, () => {
        Toast({
          type: 'error',
          message: t('freeTrialExtendError'),
        });
      });
    }

    setIsSavingDates(false);
  };

  const loadCompanyLatestSubscription = async companyId => {
    try {
      const subscriptions = await fetchLatestCompanySubscription(companyId);
      setLatestActivePlan(subscriptions[0]);
    } catch (error) {
      handleApiErrors(error.response);
    }
  };

  const standardPlansOptions = useMemo(() => {
    const plans = [];

    if (Array.isArray(pricesStore.plans)) {
      pricesStore.plans.forEach(plan => {
        plans.push({
          label: plan.level,
          value: plan.order,
        });
      });
    }

    return plans;
  }, [pricesStore.plans]);

  const customPlansOptions = useMemo(() => {
    const plans = [];

    if (Array.isArray(pricesStore.customPlans)) {
      pricesStore.customPlans.forEach(plan => {
        plans.push({
          label: plan.name,
          value: plan._id,
        });
      });
    }

    return plans;
    // eslint-disable-next-line
  }, [pricesStore.customPlans]);

  useEffect(() => {
    if (HAS_FREE_TRIAL) {
      loadCompanyLatestSubscription(currentCompany._id);
      dispatch(emitFetchCustomPricesData());
      dispatch(loadPricesData(currentCompany._id));
    }
    // eslint-disable-next-line
  }, []);

  if (isEditingFreeTrial) {
    return (
      <Row gutter={[10, 10]} align="middle" style={{ marginTop: 10, maxWidth: 250 }}>
        {!latestActivePlan && (
          <Col flex="100%">
            <FormItem label={t('freeTrialEndDate')} className="no-margin-bottom">
              <Select
                style={{ width: '100%' }}
                allowClear={false}
                defaultValue={planToActivate}
                options={standardPlansOptions.concat(customPlansOptions)}
                onChange={setPlanToActivate}
              />
            </FormItem>
          </Col>
        )}

        <Col flex="100%">
          <FormItem label={t('freeTrialEndDate')} className="no-margin-bottom">
            <DatePicker
              style={{ width: '100%' }}
              allowClear={false}
              disabled={isSavingDates}
              value={freeTrialEndDate}
              onChange={setFreeTrialEndDate}
            />
          </FormItem>
        </Col>

        <Col flex="100%">
          <SubmitCancelButtonGroup
            size="xs"
            loading={isSavingDates}
            disabled={isSavingDates}
            onCancel={toggleEditFreeTrial}
            onSubmit={handleSaveFreeTrialDates}
            submitText={t('Save')}
          />
        </Col>
      </Row>
    );
  }

  return (
    <Row wrap={false} gutter={10} align="middle" style={{ minHeight: 36 }}>
      <Col>
        <Text>
          {t('freeTrialPeriod')}: {FreeTrialTextPeriod || 'N/A'}
        </Text>
      </Col>

      {HAS_FREE_TRIAL && (
        <Col>
          <Button size="xs" type="secondary" onClick={toggleEditFreeTrial} disabled={isSavingDates}>
            {t('Edit')}
          </Button>
        </Col>
      )}
    </Row>
  );
};

export default EditFreeTrialSection;
