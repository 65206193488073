import { useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';

import { DWOLLA_API, parseDwollaApiErrorMessage } from '../api/dwolla';
import Toast from '../components/Toast';
import { STATUS_LIST } from '../enum';
import { selectStoreCurrentCompany } from '../utils/storeSelectors';
import usePaginatedDwollaResourcesQuery from './queries/usePaginatedDwollaResourcesQuery';
import useDwollaCompanyInfo from './useDwollaCompanyInfo';

const useDwollaAccountBalance = (t, callbacks = {}) => {
  const queryClient = useQueryClient();
  const currentCompany = useSelector(selectStoreCurrentCompany);
  const { companyBalanceFundingSource } = useDwollaCompanyInfo();

  const {
    query: { data: dwollaTransactionHistory },
  } = usePaginatedDwollaResourcesQuery(['transfer'], {
    enabled: false,
  });

  const [pendingAccountBalance, setPendingAccountBalance] = useState(0);

  useEffect(() => {
    const newPendingAmount = dwollaTransactionHistory.documents.reduce((total, transfer) => {
      if (transfer.status === STATUS_LIST.Status.PENDING) {
        return total + transfer.totalAmount;
      }
      return total;
    }, 0);

    setPendingAccountBalance(newPendingAmount);
  }, [dwollaTransactionHistory]);

  const { isFetching: isLoadingAccountBalance, data: dwollaAccountBalance } = useQuery({
    staleTime: 1000 * 60 * 5, // 5 minutes
    queryKey: ['dwollaAccountBalance', currentCompany._id],
    queryFn: DWOLLA_API.getFundingSourceBalance,
    onError: error => {
      parseDwollaApiErrorMessage(error.response, () => {
        Toast({
          type: 'error',
          message: t('fetchDwollaAccountBalanceError'),
        });
      });
    },
    enabled: !!companyBalanceFundingSource,
    select: data => {
      return {
        balance: { value: parseFloat(data.balance.value), currency: 'USD' },
        total: { value: parseFloat(data.total.value), currency: 'USD' },
      };
    },
  });

  const addAmountToBalance = useMutation(DWOLLA_API.addFundsToCompanyBalance, {
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({
        exact: false,
        queryKey: ['dwolla-resources', 'transfer', currentCompany._id],
      });

      setPendingAccountBalance(state => state + parseFloat(variables.amount));

      Toast({
        type: 'open',
        message: t('addAmountToBalanceSuccess'),
      });

      if (typeof callbacks.onAddAmountSuccess === 'function') {
        callbacks.onAddAmountSuccess();
      }
    },
    onError: error => {
      parseDwollaApiErrorMessage(error.response, () => {
        Toast({
          type: 'error',
          message: t('addAmountToBalanceError'),
        });
      });
    },
  });

  const withdrawAmountFromBalance = useMutation(DWOLLA_API.withdrawFundsFromCompanyBalance, {
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({
        exact: false,
        queryKey: ['dwolla-resources', 'transfer', currentCompany._id],
      });

      Toast({
        type: 'open',
        message: t('withdrawAmountFromBalanceSuccess'),
      });

      if (typeof callbacks.onWithdrawAmountSuccess === 'function') {
        callbacks.onWithdrawAmountSuccess();
      }
    },
    onError: error => {
      parseDwollaApiErrorMessage(error.response, () => {
        Toast({
          type: 'error',
          message: t('withdrawAmountFromBalanceError'),
        });
      });
    },
  });

  return {
    isLoadingAccountBalance,
    pendingAccountBalance,
    dwollaAccountBalance,
    addAmountToBalance,
    withdrawAmountFromBalance,
  };
};

export default useDwollaAccountBalance;
