import { Col, Row } from 'antd';
import { first, get, kebabCase, merge } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { withNamespaces } from 'react-i18next';
import { useQuery } from 'react-query';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

import { impersonateUser } from '../../api/auth';
import { handleApiErrors } from '../../api/axiosInstance';
import { getCompanyByID } from '../../api/company';
import { FAVR_COMPLIANCES_API } from '../../api/favr-compliances';
import { fetchCurrentUserDetails, fetchUserFavrInformation, USER_API } from '../../api/user';
import { createSoftUserDataDeleteRequest } from '../../api/userDataActions';
import { ConfirmModal } from '../../components';
import Button from '../../components/Button';
import PageBreadcrumbs from '../../components/PageBreadcrumbs';
import SpaceSpinner from '../../components/SpaceSpinner';
import Tag from '../../components/Tag';
import FadedText from '../../components/Text/FadedText';
import Toast from '../../components/Toast';
import PageContainer from '../../containers/PageContainer';
import PageContainerWithFadedLeftNavigation from '../../containers/PageContainerWithFadedLeftNavigation';
import { DAILY_MILEAGE_ENTRY_MODES, IMAGES, INTERNAL_LINKS, STATUS_LIST } from '../../enum';
import { KLIKS_SYSTEM_BASENAME } from '../../enum/InternalLinks';
import { RATE_TYPES } from '../../enum/Rates';
import withAuthentication from '../../hocs/withAuthentication';
import { emitUpdateGlobalBasename, removeCompany, saveCompany } from '../../stores/actions/common';
import { updateProfile } from '../../stores/actions/profile';
import { formatPageTitle, getUserFullName } from '../../utils/common';
import { getMinutesAsMilliseconds } from '../../utils/numbers';
import {
  hasCompanyAdminRole,
  hasCompanyManagerRole,
  hasSystemAdminRole,
  hasUserRole,
} from '../../utils/roles';
import { selectStoreCompanyGroupByID } from '../../utils/storeSelectors';
import { checkIfUserIsOnVacation, normalizeUserSchema } from '../../utils/users';
import DriverLicenseView from '../driver-license';
import UserAchBankInfoView from './ach-bank-info';
import UserDetailsActivityView from './activity';
import EmailPreferencesView from './email-preferences';
import UserEmissionsDetailsView from './emissions-details';
import UserInsuranceInformationView from './insurance-info';
import MobileAppSettings from './mobile-app-settings';
import UserDetailsObdDeviceView from './obd-device';
import UserDetailsProfileView from './profile';
import BusinessVehicleView from './reimbursement-rate/BusinessVehicleView';
import UserFavrInformationView from './reimbursement-rate/UserFavrInformationView';
import UserTaxReportView from './tax-report';
import TripRecordingPreferencesPage from './trip-recording-preferences';
import {
  canImpersonateUser,
  canSoftDeleteUser,
  canViewBreadcrumb,
  canViewUserAchBankInfo,
} from './userdetails-permissions';
import UserDetailsVacationsView from './vacations';
import UserProfileDataView from './your-data';

/**
 *
 */
const UserDetailsPage = props => {
  const { t, myProfile, currentCompany } = props;
  const { id } = props.match.params;

  const [userDetails, setUserDetails] = useState();
  const [timezones, setTimezones] = useState([]);

  const [isRequestingImpersonation, setIsRequestingImpersonation] = useState(false);

  const [isDeleteUserConfirmationVisible, setIsDeleteUserConfirmationVisible] = useState(false);
  const [isDeletingUser, setIsDeletingUser] = useState(false);

  const loadUserInfo = async userId => {
    if (hasUserRole(myProfile) && myProfile.profile._id !== userId) {
      props.history.push(INTERNAL_LINKS.DASHBOARD);
      Toast({
        type: 'error',
        message: t('userFetchUnauthorized'),
      });
      return;
    }

    try {
      // If it's same logged in user
      if (myProfile.profile._id === userId) {
        const currentUserDetails = await fetchCurrentUserDetails();
        const userInfo = normalizeUserSchema(currentUserDetails);
        setUserDetails(userInfo);
      } else {
        // Fetch user's data through API
        const userInfo = await USER_API.getUserDetails(userId);
        setUserDetails(userInfo);
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        props.history.replace(INTERNAL_LINKS.DASHBOARD);
        Toast({
          type: 'error',
          message: t('userFetchError'),
        });
      } else {
        handleApiErrors(error.response, () => {
          Toast({
            type: 'error',
            message: t('userFetchError'),
          });
        });
      }
    }
  };

  const userFavrInfoQuery = useQuery({
    enabled: !!id,
    placeholderData: {},
    queryKey: ['fetchUserFavrInformation', id],
    queryFn: () => fetchUserFavrInformation(id),
    onError: error => handleApiErrors(error.response),
  });

  const userComplianceDetailsQuery = useQuery({
    enabled: !!id,
    placeholderData: {},
    queryKey: ['fetchUserFavrComplianceStatuses', id],
    queryFn: () => FAVR_COMPLIANCES_API.fetchUserFavrComplianceStatuses(id),
    onError: error => handleApiErrors(error.response),
  });

  const userTaxableYearsQuery = useQuery({
    enabled: !!id && !hasSystemAdminRole(myProfile),
    placeholderData: [],
    queryKey: ['fetchUserTaxReportYears', id],
    queryFn: () => USER_API.fetchUserTaxReportYears(id),
    onError: error => handleApiErrors(error.response),
    staleTime: getMinutesAsMilliseconds(10),
  });

  const loadCompanyDetails = async companyID => {
    try {
      const company = await getCompanyByID(companyID);
      props.saveCompany(company);
    } catch (error) {
      handleApiErrors(error.response);
    }
  };

  useEffect(() => {
    if (myProfile && !userDetails) {
      loadUserInfo(id);
    }
    // eslint-disable-next-line
  }, [myProfile]);

  useEffect(() => {
    if (myProfile && userDetails && id !== userDetails._id) {
      setUserDetails(undefined);
      loadUserInfo(id);
    }
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    // Load Company details if non have loaded
    if (!currentCompany?.name && userDetails) {
      const companyID = first(get(userDetails, 'businessInfor.companies'))?.companyID;
      if (companyID) loadCompanyDetails(companyID);
    }
    // eslint-disable-next-line
  }, [userDetails]);

  useEffect(() => {
    return () => {
      // Remove company from Store if AuthUser is a System Admin
      if (hasSystemAdminRole(myProfile)) {
        props.removeStoreCompany();
      }
    };
    // eslint-disable-next-line
  }, []);

  const USER_DETAILS_RATE = useMemo(() => {
    return get(userDetails, 'group.productId');
  }, [userDetails]);

  const USER_GROUP = useMemo(() => {
    if (userDetails?.group?._id) {
      return selectStoreCompanyGroupByID(userDetails?.group?._id);
    }
  }, [userDetails]);

  if (!userDetails || !myProfile || !currentCompany?.name) {
    return (
      <PageContainer>
        <Helmet>
          <title>{formatPageTitle('User Details')}</title>
        </Helmet>

        <SpaceSpinner />
      </PageContainer>
    );
  }

  const formViewRoutes = path => {
    const rootPah = INTERNAL_LINKS.USER_DETAILS + '/' + props.match.params.id;
    return rootPah + (path ? `/${path}` : '');
  };

  /**
   * Update the userDetails state whenever one of the Profile, Vacations or OBD forms update the user
   *
   * @param  {object} newUserData Updated User's data
   */
  const onUserUpdate = newUserData => {
    const updatedData = { ...userDetails, ...normalizeUserSchema(newUserData) };
    setUserDetails(
      merge(updatedData, {
        group: userDetails.group,
      }),
    );

    // Updates the store if the provided data is the same as the logged in user
    if (newUserData._id === myProfile.profile._id) {
      props.updateProfile(updatedData);
    }
  };

  /**
   * Replaces the currently logged in User with a selected User
   */
  const handleImpersonation = async userID => {
    setIsRequestingImpersonation(true);

    const result = await impersonateUser(userID);

    if (result) {
      const userResult = await fetchCurrentUserDetails();
      const normalizedUserSchema = normalizeUserSchema(userResult);
      await props.updateProfile(normalizedUserSchema);

      if (props.currentBasename === KLIKS_SYSTEM_BASENAME) {
        await props.updateBasename(kebabCase(currentCompany.name));
      }

      Toast({
        type: 'warning',
        message: 'Impersonation',
        description: (
          <>
            You are now impersonating <b>{getUserFullName(normalizedUserSchema)}</b>
          </>
        ),
      });
    }

    setIsRequestingImpersonation(false);
  };

  /**
   * Schedule user deletion
   */
  const handleUserSoftDelete = async userID => {
    setIsDeletingUser(true);

    try {
      await createSoftUserDataDeleteRequest(userID);
      setIsDeleteUserConfirmationVisible(false);

      const updatedData = { ...userDetails, status: STATUS_LIST.Status.DELETED };
      setUserDetails(updatedData);

      Toast({
        type: 'warning',
        message: t('userStatusChangedToDeleted'),
      });
    } catch (error) {
      handleApiErrors(error.response);
    }

    setIsDeletingUser(false);
  };

  const FULL_WIDTH_PAGES = [`${INTERNAL_LINKS.USER_REIMBURSEMENT_RATE}$`, 'tax-report'];

  return (
    <PageContainerWithFadedLeftNavigation
      style={{ paddingTop: !canViewBreadcrumb(myProfile) ? 50 : undefined, paddingBottom: 3 }}
      fullWidthPage={FULL_WIDTH_PAGES.some(pathname =>
        new RegExp(pathname).test(props.location.pathname),
      )}
      isPageLoading={isRequestingImpersonation}
      navRoutes={[
        {
          to: formViewRoutes(),
          label: t('Activity'),
        },
        {
          to: formViewRoutes('profile'),
          label: t('Profile'),
        },
        {
          to: formViewRoutes(INTERNAL_LINKS.USER_MOBILE_APP_SETTINGS),
          label: t('mobileAppSettings'),
        },
        {
          to: formViewRoutes('email-preferences'),
          label: t('emailPreferences'),
          hidden: myProfile.profile._id !== userDetails._id,
        },
        {
          to: formViewRoutes(INTERNAL_LINKS.USER_DRIVERS_LICENSE),
          label: t('driverLicense'),
        },
        {
          to: formViewRoutes('trip-recording-preferences'),
          label: t('tripRecording'),
          hidden: USER_GROUP?.mileageEntryMode === DAILY_MILEAGE_ENTRY_MODES.DAILY_MILEAGE_LOG,
        },
        {
          to: formViewRoutes('vacations'),
          label: t('Vacations'),
        },
        {
          to: formViewRoutes('obd-device'),
          label: t('OBD Device'),
          hidden: !currentCompany?.companySettingId?.settings?.obdDevice,
        },
        {
          to: INTERNAL_LINKS.USER_DETAILS_YOUR_DATA(props.match.params.id),
          label: t('yourData'),
          hidden: myProfile.profile._id !== userDetails._id,
        },
        {
          to: formViewRoutes('reimbursement-rate'),
          label: t('programInfo'),
          hidden: USER_DETAILS_RATE ? USER_DETAILS_RATE?.rates?.rateType !== RATE_TYPES.FAVR : true,
        },
        {
          to: formViewRoutes('tax-report'),
          label: t('taxReport'),
          hidden: !userTaxableYearsQuery.data?.length,
        },
        {
          to: formViewRoutes('vehicle-info'),
          label: (
            <Row align="middle" wrap={false} gutter={8}>
              <Col>{t('vehicleInformation')}</Col>

              {!!userComplianceDetailsQuery?.data?.businessVehicleRequireConfirmation ? (
                <Col>
                  <img width="20px" src={IMAGES.ALERT_WARNING_TRIANGLE_ICON} alt="pending" />
                </Col>
              ) : (
                userComplianceDetailsQuery?.data?.businessVehicleComplianceStatus && (
                  <Col>
                    {[STATUS_LIST.Status.DENIED, STATUS_LIST.Status.NON_COMPLIANT].includes(
                      userComplianceDetailsQuery?.data?.businessVehicleComplianceStatus,
                    ) && <img width="20px" src={IMAGES.BELL_ALERT_ICON} alt="denied" />}

                    {userComplianceDetailsQuery?.data?.businessVehicleComplianceStatus ===
                      STATUS_LIST.Status.APPROVED && (
                      <img width="20px" src={IMAGES.BELL_CHECK_ICON} alt="approved" />
                    )}

                    {userComplianceDetailsQuery?.data?.businessVehicleComplianceStatus ===
                      STATUS_LIST.Status.PENDING && (
                      <img width="20px" src={IMAGES.BELL_CLOCK_ICON} alt="pending" />
                    )}
                  </Col>
                )
              )}
            </Row>
          ),
        },
        {
          to: formViewRoutes(INTERNAL_LINKS.EMISSIONS_DETAILS.replace('/', '')),
          label: t('vehicleEmissions'),
          hidden: !currentCompany?.companySettingId?.settings?.esgScope3,
        },
        {
          to: formViewRoutes(INTERNAL_LINKS.USER_INSURANCE_INFO),
          label: (
            <Row align="middle" wrap={false} gutter={8}>
              <Col>{t('insuranceInfo')}</Col>

              {userComplianceDetailsQuery?.data?.insuranceRequireConfirmation && (
                <Col>
                  <img width="20px" src={IMAGES.ALERT_WARNING_TRIANGLE_ICON} alt="pending" />
                </Col>
              )}
            </Row>
          ),
          hidden: hasUserRole(myProfile) && myProfile.profile._id !== userDetails._id,
        },
        {
          to: formViewRoutes(INTERNAL_LINKS.USER_BANK_INFO),
          label: (
            <Row align="middle" wrap={false} gutter={8}>
              <Col>{t('achBankInfo')}</Col>

              <Col>
                {canViewUserAchBankInfo(userDetails._id) ? (
                  !!get(userDetails, 'dwollaPaymentInfo.fundingSource') ? (
                    <img
                      width="20px"
                      src={IMAGES.CIRCLE_CHECK_MARK_ICON}
                      alt={t('bankAccountAdded')}
                    />
                  ) : (
                    <img
                      width="20px"
                      src={IMAGES.CIRCLE_REMOVE_ICON}
                      alt={t('bankAccountNotAdded')}
                    />
                  )
                ) : userDetails?.dwollaBankAccountAdded ? (
                  <img
                    width="20px"
                    src={IMAGES.CIRCLE_CHECK_MARK_ICON}
                    alt={t('bankAccountAdded')}
                  />
                ) : (
                  <img
                    width="20px"
                    src={IMAGES.CIRCLE_REMOVE_ICON}
                    alt={t('bankAccountNotAdded')}
                  />
                )}
              </Col>
            </Row>
          ),
          disabled: !canViewUserAchBankInfo(userDetails._id),
          hidden:
            hasCompanyAdminRole(myProfile) &&
            !!get(currentCompany, 'companySettingId.settings.ach_enabled') &&
            get(currentCompany, 'dwollaPaymentInfo.controllerInfo.customerStatus') ===
              STATUS_LIST.Status.VERIFIED
              ? false
              : !canViewUserAchBankInfo(userDetails._id),
        },
      ]}
      pageContainerProps={{
        title: hasCompanyManagerRole(myProfile)
          ? ' '
          : canViewBreadcrumb(myProfile) && (
              <Row gutter={16}>
                <Col>
                  <PageBreadcrumbs
                    items={[
                      {
                        label: 'Users',
                        onClick: () => {
                          if (hasSystemAdminRole(myProfile)) {
                            props.history.replace(
                              `${INTERNAL_LINKS.SELECT_COMPANY}/${currentCompany._id}?activeTab=Users`,
                            );
                          } else {
                            props.history.push(INTERNAL_LINKS.USER_MANAGER);
                          }
                        },
                      },
                      ...(userDetails ? [{ label: getUserFullName(userDetails) }] : []),
                    ]}
                  />
                </Col>
                {checkIfUserIsOnVacation(userDetails.vacations) &&
                  ![STATUS_LIST.Status.DELETED, STATUS_LIST.Status.INACTIVE].includes(
                    userDetails.status,
                  ) && (
                    <Col>
                      <Tag variant="orange">On Vacation</Tag>
                    </Col>
                  )}
              </Row>
            ),
        sideActionComponent: (
          <>
            <Col>
              <Row justify="end" gutter={16}>
                {canSoftDeleteUser(myProfile, userDetails) &&
                  userDetails.status !== STATUS_LIST.Status.DELETED && (
                    <Col>
                      <Button
                        loading={isDeletingUser}
                        disabled={isDeletingUser || isRequestingImpersonation}
                        onClick={() => setIsDeleteUserConfirmationVisible(true)}
                      >
                        {t(isDeletingUser ? 'deletingUser' : 'deleteUser')}
                      </Button>
                    </Col>
                  )}

                {canImpersonateUser(myProfile, userDetails) &&
                  ![
                    STATUS_LIST.Status.INACTIVE,
                    STATUS_LIST.Status.DELETED,
                    STATUS_LIST.Status.PENDING,
                  ].includes(userDetails.status) && (
                    <Col>
                      <Button
                        disabled={
                          isDeletingUser || isRequestingImpersonation || userDetails.isAnonymized
                        }
                        loading={isRequestingImpersonation}
                        onClick={() => handleImpersonation(userDetails._id)}
                      >
                        {t(isRequestingImpersonation ? 'Impersonating' : 'Impersonate User')}
                      </Button>
                    </Col>
                  )}
              </Row>

              {canImpersonateUser(myProfile, userDetails) && userDetails.isAnonymized && (
                <FadedText size="sm" style={{ marginTop: 10 }}>
                  {t('anonymizedUserCannotBeImpersonated')}
                </FadedText>
              )}

              {canImpersonateUser(myProfile, userDetails) &&
                !userDetails.isAnonymized &&
                userDetails.status === STATUS_LIST.Status.INACTIVE && (
                  <FadedText size="sm" style={{ marginTop: 10 }}>
                    {t('inactiveUserCannotBeImpersonated')}
                  </FadedText>
                )}
            </Col>
          </>
        ),
      }}
    >
      <Route
        exact
        path={formViewRoutes()}
        render={routeProps => (
          <UserDetailsActivityView userDetails={userDetails} {...routeProps} {...props} />
        )}
      />
      <Route
        path={formViewRoutes('profile')}
        render={routeProps => (
          <UserDetailsProfileView
            timezones={timezones}
            onTimezonesFetch={setTimezones}
            userDetails={userDetails}
            onUserUpdate={onUserUpdate}
            {...routeProps}
            {...props}
          />
        )}
      />
      <Route
        path={formViewRoutes(INTERNAL_LINKS.USER_MOBILE_APP_SETTINGS)}
        render={routeProps => (
          <MobileAppSettings userDetails={userDetails} {...routeProps} {...props} />
        )}
      />
      <Route
        path={formViewRoutes('email-preferences')}
        render={routeProps => (
          <EmailPreferencesView userDetails={userDetails} {...routeProps} {...props} />
        )}
      />
      <Route
        path={formViewRoutes('vacations')}
        render={routeProps => (
          <UserDetailsVacationsView
            userDetails={userDetails}
            isUserDeleted={userDetails.status === STATUS_LIST.Status.DELETED}
            onUserUpdate={onUserUpdate}
            {...routeProps}
            {...props}
          />
        )}
      />

      {!currentCompany?.companySettingId?.settings?.obdDevice && (
        <Route
          path={formViewRoutes('obd-device')}
          render={routeProps => (
            <UserDetailsObdDeviceView
              userDetails={userDetails}
              isUserDeleted={userDetails.status === STATUS_LIST.Status.DELETED}
              onUserUpdate={onUserUpdate}
              {...routeProps}
              {...props}
            />
          )}
        />
      )}

      <Route
        path={INTERNAL_LINKS.USER_DETAILS_YOUR_DATA(props.match.params.id)}
        render={routeProps =>
          myProfile.profile._id === userDetails._id ? (
            <UserProfileDataView
              userDetails={userDetails}
              currentCompany={currentCompany}
              {...routeProps}
              {...props}
            />
          ) : (
            <Redirect to={formViewRoutes('profile')} />
          )
        }
      />

      <Route
        path={formViewRoutes(INTERNAL_LINKS.USER_DRIVERS_LICENSE)}
        render={routeProps => (
          <DriverLicenseView
            userDetails={userDetails}
            userComplianceDetails={userComplianceDetailsQuery?.data}
            isUserDeleted={userDetails.status === STATUS_LIST.Status.DELETED}
            onUserUpdate={onUserUpdate}
            {...routeProps}
            {...props}
          />
        )}
      />

      <Route
        path={formViewRoutes('reimbursement-rate')}
        render={routeProps => (
          <UserFavrInformationView
            userDetails={userDetails}
            rootPath={formViewRoutes('reimbursement-rate')}
            isLoading={userFavrInfoQuery.isFetching}
            userComplianceDetails={userComplianceDetailsQuery?.data}
            favrInformation={userFavrInfoQuery.data}
            isUserDeleted={userDetails.status === STATUS_LIST.Status.DELETED}
            {...routeProps}
            {...props}
          />
        )}
      />

      <Route
        path={formViewRoutes('tax-report')}
        render={routeProps => {
          return (
            <UserTaxReportView
              {...routeProps}
              userDetails={userDetails}
              taxableYears={userTaxableYearsQuery.data}
              isLoadingTaxableYears={userTaxableYearsQuery.isFetching}
            />
          );
        }}
      />

      <Route
        path={formViewRoutes('vehicle-info')}
        render={routeProps => (
          <BusinessVehicleView
            userDetails={userDetails}
            userComplianceDetails={userComplianceDetailsQuery?.data}
            isUserDeleted={userDetails.status === STATUS_LIST.Status.DELETED}
            msrpAndSalesTax={userFavrInfoQuery?.data?.msrpAndSalesTax}
            isVinDecoderEnabled={!!currentCompany?.companySettingId?.settings?.vinDecoder}
            isFAVR={
              USER_DETAILS_RATE ? USER_DETAILS_RATE?.rates?.rateType === RATE_TYPES.FAVR : false
            }
            rootPath={formViewRoutes('reimbursement-rate')}
            {...routeProps}
            {...props}
          />
        )}
      />

      <Route
        path={formViewRoutes(INTERNAL_LINKS.EMISSIONS_DETAILS.replace('/', ''))}
        render={routeProps =>
          currentCompany?.companySettingId?.settings?.esgScope3 ? (
            <UserEmissionsDetailsView
              userDetails={userDetails}
              isUserDeleted={userDetails.status === STATUS_LIST.Status.DELETED}
              {...routeProps}
              {...props}
            />
          ) : (
            <Redirect to={formViewRoutes('profile')} />
          )
        }
      />

      <Route
        path={formViewRoutes(INTERNAL_LINKS.USER_INSURANCE_INFO)}
        render={routeProps =>
          hasUserRole(myProfile) && myProfile.profile._id !== userDetails._id ? (
            <Redirect to={formViewRoutes('profile')} />
          ) : (
            <UserInsuranceInformationView
              userDetails={userDetails}
              userComplianceDetails={userComplianceDetailsQuery?.data}
              isUserDeleted={userDetails.status === STATUS_LIST.Status.DELETED}
              rootPath={formViewRoutes(INTERNAL_LINKS.USER_INSURANCE_INFO)}
              readOnly={myProfile.profile._id !== userDetails._id}
              {...routeProps}
              {...props}
            />
          )
        }
      />

      <Route
        path={formViewRoutes('trip-recording-preferences')}
        render={routeProps =>
          USER_GROUP?.mileageEntryMode !== DAILY_MILEAGE_ENTRY_MODES.DAILY_MILEAGE_LOG ? (
            <TripRecordingPreferencesPage
              userDetails={userDetails}
              isUserDeleted={userDetails.status === STATUS_LIST.Status.DELETED}
              onUserUpdate={onUserUpdate}
              rootPath={formViewRoutes('trip-recording-preferences')}
              {...routeProps}
              {...props}
            />
          ) : (
            <Redirect to={formViewRoutes('profile')} />
          )
        }
      />

      <Route
        path={formViewRoutes(INTERNAL_LINKS.USER_BANK_INFO)}
        render={routeProps =>
          canViewUserAchBankInfo(userDetails._id) ? (
            <UserAchBankInfoView
              userDetails={userDetails}
              isUserDeleted={userDetails.status === STATUS_LIST.Status.DELETED}
              onUserUpdate={onUserUpdate}
              {...routeProps}
              {...props}
            />
          ) : (
            <Redirect to={formViewRoutes('profile')} />
          )
        }
      />

      <ConfirmModal
        loading={isDeletingUser}
        visible={isDeleteUserConfirmationVisible}
        title={t('deleteUserConfirmationTitle')}
        onOk={() => handleUserSoftDelete(userDetails._id)}
        onCancel={() => setIsDeleteUserConfirmationVisible(false)}
        okText={t('yesDelete')}
      />
    </PageContainerWithFadedLeftNavigation>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    currentCompany: state.common.currentCompany,
    currentBasename: state.common.basename,
    myProfile: state.profile,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    updateProfile: data => dispatch(updateProfile(data)),
    saveCompany: company => dispatch(saveCompany(company)),
    removeStoreCompany: () => dispatch(removeCompany()),
    updateBasename: basename => dispatch(emitUpdateGlobalBasename(basename)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withNamespaces()(withAuthentication(UserDetailsPage)));
