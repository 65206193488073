import Toast from '../components/Toast';
import { DAILY_MILEAGE_ENTRY_MODES } from '../enum';
import { formatQueryRange } from '../utils/common';
import { cleanQueryParams } from '../utils/queryParams';
import { handleApiCalls, handleApiErrors } from './axiosInstance';

/**
 * Performs an API call to delete a Group
 *
 * @param {string} groupId ID of Group to delete
 */
export const deleteGroup = async groupId => {
  let success = true;

  if (!groupId) {
    Toast({
      type: 'error',
      message: 'You must select a group to be deleted',
    });
    return;
  }

  try {
    await handleApiCalls('delete', `${process.env.REACT_APP_HOST_API}group/${groupId}`);
  } catch (error) {
    success = false;
    Toast({
      type: 'error',
      message: 'Unable to delete group',
    });
  }

  return success;
};

/**
 * Performs an API call to set a Group's Manager
 * @param {string[]} groupId List of Group IDs to be updated
 * @param {string} userId ID of User to be Manager of Group
 * @param {string[]} additionalManagers List of additional managers for the Group
 */
export const updateGroupManager = async (groupIds = [], userId, additionalManagers = []) => {
  if (!groupIds.length) throw new Error('You must provide a valid list of Groups');

  let managerResult;

  try {
    managerResult = await handleApiCalls(
      'post',
      `${process.env.REACT_APP_HOST_API}group/set-manager`,
      { userId, groupIds, additionalManagers },
    );

    if (managerResult.status === 200) {
      return managerResult.data;
    } else {
      throw new Error('Error setting a Manager for the Group');
    }
  } catch (error) {
    handleApiErrors(error.response, () => {
      Toast({
        type: 'error',
        message: "Unable to set group's manager",
      });
    });
  }

  return managerResult;
};

/**
 * Peform an API call to create a new Group for a Company and set a Manager for said Group
 *
 * @param {string} name Group name
 * @param {string} companyID ID of Company to link the Group
 */
export const createGroup = async (
  name,
  companyId,
  managerId,
  productId,
  additionalManagers = [],
  paymentScheduleId,
  mileageEntryMode = DAILY_MILEAGE_ENTRY_MODES.TRIP_BASED,
  mileageCapInMeters,
  commute,
) => {
  let createResult;

  try {
    createResult = await handleApiCalls('post', `${process.env.REACT_APP_HOST_API}group`, {
      name,
      companyId,
      productId,
      paymentScheduleId,
      mileageEntryMode,
      mileageCapInMeters: !!mileageCapInMeters ? parseFloat(mileageCapInMeters) : undefined,
      commute,
    });

    const managerResult = await updateGroupManager(
      [createResult.data.data._id],
      managerId,
      additionalManagers,
    );
    if (!managerResult) throw new Error('Error setting manager');

    return createResult.data.data;
  } catch (error) {
    if (createResult) {
      await deleteGroup(createResult.data.data._id);
    }

    handleApiErrors(error.response, () => {
      Toast({
        type: 'error',
        message: 'Unable to create group',
      });
    });
  }
};

/**
 * Performs an API call to fetch the future payment deates based on a set of payment settings
 *
 * @param {object} params Payment schedule settings
 */
export const fetchFuturePaymentScheduleDates = async params => {
  const result = await handleApiCalls(
    'post',
    `${process.env.REACT_APP_HOST_API}payment-schedules/future-dates`,
    params,
  );

  return result.data.data;
};

export const GROUPS_API = {
  /**
   * Returns the list of Groups that are part of a company from the API
   *
   * @param {string} companyID ID of Company
   * @param {Function} onSuccess On Success callback
   * @param {Function=} onError On Error callback
   */
  fetchGroupList: async (companyID, onSuccess, onError) => {
    const result = await handleApiCalls(
      'get',
      `${process.env.REACT_APP_HOST_API}group?company=${companyID}`,
    );

    if (result?.status === 200) {
      if (typeof onSuccess === 'function') onSuccess(result.data.data);
      return result?.data?.data;
    } else {
      Toast({
        type: 'error',
        message: 'Failed to load',
        description: 'Unable to load company groups data',
      });

      if (typeof onError === 'function') onError();
    }
  },
  fetchGroupsUsers: async (filters = {}, pageNumber, pageSize) => {
    const { groups = [], ...otherFilters } = filters;
    const range = formatQueryRange(pageNumber, pageSize);
    const queryParams = cleanQueryParams({
      ...otherFilters,
      groups: JSON.stringify(groups),
      range,
    });

    const result = await handleApiCalls(
      'get',
      `${process.env.REACT_APP_HOST_API}group/users?${queryParams}`,
    );
    return result.data;
  },
  /**
   * @param {string} groupId
   * @param {number} pageNumber
   * @param {number} pageSize
   * @returns {{ periods: object[], count: number }}
   */
  fetchPeriodsByGroup: async (groupId, pageNumber, pageSize) => {
    const queryParams = cleanQueryParams({ range: formatQueryRange(pageNumber, pageSize) });
    const result = await handleApiCalls(
      'get',
      `${process.env.REACT_APP_HOST_API}group/${groupId}/periods?${queryParams}`,
    );
    return {
      count: result.data.count,
      periods: result.data.periods,
      hasAdHocPeriod: !!result.data.periods.find(period => !period.start || !period.end),
    };
  },
};
