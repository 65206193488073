import { Form } from 'antd';
import { omit, pick } from 'lodash';
import React from 'react';

import useDidUpdateEffect from '../../../hooks/useDidUpdateEffect';
import { ERROR_MESSAGE, FORMAT_FORM_RULE } from '../../../utils/constants';
import FormItem from '../../Form/FormItem';
import Select from '../../Select';
import SubmitCancelButtonGroup from '../../SubmitCancelButtonGroup';
import FadedText from '../../Text/FadedText';
import TextInput from '../../TextInput';
import GenericModal from '../GenericModal';

const BankAccountFormModal = props => {
  const {
    t,
    visible,
    fundingSourceId,
    isSourceVerified,
    initialValues,
    isSubmitting,
    onCancel,
    onSubmit,
  } = props;

  const [form] = Form.useForm();

  useDidUpdateEffect(() => {
    if (visible) {
      form.setFieldsValue(
        initialValues
          ? omit(
              {
                name: initialValues.accountName,
                type: initialValues.accountType,
                routingNumber: initialValues.routingNumber,
                accountNumber: initialValues.accountNumber,
                accountNumberConfirm: initialValues.accountNumber,
              },
              isSourceVerified ? [] : ['accountNumber', 'accountNumberConfirm'],
            )
          : {},
      );
    } else {
      form.resetFields();
    }
  }, [visible]);

  const handleSubmit = values => {
    const formValues = { ...values, fundingSourceId };

    if (isSourceVerified) {
      onSubmit(pick(formValues, ['name', 'fundingSourceId']));
    } else {
      onSubmit(formValues);
    }
  };

  return (
    <GenericModal
      centered
      title={t('bankAccountInformation')}
      visible={visible}
      onCancel={onCancel}
      closable={!isSubmitting}
      footer={
        <SubmitCancelButtonGroup
          onCancel={onCancel}
          loading={isSubmitting}
          disabled={isSubmitting}
          submitText={t('saveBankAccount')}
          onSubmit={form.submit}
        />
      }
    >
      <Form form={form} autoComplete="off" onFinish={handleSubmit}>
        {isSourceVerified && (
          <div style={{ marginBottom: 10 }}>
            <FadedText size="sm">{t('verifiedBankAccountAreOnlyAbleToEditName')}</FadedText>
          </div>
        )}

        <FormItem
          required
          label={t('accountName')}
          name="name"
          rules={[
            { required: true, message: ERROR_MESSAGE.BLANK_FIELD },
            { validateTrigger: 'onSubmit', max: 50, message: ERROR_MESSAGE.STRING_MAX_LENGTH(50) },
          ]}
        >
          <TextInput disabled={isSubmitting} />
        </FormItem>

        <FormItem
          required={!isSourceVerified}
          label={t('accountType')}
          name="type"
          rules={!isSourceVerified ? [{ required: true, message: ERROR_MESSAGE.BLANK_FIELD }] : []}
        >
          <Select
            disabled={isSubmitting || isSourceVerified}
            options={[
              { label: t('checking'), value: 'checking' },
              { label: t('savings'), value: 'savings' },
            ]}
          />
        </FormItem>

        <FormItem
          required={!isSourceVerified}
          label={t('routingNumber')}
          name="routingNumber"
          rules={
            !isSourceVerified
              ? [
                  { required: true, message: ERROR_MESSAGE.BLANK_FIELD },
                  {
                    validateTrigger: 'onSubmit',
                    min: 9,
                    message: ERROR_MESSAGE.STRING_MIN_LENGTH(9),
                  },
                  {
                    validateTrigger: ['onBlur', 'onSubmit'],
                    validator: (_, value) => {
                      if (!value) return Promise.resolve();
                      if (/[a-z]/gi.test(value)) {
                        return Promise.reject(ERROR_MESSAGE.ONLY_NUMBERS_ALLOWED);
                      }

                      return Promise.resolve();
                    },
                  },
                  /**
                   * the first two digits of the routing number
                   * must fall within the range "01" through "12", or "21" through "32",
                   * and the string value must consist of nine digits.
                   */
                  ...FORMAT_FORM_RULE(/((0[0-9])|(1[0-2])|(2[1-9])|(3[0-2]))([0-9]{7})/),
                ]
              : []
          }
        >
          <TextInput
            step="any"
            minLength={9}
            maxLength={9}
            pattern="[0-9]*"
            disabled={isSubmitting || isSourceVerified}
          />
        </FormItem>

        <FormItem
          required={!isSourceVerified}
          label={t('accountNumber')}
          name="accountNumber"
          rules={
            !isSourceVerified
              ? [
                  { required: true, message: ERROR_MESSAGE.BLANK_FIELD },
                  {
                    validateTrigger: 'onSubmit',
                    min: 4,
                    message: ERROR_MESSAGE.STRING_MIN_LENGTH(4),
                  },
                  {
                    validateTrigger: 'onSubmit',
                    max: 17,
                    message: ERROR_MESSAGE.STRING_MAX_LENGTH(17),
                  },
                  {
                    validateTrigger: ['onChange', 'onBlur'],
                    validator: (_, value) => {
                      if (!value) return Promise.resolve();
                      if (/[a-z]/gi.test(value)) {
                        return Promise.reject(ERROR_MESSAGE.ONLY_NUMBERS_ALLOWED);
                      }

                      return Promise.resolve();
                    },
                  },
                  ...FORMAT_FORM_RULE(/[0-9]/),
                ]
              : []
          }
        >
          <TextInput
            step="any"
            minLength={4}
            maxLength={17}
            pattern="[0-9]*"
            disabled={isSubmitting || isSourceVerified}
          />
        </FormItem>

        <FormItem
          required={!isSourceVerified}
          label={t('reEnterAccountNumber')}
          name="accountNumberConfirm"
          rules={
            !isSourceVerified
              ? [
                  { required: true, message: ERROR_MESSAGE.BLANK_FIELD },
                  {
                    validateTrigger: ['onChange', 'onBlur'],
                    validator: (_, value) => {
                      if (!value) return Promise.resolve();
                      if (/[a-z]/gi.test(value)) {
                        return Promise.reject(ERROR_MESSAGE.ONLY_NUMBERS_ALLOWED);
                      }

                      return Promise.resolve();
                    },
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('accountNumber') === value) {
                        return Promise.resolve();
                      }

                      return Promise.reject(new Error(t('accountNumbersMustMatch')));
                    },
                  }),
                ]
              : []
          }
        >
          <TextInput step="any" pattern="[0-9]*" disabled={isSubmitting || isSourceVerified} />
        </FormItem>
      </Form>
    </GenericModal>
  );
};

export default BankAccountFormModal;
