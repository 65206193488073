import { Col, Row } from 'antd';
import { capitalize, get } from 'lodash';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';

import { STATUS_LIST } from '../../enum';
import useFeatureToggle from '../../hooks/useFeatureToggle';
import { getUserFullName, momentEST } from '../../utils/common';
import Box from '../Box';
import Button from '../Button';
import HorizontalLineDivider from '../HorizontalLineDivider';
import Text from '../Text';
import UserProfileForm from '../UserProfileForm';
import classNames from './style.module.scss';

/**
 * Renders a Form to update the User's data
 */
const UserProfileFormSection = props => {
  const {
    t,
    isSubmitting,
    isSendingActivationLink,
    userDetails,
    groupDetails,
    timezoneOptions,
    canUpdateUserActiveStatus,
    canSendActivationLink,
    onSubmit,
    onImageUpload,
    onUserStatusChange,
    sendActivationLink,
    onChangePassword,
    canChangePassword,
    canEditUserIdentity,
  } = props;

  const { isUserDataReadOnly } = useFeatureToggle();

  const [formValues, setFormValues] = useState({
    firstName: userDetails.firstName,
    lastName: userDetails.lastName,
    email: userDetails.email,
    phone: userDetails.phone,
    preferences: userDetails.preferences || { preferredTimezone: undefined },
    department: userDetails.department,
    employeeId: userDetails?.employee?.employeeId,
    sendActivationEmailDate: userDetails.sendActivationEmailDate
      ? momentEST(userDetails.sendActivationEmailDate)
      : null,
  });

  const groupProduct = useMemo(() => get(userDetails, 'group.productId') || {}, [userDetails]);

  const bottomBoxContent = [
    {
      label: t('Group'),
      value: groupDetails?.name ? groupDetails.name : null,
    },
    {
      label: t('Group Manager'),
      value: groupDetails?.groupManager ? getUserFullName(groupDetails.groupManager) : null,
    },
    {
      label: t('Products'),
      value: groupProduct?.name ? groupProduct.name : null,
    },
    {
      label: t('Payment Frequency'),
      value: groupDetails?.paymentScheduleId
        ? capitalize(t(get(groupDetails, 'paymentScheduleId.paymentInterval')))
        : null,
    },
    {
      label: t('Payment Approval'),
      value: groupProduct?.approvalConfig
        ? t(`${groupProduct.approvalConfig}ApprovalConfig_short`)
        : null,
    },
    {
      label: t('Mileage Capture'),
      value: 'Kliks Mobile App',
    },
  ];

  return (
    <Col span={24}>
      <Row gutter={[0, 32]}>
        <Col span={24}>
          <Box>
            <Row>
              <Col span={24}>
                <UserProfileForm
                  t={t}
                  userDetails={userDetails}
                  isSubmitting={isSubmitting}
                  isSendingActivationLink={isSendingActivationLink}
                  values={formValues}
                  currentStatus={userDetails.status}
                  formDisabled={
                    isUserDataReadOnly || userDetails.status === STATUS_LIST.Status.DELETED
                  }
                  onUserStatusChange={onUserStatusChange}
                  profilePicture={userDetails.profilePicture}
                  timezoneOptions={timezoneOptions}
                  canUpdateUserStatus={canUpdateUserActiveStatus}
                  onFieldChange={field => setFormValues({ ...formValues, ...field })}
                  onImageUpload={onImageUpload}
                  sendActivationLink={sendActivationLink}
                  canSendActivationLink={canSendActivationLink}
                  onChangePassword={onChangePassword}
                  canChangePassword={canChangePassword}
                  canEditUserIdentity={canEditUserIdentity}
                />
              </Col>
            </Row>

            <Row>
              <HorizontalLineDivider marginTop={8} marginBottom={32} />
            </Row>

            <Row>
              <Col xs={24} lg={12}>
                {bottomBoxContent.map(content => (
                  <Row
                    key={content.label}
                    className={classNames.bottomBoxRow}
                    justify="space-between"
                  >
                    <Col>
                      <Text variant="strong" size="sm" renderAs="div">
                        {content.label}
                      </Text>
                    </Col>
                    <Col>
                      <Text variant="p" size="sm" renderAs="div">
                        {content.value || '-'}
                      </Text>
                    </Col>
                  </Row>
                ))}
              </Col>
            </Row>
            <br />

            <Row justify="end">
              <Button
                loading={isSubmitting}
                disabled={isUserDataReadOnly || userDetails.status === STATUS_LIST.Status.DELETED}
                onClick={() => onSubmit(formValues)}
              >
                Update Profile
              </Button>
            </Row>
          </Box>
        </Col>
      </Row>
    </Col>
  );
};

UserProfileFormSection.propTypes = {
  t: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isUpdating: PropTypes.bool,
  userDetails: PropTypes.shape({}),
  canUpdateUserNames: PropTypes.bool,
  canUpdateUserActiveStatus: PropTypes.bool,
  timezoneOptions: PropTypes.arrayOf(PropTypes.shape({})),
};

UserProfileFormSection.defaultProps = {
  isUpdating: false,
  userDetails: {},
  canUpdateUserNames: true,
  canUpdateUserActiveStatus: false,
  timezoneOptions: [],
};

export default UserProfileFormSection;
