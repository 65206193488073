import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { withNamespaces } from 'react-i18next';
import { useDispatch } from 'react-redux';

import RateFormWizard from '../../components/RateFormWizard';
import SpaceSpinner from '../../components/SpaceSpinner';
import PageContainer from '../../containers/PageContainer';
import { emitResetRateWizard, emitSetupRateWizard } from '../../stores/actions/rateWizard';
import { formatPageTitle } from '../../utils/common';
import { useStoreSelector } from '../../utils/storeSelectors';

const CreateRateView = props => {
  const { t, match, history } = props;

  const dispatch = useDispatch();
  const rateWizard = useStoreSelector('rateWizard');

  useEffect(() => {
    dispatch(emitSetupRateWizard());

    return () => {
      dispatch(emitResetRateWizard());
    };
  }, [dispatch]);

  if (rateWizard.isSettingUpWizard) {
    return <SpaceSpinner />;
  }

  return (
    <PageContainer title="Create Rate">
      <Helmet>
        <title>{formatPageTitle('Create Rate')}</title>
      </Helmet>

      <RateFormWizard t={t} match={match} history={history} />
    </PageContainer>
  );
};

export default withNamespaces()(CreateRateView);
