import { Form } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { withNamespaces } from 'react-i18next';

import Select from '../../Select';
import TextInput from '../../TextInput';
import States from './state';

class USFormat extends React.Component {
  render() {
    const { t } = this.props;

    return (
      <React.Fragment>
        {this.props.visibleName && (
          <React.Fragment>
            <Form.Item
              label="First Name"
              name="firstName"
              rules={[{ required: true, message: 'Please input fist Name!' }]}
            >
              <TextInput />
            </Form.Item>
            <Form.Item
              label="Last Name"
              name="lastName"
              rules={[{ required: true, message: 'Please input last Name!' }]}
            >
              <TextInput />
            </Form.Item>
          </React.Fragment>
        )}
        <Form.Item
          label="Address 1"
          name="address1"
          rules={[
            { required: true, message: 'Please type the address.' },
            { max: 35, message: 'Address has 35 character limits.' },
          ]}
        >
          <TextInput />
        </Form.Item>
        <Form.Item
          label="Address 2"
          name="address2"
          rules={[{ max: 35, message: 'Address has 35 character limits.' }]}
        >
          <TextInput />
        </Form.Item>
        <Form.Item
          label="City"
          name="city"
          rules={[{ required: true, message: 'Please type the country' }]}
        >
          <TextInput />
        </Form.Item>
        <Form.Item
          label={t('State')}
          name="state"
          rules={[{ required: true, message: 'Please select state!' }]}
        >
          <Select placeholder="Select the state" options={States.map(state => state)} />
        </Form.Item>
        <Form.Item
          label="Zip Code"
          name="postalCode"
          rules={[{ required: true, message: 'Please input the Zip Code!' }]}
        >
          <TextInput masked mask="00000" />
        </Form.Item>
      </React.Fragment>
    );
  }
}

USFormat.propTypes = {
  visibleName: PropTypes.bool,
};

USFormat.defaultProps = {
  visibleName: false,
};

export default withNamespaces()(USFormat);
