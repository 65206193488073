import './style.scss';

import { Button } from 'components';
import PropTypes from 'prop-types';
import React from 'react';
// the hoc
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { updateGroupList } from 'stores/actions/group';

import { handleApiCalls } from '../../../api/axiosInstance';
import { GROUPS_API } from '../../../api/group';
import Banner from '../../../components/Banner';
import GroupsTable from '../../../components/Table/GroupsTable';
import Toast from '../../../components/Toast';

class GroupForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      notifications: [],
      groups: [],
      loadingFlag: false,
      selectedRowKeys: [],
      model: {
        name: {
          value: '',
        },
      },
    };
  }

  componentDidMount() {
    if (
      this.props.profile &&
      !(this.props.location.state && this.props.location.state.stopRefetch)
    ) {
      this.handleFetchGroup();
    }
  }

  handleFetchGroup = async () => {
    const { updateGroupList, profile } = this.props;
    this.setState({ loadingFlag: true });

    if (
      profile &&
      profile.profile &&
      profile.profile.businessInfor &&
      profile.profile.businessInfor &&
      profile.profile.businessInfor.companies[0].companyID
    ) {
      await GROUPS_API.fetchGroupList(
        profile.profile.businessInfor.companies[0].companyID,
        // On Success
        data => {
          updateGroupList(data);
          this.setState(state => ({ ...state, loadingFlag: false }));
        },
        // On Error
        () => {
          this.setState(state => ({ ...state, loadingFlag: false }));
        },
      );
    }
  };

  handleCloseNotification = index => {
    const { notifications } = this.state;
    this.setState({
      notifications: notifications
        .slice(0, index)
        .concat(notifications.slice(index + 1, notifications.length)),
    });
  };

  handleDeleteGroups = async () => {
    const { selectedRowKeys } = this.state;
    if (selectedRowKeys && selectedRowKeys.length) {
      this.setState({ loadingFlag: true });
      const { groupData } = this.props;
      let requests = [];
      try {
        selectedRowKeys.forEach(row => {
          const group = groupData.groupList.find(g => g._id === row);

          if (group && group.defaultGroup) {
            throw new Error("You can't delete default company group.");
          }

          const url = `${process.env.REACT_APP_HOST_API}group/${row}`;
          requests.push(handleApiCalls('delete', url));
        });
        await Promise.all(requests);
        await this.handleFetchGroup();
      } catch (err) {
        Toast({
          type: 'error',
          message: 'Failed',
          description: err.message || 'There were some errors deleting groups',
        });
      }
      this.setState({ loadingFlag: false });
    } else {
      Toast({
        type: 'error',
        message: 'Failed',
        description: 'Please select the group to delete.',
      });
    }
  };

  changeSelectedGroups = selectedRowKeys => {
    this.setState({ selectedRowKeys });
  };

  render() {
    const { notifications } = this.state;

    const rowSelection = {
      onChange: selectedRowKeys => {
        this.changeSelectedGroups(selectedRowKeys);
      },
    };

    return (
      <div className="group-form">
        <div className="group-form-notifications">
          {notifications.map((m, index) => {
            return (
              <Banner key={index} title={m} onClose={() => this.handleCloseNotification(index)} />
            );
          })}
        </div>
        <div className="group-form-content">
          <GroupsTable
            t={this.props.t}
            showSearchInput={!this.state.loadingFlag}
            loading={this.state.loadingFlag}
            rowSelection={{
              type: 'checkbox',
              ...rowSelection,
            }}
            dataSource={this.props.groupData.groupList}
          />
        </div>
        <div className="group-form-actions">
          <Button
            disabled={this.state.loadingFlag}
            text="Create New Group"
            onClick={() => this.props.goToGroupDetails()}
          />
          <Button
            disabled={this.state.loadingFlag}
            type={'secondary'}
            text="Delete Selected Groups"
            onClick={this.handleDeleteGroups}
          />
        </div>
      </div>
    );
  }
}

GroupForm.propTypes = {
  goToGroupDetails: PropTypes.func,
};

GroupForm.defaultProps = {
  goToGroupDetails: () => {},
};

const mapStateToProps = (state, ownProps) => {
  return {
    groupData: state.group,
    profile: state.profile,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    updateGroupList: groupList => {
      dispatch(updateGroupList(groupList));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(GroupForm));
