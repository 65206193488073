import './style.scss';

import { LoadingOutlined } from '@ant-design/icons';
import { Form, Spin } from 'antd';
import { Button } from 'components';
import FullLogo from 'components/FullLogo';
import TextInput from 'components/TextInput';
import AuthContainer from 'containers/Auth';
import { INTERNAL_LINKS } from 'enum';
import React from 'react';
// import ReCAPTCHA from 'react-google-recaptcha';
import { Helmet } from 'react-helmet';
// the hoc
import { withNamespaces } from 'react-i18next';

import { handleApiCalls, handleApiErrors } from '../../api/axiosInstance';
import Text from '../../components/Text';
import Toast from '../../components/Toast';
import { formatPageTitle } from '../../utils/common';

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);

    // const secretKey =
    //   process.env.NODE_ENV === 'production'
    //     ? process.env.REACT_APP_RECAPTCHA_SECRET_KEY_PRODUCTION
    //     : process.env.REACT_APP_RECAPTCHA_SECRET_KEY_LOCAL;

    this.state = {
      email: '',
      recaptcha: '',
      sentEmail: false,
      loading: false,
      // secretKey,
    };
  }

  onFinish = async values => {
    const { recaptcha } = this.state;
    const { email } = values;
    // if (!recaptcha) {
    //   Toast({
    //     type: 'error',
    //     message: 'Error',
    //     description: 'Please resolve the reCAPTCHA',
    //   });
    //   return;
    // }
    const url = `${process.env.REACT_APP_HOST_API}auth/forgot-password`;
    const data = {
      email,
      reCaptchaResponse: recaptcha,
    };

    this.setState({ loading: true });
    try {
      const result = await handleApiCalls('post', url, data);
      if (result.status === 202) {
        this.setState({ sentEmail: true });
      } else {
        Toast({
          type: 'error',
          message: 'Error',
          description: result.data || 'There was an error during sending email',
        });
      }
    } catch (err) {
      handleApiErrors(err.response);
    }
    this.setState({ loading: false });
  };

  onFinishFailed = errorInfo => {};

  onChange = value => {
    this.setState({ recaptcha: value });
  };

  goToSignin = () => {
    this.props.history.push(INTERNAL_LINKS.LOGIN);
  };

  render() {
    const { sentEmail } = this.state;
    const layout = {
      labelCol: { span: 4 },
      wrapperCol: { span: 24 },
    };
    const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

    return (
      <div className="fpp">
        <Helmet>
          <title>{formatPageTitle('Forgot Password')}</title>
        </Helmet>

        <div className="fpp-logo">
          <FullLogo />
        </div>
        <div className="fpp-container">
          <h5 className="fpp-container-title">
            {sentEmail ? 'Password Reset Successful' : 'Reset your password'}
          </h5>

          {sentEmail && (
            <>
              <Text>
                We're looking through our records to find the Kliks account for{' '}
                <b>{this.state.email}</b>.
              </Text>

              <br />

              <Text>
                If we find a match, you'll receive an email to reset your password. If your account
                has not been activated, we'll send you an email asking you to confirm your account.
              </Text>

              <br />

              <Text>
                If you don't receive an email, please double check that you entered the correct
                email address and check your spam folder.
              </Text>
            </>
          )}

          {!sentEmail && (
            <Text>
              Enter your user account's verified email address and we will send you a password reset
              link.
            </Text>
          )}

          <br />

          <div className="fpp-container-section">
            {!sentEmail && (
              <Form
                {...layout}
                name="basic"
                onFinish={this.onFinish}
                onFinishFailed={this.onFinishFailed}
              >
                <Form.Item
                  className="kliks-row reg-forms"
                  name="email"
                  rules={[
                    {
                      required: true,
                      type: 'email',
                      message: 'Please enter a valid email address.',
                    },
                  ]}
                >
                  <TextInput
                    size="large"
                    placeholder="Enter a valid email address"
                    onChange={e => this.setState({ email: e.target.value })}
                  />
                </Form.Item>

                {/* <div className="d-flex justify-content-center mb-3">
                  <ReCAPTCHA sitekey={this.state.secretKey} onChange={this.onChange} />
                </div> */}
                <div className="d-flex justify-content-center">
                  <Button
                    text="Send password reset email"
                    htmlType="submit"
                    className="fpp-container-submit text-center"
                  />
                </div>
              </Form>
            )}
            {sentEmail && (
              <div className="d-flex justify-content-center">
                <Button
                  text="Back to Login"
                  className="fpp-container-submit text-center"
                  onClick={this.goToSignin}
                />
              </div>
            )}
          </div>
        </div>
        {this.state.loading && (
          <div className="loading-container">
            <Spin indicator={antIcon} />
          </div>
        )}
      </div>
    );
  }
}

export default AuthContainer(withNamespaces()(ForgotPassword));
