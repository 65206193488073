import { Col, Form, Row } from 'antd';
import React from 'react';

import Checkbox from '../../../../components/Checkbox';
import TextInput from '../../../../components/TextInput';
import { ERROR_MESSAGE } from '../../../../utils/constants';

const CreditCardForm = props => {
  const { t, isUpdate, formInstance, initialValues, isSubmitting } = props;

  return (
    <Form form={formInstance} labelCol={{ span: 24 }} initialValues={initialValues}>
      <Row gutter={17}>
        <Col xs={24}>
          <Form.Item
            required={!isUpdate}
            label="Card Number"
            name="cardNumber"
            rules={[{ required: true, message: ERROR_MESSAGE.BLANK_FIELD }]}
          >
            <TextInput
              masked={!isUpdate}
              disabled={isSubmitting || isUpdate}
              placeholderChar=" "
              mask="0000 0000 0000 0000"
              placeholder="Enter card number"
              autoComplete="cc-number"
            />
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Form.Item
            required
            label="Cardholder Name"
            name="name"
            rules={[{ required: true, message: ERROR_MESSAGE.BLANK_FIELD }]}
          >
            <TextInput
              required
              autoComplete="cc-name"
              disabled={isSubmitting}
              placeholder="Enter card holder name"
            />
          </Form.Item>
        </Col>

        <Col xs={24} md={12}>
          <Form.Item
            required
            label="Expiration Date"
            name="expirationDate"
            rules={[{ required: true, message: ERROR_MESSAGE.BLANK_FIELD }]}
          >
            <TextInput
              masked
              mask="00 / 00"
              autoComplete="cc-exp"
              placeholder="MM / YY"
              disabled={isSubmitting}
            />
          </Form.Item>
        </Col>

        <Col xs={24} md={12}>
          <Form.Item
            required
            hidden={isUpdate}
            label="Security Code"
            name="cvc"
            rules={[{ required: !isUpdate, message: ERROR_MESSAGE.BLANK_FIELD }]}
          >
            <TextInput
              type="text"
              disabled={isSubmitting}
              autoComplete="cc-csc"
              placeholder="Enter CVC"
              onInput={e => {
                e.target.value = e.target.value.replace(/[^0-9]/, '').slice(0, 4);
              }}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={10} align="middle">
        <Col flex={1}>
          <Form.Item
            hidden={isUpdate}
            name="isDefault"
            valuePropName="checked"
            className="no-margin-bottom"
          >
            <Checkbox disabled={isSubmitting}>{t('setAsDefault')}</Checkbox>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default CreditCardForm;
