import React from 'react';

import { hasSystemAdminRole } from '../../../utils/roles';
import FeatureFormItem from './FeatureFormItem';
import SettingSectionWrapper from './SettingSectionWrapper';

const TripsAndTripsRecordingSettingsFormItems = props => {
  const { t, authUser, companySettings, onFeatureChange, disabled } = props;

  if (!hasSystemAdminRole(authUser)) return null;

  return (
    <SettingSectionWrapper title={t('trips&tripsRecording')}>
      <FeatureFormItem
        label={t('importTripsViaCsv ')}
        value={companySettings.importTripsViaCsv}
        onChange={checked => onFeatureChange({ importTripsViaCsv: checked })}
        helpText={t('importTripsViaCsv_SysAdminHelpText')}
        disabled={disabled}
      />

      <FeatureFormItem
        label={t('dailyMileageLog')}
        value={companySettings.dailyMileageLog}
        onChange={checked => onFeatureChange({ dailyMileageLog: checked })}
      />

      <FeatureFormItem
        label={t('gpsLitterValidation')}
        value={companySettings.gpsLitterValidation}
        onChange={checked => onFeatureChange({ gpsLitterValidation: checked })}
      />

      <FeatureFormItem
        label={t('gpsTripAdjustments')}
        value={companySettings.gpsTripAdjustments}
        onChange={checked => onFeatureChange({ gpsTripAdjustments: checked })}
      />

      <FeatureFormItem
        label={t('mileageCap')}
        value={companySettings.mileageCap}
        onChange={checked => onFeatureChange({ mileageCap: checked })}
      />

      <FeatureFormItem
        label={t('ObdForMileageCapture')}
        value={companySettings.obdDevice}
        onChange={checked => onFeatureChange({ obdDevice: checked })}
        disabled={disabled}
      />

      <FeatureFormItem
        label={t('useRadarForGeocoding')}
        value={companySettings.radarGeocoding}
        onChange={checked =>
          onFeatureChange({
            radarGeocoding: checked,
            gpsLitterValidation: checked ? false : companySettings.gpsLitterValidation,
            gpsTripAdjustments: checked ? false : companySettings.gpsTripAdjustments,
          })
        }
      />
    </SettingSectionWrapper>
  );
};

export default TripsAndTripsRecordingSettingsFormItems;
