import { Col, Row, Spin } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useInfiniteQuery } from 'react-query';

import { fetchInsuranceCompanies } from '../../api/insurance-companies';
import useDebouncedState from '../../hooks/useDebouncedState';
import { checkIfElementIsScrolledToBottom } from '../../utils/common';
import Select from '../Select';

const INSURANCE_COMPANIES_PER_PAGE = 100;

const InsuranceCompanySelect = props => {
  const { t, disabled, onChange, notFoundContent, value, showOtherOption, ...rest } = props;

  const [searchTerm, setSearchTerm] = useDebouncedState('', 500);
  const [initialCount, setInitialCount] = useState();
  const [isLookingForMoreOptions, setIsLookingForMoreOptions] = useState(true);

  const insuranceCompaniesQuery = useInfiniteQuery({
    queryKey: ['infinite', 'fetchInsuranceCompanies', searchTerm],
    staleTime: Infinity,
    queryFn: ({ pageParam = 1 }) =>
      fetchInsuranceCompanies(
        pageParam,
        INSURANCE_COMPANIES_PER_PAGE,
        { name: searchTerm },
        JSON.stringify({ name: 1 }),
      ),
    getNextPageParam: (lastPage, pages) => {
      const { totalCount = 0 } = lastPage || {};
      const totalPages = Math.ceil(totalCount / INSURANCE_COMPANIES_PER_PAGE);
      if (pages.length < totalPages) return pages.length + 1;
    },
    onSuccess: ({ pages }) => {
      if (
        Array.isArray(pages) &&
        typeof pages[0]?.totalCount !== 'undefined' &&
        typeof initialCount === 'undefined'
      ) {
        setInitialCount(pages[0]?.totalCount);
      }
    },
  });

  const flatPages = useMemo(() => {
    let optionsArray = [];

    if (Array.isArray(insuranceCompaniesQuery?.data?.pages)) {
      insuranceCompaniesQuery.data.pages.forEach(({ documents }) => {
        optionsArray = optionsArray.concat(documents);
      });
    }

    return optionsArray;
  }, [insuranceCompaniesQuery]);

  const options = useMemo(() => {
    let optionsArray = flatPages.map(item => ({
      value: item._id,
      label: item.name,
    }));

    if (showOtherOption) {
      optionsArray.unshift({ value: 'other', label: t('Other') });
    }

    return optionsArray;
  }, [t, flatPages, showOtherOption]);

  const handleChange = useCallback(
    (value, option) => {
      if (typeof onChange === 'function') {
        const fullSelectedItem = flatPages.find(item => item._id === value);
        onChange(value, fullSelectedItem);
      }
      setSearchTerm('');
    },
    [flatPages, setSearchTerm, onChange],
  );

  const handleOptionsScroll = useCallback(
    e => {
      const bottom = checkIfElementIsScrolledToBottom(e.target);
      if (
        bottom &&
        !insuranceCompaniesQuery.isFetchingNextPage &&
        insuranceCompaniesQuery.hasNextPage
      ) {
        insuranceCompaniesQuery.fetchNextPage();
      }
    },
    [insuranceCompaniesQuery],
  );

  useEffect(() => {
    if (value) {
      if (!!options?.length) {
        const valueInOptions = !!options.find(option => option.value === value);
        if (
          !valueInOptions &&
          !insuranceCompaniesQuery.isFetching &&
          insuranceCompaniesQuery.hasNextPage
        ) {
          setIsLookingForMoreOptions(true);
          insuranceCompaniesQuery.fetchNextPage();
        } else if (
          !insuranceCompaniesQuery.isFetching &&
          (!insuranceCompaniesQuery.hasNextPage || valueInOptions)
        ) {
          setIsLookingForMoreOptions(false);
        }
      }
    } else {
      setIsLookingForMoreOptions(false);
    }
  }, [value, options, insuranceCompaniesQuery]);

  return (
    <Select
      {...rest}
      fullWidth
      showSearch
      resetOnNoMatch={false}
      onSearch={setSearchTerm}
      onChange={handleChange}
      disabled={disabled || isLookingForMoreOptions}
      loading={insuranceCompaniesQuery.isFetching}
      options={options}
      placeholder={isLookingForMoreOptions ? t('searchingCompanies') : undefined}
      onPopupScroll={handleOptionsScroll}
      value={(value && !options.length) || isLookingForMoreOptions ? undefined : value}
      notFoundContent={
        insuranceCompaniesQuery.isFetching ? (
          <Row justify="center" align="middle">
            <Col>
              <Spin size="default" />
            </Col>
          </Row>
        ) : initialCount === 0 ? (
          /* message to display only if the initial search has not results,
           hence any other search term queries will be return the same */
          notFoundContent
        ) : undefined
      }
    />
  );
};

export default InsuranceCompanySelect;
