import React, { useEffect, useState } from 'react';
import { Col, Form, Row } from 'antd';
import { Trans } from 'react-i18next';

import { ERROR_MESSAGE } from '../../../utils/constants';

import GenericModal from '../GenericModal';
import SubmitCancelButtonGroup from '../../SubmitCancelButtonGroup';
import TextInput from '../../TextInput';
import Text from '../../Text';

const TypeFullNameConfirmationModal = props => {
  const {
    t,
    visible,
    onCancel,
    onSubmit,
    firstNameToMatch,
    lastNameToMatch,
    additionalContent,
  } = props;

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [form] = Form.useForm();

  useEffect(() => {
    if (visible) {
      form.resetFields();
    }
    // eslint-disable-next-line
  }, [visible]);

  const handleSubmit = async ({ firstName, lastName }) => {
    form.setFields([
      { name: 'firstName', value: firstName, errors: [] },
      { name: 'lastName', value: lastName, errors: [] },
    ]);

    const firstnameMATCH = firstName === firstNameToMatch.toUpperCase();
    const lastnameMATCH = lastName === lastNameToMatch.toUpperCase();

    if (!firstnameMATCH) {
      form.setFields([
        {
          name: 'firstName',
          value: firstName,
          errors: [t('profileFirstNameMustMatch')],
        },
      ]);
    }

    if (!lastnameMATCH) {
      form.setFields([
        {
          name: 'lastName',
          value: lastName,
          errors: [t('profileLastNameMustMatch')],
        },
      ]);
    }

    if (typeof onSubmit === 'function' && firstnameMATCH && lastnameMATCH) {
      setIsSubmitting(true);
      await onSubmit({ firstName, lastName });
      setIsSubmitting(false);
    }
  };

  return (
    <GenericModal footer={null} title={t('Confirm')} visible={visible} onCancel={onCancel}>
      <Text size="sm">
        {visible && (
          <Trans
            t={t}
            i18nKey="typeFullNameCapitalLetters"
            components={[
              <Text variant="strong" size="sm">
                dummyText
              </Text>,
            ]}
          />
        )}
      </Text>

      <br />

      {additionalContent && (
        <>
          {additionalContent}
          <br />
        </>
      )}

      <Form form={form} onFinish={handleSubmit}>
        <Row gutter={17}>
          <Col xs={24} md={12}>
            <Form.Item
              name="firstName"
              rules={[{ required: true, message: ERROR_MESSAGE.BLANK_FIELD }]}
            >
              <TextInput placeholder={t('firstName')} />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              name="lastName"
              rules={[{ required: true, message: ERROR_MESSAGE.BLANK_FIELD }]}
            >
              <TextInput placeholder={t('lastName')} />
            </Form.Item>
          </Col>
        </Row>

        <SubmitCancelButtonGroup
          isForm
          loading={isSubmitting}
          onCancel={onCancel}
          submitText={t('Confirm')}
        />
      </Form>
    </GenericModal>
  );
};

export default TypeFullNameConfirmationModal;
