import { Col, Form, Row } from 'antd';
import React, { useCallback, useState } from 'react';
import { Helmet } from 'react-helmet';

import { handleApiErrors } from '../../../api/axiosInstance';
import { updateUserVacationsApiCall } from '../../../api/user';
import Box from '../../../components/Box';
import Button from '../../../components/Button';
import Text from '../../../components/Text';
import LinkText from '../../../components/Text/LinkText';
import Toast from '../../../components/Toast';
import VacationForm from '../../../components/VacationForm';
import { IMAGES } from '../../../enum';
import { formatPageTitle } from '../../../utils/common';
import EmptyVacationsSection from './EmptyVacationsSection';
import { validateUserVacations, validateUserVacationsCollision } from './vacations-validations';

const PageHelmet = () => (
  <Helmet>
    <title>{formatPageTitle('Vacations')}</title>
  </Helmet>
);

/**
 * User Vacation page
 */
const UserDetailsVacationsView = props => {
  const { t, userDetails, onUserUpdate, isUserDeleted } = props;

  const [vacationsWithErrors, setVacationsWithErrors] = useState([]);
  const [isUpdatingVacations, setIsUpdatingVacations] = useState(false);
  const [vacations, setVacations] = useState(
    Array.isArray(userDetails.vacations)
      ? userDetails.vacations.map(vacation => ({
          id: +new Date(),
          startDate: vacation.startDate.slice(0, -14),
          endDate: vacation.endDate.slice(0, -14),
        }))
      : [],
  );

  /**
   * Removes the IDs from the Arrays of Vacations
   */
  const cleanVacationsArray = useCallback(() => {
    let vacationsDataWithoutIDs = [];

    vacations.forEach(({ id, ...vacation }) => {
      if (vacation.startDate && vacation.endDate) {
        const DATE_FORMAT = 'YYYY-MM-DD';
        vacationsDataWithoutIDs.push({
          // Dates are `moment` instances, or strings when coming directly from Backend
          startDate:
            typeof vacation.startDate === 'string'
              ? vacation.startDate
              : vacation.startDate.format(DATE_FORMAT),
          endDate:
            typeof vacation.endDate === 'string'
              ? vacation.endDate
              : vacation.endDate.format(DATE_FORMAT),
        });
      }
    });

    return vacationsDataWithoutIDs;
  }, [vacations]);

  /**
   * Update the user's vacation dates if the does not contain errors
   */
  const handleUpdateUserVacations = async () => {
    if (!vacationsWithErrors.length) {
      const cleanVacations = cleanVacationsArray();

      if (!validateUserVacationsCollision(cleanVacations)) {
        return Toast({
          type: 'error',
          message: 'Update failed',
          description: 'Some dates are colliding with other vacation periods',
        });
      }

      setIsUpdatingVacations(true);

      try {
        const responseData = await updateUserVacationsApiCall({
          userID: userDetails._id,
          vacationDates: cleanVacations,
        });
        onUserUpdate(responseData);
        Toast({
          type: 'open',
          message: 'Vacations updated successfully',
        });
      } catch (error) {
        handleApiErrors(error.response, () => {
          Toast({
            type: 'error',
            message: 'Update failed',
            description: 'Error while updating vacation dates',
          });
        });
      }

      setIsUpdatingVacations(false);
    } else {
      Toast({
        type: 'error',
        message: 'Form errors',
        description: 'One or more of your items have errors',
      });
    }
  };

  /**
   * Adds another row to the form to set a new Vacation period
   */
  const addAnotherVacation = () => setVacations([...vacations, { id: +new Date() }]);

  const SAVE_BUTTON = (
    <Row align="middle" justify="end" gutter={[16, 16]}>
      <Col>
        <Button
          type="primary"
          loading={isUpdatingVacations}
          disabled={isUserDeleted}
          onClick={handleUpdateUserVacations}
        >
          {t('Update Vacations')}
        </Button>
      </Col>
    </Row>
  );

  if (!vacations.length) {
    return (
      <Box>
        <PageHelmet />

        <Row gutter={[0, 17]}>
          <Text variant="h5">{t('Time Off')}</Text>
        </Row>

        <EmptyVacationsSection
          t={t}
          disabled={isUserDeleted}
          addAnotherVacation={addAnotherVacation}
        />

        {SAVE_BUTTON}
      </Box>
    );
  }

  return (
    <div>
      <PageHelmet />

      <Row gutter={[0, 32]}>
        <Col span={24}>
          <Box>
            <Row>
              <Col span={24}>
                <Row gutter={[0, 17]}>
                  <Text variant="h5">{t('Time Off')}</Text>
                </Row>

                <Row>
                  <Col span={24}>
                    <Form labelCol={{ span: 24 }} autoComplete="off">
                      {vacations.map((vacation, i) => (
                        <VacationForm
                          key={vacation.id}
                          t={t}
                          disabled={isUpdatingVacations || isUserDeleted}
                          vacation={vacation}
                          hasError={vacationsWithErrors.includes(i)}
                          onDateChange={date => {
                            const newVacations = [...vacations];
                            newVacations[i] = date;
                            setVacations(newVacations);

                            if (date.startDate && date.endDate) {
                              if (!validateUserVacations(date.startDate, date.endDate)) {
                                setVacationsWithErrors([
                                  ...vacationsWithErrors.filter(index => index !== i),
                                  i,
                                ]);
                              } else if (vacationsWithErrors.includes(i)) {
                                setVacationsWithErrors([
                                  ...vacationsWithErrors.filter(index => index !== i),
                                ]);
                              }
                            }
                          }}
                          onDelete={() => {
                            const newVacations = [...vacations];
                            const vacationIndex = newVacations.findIndex(v => v.id === vacation.id);
                            setVacationsWithErrors([
                              ...vacationsWithErrors.filter(index => index !== vacationIndex),
                            ]);
                            newVacations.splice(vacationIndex, 1);
                            setVacations([...newVacations]);
                          }}
                        />
                      ))}

                      {!isUpdatingVacations && !isUserDeleted && (
                        <Row align="middle" justify="center" style={{ marginBottom: 30 }}>
                          <LinkText onClick={addAnotherVacation}>
                            <Row align="middle" gutter={5} wrap={false}>
                              <Col>
                                <img
                                  alt="plus"
                                  src={IMAGES.GREEN_PLUS_ICON}
                                  width="16px"
                                  style={{ display: 'block' }}
                                />
                              </Col>
                              <Col>{t('Add Another Period')}</Col>
                            </Row>
                          </LinkText>
                        </Row>
                      )}

                      {SAVE_BUTTON}
                    </Form>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Box>
        </Col>
      </Row>
    </div>
  );
};

export default UserDetailsVacationsView;
