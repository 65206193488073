import { Col, List, Row } from 'antd';
import { get } from 'lodash';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { DWOLLA_API, parseDwollaApiErrorMessage } from '../../../../api/dwolla';
import { Button } from '../../../../components';
import Box from '../../../../components/Box';
import Checkbox from '../../../../components/Checkbox';
import StatusTag from '../../../../components/Tag/StatusTag';
import Text from '../../../../components/Text';
import LinkText from '../../../../components/Text/LinkText';
import Toast from '../../../../components/Toast';
import { STATUS_LIST } from '../../../../enum';
import { DWOLLA_BUSINESS_TYPES } from '../../../../enum/Dwolla';
import useDidUpdateEffect from '../../../../hooks/useDidUpdateEffect';
import useModal from '../../../../hooks/useModal';
import { saveCompany } from '../../../../stores/actions/common';
import { getUserFullName } from '../../../../utils/common';
import { formatBenenificalOwnersToBE } from './ach-verification.utils';
import AdditionalDocumentsRequiredForVerificationModal from './AdditionalDocumentsRequiredForVerificationModal';
import BeneficialOwnerInformationFormModal from './BeneficialOwnerInformationFormModal';
import CertifyOwnerModal from './CertifyOwnerModal';
import CollapsibleStepBox from './CollapsibleStepBox';

const BeneficialOwnersBox = props => {
  const { t, controllerInfo, onStepComplete } = props;

  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [beneficialOwners, setBeneficialOwners] = useState(props.beneficialOwners || []);
  const [hasOneOrMoreOwnwers, setHasOneOrMoreOwnwers] = useState(!!props?.beneficialOwners?.length);

  const [isCertifyingUser, setIsCertifyingUser] = useState(false);
  const [
    isCertifyOwnerModalOpen,
    openCertifyOwnerModalOpen,
    closeCertifyOwnerModalOpen,
  ] = useModal();

  const [selectedBeneficialOwnerId, setSelectedBeneficialOwnerId] = useState();

  const [
    isBeneficialOwnerModalVisible,
    openBeneficialOwnerModal,
    closeBeneficialOwnerModal,
  ] = useModal();

  useDidUpdateEffect(() => {
    if (!hasOneOrMoreOwnwers) {
      // pop up confirmation to delete all beneficials
    }
  }, [hasOneOrMoreOwnwers]);

  useDidUpdateEffect(() => {
    setBeneficialOwners(props.beneficialOwners || []);
  }, [props.beneficialOwners]);

  const handleAddBeneficialOwner = async values => {
    setIsSubmitting(true);

    try {
      const formattedValues = formatBenenificalOwnersToBE(values);
      const response = await DWOLLA_API.addBeneficialOwner(formattedValues);
      setBeneficialOwners(response.dwollaPaymentInfo.beneficialOwners);
      dispatch(saveCompany({ dwollaPaymentInfo: response.dwollaPaymentInfo }));
      closeBeneficialOwnerModal();
      Toast({
        type: 'open',
        message: t('beneficialOwnerAddSuccess'),
      });
    } catch (error) {
      Toast({
        type: 'error',
        message: t('beneficialOwnerAddError'),
      });
    }

    setIsSubmitting(false);
  };

  const handleCertifyBusinessOwner = async () => {
    setIsCertifyingUser(true);

    try {
      const response = await DWOLLA_API.certifyBusinessOwner();
      dispatch(saveCompany({ dwollaPaymentInfo: response.dwollaPaymentInfo }));
      closeCertifyOwnerModalOpen();
      Toast({
        type: 'open',
        message: t('businessOwnerCertifySuccess'),
      });
    } catch (error) {
      parseDwollaApiErrorMessage(error.response, () => {
        Toast({
          type: 'error',
          message: t('businessOwnerCertifyError'),
        });
      });
    }

    setIsCertifyingUser(false);
  };

  return (
    <Box>
      <CollapsibleStepBox
        stepNumber={2}
        completed={
          get(controllerInfo, 'businessType') === DWOLLA_BUSINESS_TYPES.SOLE_PROPRIETORSHIP ||
          !!props?.beneficialOwners?.length
        }
        onComplete={onStepComplete}
        stepTitle={
          <Row
            wrap={false}
            align="middle"
            gutter={16}
            justify="space-between"
            style={{ width: '100%' }}
          >
            <Col flex={1}>
              <Text variant="b" size="sm">
                {t('beneficialOwnerInformation')}
              </Text>
            </Col>

            {controllerInfo?.needCertification && (
              <Col>
                <Button size="xs" onClick={openCertifyOwnerModalOpen}>
                  {t('certifyOwners')}
                </Button>
              </Col>
            )}
          </Row>
        }
      >
        <Checkbox
          disabled={!!beneficialOwners.length}
          defaultChecked={hasOneOrMoreOwnwers}
          onChange={e => {
            if (e.target.checked) {
              openBeneficialOwnerModal();
            }

            setHasOneOrMoreOwnwers(e.target.checked);
          }}
        >
          <Text size="sm">{t('oneOrMoreBeneficialOwnersExist')}</Text>
          <Text size="sm" variant="b">
            ({t('individualHas25OrMoreOwnership')})
          </Text>
        </Checkbox>

        {!!beneficialOwners.length && (
          <List
            style={{ marginTop: 24 }}
            dataSource={beneficialOwners}
            itemLayout="vertical"
            renderItem={(owner, index) => {
              return (
                <List.Item key={owner._id}>
                  <Row wrap={false} gutter={16} align="middle">
                    <Col>
                      {index + 1}. <Text variant="b">{getUserFullName(owner)}</Text>
                    </Col>
                    <Col flex={1}>
                      <StatusTag size="small" status={owner.beneficialOwnerStatus} />
                    </Col>

                    {[STATUS_LIST.Status.DOCUMENT, STATUS_LIST.Status.RETRY].includes(
                      owner.beneficialOwnerStatus,
                    ) && (
                      <Col>
                        <Button
                          block
                          size="xs"
                          type="primary"
                          onClick={() => {
                            setSelectedBeneficialOwnerId(owner.beneficialOwnerId);
                          }}
                        >
                          {t('uploadRequiredDocument')}
                        </Button>
                      </Col>
                    )}
                  </Row>
                </List.Item>
              );
            }}
          />
        )}

        <br />

        {hasOneOrMoreOwnwers && (
          <>
            <LinkText variant="b" onClick={openBeneficialOwnerModal}>
              + {t('addBeneficalOwner')}
            </LinkText>
            <br />
            <br />
          </>
        )}

        <Text>{t('beneficialOwnerForm_govermentWarning')}</Text>

        <BeneficialOwnerInformationFormModal
          t={t}
          visible={isBeneficialOwnerModalVisible}
          onCancel={closeBeneficialOwnerModal}
          isLoading={isSubmitting}
          onSubmit={handleAddBeneficialOwner}
        />

        <AdditionalDocumentsRequiredForVerificationModal
          t={t}
          visible={!!selectedBeneficialOwnerId}
          beneficialOwnerId={selectedBeneficialOwnerId}
          onCancel={() => setSelectedBeneficialOwnerId()}
        />

        <CertifyOwnerModal
          t={t}
          visible={isCertifyOwnerModalOpen}
          isLoading={isCertifyingUser}
          onSubmit={handleCertifyBusinessOwner}
          onCancel={closeCertifyOwnerModalOpen}
        />
      </CollapsibleStepBox>
    </Box>
  );
};

export default BeneficialOwnersBox;
