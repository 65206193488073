import { Col, Row, Space, Table } from 'antd';
import { get, set, sortBy } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';

import { getUserFullName } from '../../../utils/common';
import { formatNumberWithCurrency, formatNumberWithDistanceUnit } from '../../../utils/numbers';
import { selectStoreCompanyGroupByID } from '../../../utils/storeSelectors';
import { CustomTable } from '../..';
import Button from '../../Button';
import Text from '../../Text';
import FadedText from '../../Text/FadedText';
import GenericModal from '../GenericModal';

/**
 * Confirmation Modal for Trips that have been Submitted for Approval
 */
const TripsReceiptConfirmationModal = props => {
  const { t, visible, onOk, onCancel, userProfile, selectedTrips, isLoading, ...rest } = props;

  const [tripsTotalData, setTripsTotalData] = useState();

  useEffect(() => {
    if (visible) {
      const ids = [];
      const dataPerCurrency = {};
      const totalsPerCurrency = {};

      try {
        selectedTrips.forEach(trip => {
          ids.push(trip._id);

          if (Array.isArray(trip.calculations)) {
            trip.calculations.forEach(calc => {
              const rateDistanceKey = `${trip.distanceUnit}-${calc.rateAmount
                .toString()
                .replace('.', '')}`;

              const rateCurrencyKey = `${trip.currency}.${rateDistanceKey}`;
              const calculatedTotalAmount = calc.rateAmount * calc.distance;

              if (!get(dataPerCurrency, rateCurrencyKey)) {
                set(dataPerCurrency, rateCurrencyKey, {
                  rateAmount: calc.rateAmount,
                  distanceUnit: trip.distanceUnit,
                  totalDistance: calc.distance,
                  totalAmount: calculatedTotalAmount,
                });
              } else {
                set(dataPerCurrency, rateCurrencyKey, {
                  ...get(dataPerCurrency, rateCurrencyKey),
                  totalDistance:
                    get(dataPerCurrency, `${rateCurrencyKey}.totalDistance`) + calc.distance,
                  totalAmount:
                    get(dataPerCurrency, `${rateCurrencyKey}.totalAmount`) + calculatedTotalAmount,
                });
              }
            });
          }
        });

        Object.keys(dataPerCurrency).forEach(currency => {
          totalsPerCurrency[currency] = sortBy(Object.values(dataPerCurrency[currency]), [
            'rateAmount',
          ]);
        });
      } catch (error) {
        console.log({ error });
      }
      return setTripsTotalData({ ids, totalsPerCurrency });
    } else {
      setTripsTotalData(undefined);
    }
    // eslint-disable-next-line
  }, [visible]);

  const userGroup = useMemo(() => {
    if (userProfile.group?._id) {
      return selectStoreCompanyGroupByID(userProfile.group._id);
    }

    return {};
  }, [userProfile]);

  return (
    <GenericModal
      {...rest}
      centered
      className="trip-submit-modal"
      width="700px"
      title={
        <Text variant="strong" size="large">
          {t('submitCountTripsForReimbursement', { count: selectedTrips.length })}
        </Text>
      }
      visible={visible}
      onCancel={onCancel}
      footer={
        <Row gutter={10} align="middle" justify="end">
          {userProfile.group && userProfile.group.groupManager && (
            <Col>
              <FadedText>
                {t('approvingManager')} - {getUserFullName(userGroup?.groupManager)}
              </FadedText>
            </Col>
          )}
          <Col>
            <Button
              disabled={isLoading}
              type="secondary"
              text="Cancel"
              className="btn-confirm"
              onClick={onCancel}
            />
          </Col>
          <Col>
            <Button
              disabled={isLoading}
              text="Confirm"
              className="btn-confirm"
              onClick={() => onOk({ ids: tripsTotalData?.ids })}
            />
          </Col>
        </Row>
      }
    >
      <Space direction="vertical" size="middle">
        {get(tripsTotalData, 'totalsPerCurrency') &&
          Object.keys(get(tripsTotalData, 'totalsPerCurrency')).map(currency => (
            <CustomTable
              key={currency}
              showSearchInput={false}
              pagination={false}
              columns={[
                {
                  align: 'right',
                  title: t('Distance'),
                  dataIndex: 'totalDistance',
                  render: (totalDistance, data) =>
                    formatNumberWithDistanceUnit(totalDistance, data.distanceUnit),
                },
                {
                  align: 'right',
                  title: t('rate'),
                  dataIndex: 'rateAmount',
                  render: (rateAmount, data) =>
                    `${formatNumberWithCurrency(rateAmount, currency)} / ${data.distanceUnit}`,
                },
                {
                  align: 'right',
                  title: t('Total'),
                  dataIndex: 'totalAmount',
                  render: totalAmount => formatNumberWithCurrency(totalAmount, currency),
                },
              ]}
              dataSource={get(tripsTotalData, `totalsPerCurrency.${currency}`, [])}
              summary={pageData => {
                const totalAmountForCurrency = pageData.reduce((currentTotal, { totalAmount }) => {
                  return currentTotal + totalAmount;
                }, 0);

                return (
                  <Table.Summary.Row>
                    <Table.Summary.Cell index={0} />
                    <Table.Summary.Cell index={1} />

                    <Table.Summary.Cell index={2} align="right">
                      <Text variant="b">
                        {formatNumberWithCurrency(totalAmountForCurrency, currency)}
                      </Text>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                );
              }}
            />
          ))}
      </Space>
    </GenericModal>
  );
};

TripsReceiptConfirmationModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onOk: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  userProfile: PropTypes.shape({}),
  tripsTotalData: PropTypes.shape({
    ids: [],
  }),
};

TripsReceiptConfirmationModal.defaultProps = {
  userProfile: undefined,
  tripsTotalData: undefined,
};

export default TripsReceiptConfirmationModal;
