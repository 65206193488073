// import { getNumberRage } from '../../utils/common';
import { createReducer } from '../../utils/storeCreators';
import {
  COMPLETE_FAVR_VEHICLES_SETUP,
  TOGGLE_FAVR_MAKES_LOADER,
  TOGGLE_FAVR_MODELS_LOADER,
  TOGGLE_FAVR_YEARS_LOADER,
  UPDATE_FAVR_VEHICLE_MAKES,
  UPDATE_FAVR_VEHICLE_MODELS,
  UPDATE_FAVR_VEHICLE_YEARS,
} from '../actions/favrVehicles';
import initialState from '../initialState';

const favrVehiclesReducer = createReducer({
  initialState: initialState.favrVehicles,
  reducer: (state, action) => {
    let vehicles = state;

    switch (action.type) {
      case TOGGLE_FAVR_YEARS_LOADER: {
        vehicles.isLoadingYears = action.data;
        return { ...state, ...vehicles };
      }

      case COMPLETE_FAVR_VEHICLES_SETUP: {
        return { ...state, isSetup: true };
      }

      case UPDATE_FAVR_VEHICLE_YEARS: {
        const { yearsRange } = action.data;

        vehicles.availableYears = yearsRange.reverse();

        yearsRange.forEach(year => {
          vehicles.byYearData[year] = {
            availableMakes: [],
            modelsByMake: {},
          };
        });

        return { ...state, ...vehicles };
      }

      case TOGGLE_FAVR_MAKES_LOADER: {
        vehicles.isLoadingMakes = action.data;
        return { ...state, ...vehicles };
      }

      case UPDATE_FAVR_VEHICLE_MAKES: {
        const { year, makesData } = action.data;

        vehicles.byYearData[year].availableMakes = makesData;
        makesData.forEach(make => {
          vehicles.byYearData[year].modelsByMake[make] = [];
        });

        return { ...state, ...vehicles };
      }

      case TOGGLE_FAVR_MODELS_LOADER: {
        vehicles.isLoadingModels = action.data;
        return { ...state, ...vehicles };
      }

      case UPDATE_FAVR_VEHICLE_MODELS: {
        const { year, make, modelsData } = action.data;

        vehicles.byYearData[year].modelsByMake[make] = modelsData.map(model => JSON.parse(model));

        return { ...state, ...vehicles };
      }

      default:
        return vehicles;
    }
  },
});

export default favrVehiclesReducer;
