import { Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { withNamespaces } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { handleApiErrors } from '../../api/axiosInstance';
import { updateSystemAdmin } from '../../api/systemAdmin';
import { changeUserStatus, fetchUserByID, uploadUserProfileImage } from '../../api/user';
import Box from '../../components/Box';
import PageBreadcrumbs from '../../components/PageBreadcrumbs';
import SpaceSpinner from '../../components/SpaceSpinner';
import SystemAdminForm from '../../components/SystemAdminForm';
import Toast from '../../components/Toast';
import PageContainer from '../../containers/PageContainer';
import { INTERNAL_LINKS } from '../../enum';
import withAuthentication from '../../hocs/withAuthentication';
import { updateProfile } from '../../stores/actions/profile';
import { formatPageTitle, getUserFullName } from '../../utils/common';
import { normalizeUserSchema } from '../../utils/users';

const PageTitle = () => (
  <Helmet>
    <title>{formatPageTitle('System Admin Details')}</title>
  </Helmet>
);

const SystemAdminDetails = props => {
  const { t, history, match } = props;

  const dispatch = useDispatch();
  const myProfile = useSelector(store => store.profile);

  const { systemAdmins } = useSelector(store => store.systemAdmin);

  const [userDetails, setUserDetails] = useState();
  const [formValues, setFormValues] = useState({});
  const [isProcessingUpdate, setIsProcessingUpdate] = useState(false);

  const fetchUserDetails = async userID => {
    let user = systemAdmins.find(user => user._id === userID);

    if (!user) {
      try {
        user = await fetchUserByID(userID);
        setUserDetails(user);
      } catch (error) {
        history.push(INTERNAL_LINKS.SYSTEM_ADMINS);
        handleApiErrors(error.response);
      }
    } else {
      setUserDetails(user);
    }
  };

  /**
   * Update the userDetails state whenever one of the Profile, Vacations or OBD forms update the user
   *
   * @param  {object} newUserData Updated User's data
   */
  const onUserUpdate = newUserData => {
    const updatedData = { ...userDetails, ...normalizeUserSchema(newUserData) };
    setUserDetails(updatedData);

    // Updates the store if the provided data is the same as the logged in user
    if (newUserData._id === myProfile.profile._id) {
      dispatch(updateProfile(updatedData));
    }
  };

  const handleFormSubmit = async formValues => {
    setIsProcessingUpdate(true);

    try {
      const updatedUser = await updateSystemAdmin(userDetails._id, {
        ...formValues,
        password_repeat: formValues.confirmPassword,
      });
      onUserUpdate(updatedUser);
      Toast({
        type: 'open',
        message: t('userUpdateSuccess'),
      });
    } catch (error) {
      handleApiErrors(error.response, () => {
        Toast({
          type: 'open',
          message: t('userUpdateError'),
        });
      });
    }

    setIsProcessingUpdate(false);
  };

  /**
   * Handler to update the store and show a Toast message
   * after a successful profile update
   *
   * @param {object} apiResult On Profile Update success
   */
  const onProfileUpdateSuccess = apiResult => {
    const { data } = apiResult.data;
    onUserUpdate(data);

    Toast({
      type: 'open',
      message: t('profileUpdatedSuccess'),
    });
  };

  /**
   * Updates the User's status
   *
   * @param {string} status User status
   */
  const handleUserStatusUpdate = async status => {
    setIsProcessingUpdate(true);

    try {
      const updatedUser = await changeUserStatus(userDetails._id, status);
      onUserUpdate(updatedUser);
    } catch (error) {
      handleApiErrors(error.response, () => {
        Toast({
          type: 'error',
          message: 'Error while updating user status',
        });
      });
    }

    setIsProcessingUpdate(false);
  };

  /**
   * Updates the User's profile picture
   *
   * @param {File} profilePicture Image file to upload
   */
  const handleProfileImageUpload = async profilePicture => {
    setIsProcessingUpdate(true);

    try {
      await uploadUserProfileImage(
        { userID: userDetails._id, imageFile: profilePicture },
        result => {
          onProfileUpdateSuccess({
            data: {
              data: {
                ...userDetails,
                profilePicture: result.data.data.location,
              },
            },
          });
        },
        () => {
          Toast({
            type: 'error',
            message: t('uploadFailed'),
            description: t('profilePictureUploadError'),
          });
        },
      );
    } catch (error) {
      handleApiErrors(error.response, () => {
        Toast({
          type: 'error',
          message: t('uploadFailed'),
          description: t('profilePictureUploadError'),
        });
      });
    }

    setIsProcessingUpdate(false);
  };

  useEffect(() => {
    const { id } = match.params;
    fetchUserDetails(id);
    // eslint-disable-next-line
  }, []);

  if (!userDetails) {
    return (
      <>
        <PageTitle />
        <SpaceSpinner />
      </>
    );
  }

  return (
    <PageContainer
      title={
        <PageBreadcrumbs
          items={[
            {
              label: t('systemAdmins'),
              onClick: () => props.history.push(INTERNAL_LINKS.SYSTEM_ADMINS),
            },
            { label: getUserFullName(userDetails) },
          ]}
        />
      }
    >
      <PageTitle />

      <Row justify="center">
        <Col flex="832px">
          <Box>
            <SystemAdminForm
              t={t}
              isUpdate
              isSubmitting={isProcessingUpdate}
              profilePicture={userDetails?.profilePicture}
              onFieldChange={field => setFormValues({ ...formValues, ...field })}
              onImageUpload={handleProfileImageUpload}
              onUserStatusChange={handleUserStatusUpdate}
              onSubmit={handleFormSubmit}
              onCancel={() => history.push(INTERNAL_LINKS.SYSTEM_ADMINS)}
              values={userDetails}
            />
          </Box>
        </Col>
      </Row>
    </PageContainer>
  );
};

export default withNamespaces()(withAuthentication(SystemAdminDetails));
