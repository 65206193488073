import { Col, Row } from 'antd';
import React from 'react';
import { Trans } from 'react-i18next';

import Button from '../../../../components/Button';
import HelpIcon from '../../../../components/HelpIcon';
import RowButton from '../../../../components/RowButton';
import StatusTag from '../../../../components/Tag/StatusTag';
import Text from '../../../../components/Text';
import FadedText from '../../../../components/Text/FadedText';
import LinkText from '../../../../components/Text/LinkText';
import { IMAGES, STATUS_LIST } from '../../../../enum';
import { getUserFullName, momentFormat } from '../../../../utils/common';

const AnonymizeRowButton = props => {
  const {
    t,
    request,
    isAdmin,
    loggedInUserID,
    onDeny,
    onCancel,
    onApprove,
    onViewUsers,
    denyingRequestID,
    approvingRequestID,
  } = props;

  const renderCancelUserInfo = () => {
    if (!request?.statusChangedBy?._id) return null;

    const STATUS_TRANS_MAP = {
      [STATUS_LIST.Status.DENIED]: 'requestDeniedByOn',
      [STATUS_LIST.Status.APPROVED]: 'requestApprovedByOn',
      [STATUS_LIST.Status.CANCELLED]: 'requestCancelledByOn',
    };

    return (
      <HelpIcon
        hint={
          <Text size="sm" style={{ width: 250 }} renderAs="span">
            <Trans
              t={t}
              i18nKey={STATUS_TRANS_MAP[request.status]}
              values={{
                name: getUserFullName(request.statusChangedBy),
                date: momentFormat(request.statusChangeTimestamp, 'LL'),
                time: momentFormat(request.statusChangeTimestamp, 'hh:mm A'),
              }}
              components={[<span style={{ whiteSpace: 'nowrap' }}>DummyText</span>]}
            />
          </Text>
        }
      />
    );
  };

  const displayDynamicUserNameList = () => {
    if (request.userIds.length <= 3) {
      return (
        <Text size="sm" variant="strong">
          (
          {t('anonymize_user_list', {
            count: request.userIds.length,
            names: request.userIds.map(r => r.name).join(', '),
          })}
          )
        </Text>
      );
    }

    if (typeof onViewUsers !== 'function') return null;

    return (
      <LinkText size="sm" onClick={() => onViewUsers(request.userIds)}>
        {t('viewUsers')}
      </LinkText>
    );
  };

  return (
    <RowButton
      key={request._id}
      clickable={false}
      icon={IMAGES.TEXT_FILE_REMOVE_ICON}
      content={
        <>
          {isAdmin ? (
            <>
              {request.requestType === 'anonymize' && (
                <>
                  <Text variant="strong" size="sm">
                    {t('anonymizeUserData')} {displayDynamicUserNameList(request)}
                  </Text>
                  {request.userIds.length > 3 && (
                    <Text size="sm">{t('users_count', { count: request.userIds.length })}</Text>
                  )}
                </>
              )}
            </>
          ) : (
            <>
              {request.requestType === 'anonymize' && (
                <>
                  <Text variant="strong" size="sm">
                    {t('anonymizeMyData')}
                  </Text>
                  <Text size="sm">{t('tripsReceiptsReimbursementsProfile')}</Text>
                </>
              )}
            </>
          )}

          <FadedText size="sm">
            <Trans
              t={t}
              i18nKey={isAdmin ? 'requestOnDateBy' : 'requestOnDate'}
              values={{
                date: momentFormat(new Date(request.created), 'LL'),
                time: momentFormat(new Date(request.created), 'hh:mm A'),
                name: request.requestingUser.name,
              }}
              components={[<span style={{ whiteSpace: 'nowrap' }}>DummyText</span>]}
            />
          </FadedText>
        </>
      }
      sideContent={
        <Row justify="start" align="middle" gutter={17}>
          {request.requestType === 'anonymize' && (
            <Col>
              <Row gutter={5} style={{ flexWrap: 'nowrap' }}>
                <Col>
                  <StatusTag status={request.status} />
                </Col>

                <Col>
                  {![
                    STATUS_LIST.Status.AWAITING_APPROVAL,
                    STATUS_LIST.Status.AWAITING_ANONYMIZATION,
                    STATUS_LIST.Status.ANONYMIZED,
                  ].includes(request.status) && renderCancelUserInfo(request)}

                  {isAdmin &&
                    loggedInUserID === request.requestingUser._id &&
                    request.status === STATUS_LIST.Status.AWAITING_APPROVAL && (
                      <HelpIcon
                        hint={
                          <Text style={{ width: 250 }}>
                            {t('onlyAnotherAdminCanChangeRequestStatus')}
                          </Text>
                        }
                      />
                    )}
                </Col>
              </Row>
            </Col>
          )}

          {[STATUS_LIST.Status.AWAITING_APPROVAL].includes(request.status) && (
            <>
              {!isAdmin && (
                <Col>
                  <Button
                    size="sm"
                    disabled={!!approvingRequestID}
                    type={isAdmin ? 'secondary' : 'primary'}
                    onClick={() => onCancel(request._id)}
                  >
                    {t('Cancel')}
                  </Button>
                </Col>
              )}

              {isAdmin &&
                loggedInUserID !== request.requestingUser._id &&
                STATUS_LIST.Status.AWAITING_APPROVAL && (
                  <>
                    <Col>
                      <Button
                        size="sm"
                        disabled={!!approvingRequestID || !!denyingRequestID}
                        loading={denyingRequestID === request._id}
                        type={'secondary'}
                        onClick={() => onDeny(request._id)}
                      >
                        {t('deny')}
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        size="sm"
                        disabled={!!approvingRequestID || !!denyingRequestID}
                        loading={approvingRequestID === request._id}
                        onClick={() => onApprove(request._id)}
                      >
                        {t('approve')}
                      </Button>
                    </Col>
                  </>
                )}
            </>
          )}
        </Row>
      }
    />
  );
};

export default AnonymizeRowButton;
