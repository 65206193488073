import axios from 'axios';
import ContentDisposition from 'content-disposition';
import { isArrayBuffer } from 'lodash';
import storage from 'store';

import Toast from '../components/Toast';
import i18n from '../i18n';
import { logoutUser } from '../utils/auth';
import { isCurrentCompanySubActive } from '../utils/company';
import { hasSystemAdminRole } from '../utils/roles';
import { selectStoreCurrentAuthUser } from '../utils/storeSelectors';

const axiosInstance = axios.create();

const handleExpiredSessionError = () => {
  logoutUser();

  Toast({
    type: 'error',
    message: i18n.t('sessionHasExpired'),
  });
};

axiosInstance.interceptors.response.use(undefined, error => {
  return new Promise(async (resolve, reject) => {
    let originalRequest = error.config;
    const refreshToken = storage.get('refreshToken');

    const IS_INVALID_TOKEN =
      /invalid token/i.test(error?.response?.data || '') ||
      /invalid token/i.test(error?.response?.data?.message || '');

    if (IS_INVALID_TOKEN && !originalRequest.__isRetryRequest && refreshToken) {
      originalRequest.__isRetryRequest = true;

      try {
        const tokenResponse = await axios.post(`${process.env.REACT_APP_HOST_API}auth/token`, {
          token: refreshToken,
        });

        storage.set('accessToken', tokenResponse.data.accessToken);
        storage.set('refreshToken', tokenResponse.data.refreshToken); // <-- TODO: How do we refresh the "refreshToken" once it expires?

        originalRequest.headers.Authorization = `Bearer ${tokenResponse.data.accessToken}`;
        const retryResponse = await axios(originalRequest);
        return resolve(retryResponse);
      } catch (error) {
        handleExpiredSessionError();
      }
    } else if (IS_INVALID_TOKEN && error.config && error.config.__isRetryRequest) {
      handleExpiredSessionError();
    }

    return reject(error);
  });
});

/**
 * Handler to read the upload progress of a file from Axios
 */
const uploadProgressHandler = onUploadProgress => {
  return progressEvent => {
    if (typeof onUploadProgress === 'function') {
      var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      onUploadProgress(percentCompleted);
    }
  };
};

/**
 * Performs an Axios Request with the provided params
 *
 * @param {'get' | 'post' | 'put' | 'patch' | 'delete'} type HTTP Request type
 * @param {string} url API endpoint URL
 * @param {any} params Endpoint body, query, params
 * @param {{ [header: string] : any }} headers Request headers
 * @param {(percent: number) => undefined=} onUploadProgress Upload progress reader
 */
export const handleApiCalls = (type, url, params, headers = null, onUploadProgress) => {
  const accessToken = storage.get('accessToken');

  if (accessToken) {
    axiosInstance.defaults.withCredentials = true;
    axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
  }

  switch (type) {
    case 'post':
      if (headers) {
        return axiosInstance.post(url, params, {
          headers,
          onUploadProgress: uploadProgressHandler(onUploadProgress),
        });
      }
      return axiosInstance.post(url, params);
    case 'get':
      return axiosInstance.get(url, params);
    case 'put':
      return axiosInstance.put(url, params);
    case 'delete':
      return axiosInstance.delete(url, params);
    case 'patch':
      return axiosInstance.patch(url, params);
    default:
      return axiosInstance(url, params);
  }
};

/**
 * Performs an Axios Request where the body data is url encoded
 *
 * @param {'get' | 'post' | 'put' | 'patch' | 'delete'} method HTTP Request method
 * @param {string} url API endpoint URL
 * @param {any} params Endpoint body, params
 * @param {object} headers Axios config
 */
export const handleUrlEncodedApiCalls = (method, url, params, config = {}) => {
  const accessToken = storage.get('accessToken');

  if (accessToken) {
    axiosInstance.defaults.withCredentials = true;
    axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
  }

  let formBody = [];

  for (let property in params) {
    const encodedKey = encodeURIComponent(property);
    const encodedValue = encodeURIComponent(params[property]);
    formBody.push(encodedKey + '=' + encodedValue);
  }

  formBody = formBody.join('&');

  return axios({
    ...config,
    method,
    url,
    data: formBody,
    'Content-Type': 'application/x-www-form-urlencoded',
  });
};

const handleApiValidationErrors = apiResponse => {
  if (apiResponse?.data?.errorMessage) {
    return Toast({
      type: 'error',
      message: apiResponse?.data?.errorMessage,
    });
  }

  const errorKeys = Object.keys(apiResponse.data.errors);

  const emptyFieldError = errorKeys[0]
    ? /empty/.test(apiResponse.data.errors[errorKeys[0]].msg)
    : false;

  const alreadyExists = errorKeys[0]
    ? /already exist/i.test(apiResponse.data.errors[errorKeys[0]].msg)
    : false;

  Toast({
    type: 'error',

    ...(alreadyExists && !emptyFieldError
      ? {
          message: `${apiResponse.data.errors[errorKeys[0]].msg}`,
          description: apiResponse.data.errors[errorKeys[0]].value,
        }
      : {}),

    ...(emptyFieldError && !alreadyExists
      ? {
          message: `${i18n.t(errorKeys[0])} ${`${
            apiResponse.data.errors[errorKeys[0]].msg
          }`.toLowerCase()}`,
        }
      : {}),

    ...(!emptyFieldError && !alreadyExists
      ? { message: errorKeys[0] ? apiResponse.data.errors[errorKeys[0]].msg : 'Request error' }
      : {}),
  });
};

export const handleApiErrors = (apiResult, customHandler) => {
  if (process.env.NODE_ENV === 'development') console.error(apiResult);

  if (typeof apiResult?.data === 'string' && apiResult?.status >= 400) {
    Toast({
      type: 'error',
      message: apiResult?.data,
    });
  } else if ('TextDecoder' in window && isArrayBuffer(apiResult?.data)) {
    const decoder = new TextDecoder('utf-8');
    const decodedMessage = decoder.decode(apiResult?.data);

    if (apiResult.status === 422) {
      try {
        const parsedMessage = JSON.parse(decodedMessage);
        handleApiValidationErrors({ data: parsedMessage });
      } catch (error) {
        Toast({
          type: 'error',
          message: decodedMessage,
        });
      }
    } else {
      Toast({
        type: 'error',
        message: decodedMessage,
      });
    }
  } else if (apiResult && apiResult.data && apiResult.data.message && apiResult?.status >= 400) {
    Toast({
      type: 'error',
      message: apiResult.data.message,
    });
  } else if (apiResult && apiResult.status === 422) {
    handleApiValidationErrors(apiResult);
  } else if (typeof customHandler === 'function') {
    customHandler(apiResult);
  }
};

export const performApiCallIfCompanySubIsActive = async (
  type,
  url,
  params,
  headers = null,
  onUploadProgress,
) => {
  const authUser = selectStoreCurrentAuthUser();

  if (!isCurrentCompanySubActive()) {
    if (authUser && !hasSystemAdminRole(authUser)) {
      const error = {
        message: i18n.t('companyDoesthaveSubPleaseReactivate'),
        response: { status: 403, data: i18n.t('companyDoesthaveSubPleaseReactivate') },
      };

      throw error;
    }
  }

  return handleApiCalls(type, url, params, headers, onUploadProgress);
};

export const performApiCallIfCompanySubIsActiveOrCompanyAdmin = async (
  type,
  url,
  params,
  headers = null,
  onUploadProgress,
  allowedRoles = [],
) => {
  const authUser = selectStoreCurrentAuthUser();

  if (!isCurrentCompanySubActive()) {
    if (
      (authUser && !hasSystemAdminRole(authUser)) ||
      (allowedRoles.length && !allowedRoles.includes(authUser.profile.role))
    ) {
      const error = {
        message: i18n.t('companyDoesthaveSubPleaseReactivate'),
        response: { status: 403, data: i18n.t('companyDoesthaveSubPleaseReactivate') },
      };

      throw error;
    }
  }

  return handleApiCalls(type, url, params, headers, onUploadProgress);
};

export const handleApiFileDownloads = async (type, url, params, filename, extension) => {
  const accessToken = storage.get('accessToken');
  let name = filename;

  if (accessToken) {
    axiosInstance.defaults.withCredentials = true;
    axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
  }

  const handleFileResponse = response => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');

    if (!name) {
      const disposition = ContentDisposition.parse(response.headers['content-disposition']);
      name = disposition?.parameters?.filename;
    }

    if (typeof extension === 'string' && typeof name === 'string' && !name.includes(extension)) {
      name += extension;
    }

    link.href = url;
    link.setAttribute('download', name);
    document.body.appendChild(link);
    link.click();
  };

  switch (type) {
    case 'patch':
      return axiosInstance
        .patch(url, params, { responseType: 'arraybuffer' })
        .then(handleFileResponse);
    case 'post':
      return axiosInstance
        .post(url, params, { responseType: 'arraybuffer' })
        .then(handleFileResponse);
    case 'get':
    default:
      return axiosInstance
        .get(url, { responseType: 'arraybuffer', params })
        .then(handleFileResponse);
  }
};
