import Spinner from 'components/Spinner';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { withNamespaces } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { handleApiCalls } from '../../../api/axiosInstance';
import ReimbursementForm from '../../../components/ReimbursementForm';
import SpaceSpinner from '../../../components/SpaceSpinner';
import Text from '../../../components/Text';
import Toast from '../../../components/Toast';
import PageContainer from '../../../containers/PageContainer';
import { INTERNAL_LINKS, USER_REG_STATUS } from '../../../enum';
import useDefaultRate from '../../../hooks/queries/useDefaultRate';
import { saveCompany } from '../../../stores/actions/common';
import { setRegStatus } from '../../../stores/actions/user';
import { formatPageTitle } from '../../../utils/common';
import { useStoreSelector } from '../../../utils/storeSelectors';

const OnboardingRate = ({ t, ...props }) => {
  const dispatch = useDispatch();
  const defaultRateQuery = useDefaultRate();
  const currentCompany = useSelector(state => state.common.currentCompany);
  const groupList = useStoreSelector('group.groupList', []);
  const regStatus = useSelector(state => state.user.regStatus);
  const [loading, setLoading] = useState(false);

  const onFinish = async requestParams => {
    setLoading(true);

    try {
      const result = await handleApiCalls(
        'put',
        `${process.env.REACT_APP_HOST_API}products/${defaultRateQuery.data._id}`,
        {
          ...requestParams,
          defaultRate: true,
          groups: groupList.map(group => group._id),
        },
      );

      if (result.data.success) {
        // if user has completed this step before
        // skip regStatus update

        if (regStatus < USER_REG_STATUS.INVITE) {
          try {
            // set regStatus via api call
            const regStatusUpdateResult = await handleApiCalls(
              'patch',
              `${process.env.REACT_APP_HOST_API}company/${currentCompany._id}/update-registration-status`,
              {
                regStatus: USER_REG_STATUS.SUCCESS,
              },
            );
            if (regStatusUpdateResult.data.success) {
              await dispatch(setRegStatus(USER_REG_STATUS.SUCCESS));
              await dispatch(
                saveCompany({
                  regStatus: regStatusUpdateResult.data.data.regStatus,
                  regCompleted: regStatusUpdateResult.data.data.regCompleted,
                }),
              );
              setLoading(false);
            } else {
              Toast({
                type: 'error',
                message: 'Error while creating rate',
              });
            }
          } catch (error) {
            Toast({
              type: 'error',
              message: 'Error while creating rate',
            });
            setLoading(false);
            return false;
          }
        }

        // update store
        defaultRateQuery.remove();
        props.history.push(INTERNAL_LINKS.COMPANY_ONBOARDING);
      }
    } catch (error) {
      Toast({
        type: 'error',
        message: 'Error while creating rate',
      });
      setLoading(false);
    }
    setLoading(false);
  };

  if (defaultRateQuery.isFetching) return <SpaceSpinner />;

  return (
    <PageContainer title="" className="onboarding reimbursement-rate">
      <Helmet>
        <title>{formatPageTitle('Mileage Rate | Onboarding')}</title>
      </Helmet>

      <Text variant="h2" textAlign="center">
        {t('Mileage Rate')}
      </Text>

      <ReimbursementForm
        currentCompany={currentCompany}
        currentProduct={defaultRateQuery.data}
        product={onFinish}
        props={props}
        t={t}
      />

      {loading && (
        <div className="loading-container">
          <Spinner />
        </div>
      )}
    </PageContainer>
  );
};

export default withNamespaces()(OnboardingRate);
