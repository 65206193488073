import { Col, Row } from 'antd';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';

import { INTERNAL_LINKS, STATUS_LIST } from '../../../enum';
import { getUserFullName, getUserInitials } from '../../../utils/common';
import { parseUserComplianceStatus } from '../../../utils/user';
import AnonymizedAsterisk from '../../AnonymizedAsterisk';
import Avatar from '../../Avatar';
import OutOfComplianceIcon from '../../OutOfComplianceIcon';
import StatusTag from '../../Tag/StatusTag';
import Text from '../../Text';
import LinkText from '../../Text/LinkText';
import classNames from './style.module.scss';

/**
 * A Row with the User's Profile Picture, the User's Full Name and, optionally, a tag with the User's Status
 */
const ProfileNamePicture = props => {
  const {
    t,
    user,
    complianceStatus,
    showStatus,
    showAvatar,
    linkableUser,
    userLinkProps,
    isSystemAdmin,
    redirectTo,
  } = props;

  const [isOutOfCompliance, outOfComplianceComponents] = useMemo(
    () => parseUserComplianceStatus(complianceStatus),
    [complianceStatus],
  );

  if (!user) return null;

  const userFullName = getUserFullName(user);
  const userStatus = user.status || user.userStatus;
  const userPicture = user.profilePicture || user.userProfilePicture;

  return (
    <Row wrap={false} gutter={6} align="middle">
      {showAvatar && !isOutOfCompliance && (
        <Col>
          <div
            className={classnames(classNames.avatarCol, {
              [classNames.isInactive]: userStatus === STATUS_LIST.Status.INACTIVE,
            })}
          >
            <Avatar
              size="small"
              src={userPicture}
              shape="circle"
              alt={userFullName}
              initials={getUserInitials(user)}
            />
          </div>
        </Col>
      )}

      {isOutOfCompliance && (
        <OutOfComplianceIcon t={t} outOfComplianceComponents={outOfComplianceComponents} />
      )}

      <Col flex={1}>
        <div
          className={classnames(classNames.nameCol, {
            [classNames.isInactive]: userStatus === STATUS_LIST.Status.INACTIVE,
          })}
        >
          {linkableUser && !!user._id ? (
            <Link
              className={classNames.userName}
              to={
                isSystemAdmin
                  ? `${INTERNAL_LINKS.UPDATE_SYSTEM_ADMINS}/${user._id}`
                  : redirectTo || `${INTERNAL_LINKS.USER_DETAILS}/${user._id}/profile`
              }
              {...userLinkProps}
            >
              <LinkText
                title={user.isAnonymized ? t('anonymizedUser') : userFullName}
                renderAs="span"
                variant="strong"
                size="sm"
              >
                {userFullName}
              </LinkText>
            </Link>
          ) : (
            <Text
              size="sm"
              renderAs="span"
              variant="strong"
              className={classNames.userName}
              color={userStatus === STATUS_LIST.Status.INACTIVE ? 'faded' : undefined}
              title={user.isAnonymized ? t('anonymizedUser') : userFullName}
            >
              {userFullName}
            </Text>
          )}

          {user.isAnonymized && (
            <div className={classNames.asteriskCol}>
              <AnonymizedAsterisk t={t} />
            </div>
          )}
        </div>

        {showStatus && !!userStatus && (
          <div className={classNames.statusCol}>
            <StatusTag size="small" status={userStatus} />
          </div>
        )}
      </Col>
    </Row>
  );
};

ProfileNamePicture.propTypes = {
  user: PropTypes.shape({}).isRequired,
  showStatus: PropTypes.bool,
  showAvatar: PropTypes.bool,
  linkableUser: PropTypes.bool,
  complianceStatus: PropTypes.arrayOf(PropTypes.shape({})),
  userLinkProps: PropTypes.shape({}),
};

ProfileNamePicture.defaultProps = {
  showStatus: true,
  showAvatar: true,
  linkableUser: true,
  complianceStatus: undefined,
  userLinkProps: {},
};

export default ProfileNamePicture;
