import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Col, DatePicker, Form, Row } from 'antd';
import { Button, Modal } from 'components';
import Select from 'components/Select';
import TextInput from 'components/TextInput';
import { UserRoleSelectOptions } from 'enum/UserRoles';
import { find, get } from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { withNamespaces } from 'react-i18next';
import { useSelector } from 'react-redux';
import { EMAIL_VALIDATION_FORM_RULES, ERROR_MESSAGE } from 'utils/constants';

import { handleApiErrors } from '../../api/axiosInstance';
import { USER_API } from '../../api/user';
import { momentEST } from '../../utils/common';
import { EMPLOYEE_NUMBER_FORM_RULES } from '../../utils/constants';
import { selectStoreCountries, selectStoreCurrentCompany } from '../../utils/storeSelectors';
import { USER_PERMISSIONS } from '../../views/userdetails/userdetails-permissions';
import AddressForm from '../AddressForm';
import Checkbox from '../Checkbox';
import NameInput from '../NameInput';
import Toast from '../Toast';
import classNames from './style.module.scss';

const horizontalSpan = { span: 24 };

const AddUserModal = ({
  t,
  groups,
  modalVisibility,
  requireEmployeeID,
  visible,
  companyId,
  onSuccess,
}) => {
  const [form] = Form.useForm();
  const [formValues, setFormValues] = useState({});
  const currentCompany = useSelector(selectStoreCurrentCompany);
  const supportedCountries = useSelector(selectStoreCountries);
  const formRef = useRef(null);
  const [visibility, setVisibility] = useState(modalVisibility);
  // eslint-disable-next-line no-unused-vars
  const [duplicateEmail, setDuplicateEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [closeLoading, setCloseLoading] = useState(false);
  const [displayAddress, setDisplayAddress] = useState(false);
  const [sendActivationEmailImmediately, setSendActivationEmailImmediately] = useState(true);

  useEffect(() => {
    setVisibility(modalVisibility);
    visible(modalVisibility);
    // eslint-disable-next-line
  }, [modalVisibility]);

  const onFinish = async (values, action) => {
    if (action === 'clear') {
      setLoading(true);
    } else {
      setCloseLoading(true);
    }

    const userData = {
      email: values.email,
      firstName: values.firstName,
      lastName: values.lastName,
      role: values.role,
      groupId: values.groupId,
      department: values.department,
    };

    if (values.sendActivationEmailDate) {
      userData.sendActivationEmailDate = momentEST(values.sendActivationEmailDate);
    }

    if (requireEmployeeID) {
      userData.employeeId = values.employeeId;
    }

    if (values.streetOne) {
      userData.homeAddress = {
        streetOne: values.streetOne,
        streetTwo: values.streetTwo,
        country: values.country,
        state: values.state,
        city: values.city,
        postalCode: values.postalCode,
      };
    }

    try {
      const result = await USER_API.inviteUsers([userData], companyId);
      if (typeof onSuccess === 'function') {
        onSuccess();
      }
      if (action === 'clear') {
        form.resetFields();
        if (displayAddress) toggleUserAddress();
        setLoading(false);
      } else {
        setCloseLoading(false);
        visible(false);
      }

      // await addTempUserData(result.data.userDetails, values);

      Toast({
        type: 'success',
        message: 'Success!',
        description: result.data.data,
      });
    } catch (error) {
      const errorData = error.response.data.errors;
      if (errorData) {
        Object.values(errorData).map(error => setDuplicateEmail(error.value));
      }
      formRef.current.submit();
      setLoading(false);
      setCloseLoading(false);
      handleApiErrors(error.response);
    }
  };

  const toggleUserAddress = () => {
    setDisplayAddress(displayed => {
      if (!displayed === false) {
        form.setFieldsValue({
          streetOne: undefined,
          streetTwo: undefined,
          country: undefined,
          state: undefined,
          city: undefined,
          postalCode: undefined,
        });
      }

      return !displayed;
    });
  };

  const handleAddressChange = useCallback(
    values => {
      form.setFieldValue(values);
      setFormValues(currentVal => ({ ...currentVal, ...values }));
    },
    [form],
  );

  return (
    <Modal
      centered
      className={classNames.addUserModal}
      title="Add User"
      visible={visibility}
      onCancel={() => visible(false)}
      bodyStyle={{ overflowY: 'auto' }}
      footer={
        <Row gutter={[10, 10]}>
          <Col>
            <Button
              block
              disabled={closeLoading || loading}
              type="secondary"
              key="close"
              onClick={() => visible(false)}
            >
              Close
            </Button>
          </Col>
          <Col>
            <Button
              block
              disabled={closeLoading}
              key="invite-another"
              loading={loading}
              type="primary"
              onClick={() =>
                form.validateFields().then(values => {
                  onFinish(values, 'clear');
                })
              }
            >
              Invite and Add Another
            </Button>
          </Col>
          <Col>
            <Button
              block
              disabled={loading}
              key="invite-close"
              loading={closeLoading}
              type="primary"
              onClick={() =>
                form.validateFields().then(values => {
                  onFinish(values, 'close');
                })
              }
            >
              Invite and Close
            </Button>
          </Col>
        </Row>
      }
    >
      <Form
        ref={formRef}
        name="invite"
        form={form}
        labelCol={horizontalSpan}
        initialValues={{
          groupId: get(find(groups, { defaultGroup: true }), '_id'),
        }}
      >
        <Row gutter={[24, 0]}>
          <Col xs={24} md={12}>
            <NameInput label="First Name" labelcol={horizontalSpan} name="firstName" />
          </Col>
          <Col xs={24} md={12}>
            <NameInput label="Last Name" labelcol={horizontalSpan} name="lastName" />
          </Col>
        </Row>

        <Form.Item
          name="email"
          label="Email Address"
          labelcol={horizontalSpan}
          rules={EMAIL_VALIDATION_FORM_RULES}
        >
          <TextInput />
        </Form.Item>

        <Form.Item
          name="role"
          rules={[{ required: true, message: ERROR_MESSAGE.BLANK_FIELD }]}
          label="User Role"
          labelcol={horizontalSpan}
        >
          <Select
            className="role-dropdown"
            placeholder="Select User Role"
            options={UserRoleSelectOptions}
          ></Select>
        </Form.Item>

        <Form.Item
          name="groupId"
          rules={[{ required: true, message: ERROR_MESSAGE.BLANK_FIELD }]}
          label="Associated Group"
          labelcol={horizontalSpan}
        >
          <Select
            labelcol={horizontalSpan}
            placeholder="Select group"
            options={
              groups ? groups.map((group, key) => ({ value: group._id, label: group.name })) : []
            }
          />
        </Form.Item>

        {requireEmployeeID && (
          <Form.Item
            name="employeeId"
            label="Employee Number"
            labelcol={horizontalSpan}
            rules={EMPLOYEE_NUMBER_FORM_RULES}
          >
            <TextInput />
          </Form.Item>
        )}

        <Form.Item label={t('Cost Center')} name="department" labelcol={horizontalSpan}>
          <TextInput />
        </Form.Item>

        <Form.Item>
          <Checkbox
            checked={sendActivationEmailImmediately}
            onChange={e => {
              setSendActivationEmailImmediately(e.target.checked);
              if (e.target.checked) {
                form.setFieldValue('sendActivationEmailDate', null);
              } else {
                form.setFieldValue(
                  'sendActivationEmailDate',
                  momentEST().add(2, 'hour').startOf('hour'),
                );
              }
            }}
          >
            {t('sendActivationEmailImmediately')}
          </Checkbox>
        </Form.Item>

        <Form.Item
          label={t('sendActivationEmailDate')}
          name="sendActivationEmailDate"
          hidden={
            !USER_PERMISSIONS.canChangeActivationEmailDate() || sendActivationEmailImmediately
          }
          rules={[
            {
              validateTrigger: ['onChange', 'onSubmit'],
              validator: (_, value) => {
                if (!value) return Promise.resolve();

                if (momentEST().isAfter(momentEST(value))) {
                  return Promise.reject('Date must be in the future');
                }

                return Promise.resolve();
              },
            },
          ]}
        >
          <DatePicker showTime use12Hours showNow={false} format="YYYY-MM-DD hh:mm a zz" />
        </Form.Item>

        <Row justify="start" align="middle">
          <Col style={{ cursor: 'pointer', marginBottom: 10 }} onClick={toggleUserAddress}>
            <Row gutter={5}>
              <Col>{displayAddress ? <MinusCircleOutlined /> : <PlusCircleOutlined />}</Col>
              <Col>
                <span>{displayAddress ? 'Remove address' : 'Add address'}</span>
              </Col>
            </Row>
          </Col>
        </Row>

        {displayAddress && (
          <Row>
            <AddressForm
              t={t}
              formInstance={form}
              countries={supportedCountries}
              companyAddress={currentCompany?.address}
              onValueChange={handleAddressChange}
              values={formValues}
            />
          </Row>
        )}
      </Form>
    </Modal>
  );
};

AddUserModal.defaultProps = {
  requireEmployeeID: false,
};

AddUserModal.propTypes = {
  companyId: PropTypes.string,
  groups: PropTypes.array.isRequired,
  modalVisibility: PropTypes.bool.isRequired,
  visible: PropTypes.func,
  requireEmployeeID: PropTypes.bool,
};

export default withNamespaces()(AddUserModal);
