const StatusList = [
  {
    label: 'Active',
    value: 'active',
  },
  {
    label: 'Inactive',
    value: 'inactive',
  },
  {
    label: 'Pending',
    value: 'pending',
  },
];

const Status = {
  // General
  IN_PROGRESS: 'in-progress',
  IN_PROGRESS_measureOne: 'in_progress',
  PENDING: 'pending',
  APPROVED: 'approved',
  DENIED: 'denied',
  SUBMITTED: 'submitted',
  UNSUBMITTED: 'unsubmitted',
  REQUEST: 'request',
  PAID: 'paid',
  NOT_PAID: 'not-paid',
  COMPLETED_WITH_ERRORS: 'completed-with-errors',

  // User-specific
  ACTIVE: 'active',
  ACTIVE_TRIAL: 'active-trial',
  TRIAL_END: 'trial-ended',
  INACTIVE: 'inactive',
  ON_LEAVE: 'on_leave',
  DELETED: 'deleted',
  ACCOUNT_LOCKED: 'account_locked',

  // Receipt specific
  MORE_INFO: 'more-info',
  NA: 'n/a',
  NA_UPPER: 'N/A',

  // Trip-specific
  OVERDUE: 'overdue',

  // Frontend specific
  NEW: 'new',

  // Anonymize
  AWAITING_APPROVAL: 'awaiting-approval',
  AWAITING_ANONYMIZATION: 'awaiting-anonymization',
  ANONYMIZED: 'anonymized',

  // Download Data request
  GENERATING_FILE: 'generating',

  // Company specific
  CANCELLED: 'cancelled',
  PROCESSING_DELETE: 'processing_delete',

  // Plan specific
  DEACTIVATED: 'deactivated',

  // Import location specific
  COMPLETED: 'completed',
  PROCESSING: 'processing',
  AWAITING_CONFIRMATION: 'awaiting-confirmation',

  // FAVR
  FIX: 'fix',

  // Stripe invoice status
  DRAFT_INVOICE: 'draft',
  OPEN_INVOICE: 'open',
  VOID_INVOICE: 'void',
  UNCOLLECTIBLE: 'uncollectible',

  // Compliance Status
  NOT_AVAILABLE: 'not-available',
  VALID: 'valid',
  INVALID: 'invalid',
  IN_COMPLIANCE: 'inCompliance',
  OUT_OF_COMPLIANCE: 'outOfCompliance',
  NON_COMPLIANT: 'non-compliant',

  // ACH Dwolla
  NOT_STARTED: 'not-started',
  VERIFIED: 'verified',
  UNVERIFIED: 'unverified',
  RETRY: 'retry',
  DOCUMENT: 'document',
  SUSPENDED: 'suspended',
  INCOMPLETE: 'incomplete',
  NOT_INITIATED: 'not-initiated',
  BENEFICIAL_REQUIRES_CERTIFICATION: 'beneficial-requires-certification',
  PENDING_VERIFICATION: 'pending-verification',
  PROCESSED: 'processed',
  FAILED: 'failed',
  SUCCESS: 'success',
  ERROR: 'error',
  COMPLETE: 'complete',
  ACH_PENDING: 'ach-pending',
  ACH_CANCELLED: 'ach-cancelled',
  ACH_FAILED: 'ach-failed',
  INSUFFICIENT_FUNDS: 'insufficient-funds',
  UNPROCESSABLE: 'unprocessable',

  // Insurance MO
  ITEM_UNAVAILABLE: 'item_unavailable',
  REFRESH_FAILED: 'refresh_failed',
};

const StatusTagColorConfig = {
  [Status.DENIED]: {
    variant: 'red',
    label: 'Denied',
  },
  [Status.SUBMITTED]: {
    variant: 'orange',
    label: 'Awaiting Approval',
  },
  [Status.UNSUBMITTED]: {
    variant: 'secondary',
    label: 'Unsubmitted',
  },
  [Status.REQUEST]: {
    variant: 'orange',
    label: 'Request',
  },
  [Status.IN_PROGRESS]: {
    variant: 'blue',
    label: 'In Progress',
  },
  [Status.IN_PROGRESS_measureOne]: {
    variant: 'blue',
    label: 'In Progress',
  },
  [Status.PENDING]: {
    variant: 'orange',
    label: 'Pending',
  },
  [Status.APPROVED]: {
    variant: 'primary',
    label: 'Approved',
  },
  [Status.PAID]: {
    variant: 'primary',
    label: 'Paid',
  },
  [Status.NOT_PAID]: {
    variant: 'red',
    label: 'Not Paid',
  },
  [Status.OVERDUE]: {
    variant: 'red',
    label: 'Overdue',
  },
  [Status.ACTIVE]: {
    variant: 'primary',
    label: 'Active',
  },
  [Status.ACTIVE_TRIAL]: {
    variant: 'primary',
    label: 'Active Trial',
  },
  [Status.TRIAL_END]: {
    variant: 'red',
    label: 'Trial Ended',
  },
  [Status.INACTIVE]: {
    variant: 'secondary',
    label: 'Inactive',
  },
  [Status.ON_LEAVE]: {
    variant: 'orange',
    label: 'On Leave',
  },
  [Status.DELETED]: {
    variant: 'red',
    label: 'Deleted',
  },
  [Status.NEW]: {
    variant: 'blue',
    label: 'New',
  },
  [Status.AWAITING_ANONYMIZATION]: {
    variant: 'orange',
    label: 'Awaiting Anonymization',
  },
  [Status.AWAITING_APPROVAL]: {
    variant: 'orange',
    label: 'Awaiting Approval',
  },
  [Status.ANONYMIZED]: {
    variant: 'primary',
    label: 'Anonymized',
  },
  [Status.CANCELLED]: {
    variant: 'red',
    label: 'Cancelled',
  },
  [Status.DEACTIVATED]: {
    variant: 'red',
    label: 'Deactivated',
  },
  [Status.GENERATING_FILE]: {
    variant: 'blue',
    label: 'Generating File',
  },
  [Status.MORE_INFO]: {
    variant: 'orange',
    label: 'More Info',
  },
  [Status.COMPLETED]: {
    variant: 'primary',
    label: 'Completed',
  },
  [Status.AWAITING_CONFIRMATION]: {
    variant: 'blue',
    label: 'Awaiting Confirmation',
  },
  [Status.COMPLETED_WITH_ERRORS]: {
    variant: 'red',
    label: 'Completed with Errors',
  },
  [Status.PROCESSING]: {
    variant: 'orange',
    label: 'Processing',
  },
  [Status.PROCESSING_DELETE]: {
    variant: 'red',
    label: 'Deletion in Process',
  },
  [Status.FIX]: {
    variant: 'red',
    label: 'Fix',
  },
  [Status.DRAFT_INVOICE]: {
    variant: 'orange',
    label: 'Draft',
  },
  [Status.OPEN_INVOICE]: {
    variant: 'orange',
    label: 'Not Paid',
  },
  [Status.VOID_INVOICE]: {
    variant: 'red',
    label: 'Void',
  },
  [Status.UNCOLLECTIBLE]: {
    variant: 'red',
    label: 'Uncollectible',
  },
  [Status.NA]: {
    variant: 'red',
    label: 'N/A',
  },
  [Status.NA_UPPER]: {
    variant: 'red',
    label: 'N/A',
  },
  [Status.NOT_STARTED]: {
    variant: 'red',
    label: 'Not Started',
  },
  [Status.NOT_INITIATED]: {
    variant: 'red',
    label: 'Requires Confirmation',
  },
  [Status.VERIFIED]: {
    variant: 'primary',
    label: 'Verified',
  },
  [Status.UNVERIFIED]: {
    variant: 'red',
    label: 'Unverified',
  },
  [Status.INCOMPLETE]: {
    variant: 'red',
    label: 'Incomplete',
  },
  [Status.RETRY]: {
    variant: 'red',
    label: 'Retry',
  },
  [Status.DOCUMENT]: {
    variant: 'blue',
    label: 'Requires Documents',
  },
  [Status.SUSPENDED]: {
    variant: 'red',
    label: 'Suspended',
  },
  [Status.BENEFICIAL_REQUIRES_CERTIFICATION]: {
    variant: 'blue',
    label: 'Beneficial Owner requires certification',
  },
  [Status.PENDING_VERIFICATION]: {
    variant: 'orange',
    label: 'Pending Verification',
  },
  [Status.PROCESSED]: {
    variant: 'primary',
    label: 'Processed',
  },
  [Status.FAILED]: {
    variant: 'red',
    label: 'Failed',
  },
  [Status.SUCCESS]: {
    variant: 'primary',
    label: 'Success',
  },
  [Status.ERROR]: {
    variant: 'red',
    label: 'Error',
  },
  [Status.COMPLETE]: {
    variant: 'primary',
    label: 'Completed',
  },
  [Status.NOT_AVAILABLE]: {
    variant: 'red',
    label: 'Not Available',
  },
  [Status.VALID]: {
    variant: 'primary',
    label: 'Valid',
  },
  [Status.INVALID]: {
    variant: 'red',
    label: 'Invalid',
  },
  [Status.IN_COMPLIANCE]: {
    variant: 'primary',
    label: 'In Compliance',
  },
  [Status.OUT_OF_COMPLIANCE]: {
    variant: 'red',
    label: 'Out of Compliance',
  },
  [Status.NON_COMPLIANT]: {
    variant: 'red',
    label: 'Non-Compliant',
  },
  [Status.ACH_PENDING]: {
    variant: 'orange',
    label: 'ACH - Pending',
  },
  [Status.ACH_FAILED]: {
    variant: 'red',
    label: 'ACH - Failed',
  },
  [Status.ACH_CANCELLED]: {
    variant: 'red',
    label: 'ACH - Cancelled',
  },
  [Status.ACCOUNT_LOCKED]: {
    variant: 'red',
    label: 'Account Locked',
  },
  [Status.INSUFFICIENT_FUNDS]: {
    variant: 'red',
    label: 'Insufficient Funds',
  },
  [Status.UNPROCESSABLE]: {
    variant: 'red',
    label: 'Unprocessable',
  },
  [Status.ITEM_UNAVAILABLE]: {
    variant: 'red',
    label: 'Item Unavailable',
  },
  [Status.REFRESH_FAILED]: {
    variant: 'red',
    label: 'Refresh Failed',
  },
};

export default { Status, StatusList, StatusTagColorConfig };
