import { Col, DatePicker, Form, Row } from 'antd';
import moment from 'moment-timezone';
import React, { useEffect, useMemo, useState } from 'react';

import { setModalFullscreenWidth } from '../../../utils/common';
import { ERROR_MESSAGE } from '../../../utils/constants';
import {
  formatItineraryBEToFormValues,
  formatItineraryFormValuesToBE,
} from '../../../utils/itinerary';
import { useStoreSelector } from '../../../utils/storeSelectors';
import { Modal } from '../..';
import FormItem from '../../Form/FormItem';
import ItineraryForm from '../../Forms/ItineraryForm';
import SubmitCancelButtonGroup from '../../SubmitCancelButtonGroup';
import Text from '../../Text';

const CreateItineraryModal = props => {
  const {
    t,
    isSavingItinerary,
    visible,
    initialValues,
    onCancel,
    onSubmit,
    disabledClientChange,
  } = props;

  const [form] = Form.useForm();
  const [createNewContact, setCreateNewContact] = useState(false);
  const { isFetchingContacts } = useStoreSelector('realtor');

  const toggleCreateNewContact = () => setCreateNewContact(!createNewContact);

  const handleCancel = () => {
    onCancel();
    form.resetFields();
  };

  const defaultFormValues = useMemo(() => {
    if (visible) {
      return formatItineraryBEToFormValues({
        dateFrom: moment().startOf('hour').add(1, 'hour').toISOString(),
        trips: [{ tripEndTime: moment().startOf('hour').add(1, 'hour').toISOString() }],
        ...initialValues,
      });
    }

    return {};
  }, [visible, initialValues]);

  useEffect(() => {
    if (visible) {
      setCreateNewContact(false);
      form.setFieldsValue(defaultFormValues);
    }
    // eslint-disable-next-line
  }, [visible]);

  return (
    <Modal
      centered
      destroyOnClose
      onCancel={handleCancel}
      visible={visible}
      width={setModalFullscreenWidth(700)}
      bodyStyle={{ overflowY: 'auto', paddingBottom: 12, paddingTop: 12 }}
      modalProps={{
        title: (
          <Row gutter={[16, 16]} align="middle" style={{ width: '100%' }}>
            <Col>
              <Text variant="h4" renderAs="p">
                {t('itineraryDate')}
              </Text>
            </Col>
            <Col>
              <Form form={form} initialValues={defaultFormValues}>
                <FormItem
                  required
                  name="dateFrom"
                  className="no-margin-bottom"
                  rules={[{ required: true, message: ERROR_MESSAGE.BLANK_FIELD }]}
                >
                  <DatePicker
                    allowClear={false}
                    onChange={dateFrom => form.setFieldsValue({ dateFrom })}
                  />
                </FormItem>
              </Form>
            </Col>
          </Row>
        ),
      }}
      footer={
        <SubmitCancelButtonGroup
          loading={isSavingItinerary}
          disabled={isSavingItinerary || isFetchingContacts}
          submitText={t('Save')}
          onCancel={handleCancel}
          onSubmit={() => {
            form.validateFields().then(values => {
              onSubmit(
                formatItineraryFormValuesToBE({
                  ...values,
                  createNewContact,
                }),
              );
            });
          }}
        />
      }
    >
      <div style={{ marginBottom: 30 }}>
        <ItineraryForm
          t={t}
          createNewContact={createNewContact}
          formInstance={form}
          isSubmitting={isSavingItinerary}
          disabledClientChange={disabledClientChange}
          initialValues={defaultFormValues}
          onCancel={handleCancel}
          onCreateNewContact={toggleCreateNewContact}
        />
      </div>
    </Modal>
  );
};

CreateItineraryModal.defaultProps = {
  initialValues: {},
};

export default CreateItineraryModal;
