import React from 'react';

import Toast from '../../../components/Toast';

/**
 * Validate address form schema
 *
 * @param {object} addressData - Address data
 * @param {string} addressType - Home address or Office address
 */
export const validateAddress = (addressData = {}, addressType) => {
  let isValid = true;
  const errorTitle = addressType;

  if (!addressData) {
    isValid = false;
    Toast({
      type: 'error',
      message: errorTitle,
      description: 'You must fill the form first.',
    });
  } else if (!addressData.streetOne) {
    isValid = false;
    Toast({
      type: 'error',
      message: errorTitle,
      description: (
        <>
          <strong>Address 1</strong> cannot be blank.
        </>
      ),
    });
  } else if (!addressData.country) {
    isValid = false;
    Toast({
      type: 'error',
      message: errorTitle,
      description: (
        <>
          <strong>Country</strong> cannot be blank.
        </>
      ),
    });
  } else if (!addressData.state) {
    isValid = false;
    Toast({
      type: 'error',
      message: errorTitle,
      description: (
        <>
          <strong>State</strong> cannot be blank.
        </>
      ),
    });
  } else if (!addressData.city) {
    isValid = false;
    Toast({
      type: 'error',
      message: errorTitle,
      description: (
        <>
          <strong>City</strong> cannot be blank.
        </>
      ),
    });
  } else if (!addressData.postalCode) {
    isValid = false;
    Toast({
      type: 'error',
      message: errorTitle,
      description: (
        <>
          <strong>ZIP Code</strong> cannot be blank.
        </>
      ),
    });
  } else if (
    addressData.streetOne.length > 35 ||
    (addressData.streetTwo && addressData.streetTwo.length > 35)
  ) {
    isValid = false;
    Toast({
      type: 'error',
      message: errorTitle,
      description: (
        <>
          Your <strong>Addresses</strong> cannot be longer than 35 characters.
        </>
      ),
    });
  }

  return isValid;
};

/**
 * Validate the User profile schema
 *
 * @param {object} formData - Profile data
 */
export const validateUserProfile = (formData = {}) => {
  let isValid = true;

  if (!formData.firstName) {
    isValid = false;
    Toast({
      type: 'error',
      message: 'Validation error',
      description: (
        <>
          <strong>First Name</strong> cannot be blank.
        </>
      ),
    });
  } else if (!formData.lastName) {
    isValid = false;
    Toast({
      type: 'error',
      message: 'Validation error',
      description: (
        <>
          <strong>Last Name</strong> cannot be blank.
        </>
      ),
    });
  } else if (!formData.email) {
    isValid = false;
    Toast({
      type: 'error',
      message: 'Validation error',
      description: (
        <>
          <strong>Email</strong> cannot be blank.
        </>
      ),
    });
  } else if (formData.phone) {
    if (formData.phone.length < 8) {
      isValid = false;
      Toast({
        type: 'error',
        message: 'Validation error',
        description: (
          <>
            <strong>Phone</strong> is incomplete.
          </>
        ),
      });
    }
  }

  return isValid;
};
