import './style.scss';

import { Col, Row } from 'antd';
import { Button } from 'components';
import { INTERNAL_LINKS } from 'enum';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
// the hoc
import { withNamespaces } from 'react-i18next';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { handleApiCalls, handleApiErrors } from '../../../api/axiosInstance';
import { deleteRate, RATES_API } from '../../../api/rates';
import Banner from '../../../components/Banner';
import RateDeleteConfirmationModal from '../../../components/Modal/RateDeleteConfirmationModal';
import PageFiltersRenderer from '../../../components/shared-ui/PageFiltersRenderer';
import RatesTable from '../../../components/Table/RatesTable';
import Toast from '../../../components/Toast';
import PageContainer from '../../../containers/PageContainer';
import { RATE_TYPE_OPTIONS } from '../../../enum/Rates';
import withAuthentication from '../../../hocs/withAuthentication';
import useDefaultRate from '../../../hooks/queries/useDefaultRate';
import usePaginatedFiltersQuery from '../../../hooks/queries/usePaginatedFiltersQuery';
import useDebounce from '../../../hooks/useDebounce';
import useTableSort from '../../../hooks/useTableSort';
import { formatPageTitle } from '../../../utils/common';
import { normalizeRateSchema } from '../../../utils/rates';
import { selectStoreCurrentCompany } from '../../../utils/storeSelectors';

const ConfiguredRates = props => {
  const { t } = props;

  const defaultRateQuery = useDefaultRate();
  const currentCompany = useSelector(selectStoreCurrentCompany);

  const [notifications, setNotifications] = useState([]);
  const [isDeleteConfirmationVisible, setIsDeleteConfirmationVisible] = useState(false);
  const [rateToBeDeleted, setRateToBeDeleted] = useState();
  const [configuredRates, setConfiguredRates] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [deletingRate, setDeletingRate] = useState(false);
  const [deactivatingRate, setDeactivatingRate] = useState(false);
  const [activatingRate, setActivatingRate] = useState(false);
  const [filters, setFilters] = useState({ searchTerm: '', type: null, getUsersCount: true });
  const debouncedSearchTerm = useDebounce(filters.searchTerm, 300);

  const { stringTableSort, handleTableSort } = useTableSort({ name: 1 });

  const {
    query: ratesQuery,
    paginationConfig,
    handlePageChange,
    handlePageSizeChange,
  } = usePaginatedFiltersQuery(
    {
      queryKey: [
        'fetchCompanyRates',
        currentCompany._id,
        filters.type,
        debouncedSearchTerm,
        stringTableSort,
      ],
      queryFn: () =>
        RATES_API.fetchCompanyRates(
          currentCompany._id,
          filters,
          stringTableSort,
          paginationConfig.current,
          paginationConfig.pageSize,
        ),
      onSuccess: ({ documents, totalCount }) => {
        setConfiguredRates(documents.map(normalizeRateSchema));
      },
      onError: error => {
        handleApiErrors(error.response, () => {
          Toast({
            type: 'error',
            message: t('loadRatesError'),
          });
        });
      },
    },
    {
      resetPageQueryKey: [filters.type, debouncedSearchTerm],
    },
  );

  const IS_LOADING_REQUEST =
    ratesQuery.isFetching || deletingRate || deactivatingRate || activatingRate;

  const handleCloseNotification = index => {
    setNotifications(state => state.slice(0, index).concat(state.slice(index + 1, state.length)));
  };

  const onSelectChange = keys => setSelectedRowKeys(keys);

  const activateRates = async () => {
    if (!selectedRowKeys.length) return;

    setActivatingRate(true);

    let requests = [];
    selectedRowKeys.forEach(key => {
      const rate = configuredRates.find(r => r.key === key);
      if (rate) {
        requests.push(
          handleApiCalls('patch', `${process.env.REACT_APP_HOST_API}products/${rate._id}/activate`),
        );
      }
    });
    await Promise.all(requests);
    await ratesQuery.refetch();

    setActivatingRate(false);
  };

  const getNextRateToDelete = () => {
    setSelectedRowKeys(keys => {
      setIsDeleteConfirmationVisible(keys.length > 0);
      setRateToBeDeleted(configuredRates.find(r => r.key === keys[0]));

      return keys;
    });
  };

  const handleDeleteRate = async () => {
    setDeletingRate(true);

    try {
      await deleteRate(rateToBeDeleted._id);
      await ratesQuery.refetch();

      Toast({
        type: 'open',
        message: t('deleteRateSuccess'),
      });
    } catch (error) {
      handleApiErrors(error.response, () => {
        Toast({
          type: 'error',
          message: t('deleteRateError'),
        });
      });
    }

    setDeletingRate(false);
    getNextRateToDelete();
  };

  const deleteNextRate = async () => {
    if (rateToBeDeleted) {
      await handleDeleteRate();
      setSelectedRowKeys(state => state.slice(1));
      getNextRateToDelete();
    } else if (isDeleteConfirmationVisible) {
      // Close delete confirmation modal
      setRateToBeDeleted();
      setIsDeleteConfirmationVisible(false);
    }
  };

  const confirmRateDeletion = () => {
    const toastErrorKey = 'rate-delete-error';

    if (!selectedRowKeys.length) {
      return Toast({
        key: toastErrorKey,
        type: 'error',
        message: t('You must select rates to be deleted'),
      });
    }

    if (configuredRates.length === 1) {
      return Toast({
        key: toastErrorKey,
        type: 'error',
        message: t('You cannot delete your only rate'),
      });
    }

    if (configuredRates.length === selectedRowKeys.length) {
      return Toast({
        key: toastErrorKey,
        type: 'error',
        message: t('You cannot delete all your rates'),
      });
    }

    const isDefaultRateSelected = configuredRates.find(
      rate => selectedRowKeys.includes(rate._id) && rate.defaultRate,
    );
    if (isDefaultRateSelected) {
      return Toast({
        key: toastErrorKey,
        type: 'error',
        message:
          selectedRowKeys.length > 1
            ? t('defaultRateMultipleDeleteError')
            : t('defaultRateDeleteError'),
        duration: 10, // seconds
      });
    }

    getNextRateToDelete();
  };

  const deactivateRates = async () => {
    if (!selectedRowKeys.length) return;

    setDeactivatingRate(true);

    let requests = [];
    selectedRowKeys.forEach(key => {
      const rate = configuredRates.find(r => r.key === key);
      if (rate) {
        requests.push(
          handleApiCalls(
            'patch',
            `${process.env.REACT_APP_HOST_API}products/${rate._id}/deactivate`,
          ),
        );
      }
    });
    try {
      await Promise.all(requests);
      await ratesQuery.refetch();
    } catch (error) {
      handleApiErrors(error.response);
    }
    setDeactivatingRate(false);
  };

  return (
    <PageContainer title={'Rates'} className="configured-rates">
      <Helmet>
        <title>{formatPageTitle('Rates')}</title>
      </Helmet>

      <div className="configured-rates-notifications">
        <h6>{t('Your existing rates')}</h6>
        {notifications.map((n, index) => {
          return (
            <Banner
              key={index}
              // type={n.type}
              title={n.text}
              onClose={() => handleCloseNotification(index)}
            />
          );
        })}
      </div>
      <div className="configured-rates-table">
        <Row gutter={16} justify="end" align="middle">
          <Col>
            <Button
              type="secondary"
              size="xs"
              onClick={activateRates}
              disabled={IS_LOADING_REQUEST || !selectedRowKeys.length}
              loading={activatingRate}
            >
              {t('Active')}
            </Button>
          </Col>
          <Col>
            <Button
              type="secondary"
              size="xs"
              onClick={deactivateRates}
              disabled={IS_LOADING_REQUEST || !selectedRowKeys.length}
              loading={deactivatingRate}
            >
              {t('Inactive')}
            </Button>
          </Col>
          <Col>
            <Button
              type="secondary"
              size="xs"
              onClick={confirmRateDeletion}
              disabled={IS_LOADING_REQUEST || !selectedRowKeys.length}
              loading={deletingRate}
            >
              {t('Delete')}
            </Button>
          </Col>
        </Row>

        <PageFiltersRenderer
          t={t}
          loading={ratesQuery.isFetching}
          filters={[
            {
              componentType: 'select',
              label: t('type'),
              name: 'type',
              defaultValue: filters.type,
              value: filters.type,
              options: [{ label: t('All'), value: null }, ...RATE_TYPE_OPTIONS],
              responsive: { xs: 24, md: 12, lg: 6 },
            },
          ]}
          onFiltersChange={setFilters}
          search={{
            defaultValue: filters.searchTerm,
          }}
        />

        <RatesTable
          asyncSort
          loading={IS_LOADING_REQUEST}
          rowSelection={{
            selectedRowKeys,
            onChange: onSelectChange,
          }}
          dataSource={configuredRates}
          scroll={{ y: 500 }}
          companyCountry={currentCompany.address.country}
          onChange={({ current }, filters, sorters) => {
            handlePageChange(current);
            handleTableSort(sorters?.columnKey, sorters?.order);
          }}
          pagination={{
            pageSize: paginationConfig.pageSize,
            total: paginationConfig.total,
            current: paginationConfig.current,
            onShowSizeChange: handlePageSizeChange,
          }}
        />
        <RateDeleteConfirmationModal
          t={t}
          rateName={rateToBeDeleted?.name}
          isDeletingRate={deletingRate}
          visible={isDeleteConfirmationVisible || deletingRate}
          affectedGroups={rateToBeDeleted?.originalGroups || []}
          defaultRate={defaultRateQuery.data}
          onCancel={() => {
            if (!deletingRate) {
              setRateToBeDeleted();
              setIsDeleteConfirmationVisible(false);
            }
          }}
          onDelete={deleteNextRate}
        />
        <div className="configured-rates-table-footer">
          <NavLink to={INTERNAL_LINKS.CREATE_RATE}>
            <Button className="create-new-product" text={t('Create New Rate')} />
          </NavLink>
        </div>
      </div>
    </PageContainer>
  );
};

ConfiguredRates.propTypes = {};

ConfiguredRates.defaultProps = {};

export default withNamespaces()(withAuthentication(ConfiguredRates));
