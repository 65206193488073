import { Col, Row } from 'antd';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { withNamespaces } from 'react-i18next';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';

import { handleApiErrors } from '../../../api/axiosInstance';
import { createHardUserDataDeleteRequest } from '../../../api/userDataActions';
import CompanyUsersLookupSelect from '../../../components/CompanyUsersLookupSelect';
import TypeFullNameConfirmationModal from '../../../components/Modal/TypeFullNameConfirmationModal';
import PageBreadcrumbs from '../../../components/PageBreadcrumbs';
import SubmitCancelButtonGroup from '../../../components/SubmitCancelButtonGroup';
import DownloadUserDataUsersTable from '../../../components/Table/DownloadUserDataUsersTable';
import Text from '../../../components/Text';
import Toast from '../../../components/Toast';
import PageContainer from '../../../containers/PageContainer';
import { INTERNAL_LINKS, STATUS_LIST } from '../../../enum';
import withAuthentication from '../../../hocs/withAuthentication';
import { formatPageTitle } from '../../../utils/common';
import { selectStoreCurrentAuthUser } from '../../../utils/storeSelectors';

const AnonymizeUserDataPage = props => {
  const { t, history } = props;

  const [usersOnTable, setUsersOnTable] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);

  const [isConfirmationVisible, setIsConfirmationVisible] = useState(false);

  const authUser = useSelector(selectStoreCurrentAuthUser);

  const handleAnonymizeRequest = async values => {
    try {
      await createHardUserDataDeleteRequest(values.firstName, values.lastName, selectedUsers, true);
      Toast({
        type: 'success',
        message: t('anonymizeRequestInProgress'),
        description: t('requestProcessedOnNightlyBasis'),
        duration: null,
      });
      setIsConfirmationVisible(false);
      history.push({
        pathname: INTERNAL_LINKS.GDPR,
        search: '?activeTab=status',
      });
    } catch (error) {
      handleApiErrors(error.response, () => {
        Toast({
          type: 'error',
          message: t('errorAnonymizeRequest'),
        });
      });
    }
  };

  return (
    <PageContainer
      title={
        <PageBreadcrumbs
          items={[
            {
              label: t('gdpr_ccpa'),
              onClick: () => props.history.push(INTERNAL_LINKS.GDPR),
            },
            { label: t('anonymizeUserData') },
          ]}
        />
      }
    >
      <Helmet>
        <title>{formatPageTitle('Anonymize User Data')}</title>
      </Helmet>

      <Text variant="h5" style={{ marginBottom: 15 }}>
        {t('findSelectUsers')}
      </Text>

      <Row style={{ marginBottom: 5 }}>
        <Text size="sm">{t('adminCannotAnonymizeOwnData')}</Text>
      </Row>

      <Row style={{ marginBottom: 5 }}>
        <Text size="sm">{t('contactAdminToRemoveYourRole')}</Text>
      </Row>

      <Row style={{ marginBottom: 15 }}>
        <Text size="sm">
          <Trans
            t={t}
            i18nKey="anonymizeOnlyInactiveUsers"
            components={[
              <Text variant="strong" size="sm">
                dummyText
              </Text>,
            ]}
          />
        </Text>
      </Row>

      <Row>
        <Col>
          <Row justify="end">
            <Col>
              <CompanyUsersLookupSelect
                t={t}
                style={{ width: 250 }}
                value={null}
                placeholder="Search by first or last name"
                notFoundContent="No inactive users"
                optionsFilter={user => !usersOnTable.map(user => user._id).includes(user._id)}
                additionalQueryParams={{
                  status: [STATUS_LIST.Status.INACTIVE],
                  isAnonymized: false,
                }}
                onChange={(_, user) => {
                  setUsersOnTable(state => [...state, user]);
                  setSelectedUsers(state => [...state, user._id]);
                }}
              />
            </Col>
          </Row>

          <DownloadUserDataUsersTable
            dataSource={usersOnTable}
            rowSelection={{
              type: 'checkbox',
              selectedRowKeys: selectedUsers,
              onChange: selectedRowKeys => {
                setSelectedUsers(selectedRowKeys);
              },
            }}
          />
        </Col>
      </Row>

      <SubmitCancelButtonGroup
        onCancel={() => props.history.push(INTERNAL_LINKS.GDPR)}
        submitText={t('anonymizeData')}
        onSubmit={() => {
          if (!selectedUsers.length) {
            Toast({
              type: 'error',
              message: t('noUsersSelect'),
            });
          } else {
            setIsConfirmationVisible(true);
          }
        }}
      />

      <TypeFullNameConfirmationModal
        t={t}
        firstNameToMatch={authUser.profile.firstName}
        lastNameToMatch={authUser.profile.lastName}
        visible={isConfirmationVisible}
        onCancel={() => setIsConfirmationVisible(false)}
        onSubmit={handleAnonymizeRequest}
      />
    </PageContainer>
  );
};

export default withNamespaces()(withAuthentication(AnonymizeUserDataPage));
