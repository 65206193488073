import { STATUS_LIST } from './index';

const SelectOptions = [
  {
    label: 'Active',
    value: STATUS_LIST.Status.ACTIVE,
  },
  {
    label: 'Inactive',
    value: STATUS_LIST.Status.INACTIVE,
  },
  {
    label: 'On Leave',
    value: STATUS_LIST.Status.ON_LEAVE,
  },
];

export default { SelectOptions };
