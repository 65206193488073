import { DeleteFilled } from '@ant-design/icons';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Col, Row } from 'antd';
import classnames from 'classnames';
import React from 'react';

import { IMAGES } from '../../../enum';
import { ERROR_MESSAGE } from '../../../utils/constants';
import FormItem from '../../Form/FormItem';
import LocationInput from '../../LocationInput';
import Text from '../../Text';
import classNames from './styles.module.scss';
import TogglableArrivalTimeFormItem from './TogglableArrivalTimeFormItem';

const DraggableItineraryDestinationItem = props => {
  const {
    t,
    id,
    order,
    timeLabel,
    addressLabel,
    isSubmitting,
    destination,
    onDestinationChange,
    onDestinationRemoval,
    onTimeVisibilityToggle,
    ...rest
  } = props;

  const {
    attributes,
    listeners,
    setDroppableNodeRef,
    setDraggableNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    zIndex: isDragging ? '100' : 'auto',
    opacity: isDragging ? 0.3 : 1,
  };

  return (
    <div {...rest} ref={setDroppableNodeRef} padding="5px" style={style}>
      <Row align="middle" style={{ width: '100%' }}>
        <Col style={{ flex: '318px' }}>
          <div>
            <Row align="middle" gutter={8} wrap={false}>
              <Col
                {...attributes}
                {...listeners}
                style={{ cursor: 'grab', userSelect: 'none', touchAction: 'none' }}
                className={classNames.iconColumn}
              >
                <img
                  className={classNames.rowIcon}
                  alt="drag"
                  width="15px"
                  src={IMAGES.DRAG_HANDLE_ICON}
                />
              </Col>

              <Col flex={1} ref={setDraggableNodeRef}>
                <FormItem
                  required
                  style={{ width: '100%', maxWidth: 336, marginBottom: 5 }}
                  rules={[{ required: true, message: ERROR_MESSAGE.BLANK_FIELD }]}
                  name={addressLabel}
                  label={t('destinationPropertyAddress')}
                >
                  <LocationInput
                    name={addressLabel}
                    disabled={isSubmitting}
                    defaultValue={destination.address}
                    limitedToCountryCode="US"
                    placeholder={t('destinationPropertyAddress_placeholder')}
                    onSelect={address => onDestinationChange(order, { address })}
                    onChange={address => onDestinationChange(order, { address })}
                  />
                </FormItem>
              </Col>

              <Col
                className={classnames(classNames.visibleOnMobile, classNames.iconColumn)}
                onClick={order > 0 ? () => onDestinationRemoval(order) : undefined}
                style={{ cursor: order > 0 ? 'pointer' : 'initial ' }}
              >
                <div style={{ width: 14, marginTop: 4 }}>
                  {order > 0 && (
                    <DeleteFilled
                      className={classnames(classNames.rowIcon, 'item-container-trash')}
                    />
                  )}
                </div>
              </Col>
            </Row>
          </div>
        </Col>

        <Col style={{ flex: '297px' }}>
          <div>
            <Row wrap={false} align="middle">
              <Col>
                <Text
                  style={{
                    paddingLeft: 8,
                    paddingRight: 8,
                    display: 'block',
                    transform: 'translateY(8px)',
                  }}
                  variant="b"
                >
                  {t('at')}
                </Text>
              </Col>

              <Col flex="250px">
                <TogglableArrivalTimeFormItem
                  t={t}
                  visibleOnMount={order === 0 || !!destination.reachingTime}
                  required={order === 0}
                  name={timeLabel}
                  label={`${order === 0 ? t('arrivalTime') : t('optional_undercase')}`}
                  disabled={isSubmitting}
                  value={destination.reachingTime}
                  onSelect={reachingTime => onDestinationChange(order, { reachingTime })}
                  availableMinutes={['00', '15', '30', '45']}
                  onVisible={onTimeVisibilityToggle}
                />
              </Col>

              <Col
                className={classnames(classNames.hiddenOnMobile, classNames.iconColumn)}
                onClick={order > 0 ? () => onDestinationRemoval(order) : undefined}
                style={{ cursor: order > 0 ? 'pointer' : 'initial ' }}
              >
                <div style={{ width: 14, marginTop: 4 }}>
                  {order > 0 && (
                    <DeleteFilled
                      className={classnames(classNames.rowIcon, 'item-container-trash')}
                    />
                  )}
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default DraggableItineraryDestinationItem;
