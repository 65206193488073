import { Col, Form, Row } from 'antd';
import React from 'react';

import { ERROR_MESSAGE } from '../../../utils/constants';
import Text from '../../Text';
import TextInput from '../../TextInput';

const RateFormSegmentUSA = props => {
  const { t, isSubmitting } = props;

  return (
    <>
      <Text>{t('standardMileageRateIRS')}</Text>

      <br />

      <Row gutter={16}>
        <Col xs={24} md={12}>
          <Form.Item
            required
            name="businessMiles"
            label={t('businessMiles')}
            rules={[{ required: true, message: ERROR_MESSAGE.BLANK_FIELD }]}
          >
            <TextInput
              disabled={isSubmitting}
              step="any"
              type="number"
              placeholder={t('enterAmountPerMile')}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col xs={24} md={12}>
          <Form.Item
            required
            name="medicalMovingMiles"
            label={t('medicalMovingMiles')}
            rules={[{ required: true, message: ERROR_MESSAGE.BLANK_FIELD }]}
          >
            <TextInput
              disabled={isSubmitting}
              step="any"
              type="number"
              placeholder={t('enterAmountPerMile')}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col xs={24} md={12}>
          <Form.Item
            required
            name="charitableOrganizationMiles"
            label={t('charitableOrganizationMiles')}
            rules={[{ required: true, message: ERROR_MESSAGE.BLANK_FIELD }]}
          >
            <TextInput
              disabled={isSubmitting}
              step="any"
              type="number"
              placeholder={t('enterAmountPerMile')}
            />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

export default RateFormSegmentUSA;
