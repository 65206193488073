import { Form } from 'antd';
import React, { useEffect } from 'react';

import { ERROR_MESSAGE } from '../../../utils/constants';
import FormItem from '../../Form/FormItem';
import TextInput from '../../TextInput';

const CustomPlanForm = props => {
  const { t, formInstance, initialValues, isUpdate, isSubmitting } = props;

  useEffect(() => {
    formInstance.setFieldsValue(initialValues);
    return () => {
      formInstance.resetFields();
    };
    // eslint-disable-next-line
  }, []);

  return (
    <Form form={formInstance}>
      <FormItem
        required
        name="name"
        label={t('customPlanName')}
        rules={[{ required: true, message: ERROR_MESSAGE.BLANK_FIELD }]}
      >
        <TextInput
          placeholder={isUpdate ? t('updateCustomPlanName') : t('createCustomPlanName')}
          disabled={isSubmitting}
        />
      </FormItem>

      <FormItem
        required
        name="stripePriceId"
        label={t('stripeApiId')}
        rules={[{ required: true, message: ERROR_MESSAGE.BLANK_FIELD }]}
      >
        <TextInput placeholder={t('createStripeApiId')} disabled={isSubmitting} />
      </FormItem>
    </Form>
  );
};

export default CustomPlanForm;
