import { Col, Form, Row, Upload } from 'antd';
import React, { useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { withNamespaces } from 'react-i18next';
import { useSelector } from 'react-redux';
import storage from 'store';

import { handleApiErrors } from '../../api/axiosInstance';
import { deleteUserLicense, downloadUserLicense, USER_API } from '../../api/user';
import { Button, HelpIcon } from '../../components';
import Box from '../../components/Box';
import FormItem from '../../components/Form/FormItem';
import Select from '../../components/Select';
import SubmitCancelButtonGroup from '../../components/SubmitCancelButtonGroup';
import UploadedFilesTable from '../../components/Table/UploadedFilesTable';
import StatusTag from '../../components/Tag/StatusTag';
import Text from '../../components/Text';
import FadedText from '../../components/Text/FadedText';
import TextInput from '../../components/TextInput';
import Toast from '../../components/Toast';
import { STATUS_LIST } from '../../enum';
import { formatPageTitle } from '../../utils/common';
import { findFullSupportedCountryData } from '../../utils/countries';
import { setupPolicyDocumentsUploadList } from '../../utils/insurancePolicies';
import { selectStoreCurrentAuthUser, selectStoreCurrentCompany } from '../../utils/storeSelectors';

const MAX_FILE_SIZE = 10;
const MAX_FILE_COUNT = 5;

const validateImage = file => {
  const isLt10M = file.size / 1024 / 1024 < MAX_FILE_SIZE;
  if (!isLt10M) {
    Toast({
      type: 'error',
      message: `File must smaller than ${MAX_FILE_SIZE}MB`,
    });
  }

  return isLt10M;
};

const DriverLicenseView = props => {
  const { t, userDetails, userComplianceDetails, onUserUpdate } = props;

  const accessToken = storage.get('accessToken');
  const [isUploadingFile, setIsUploadingFile] = useState(false);
  const [isUpdatingDriverLicense, setIsUpdatingDriverLicense] = useState(false);

  const currentAuthUser = useSelector(selectStoreCurrentAuthUser);
  const currentCompany = useSelector(selectStoreCurrentCompany);

  const { licenseImages, licenseEntry, licenseNumber } = useMemo(
    () => userDetails?.personalInfor || {},
    [userDetails],
  );

  const [imageList, setImageList] = useState(setupPolicyDocumentsUploadList(licenseImages));

  const COUNTRY_STATES = useMemo(() => {
    const fullCountry = findFullSupportedCountryData(currentCompany.country || 'US');
    return fullCountry?.states || [];
  }, [currentCompany.country]);

  const handleFileChange = filesInfo => {
    const { file, fileList } = filesInfo;
    const { status } = file;
    if (status === 'error') {
      setIsUploadingFile(false);
      setImageList(fileList.filter(item => item.status !== 'error'));
      handleApiErrors(file.response, () => {
        Toast({
          type: 'error',
          message: `Error while uploading ${file.name}`,
        });
      });
    }

    if (['removed', 'uploading'].includes(status)) {
      setImageList(fileList);
      setIsUploadingFile(false);
    }

    if (status === 'uploading') {
      setIsUploadingFile(true);
    }

    if (status === 'done') {
      onUserUpdate({
        ...userDetails,
        personalInfor: {
          ...userDetails.personalInfor,
          licenseImages: file.response,
        },
      });
      setImageList(setupPolicyDocumentsUploadList(file.response));
      Toast({
        type: 'open',
        message: `${file.name} uploaded successfully`,
      });

      setIsUploadingFile(false);
    }
  };

  const handleFileRemove = async file => {
    try {
      const response = await deleteUserLicense(file.uid);
      onUserUpdate({ ...userDetails, personalInfor: response.personalInfor });
      setImageList(setupPolicyDocumentsUploadList(response.personalInfor.licenseImages));
      Toast({
        type: 'error',
        message: `${file.name} deleted successfully`,
      });
    } catch (error) {
      handleApiErrors(error.response);
    }
  };

  const handleFileDownload = async file => {
    try {
      await downloadUserLicense(file.uid, file.name);
    } catch (error) {
      handleApiErrors(error.response);
    }
  };

  const handlePreview = () => null;

  const handleUpdateUserDriverLicense = async values => {
    setIsUpdatingDriverLicense(true);

    try {
      const response = await USER_API.updateUser(userDetails._id, { personalInfor: values });
      onUserUpdate({ ...userDetails, personalInfor: response.personalInfor });
    } catch (error) {
      handleApiErrors(error.response);
    }

    setIsUpdatingDriverLicense(false);
  };

  const isSameUser = currentAuthUser.profile._id === userDetails._id;

  return (
    <Box>
      <Helmet>
        <title>{formatPageTitle('Driver License')}</title>
      </Helmet>

      <Row align="middle" gutter={[16, 16]}>
        <Col>
          <Text variant="h5">{t('driverLicense')}</Text>
        </Col>

        {userComplianceDetails?.driverLicenseComplianceStatus && (
          <Col>
            <StatusTag status={userComplianceDetails.driverLicenseComplianceStatus} />
          </Col>
        )}

        {userComplianceDetails?.driverLicenseComplianceStatus === STATUS_LIST.Status.APPROVED && (
          <Col>
            <HelpIcon variant="info" hint={t('modifyingInsuranceDriverLicenseWarning')} />
          </Col>
        )}
      </Row>

      <Form
        onFinish={handleUpdateUserDriverLicense}
        initialValues={{ licenseEntry, licenseNumber }}
      >
        <FormItem name="licenseEntry" label={t('state')}>
          <Select
            showSearch
            optionFilterProp="children"
            autoComplete="new-password"
            placeholder={t('selectState')}
            disabled={isUpdatingDriverLicense}
            options={COUNTRY_STATES}
          />
        </FormItem>

        <FormItem name="licenseNumber" label={t('licenseNumber')}>
          <TextInput disabled={isUpdatingDriverLicense} />
        </FormItem>

        <Upload
          type={imageList.length === MAX_FILE_COUNT || !isSameUser ? 'select' : 'drag'}
          name="files"
          maxCount={MAX_FILE_COUNT}
          disabled={!isSameUser || isUpdatingDriverLicense || isUploadingFile}
          accept="application/pdf,image/*"
          listType="text"
          fileList={imageList}
          showUploadList={false}
          beforeUpload={validateImage}
          onChange={handleFileChange}
          onPreview={handlePreview}
          onRemove={handleFileRemove}
          onDownload={handleFileDownload}
          method="post"
          action={`${process.env.REACT_APP_HOST_API}user/upload-driver-license`}
          headers={{ Authorization: `Bearer ${accessToken}` }}
        >
          {imageList.length < MAX_FILE_COUNT && isSameUser ? (
            <Row justify="center">
              <Col>
                <Button loading={isUploadingFile} type="secondary">
                  Upload
                </Button>
              </Col>
            </Row>
          ) : null}
        </Upload>

        <br />

        <Text variant="h5">{t('driverLicenseHistory')}</Text>

        <UploadedFilesTable
          t={t}
          loading={isUploadingFile}
          dataSource={imageList}
          actions={{
            canDownload: true,
            download: handleFileDownload,
            canRemove: isSameUser,
            remove: handleFileRemove,
          }}
        />

        <br />

        {isSameUser && (
          <Row justify="end" align="middle" wrap={false} gutter={10}>
            {userComplianceDetails?.driverLicenseComplianceStatus ===
              STATUS_LIST.Status.APPROVED && (
              <Col>
                <FadedText>{t('modifyingInsuranceDriverLicenseWarning')}</FadedText>
              </Col>
            )}
            <Col>
              <SubmitCancelButtonGroup
                isForm
                hideCancel
                loading={isUpdatingDriverLicense}
                submitText={t('updateDriverLicense')}
              />
            </Col>
          </Row>
        )}
      </Form>
    </Box>
  );
};

export default withNamespaces()(DriverLicenseView);
