import { Col, Row } from 'antd';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { Trans } from 'react-i18next';

import { STATUS_LIST } from '../../../enum';
import useDebouncedState from '../../../hooks/useDebouncedState';
import useDidUpdateEffect from '../../../hooks/useDidUpdateEffect';
import {
  checkIfStringContainsValue,
  getUserFullName,
  momentEST,
  momentFormat,
} from '../../../utils/common';
import { getUserRoleLabel } from '../../../utils/users';
import { Button, CustomTable, HelpIcon } from '../..';
import ProfileNamePicture from '../../shared-ui/ProfileNamePicture';
import StatusTag from '../../Tag/StatusTag';
import Text from '../../Text';

const DashboardUsersTable = props => {
  const {
    t,
    dataSource,
    isSendingEmail,
    resendActivationEmail,
    sendAppLink,
    isImpersonating,
    onImpersonate,
    onGetMobileConfig,
    isGettingMobileConfig,
    usersMobileAppConfigs,
    ...rest
  } = props;

  const [debouncedSearchTerm, setSearchTerm] = useDebouncedState();

  const filteredTable = useMemo(() => {
    let array = dataSource;

    if (debouncedSearchTerm) {
      array = array.filter(user => {
        return (
          checkIfStringContainsValue(
            STATUS_LIST.StatusTagColorConfig[user.status].label,
            debouncedSearchTerm,
          ) ||
          checkIfStringContainsValue(getUserFullName(user), debouncedSearchTerm) ||
          checkIfStringContainsValue(user.email, debouncedSearchTerm)
        );
      });
    }

    return array;
  }, [debouncedSearchTerm, dataSource]);

  useDidUpdateEffect(() => {
    if (typeof props.onSearchTermChange === 'function') {
      props.onSearchTermChange(debouncedSearchTerm);
    }
  }, [props.onSearchTermChange, debouncedSearchTerm]);

  const COLUMNS = [
    {
      width: 200,
      key: ['firstName', 'lastName'].join(','),
      title: t('Name'),
      ellipsis: true,
      defaultSortOrder: 'ascend',
      render: (actions, data) => <ProfileNamePicture t={t} showStatus={false} user={data} />,
      sorter: true,
    },
    {
      width: 200,
      key: 'email',
      dataIndex: 'email',
      title: t('Email'),
      ellipsis: true,
      sorter: true,
    },
    {
      width: 100,
      title: t('role'),
      dataIndex: 'role',
      key: 'role',
      render: role => getUserRoleLabel(role),
      sorter: true,
    },
    {
      width: 170,
      key: 'lastInvite',
      title: t('Last Invite Sent'),
      dataIndex: 'lastInvite',
      sorter: true,
      render: lastInvite => {
        if (!lastInvite) return '-';

        return (
          <Row wrap={false} gutter={8}>
            <Col>{momentFormat(lastInvite, 'MM/DD/YYYY')}</Col>
            <Col>
              <HelpIcon style={{ minWidth: 115 }} hint={momentFormat(lastInvite, 'hh:mm a (zz)')} />
            </Col>
          </Row>
        );
      },
    },
    {
      width: 130,
      key: 'status',
      title: t('Status'),
      render: (actions, data) => <StatusTag status={data.status} />,
      sorter: true,
    },
    {
      width: 470,
      key: 'actions',
      title: '',
      render: (actions, data) => {
        const userMobileAppConfig = usersMobileAppConfigs[data._id];
        return (
          <Row wrap={false} gutter={10} align="middle">
            {typeof onImpersonate === 'function' && (
              <Col>
                <Button
                  size="sm"
                  type="secondary"
                  disabled={isImpersonating || isSendingEmail || isGettingMobileConfig}
                  loading={isImpersonating}
                  onClick={() => onImpersonate(data._id)}
                >
                  {t('impersonate')}
                </Button>
              </Col>
            )}
            {typeof onGetMobileConfig === 'function' && (
              <>
                <Col>
                  <Button
                    size="sm"
                    type="secondary"
                    disabled={isImpersonating || isSendingEmail}
                    loading={isGettingMobileConfig}
                    onClick={() => onGetMobileConfig(data._id)}
                  >
                    {t('getMobileDeviceConfig')}
                  </Button>
                </Col>
                {userMobileAppConfig && (
                  <Col>
                    <HelpIcon
                      hint={
                        <div>
                          <p>
                            {t('lastSent_date', {
                              date: momentFormat(
                                userMobileAppConfig.created,
                                'MM-DD-YYYY - hh:mma (z)',
                              ),
                            })}
                          </p>
                          <p>
                            <Trans
                              t={t}
                              i18nKey="deliveryStatus_status"
                              values={{
                                status: userMobileAppConfig.success ? t('Success') : t('Fail'),
                              }}
                              components={[
                                <Text
                                  size="sm"
                                  renderAs="span"
                                  variant="strong"
                                  color={userMobileAppConfig.success ? 'primary' : 'red'}
                                >
                                  DummyText
                                </Text>,
                              ]}
                            />
                          </p>
                          <br />
                          <p>{t('getMobileDeviceConfig_hint')}</p>
                        </div>
                      }
                    />
                  </Col>
                )}
              </>
            )}
          </Row>
        );
      },
    },
    {
      width: 250,
      key: 'sendInviteAction',
      title: '',
      render: (actions, data) => {
        const isUserInactive = data.status !== STATUS_LIST.Status.ACTIVE;

        if (isUserInactive && data.sendActivationEmailDate) {
          return t('activationEmailScheduleFor___date', {
            date: momentEST(data.sendActivationEmailDate).format('LLL zz'),
          });
        }

        return (
          <Button
            block
            size="sm"
            type="secondary"
            disabled={isSendingEmail || isImpersonating}
            loading={isSendingEmail}
            onClick={() => {
              if (isUserInactive) {
                resendActivationEmail(data.email);
              } else {
                sendAppLink(data._id);
              }
            }}
            style={{ marginLeft: 'auto' }}
          >
            {isUserInactive ? 'Resend Activation Email' : 'Send Link to App'}
          </Button>
        );
      },
    },
  ];

  return (
    <CustomTable
      scroll={{ x: 900 }}
      {...rest}
      rowKey={data => data._id}
      onSearchTermChange={setSearchTerm}
      dataSource={filteredTable}
      columns={COLUMNS}
    />
  );
};

DashboardUsersTable.propTypes = {
  t: PropTypes.func.isRequired,
  dataSource: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isSendingEmail: PropTypes.bool,
  resendActivationEmail: PropTypes.func,
  sendAppLink: PropTypes.func,
};

DashboardUsersTable.defaultProps = {
  sendAppLink: () => undefined,
  resendActivationEmail: () => undefined,
  userMobileAppConfigs: {},
};

export default DashboardUsersTable;
