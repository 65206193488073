export const RATE_FORM_WIZARD_STEPS = {
  RATE_FORM: '1',
  SELECT_STANDARD_VEHICLE: '2',
  COMPLIANCE: '3',
  REVIEW: '4',
};

export const APPROVAL_CONFIG_VALUES = {
  MANUAL: 'manual',
  SEMI: 'semi',
  AUTO: 'auto',
  AUTO_VALUES: {
    MILES_LESS_THAN: 1,
    CUSTOM: 2,
  },
};

export const SEMI_AUTO_VALUES = {
  DISTANCE: 'distance',
  GPS: 'gps_percentage',
  REIMBURSEMENT: 'reimbursement',
};

export const RATE_TYPES = {
  FAVR: 'favr',
  GOVT: 'govt',
  FLAT: 'flat',
  TIERED: 'band',
};

export const RATE_TYPE_OPTIONS = [
  {
    label: 'FAVR Rate',
    value: RATE_TYPES.FAVR,
  },
  {
    label: 'Flat / CPM Rate',
    value: RATE_TYPES.FLAT,
  },
  {
    label: 'Government Rate',
    value: RATE_TYPES.GOVT,
  },
  {
    label: 'Tiered Rate',
    value: RATE_TYPES.TIERED,
  },
];
