import { merge, omit } from 'lodash';
import { useState } from 'react';
import { useQueryClient } from 'react-query';
import { useHistory, useLocation } from 'react-router-dom';

import { handleApiErrors } from '../../api/axiosInstance';
import { FAVR_COMPLIANCES_API } from '../../api/favr-compliances';
import { STATUS_LIST } from '../../enum';
import usePaginatedComplianceWorklist from '../../hooks/queries/usePaginatedComplianceWorklist';
import { replaceValueInArrayByID } from '../../utils/common';
import { cleanQueryParams, parseQueryParams } from '../../utils/queryParams';

const useComplianceWorklist = () => {
  const history = useHistory();
  const location = useLocation();

  const queryClient = useQueryClient();
  const [pageFilter, setPageFilter] = useState(
    location.search
      ? parseQueryParams(location.search)
      : {
          companyId: null,
          status: null,
          inGracePeriod: false,
          searchTerm: '',
        },
  );
  const [isAddingNote, setIsAddingNote] = useState(false);

  const {
    query: complianceWorklistQuery,
    paginationConfig,
    handlePageChange,
    handlePageSizeChange,
  } = usePaginatedComplianceWorklist(pageFilter);

  const _updateComplianceItem = (_id, newValues) => {
    queryClient.setQueryData(
      [
        'favr-compliances',
        ...Object.values(pageFilter),
        paginationConfig.current,
        paginationConfig.pageSize,
      ],
      merge(complianceWorklistQuery.data, {
        documents: replaceValueInArrayByID(
          complianceWorklistQuery.data.documents,
          { _id, ...newValues },
          '_id',
        ),
      }),
    );
  };

  const handleComplianceDownload = async complianceId => {
    try {
      await FAVR_COMPLIANCES_API.downloadFavrComplianceFiles(complianceId);
    } catch (error) {
      handleApiErrors(error.response);
    }
  };

  const handleComplianceApprove = async complianceId => {
    try {
      await FAVR_COMPLIANCES_API.updateComplianceDocumentStatus(complianceId, 'approved');
      _updateComplianceItem(complianceId, { status: 'approved' });
    } catch (error) {
      handleApiErrors(error.response);
    }
  };

  const handleComplianceDeny = async complianceId => {
    try {
      await FAVR_COMPLIANCES_API.updateComplianceDocumentStatus(complianceId, 'denied');
      _updateComplianceItem(complianceId, { status: STATUS_LIST.Status.NON_COMPLIANT });
    } catch (error) {
      handleApiErrors(error.response);
    }
  };

  const handleAddNoteToCompliance = async (complianceId, note, onSuccess) => {
    setIsAddingNote(true);

    try {
      const updatedNotes = await FAVR_COMPLIANCES_API.addNoteToFavrCompliance(
        complianceId,
        note,
        true,
      );
      queryClient.setQueryData(['fetchFavrComplianceNotes', complianceId], updatedNotes);
    } catch (error) {
      handleApiErrors(error.response);
    }

    setIsAddingNote(false);
  };

  const handleFiltersChange = newFilters => {
    setPageFilter(filter => {
      const updatedFilters = merge({ ...filter }, newFilters);
      history.replace({ search: cleanQueryParams(omit(updatedFilters, ['companyId'])) });
      return updatedFilters;
    });
  };

  return {
    isAddingNote,

    pageFilter,
    paginationConfig,
    handlePageChange,
    handlePageSizeChange,
    complianceWorklistQuery,

    handleFiltersChange,
    handleComplianceDownload,
    handleComplianceApprove,
    handleComplianceDeny,
    handleAddNoteToCompliance,
  };
};

export default useComplianceWorklist;
