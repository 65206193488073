import { Col, Row, Space } from 'antd';
import { get, has } from 'lodash';
import React, { useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { withNamespaces } from 'react-i18next';

import { DWOLLA_API, parseDwollaApiErrorMessage } from '../../../api/dwolla';
import { Button, ConfirmModal } from '../../../components';
import BankAccountRemovedAlert from '../../../components/Alert/BankAccountRemovedAlert';
import BankAccountSummary from '../../../components/BankAccountSummary';
import Box from '../../../components/Box';
import BankAccountFormModal from '../../../components/Modal/BankAccountFormModal';
import DwollaCustomerFormModal from '../../../components/Modal/DwollaCustomerFormModal';
import Text from '../../../components/Text';
import { IMAGES, STATUS_LIST } from '../../../enum';
import useDwollaBankAccount from '../../../hooks/useDwollaBankAccount';
import useModal from '../../../hooks/useModal';
import { formatPageTitle } from '../../../utils/common';
import HolidaysSection from './HolidaysSection';

const UserAchBankInfoView = props => {
  const { t, userDetails, onUserUpdate } = props;

  const [isCreatingCustomer, setIsCreatingCustomer] = useState(false);
  const [isBankModalVisible, openBankModal, closeBankModal] = useModal();
  const [isCustomerModalVisible, openCustomerModal, closeCustomerModal] = useModal();
  const [isDeleteModalVisible, openDeleteModal, closeDeleteModal] = useModal();

  const {
    isSavingBankAccount,
    isDeletingBankAccount,
    saveUserBankAccount,
    deleteUserBankAccount,
  } = useDwollaBankAccount(t, {
    onSaveBankAccountSuccess: closeBankModal,
    onDeleteBankAccountSuccess: closeDeleteModal,
  });

  const startBankAccountFlow = () => {
    if (!has(userDetails, 'dwollaPaymentInfo.customerId')) {
      openCustomerModal();
    } else {
      openBankModal();
    }
  };

  const handleUserBankAccountSave = async values => {
    const updatedUser = await saveUserBankAccount(values);
    if (updatedUser) {
      onUserUpdate(updatedUser);
    }
  };

  const handleUserBankAccountDelete = async fundingSourceId => {
    const updatedUser = await deleteUserBankAccount(fundingSourceId);
    if (updatedUser) {
      onUserUpdate(updatedUser);
    }
  };

  const handleCustomerCreate = async values => {
    setIsCreatingCustomer(true);

    try {
      const updatedUser = await DWOLLA_API.createUnverifiedCustomer(values);
      onUserUpdate(updatedUser);
      closeCustomerModal();
      openBankModal();
    } catch (error) {
      parseDwollaApiErrorMessage(error.response);
    }

    setIsCreatingCustomer(false);
  };

  const userFundingSource = useMemo(() => {
    return get(userDetails, 'dwollaPaymentInfo.fundingSource');
  }, [userDetails]);

  const userFundingSourceRemovalInfo = useMemo(() => {
    return get(userDetails, 'dwollaPaymentInfo.fundingSourceRemoval');
  }, [userDetails]);

  return (
    <React.Fragment>
      <Helmet>
        <title>{formatPageTitle('User Bank Account')}</title>
      </Helmet>

      {userFundingSourceRemovalInfo && (
        <BankAccountRemovedAlert t={t} removalReason={userFundingSourceRemovalInfo} />
      )}
      <Space direction="vertical" size="large" wrap={false}>
        <Box>
          <Row align="center" gutter={16} justify="space-between" style={{ marginBottom: 30 }}>
            <Col>
              <Text variant="h5">{t('bankAccount')}</Text>
            </Col>

            <Col>
              <Row gutter={16} align="middle" justify="end">
                {userFundingSource && (
                  <Col>
                    <Button size="sm" type="secondary" onClick={openDeleteModal}>
                      {t('deleteBankAccount')}
                    </Button>
                  </Col>
                )}

                <Col>
                  <Button
                    size="sm"
                    type="primary"
                    onClick={startBankAccountFlow}
                    icon={
                      <img
                        alt="plus"
                        src={IMAGES.WHITE_PLUS_ICON}
                        width="16px"
                        style={{ marginRight: 5 }}
                      />
                    }
                  >
                    {t(userFundingSource ? 'editBankAccount' : 'addBankAccount')}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>

          <BankAccountSummary t={t} fundingSource={userFundingSource} showStatus={false} />

          <BankAccountFormModal
            t={t}
            visible={isBankModalVisible}
            onCancel={closeBankModal}
            fundingSourceId={userFundingSource?.fundingSourceId}
            isSourceVerified={
              userFundingSource?.fundingSourceStatus === STATUS_LIST.Status.VERIFIED
            }
            initialValues={userFundingSource?.accountDetails}
            isSubmitting={isSavingBankAccount}
            onSubmit={handleUserBankAccountSave}
          />

          <DwollaCustomerFormModal
            t={t}
            visible={isCustomerModalVisible}
            onCancel={closeCustomerModal}
            initialValues={userDetails}
            isSubmitting={isCreatingCustomer}
            onSubmit={handleCustomerCreate}
          />

          <ConfirmModal
            loading={isDeletingBankAccount}
            visible={isDeleteModalVisible}
            message={<Text variant="b">{t('deleteBankAccount?')}</Text>}
            onCancel={closeDeleteModal}
            cancelText={t('No')}
            onOk={() => handleUserBankAccountDelete(userFundingSource?.fundingSourceId)}
            okText={t('Yes')}
          />
        </Box>

        <HolidaysSection t={t} />
      </Space>
    </React.Fragment>
  );
};

export default withNamespaces()(UserAchBankInfoView);
