import { STATUS_LIST } from '../../../../enum';
import { getLatestAdjustments } from '../../../../utils/trip-receipts';

/**
 * Calculates the total adjusted amount
 *
 * @param {object} receipts Trips' receipts
 */
export const calculateTotalAdjustment = receipts => {
  let total = 0;

  receipts.forEach(receipt => {
    if (
      Array.isArray(receipt.adjustments) &&
      !!receipt.adjustments.length &&
      receipt.status !== STATUS_LIST.Status.DENIED
    ) {
      const firstAdjustment = receipt.adjustments[0];
      const latestAdjustement = getLatestAdjustments(receipt);

      if (latestAdjustement.actionType !== 'delete') {
        const receiptAdjustment =
          parseFloat(latestAdjustement.finalAmount) - parseFloat(firstAdjustment.previousAmount);
        total = parseFloat(total) + receiptAdjustment;
      }
    }
  });

  return Math.abs(total) === 0 ? 0 : total;
};
