import { filter, first } from 'lodash';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';

import { RATES_API } from '../../api/rates';
import { selectStoreCurrentCompany } from '../../utils/storeSelectors';

const useDefaultRate = () => {
  const currentCompany = useSelector(selectStoreCurrentCompany);

  return useQuery({
    staleTime: Infinity,
    placeholderData: {},
    enabled: !!currentCompany?._id,
    queryKey: ['fetchCompanyRates', currentCompany?._id, 'defaultRate'],
    queryFn: () => RATES_API.fetchCompanyRates(currentCompany._id, { defaultRate: true }),
    select: ({ documents }) => first(filter(documents, { active: true })),
  });
};

export default useDefaultRate;
