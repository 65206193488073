import 'react-phone-input-2/lib/style.css';

import { Col, Form, Row, Space } from 'antd';
import Select from 'components/Select';
import TextInput from 'components/TextInput';
import { FLEET_SIZE } from 'enum';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { saveCompany } from '../../stores/actions/common';
import { ERROR_MESSAGE } from '../../utils/constants';
import { selectStoreUniqueCountryCurrencies } from '../../utils/storeSelectors';
import AddressForm from '../AddressForm';
import CompanyLogoUploader from '../CompanyLogoUploader';
import FormItem from '../Form/FormItem';
import HorizontalLineDivider from '../HorizontalLineDivider';
import PhoneTextInput from '../PhoneTextInput';
import SubmitCancelButtonGroup from '../SubmitCancelButtonGroup';
import classNames from './style.module.scss';

const CompanyForm = ({
  t,
  isOnboarding,
  currentCompany,
  editableName,
  history,
  timezones,
  updateCompany,
  countries,
  ...rest
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const [companyInfo, setCompanyInfo] = useState({
    name: currentCompany.address ? currentCompany.name : '',
    country: currentCompany.address ? currentCompany.address.country : 'US',
    streetOne: currentCompany.address ? currentCompany.address.streetOne : '',
    streetTwo: currentCompany.address ? currentCompany.address.streetTwo : '',
    city: currentCompany.address ? currentCompany.address.city : '',
    state: currentCompany.address ? currentCompany.address.state : '',
    postalCode: currentCompany.address ? currentCompany.address.postalCode : '',
    numEmployee: currentCompany.numEmployee,
    phone: currentCompany.phone,
    preferredTimezone: currentCompany.preferredTimezone,
    currencyUnit: currentCompany.currencyUnit,
  });

  // eslint-disable-next-line
  const CURRENCIES = useMemo(() => selectStoreUniqueCountryCurrencies(), [countries]);

  const onSubmit = values => {
    updateCompany(values);
  };

  const handleValueChange = values => {
    setCompanyInfo(currentValues => {
      const updatedValues = { ...currentValues, ...values };
      form.setFieldsValue(updatedValues);
      return updatedValues;
    });
  };

  return (
    <Form
      form={form}
      name="details"
      initialValues={currentCompany ? companyInfo : null}
      labelCol={{ span: 24 }}
    >
      {editableName && (
        <Row>
          <Col xs={24}>
            <div className="form-row">
              <Form.Item
                label="Company Name"
                name="name"
                value={companyInfo.name}
                rules={[{ required: true, message: ERROR_MESSAGE.BLANK_FIELD }]}
              >
                <TextInput onChange={e => handleValueChange({ name: e.target.value })} />
              </Form.Item>
            </div>
          </Col>
        </Row>
      )}
      <Row>
        <Col xs={24}>
          <div className="form-row">
            <Form.Item
              label="Country"
              name="country"
              value={companyInfo.country}
              rules={[{ required: true, message: ERROR_MESSAGE.BLANK_FIELD }]}
            >
              <Select
                className="country-selector"
                onChange={country => handleValueChange({ country, state: undefined })}
                options={countries.map(c => ({
                  label: c.name,
                  value: c.code,
                }))}
                // NOTE: Using this to fully disable autocomplete of field on every browser
                autoComplete="new-password"
              />
            </Form.Item>
          </div>
        </Col>
      </Row>

      <AddressForm
        t={t}
        formInstance={form}
        companyAddress={companyInfo}
        countries={countries}
        hiddenFields={['country']}
        onValueChange={handleValueChange}
        values={companyInfo}
      />

      <HorizontalLineDivider marginTop={10} marginBottom={30} />

      <Row>
        <Col xs={24}>
          <div className="form-columns">
            <Space>
              <Row gutter={8} style={{ display: 'flex' }}>
                <Col xs={24} lg={12}>
                  <Form.Item
                    label="Number of drivers"
                    name="numEmployee"
                    value={companyInfo.numEmployee}
                    rules={[
                      {
                        required: true,
                        message: 'Number of drivers is required',
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      optionFilterProp="children"
                      onChange={e => handleValueChange({ numEmployee: e })}
                      options={FLEET_SIZE}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} lg={12}>
                  <div className={`${classNames.phoneAreaCode} phone-area-code`}>
                    <Form.Item
                      label="Phone"
                      name="phone"
                      value={companyInfo.phone}
                      rules={[
                        {
                          required: true,
                          message: 'Phone is required',
                        },
                      ]}
                    >
                      <PhoneTextInput
                        defaultValue={companyInfo.phone}
                        onChange={phone => handleValueChange({ phone })}
                      />
                    </Form.Item>
                  </div>
                </Col>
              </Row>
            </Space>
          </div>
        </Col>
      </Row>

      <Row>
        <Col xs={24}>
          <div className="form-columns">
            <Space>
              <Row gutter={8} style={{ display: 'flex' }}>
                <Col xs={24} lg={12}>
                  <Form.Item
                    label="Timezone"
                    name="preferredTimezone"
                    value={companyInfo.preferredTimezone}
                    rules={[
                      {
                        required: true,
                        message: 'Please choose your preferred timezone',
                      },
                    ]}
                  >
                    <Select
                      loading={!timezones.length}
                      disabled={!timezones.length}
                      showSearch
                      optionFilterProp="children"
                      onChange={e => handleValueChange({ preferredTimezone: e })}
                      options={timezones}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} lg={12}>
                  <Form.Item
                    label="Currency"
                    name="currencyUnit"
                    value={companyInfo.currencyUnit}
                    rules={[
                      {
                        required: true,
                        message: 'Please choose a currency',
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      optionFilterProp="children"
                      onChange={e => handleValueChange({ currencyUnit: e })}
                      options={CURRENCIES}
                      disabled={!isOnboarding}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Space>
          </div>
        </Col>
      </Row>

      <HorizontalLineDivider marginTop={10} marginBottom={30} />

      <Row>
        <FormItem
          name="logoImage"
          className="no-margin-bottom"
          label={`${t('companyLogo')} (${t('optional')})`}
          // help={t('logoWillBeDisplayedInMobileApp')}
        />

        <CompanyLogoUploader
          t={t}
          companyID={currentCompany._id}
          defaultImage={currentCompany?.logoImage?.filePath}
          onUpload={logoImage => {
            dispatch(saveCompany({ logoImage }));
          }}
        />
      </Row>

      <SubmitCancelButtonGroup
        style={{ display: 'flex' }}
        submitText="Save"
        onCancel={() => history.goBack()}
        onSubmit={() => form.validateFields().then(onSubmit)}
      />
    </Form>
  );
};

CompanyForm.propTypes = {
  currentCompany: PropTypes.object,
  timezones: PropTypes.array,
  updateCompany: PropTypes.func,
  countries: PropTypes.arrayOf(PropTypes.shape({})),
};

CompanyForm.defaultProps = {
  countries: [],
};

export default CompanyForm;
