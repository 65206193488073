import React from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { Col, DatePicker, Form, Row } from 'antd';

import { IMAGES } from '../../enum';

import classNames from './style.module.scss';

/**
 * Vacation forms
 */
const VacationForm = props => {
  const { t, vacation, onDelete, onDateChange, disabled, hasError } = props;

  /**
   * Handler for the date changes on any of the Date Pickers
   *
   * @param {"startDate" | "endDate"} dateType - Is this a start or end end date
   * @param {moment} date - Moment instance of the date
   */
  const handleDateChange = (dateType, date) => {
    if (typeof onDateChange === 'function') {
      onDateChange({ ...vacation, [dateType]: date });
    }
  };

  return (
    <Row gutter={17} align="middle">
      <Col flex="1 0 260px">
        <Form.Item
          label={t('From')}
          validateStatus={hasError ? 'error' : ''}
          help={hasError ? '' : undefined}
        >
          <DatePicker
            disabled={disabled}
            style={{ width: '100%' }}
            onChange={date => handleDateChange('startDate', date)}
            defaultValue={vacation.startDate ? moment(vacation.startDate) : undefined}
          />
        </Form.Item>
      </Col>

      <Col flex="1 0 260px">
        <Form.Item
          label={t('To')}
          validateStatus={hasError ? 'error' : ''}
          help={hasError ? t('Start date cannot be after End date') : undefined}
        >
          <DatePicker
            disabled={disabled}
            style={{ width: '100%' }}
            onChange={date => handleDateChange('endDate', date)}
            defaultValue={vacation.endDate ? moment(vacation.endDate) : undefined}
          />
        </Form.Item>
      </Col>

      <Col>
        <img
          alt="Trash"
          src={IMAGES.TRASH_ICON}
          onClick={!disabled ? onDelete : undefined}
          className={classNames.trashIcon}
        />
      </Col>
    </Row>
  );
};

VacationForm.propTypes = {
  t: PropTypes.func.isRequired,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
};

export default VacationForm;
