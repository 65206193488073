import React, { Component } from 'react';
import './style.scss';
import { Form } from 'antd';
import ReactCodeInput from 'react-verification-code-input';
import Button from '../../Button';

class NumberVerificationModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pin: '',
    };
  }

  onChange = value => {
    this.setState({ pin: value });
  };

  onSubmit = () => {
    const { pin } = this.state;
    this.props.onSubmit({
      pin,
    });
  };

  render() {
    const {
      className = '',
      visible,
      onClose,
      phoneNumber,
      pinValid,
      primaryButtonText,
      secondaryButtonText,
    } = this.props;

    if (!visible) {
      return null;
    }

    return (
      <div className={`verification-modal ${className}`}>
        <div className={`verification-modal-content`}>
          <h5 className="account-form-modal-title">
            To continue, please enter the 4-digit verification code sent to{' '}
            <span>your phone +{phoneNumber}</span>
          </h5>

          <Form initialValues={{ phone: phoneNumber }} ref={node => (this.accountForm = node)}>
            <Form.Item
              name="pin"
              validateStatus={pinValid.validateStatus || 'success'}
              help={pinValid.errorMsg}
              rules={[{ required: true, message: 'Please enter the correct pin!' }]}
            >
              <ReactCodeInput
                onChange={this.onChange}
                fields={4}
                className={'verification-modal-input'}
              />
            </Form.Item>
          </Form>

          <div className={'button-container'}>
            <Button
              type="primary"
              className="verification-modal-content-primary-button"
              size="large"
              onClick={this.onSubmit}
              block
            >
              {primaryButtonText}
            </Button>
            <Button
              className="verification-modal-content-secondary-button"
              size="large"
              type="secondary"
              onClick={onClose}
              block
            >
              {secondaryButtonText}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default NumberVerificationModal;
