export const StorageKeys = {
  lastUserLookupSelection: 'lastUserLookupSelection',
};

export const StorageUtils = {
  lastUserLookupSelection: {
    get: () => sessionStorage.getItem(StorageKeys.lastUserLookupSelection),
    remove: () => sessionStorage.removeItem(StorageKeys.lastUserLookupSelection),
    /**
     *
     * @param {object} option
     * @param {string} option.value
     * @param {string} option.children
     */
    set: option => {
      if (option) {
        sessionStorage.setItem(
          StorageKeys.lastUserLookupSelection,
          JSON.stringify({
            value: option.value,
            label: option.children,
          }),
        );
      }
    },
  },
};
