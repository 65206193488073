import { Col, Row } from 'antd';
import moment from 'moment-timezone';
import React from 'react';
import { Link } from 'react-router-dom';

import { IMAGES, INTERNAL_LINKS } from '../../enum';
import Text from '../Text';
import FadedText from '../Text/FadedText';
import LinkText from '../Text/LinkText';

/**
 * Renders the a User activity event summary item
 * used on a Profile's activity page
 *
 * @param {object} props Component props
 */
const UserActivitySummaryRow = props => {
  const { activity, iconSize, textSize } = props;

  const { activityType, count, date, id, seqId } = activity;

  const VALID_TYPES = {
    LOGIN: 'login',
    TRIP: 'trip',
    TRIP_RECEIPT: 'trip-receipt',
    REIMBURSEMENT: 'reimbursement',
    VACATION_START: 'vacations_start',
    VACATION_END: 'vacations_end',
    EMAIL: 'email',
    EMAIL_REMINDER: 'email_reminder',
    CUSTOM: 'custom',
  };

  const VALUES_PER_TYPE = {
    [VALID_TYPES.LOGIN]: {
      icon: IMAGES.KEY_ICON,
      text: 'Logged into Kliks',
    },
    [VALID_TYPES.TRIP]: {
      icon: IMAGES.CAR_RETRO_DARK_ICON,
      text: `Completed ${count} ${count > 1 ? 'trips' : 'trip'}`,
    },
    [VALID_TYPES.TRIP_RECEIPT]: {
      icon: IMAGES.MONEY_BAG_DARK_ICON,
      text: `Submitted ${count} ${count > 1 ? 'receipts' : 'receipt'}`,
    },
    [VALID_TYPES.REIMBURSEMENT]: {
      icon: IMAGES.MONEY_BAG_DARK_ICON,
      text: (
        <>
          Received reimbursement{' '}
          {seqId && (
            <Link to={`${INTERNAL_LINKS.REIMBURSEMENT}/${id}`}>
              <LinkText size="sm" variant="strong">
                {seqId}
              </LinkText>
            </Link>
          )}
        </>
      ),
    },
    [VALID_TYPES.VACATION_START]: {
      icon: IMAGES.KEY_ICON,
      text: 'Went on vacation',
    },
    [VALID_TYPES.VACATION_END]: {
      icon: IMAGES.KEY_ICON,
      text: 'Returned from vacation',
    },
    [VALID_TYPES.EMAIL]: {
      icon: IMAGES.BELL_ICON,
      text: 'Received Email invitation',
    },
    [VALID_TYPES.EMAIL_REMINDER]: {
      icon: IMAGES.BELL_ICON,
      text: 'Received reminder Email invitation',
    },
    [VALID_TYPES.CUSTOM]: {
      icon: activity.icon,
      text: activity.text,
    },
  };

  return (
    <Row style={{ padding: '19px 0' }} gutter={16} align="middle">
      <Col flex={iconSize || '16px'} style={{ display: 'flex' }}>
        <img width={iconSize || '16px'} src={VALUES_PER_TYPE[activityType].icon} alt="event icon" />
      </Col>
      <Col flex={1}>
        <Text color="secondary" variant="p" size={textSize || 'sm'} renderAs="div">
          {VALUES_PER_TYPE[activityType].text}
        </Text>
      </Col>
      <Col style={{ width: 'max-content' }}>
        <FadedText variant="strong" size="sm" renderAs="div">
          {moment(date).fromNow()}
        </FadedText>
      </Col>
    </Row>
  );
};

export default UserActivitySummaryRow;
