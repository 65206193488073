import { Form } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { ERROR_MESSAGE, NAME_MAX_CHARACTER } from 'utils/constants';

import { USER_NAME_VALIDATION_REGEXP } from '../../utils/validate';
import TextInput from '../TextInput';

const MAX_LENGTH = NAME_MAX_CHARACTER + 1;

const NameInput = ({ name, disabled, ...rest }) => {
  return (
    <div>
      <Form.Item
        name={name}
        {...rest}
        validateTrigger={['onSubmit', 'onBlur']}
        rules={[
          {
            required: true,
            message:
              name === 'firstName'
                ? `Please enter your first name.`
                : name === 'lastName'
                ? `Please enter your last name.`
                : ERROR_MESSAGE.BLANK_FIELD,
          },
          {
            min: 2,
            message: `Must be at least 2 characters long.`,
          },
          {
            pattern: USER_NAME_VALIDATION_REGEXP,
            message: 'Number or special characters are not allowed.',
          },
          {
            max: NAME_MAX_CHARACTER,
            message: `Cannot exceed more than ${NAME_MAX_CHARACTER} characters.`,
          },
        ]}
      >
        <TextInput
          disabled={disabled}
          maxLength={MAX_LENGTH}
          placeholder={
            !rest.label && name === 'firstName'
              ? 'First Name'
              : !rest.label && name === 'lastName'
              ? 'Last Name'
              : null
          }
        />
      </Form.Item>
    </div>
  );
};

NameInput.propTypes = {
  disabled: PropTypes.bool,
};

NameInput.defaultProps = {
  disabled: false,
};

export default NameInput;
