import { get } from 'lodash';

import { RECEIPT_TYPES, STATUS_LIST } from '../../enum';
import {
  hasCompanyAdminRole,
  hasCompanyManagerOrAdminRole,
  hasCompanyManagerRole,
} from '../../utils/roles';
import { selectStoreCurrentAuthUser, selectStoreCurrentCompany } from '../../utils/storeSelectors';

export const canUpdateReceiptStatus = (loggedInUserData, receipt, skipManagerCheck = false) => {
  if (!loggedInUserData) return false;
  if (receipt && [STATUS_LIST.Status.PAID, STATUS_LIST.Status.NOT_PAID].includes(receipt.status)) {
    return false;
  }

  const company = selectStoreCurrentCompany();

  if (!company) return false;
  if (
    company.companySettingId &&
    company.companySettingId.settings &&
    !company.companySettingId.settings.approveReceipt
  )
    return false;

  if (!hasCompanyManagerOrAdminRole(loggedInUserData)) return false;

  if (!skipManagerCheck && hasCompanyManagerRole(loggedInUserData)) {
    if (!receipt || !receipt.submittedBy) return false;
  }

  return true;
};

export const canResubmitReceiptForApproval = (loggedInUserData, receipt) => {
  if (![STATUS_LIST.Status.DENIED].includes(receipt?.status)) return false;
  if (receipt.type !== RECEIPT_TYPES.FIXED_TYPE) return false;

  return receipt?.submittedBy?._id === loggedInUserData?.profile?._id;
};

export const canAdjustReceipt = loggedInUserData => {
  return hasCompanyAdminRole(loggedInUserData);
};

export const canDeleteReceipt = receiptData => {
  const loggedInUserData = selectStoreCurrentAuthUser();

  return (
    hasCompanyAdminRole(loggedInUserData) &&
    get(receiptData, 'type') === RECEIPT_TYPES.FIXED_TYPE &&
    get(receiptData, 'status') !== STATUS_LIST.Status.PAID
  );
};
