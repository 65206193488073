import { Col, DatePicker, Form, Row } from 'antd';
import moment from 'moment-timezone';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';

import { updateUserObdDeviceApiCall } from '../../../api/user';
import AddressForm from '../../../components/AddressForm';
import Box from '../../../components/Box';
import Button from '../../../components/Button';
import HorizontalLineDivider from '../../../components/HorizontalLineDivider';
import Tag from '../../../components/Tag';
import Text from '../../../components/Text';
import TextInput from '../../../components/TextInput';
import Toast from '../../../components/Toast';
import classNames from '../../../components/UserProfileFormSection/style.module.scss';
import { formatPageTitle, momentFormat } from '../../../utils/common';
import { selectStoreCountryByCode } from '../../../utils/storeSelectors';
import { validateAddress } from '../profile/profile-validation';
import { canUpdateShippingAddress } from './obd-device-permissions';

/**
 * User OBD Device page
 */
const UserDetailsObdDeviceView = props => {
  const { t, myProfile, userDetails, onUserUpdate, isUserDeleted } = props;

  const { currentCompany, supportedCountries } = useSelector(state => state.common);

  const [isUpdatingDevice, setIsUpdatingDevice] = useState(false);

  const [deviceID, setDeviceID] = useState(userDetails.obdDeviceID);
  const [shipDate, setShipDate] = useState(moment(userDetails.shipDate) || moment());
  const [isDeviceConnected] = useState(userDetails.obdConnected);
  const [shipTo, setShippedTo] = useState(userDetails.shipTo || {});

  const handleDeviceUpdate = async () => {
    if (canUpdateShippingAddress(myProfile) && !validateAddress(shipTo, t('Shipping Address'))) {
      return;
    }

    setIsUpdatingDevice(true);
    await updateUserObdDeviceApiCall(
      {
        userID: userDetails._id,
        device: {
          deviceID,
          shipTo,
          shipDate: shipDate.toISOString(),
        },
      },
      apiResult => {
        const { data } = apiResult.data;
        onUserUpdate(data);

        Toast({
          type: 'open',
          message: 'Device updated successfully',
        });
      },
      () => {
        Toast({
          type: 'error',
          message: 'Unable to update device',
        });
      },
    );

    setIsUpdatingDevice(false);
  };

  const AddressOrder = ['streetOne', 'streetTwo', 'state', 'city', 'postalCode', 'country'];

  const bottomBoxContent = [
    {
      label: t('Shipped On'),
      value: userDetails.shipDate ? momentFormat(userDetails.shipDate, 'DD MMMM, YYYY') : null,
    },
    {
      label: t('Shipped To'),
      value: !!Object.keys(shipTo).length
        ? AddressOrder.map(address => {
            if (shipTo[address]) {
              let value = shipTo[address];
              if (address === 'country') {
                const country = selectStoreCountryByCode(shipTo.country);

                if (country) {
                  value = country.name;
                }
              }

              if (address === 'state') {
                const country = selectStoreCountryByCode(shipTo.country);

                if (country && Array.isArray(country.states)) {
                  const state = country.states.find(st => st.value === shipTo[address]);

                  if (state) {
                    value = state.label;
                  }
                }
              }

              return (
                <React.Fragment key={address}>
                  <Text size="sm" variant="p" renderAs="span">
                    {value}
                  </Text>
                  <br />
                </React.Fragment>
              );
            }

            return null;
          })
        : null,
    },
  ];

  return (
    <div>
      <Helmet>
        <title>{formatPageTitle('OBD Device')}</title>
      </Helmet>

      <Row gutter={[0, 32]}>
        <Col span={24}>
          <Box>
            <Row gutter={[8, 17]} align="middle">
              <Col>
                <Text variant="h5" renderAs="div">
                  {t('OBD Device')}
                </Text>
              </Col>
              <Col>
                <Tag variant={isDeviceConnected ? 'primary' : 'red'}>
                  {isDeviceConnected ? t('Active') : t('Inactive')}
                </Tag>
              </Col>
            </Row>

            <Row gutter={17} style={{ alignItems: 'flex-end' }}>
              <Col xs={24} lg={12}>
                <Form.Item required label={t('Device Number')} labelCol={{ span: 24 }}>
                  <TextInput
                    disabled={isUpdatingDevice || isUserDeleted}
                    name="deviceID"
                    value={deviceID}
                    onChange={e => setDeviceID(e.target.value)}
                  />
                </Form.Item>

                {canUpdateShippingAddress(myProfile) && (
                  <Form.Item label={t('Shipped On')} labelCol={{ span: 24 }}>
                    <DatePicker
                      disabled={isUpdatingDevice || isUserDeleted}
                      style={{ width: '100%' }}
                      onChange={date => setShipDate(date)}
                      defaultValue={shipDate}
                    />
                  </Form.Item>
                )}
              </Col>

              <Col xs={24} lg={12}>
                <Button
                  size="sm"
                  type="secondary"
                  disabled={isUpdatingDevice || isUserDeleted}
                  style={{ marginBottom: 24 }}
                >
                  {t('Ship new device')}
                </Button>
              </Col>
            </Row>

            {canUpdateShippingAddress(myProfile) ? (
              <>
                <Row>
                  <HorizontalLineDivider marginTop={8} marginBottom={32} />
                </Row>

                <Row gutter={[0, 17]}>
                  <Text variant="h5">{t('Shipping Address')}</Text>
                </Row>

                <Row gutter={[0, 17]}>
                  <AddressForm
                    t={t}
                    countries={supportedCountries}
                    values={shipTo}
                    companyAddress={currentCompany && currentCompany.address}
                    formDisabled={isUpdatingDevice || isUserDeleted}
                    onValueChange={value => setShippedTo({ ...shipTo, ...value })}
                  />
                </Row>
              </>
            ) : (
              <Col xs={24} lg={12}>
                {bottomBoxContent.map(content => (
                  <Row
                    key={content.label}
                    className={classNames.bottomBoxRow}
                    justify="space-between"
                  >
                    <Col>
                      <Text variant="strong" size="sm" renderAs="div">
                        {content.label}
                      </Text>
                    </Col>
                    <Col>
                      <Text variant="p" size="sm" renderAs="div" textAlign="right">
                        {content.value || '??'}
                      </Text>
                    </Col>
                  </Row>
                ))}
              </Col>
            )}
            <br />

            <Row justify="end">
              <Button
                loading={isUpdatingDevice}
                disabled={isUpdatingDevice || isUserDeleted}
                onClick={handleDeviceUpdate}
              >
                {t('Update OBD Settings')}
              </Button>
            </Row>
          </Box>
        </Col>
      </Row>
    </div>
  );
};

export default UserDetailsObdDeviceView;
