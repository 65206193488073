import { Col, Row, Space } from 'antd';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';

import Text from '../../../components/Text';
import LinkText from '../../../components/Text/LinkText';
import CompanyAdminPreviewTooltip from '../../../components/Tooltips/CompanyAdminPreviewTooltip';
import { INTERNAL_LINKS, STATUS_LIST } from '../../../enum';
import useAdminsAndManagersUsersLists from '../../../hooks/useAdminsAndManagersUsersLists';
import { getUserFullName, momentFormat } from '../../../utils/common';
import EditFreeTrialSection from './EditFreeTrialSection';

const CompanyDetailsSummary = props => {
  const {
    t,
    totalUsersCount,
    usersCountByStatus,
    currentCompany,
    onImpersonate,
    isRequestingImpersonation,
  } = props;

  const { companyAdminsList } = useAdminsAndManagersUsersLists();

  const [activeUsersCount, pendingUsersCount, inactiveUsersCount] = useMemo(() => {
    let active = 0;
    let pending = 0;
    let inactive = 0;
    let deleted = 0;

    if (Array.isArray(usersCountByStatus)) {
      usersCountByStatus.forEach(({ count, status }) => {
        if (status === STATUS_LIST.Status.ACTIVE) active = count;
        if (status === STATUS_LIST.Status.PENDING) pending = count;
        if (status === STATUS_LIST.Status.INACTIVE) inactive = count;
        if (status === STATUS_LIST.Status.DELETED) deleted = count;
      });
    }

    return [active, pending, inactive, deleted];
  }, [usersCountByStatus]);

  const ADMIN_USERS_SECTION =
    companyAdminsList.length > 0 ? (
      <Text>
        {t('companyAdmin_plural')}:{' '}
        {companyAdminsList
          .map(user => (
            <CompanyAdminPreviewTooltip
              t={t}
              key={user._id}
              user={user}
              isRequestingImpersonation={isRequestingImpersonation}
              onImpersonate={() => onImpersonate(user._id)}
            >
              <Link to={`${INTERNAL_LINKS.USER_DETAILS}/${user._id}/profile`}>
                <LinkText>{getUserFullName(user)}</LinkText>
              </Link>
            </CompanyAdminPreviewTooltip>
          ))
          .reduce((prevElements, el) => {
            if (prevElements) {
              return [prevElements, ', ', el];
            }

            return [el];
          }, null)}
      </Text>
    ) : null;

  return (
    <Row gutter={16} align="middle" justify="space-between" wrap>
      <Col flex={1}>
        <Space size="small" direction="vertical" wrap={false}>
          <div>
            <Text>
              {t('createdWithDate', {
                date: momentFormat(currentCompany?.created, 'MMM DD, YYYY (hh:mma z)'),
              })}
            </Text>

            {ADMIN_USERS_SECTION}
          </div>

          <EditFreeTrialSection t={t} currentCompany={currentCompany} />
        </Space>
      </Col>

      <Col flex={1}>
        <Space size="middle" direction="vertical" wrap={false}>
          <Text>
            {t('totalNumberUsers')}: {totalUsersCount}
          </Text>

          <div>
            <Text>
              {t(STATUS_LIST.Status.ACTIVE)}: {activeUsersCount}
            </Text>
            <Text>
              {t(STATUS_LIST.Status.PENDING)}: {pendingUsersCount}
            </Text>
            <Text>
              {t(STATUS_LIST.Status.INACTIVE)}: {inactiveUsersCount}
            </Text>
          </div>
        </Space>
      </Col>
    </Row>
  );
};

CompanyDetailsSummary.defaultProps = {
  usersCountByStatus: [],
};

export default CompanyDetailsSummary;
