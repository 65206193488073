export { default as ACTIONS_MAP } from './ActionsMap';
export { default as APPROVAL_TYPES } from './AutomaticApprovalTypes';
export { default as EXTERNAL_LINKS } from './ExternalLinks';
export { default as FLEET_SIZE } from './FleetSize';
export { default as INTERNAL_LINKS } from './InternalLinks';
export { default as MENU_LIST } from './MenuList';
export { default as POLICY } from './Policy';
export { default as RESTRICTED } from './Restricted';
export { default as STATUS_LIST } from './StatusList';
// export { default as SUPPORTED_COUNTRIES } from './SupportedCountries';
// export { default as SUPPORTED_CURRENCIES } from './SupportedCurrencies';
export { default as IMAGES } from './Images';
export { default as PAYMENT_CONF } from './PaymentStatus';
export { default as PLANS } from './Plans';
export { default as RECEIPT_STATUS } from './ReceiptStatus';
export { default as RECEIPT_TYPES } from './ReceiptTypes';
export { default as TASK_STATUS } from './TaskStatus';
export { default as TASK_TYPES } from './TaskTypes';
export { default as THEMES } from './Themes';
export { default as TRIP_SOURCES } from './TripSources';
export { default as TRIP_CONF } from './TripStatus';
export { default as USER_REG_STATUS } from './UserRegStatus';
export { default as USER_RIGHT } from './UserRight';
export { default as USER_ROLES } from './UserRoles';
export { default as USER_STATUS } from './UserStatus';
export { default as US_STATES } from './USStates';
export { default as VEHICLE } from './Vehicle';

export const DAILY_MILEAGE_ENTRY_MODES = {
  DAILY_MILEAGE_LOG: 'daily-mileage-log',
  TRIP_BASED: 'trip-based',
};
