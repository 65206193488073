import { Col, Row } from 'antd';
import { INTERNAL_LINKS } from 'enum';
import React from 'react';
import { Helmet } from 'react-helmet';
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { updateProfile } from 'stores/actions/profile';

import { signInUser } from '../../api/auth';
import { handleApiCalls, handleApiErrors } from '../../api/axiosInstance';
import FullLogo from '../../components/FullLogo';
import RegistrationForm from '../../components/RegistrationForm';
import FadedText from '../../components/Text/FadedText';
import LinkText from '../../components/Text/LinkText';
import Toast from '../../components/Toast';
import { formatPageTitle } from '../../utils/common';
import { setAnalyticsUserProperties } from '../../utils/googleAnalytics';
import { PENDO_ANALYTICS } from '../../utils/pendoAnalytics';
import { normalizeUserSchema } from '../../utils/users';
import classNames from './styles.module.scss';

class UserAccountActivate extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      firstName: '',
      lastName: '',
      email: '',
      status: '',
      companyID: '',
      fetchFlag: false,
      showError: false,
      activatingAcount: false,
      errorType: 0, // 0: normal error, 1: show resend email link, 2: un-show resend email link
    };
  }

  componentDidMount() {
    this.handleVerification();
  }

  handleVerification = async () => {
    const { token } = this.props.match.params;
    try {
      let result = await handleApiCalls(
        'get',
        `${process.env.REACT_APP_HOST_API}auth/activate-account/${token}`,
      );
      if (result && result.data && result.data.success) {
        this.setState({
          firstName: result.data.data.firstName,
          lastName: result.data.data.lastName,
          email: result.data.data.email,
          status: result.data.data.status,
          companyID: result.data.data.companyID,
          fetchFlag: true,
          showError: false,
        });
      }
    } catch (err) {
      const IS_TOKEN_EXPIRED =
        err.response && err.response.data && /expired/gi.test(err.response.data);
      const IS_TOKEN_INVALID =
        err.response && err.response.data && /invalid/gi.test(err.response.data);
      const IS_ACTIVATED =
        err.response && err.response.data && /already activated/gi.test(err.response.data);

      if (err.response && (IS_TOKEN_EXPIRED || IS_TOKEN_INVALID)) {
        this.setState({
          showError: true,
          errorType: 1,
          fetchFlag: true,
        });
      } else if (IS_ACTIVATED) {
        this.setState({
          showError: true,
          errorType: 3,
          fetchFlag: true,
        });
      } else if (err.response && err.response.status === 404) {
        this.setState({
          showError: true,
          errorType: 0,
          fetchFlag: true,
        });
      } else {
        this.setState({
          showError: true,
          errorType: 0,
          fetchFlag: true,
        });
      }
    }
  };

  activate = async model => {
    const { token } = this.props.match.params;
    this.setState({ activatingAcount: true });

    try {
      let result = await handleApiCalls(
        'post',
        `${process.env.REACT_APP_HOST_API}auth/activate-account/${token}`,
        model,
      );

      if (result.status === 200) {
        Toast({
          type: 'success',
          message: this.props.t('accountActivationSuccess'),
        });

        await this.handleLogin(model);
        setTimeout(() => {
          this.props.history.push(INTERNAL_LINKS.DASHBOARD);
        }, 1000);
      }
    } catch (err) {
      if (
        err.response &&
        err.response.status === 422 &&
        (err.response.data === 'The activation link is expired.' ||
          err.response.data === 'It looks like you clicked on an expired link')
      ) {
        this.setState({
          showError: true,
          errorType: 1,
        });
      } else if (
        err.response &&
        err.response.status === 422 &&
        err.response.data === 'This account has been already activated.'
      ) {
        this.setState({
          showError: true,
          errorType: 3,
        });
      } else if (err.response && err.response.status === 404) {
        this.setState({
          showError: true,
          errorType: 0,
        });
      } else {
        this.setState({
          showError: true,
          errorType: 0,
        });
        handleApiErrors(err.response);
      }
    }

    this.setState({ activatingAcount: false });
  };

  handleLogin = async model => {
    try {
      let loginResult = await signInUser(model.email, model.password);

      if (loginResult.user) {
        setAnalyticsUserProperties(loginResult.user);
        PENDO_ANALYTICS.update(loginResult.user, loginResult.company);

        const normalizedUser = normalizeUserSchema(loginResult.user);
        const { updateProfile } = this.props;
        updateProfile(normalizedUser);
      }
    } catch (error) {
      handleApiErrors(error.response, () => {
        Toast({
          type: 'error',
          message: this.props.t('errorLoginIn'),
        });
      });
    }
  };

  resendEmail = async () => {
    this.setState({ fetchFlag: false });
    try {
      await handleApiCalls('post', `${process.env.REACT_APP_HOST_API}auth/resend-activate-email`, {
        token: this.props.match.params.token,
      });

      Toast({
        type: 'success',
        message: this.props.t('activationEmailSendSuccessful'),
      });
    } catch (e) {
      handleApiErrors(e.response, () => {
        Toast({
          type: 'error',
          message: this.props.t('resendingEmailFailed'),
        });
      });
    }
    this.setState({ fetchFlag: true });
  };

  render() {
    const { fetchFlag, showError, errorType } = this.state;
    const { t } = this.props;

    return (
      <>
        <Helmet>
          <title>{formatPageTitle('Activate Account')}</title>
        </Helmet>

        <Row
          justify="center"
          align={showError || !fetchFlag ? 'middle' : 'top'}
          className={classNames.activateView}
        >
          <Col flex={1}>
            <Row justify="center" style={{ marginBottom: 30 }}>
              <Col>
                <Link to={INTERNAL_LINKS.LOGIN}>
                  <FullLogo />
                </Link>
              </Col>
            </Row>

            {fetchFlag && !showError && (
              <RegistrationForm
                activating={this.state.activatingAcount}
                user={this.state}
                fieldValues={this.activate}
                t={t}
                props={this.props}
              />
            )}
            {!fetchFlag && (
              <div className="d-flex-center">
                <FadedText>{t('loading...')}</FadedText>
              </div>
            )}
            {showError && errorType === 1 && (
              <div className="d-flex-center f-dir-column">
                <FadedText>
                  {`This email verification link has expired. Please request a new one by clicking `}
                  <LinkText variant="strong" onClick={this.resendEmail}>
                    here
                  </LinkText>
                  .
                </FadedText>
              </div>
            )}
            {showError && errorType === 3 && (
              <div className="d-flex-center f-dir-column">
                <FadedText>This account has already been activated.</FadedText>
                <br />
                <Link to={INTERNAL_LINKS.LOGIN}>
                  <LinkText variant="strong">Log in to Kliks</LinkText>
                </Link>
              </div>
            )}
            {showError && errorType === 2 && (
              <div className="d-flex-center">
                <FadedText>{t('emailVerificationLinkExpired')}</FadedText>
              </div>
            )}
            {showError && errorType === 0 && (
              <div className="d-flex-center">
                <FadedText>{t('unexpectedErrorContactAdmin')}</FadedText>
              </div>
            )}
          </Col>
        </Row>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {};
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    updateProfile: profile => {
      dispatch(updateProfile(profile));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(UserAccountActivate));
