import { Col, Form, Row } from 'antd';
import { get, omit } from 'lodash';
import moment from 'moment-timezone';
import React, { useCallback, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { withNamespaces } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';

import { handleApiErrors } from '../../../api/axiosInstance';
import { FAVR_COMPLIANCES_API } from '../../../api/favr-compliances';
import { addVehicle, deleteVehicle, updateVehicle } from '../../../api/vehicle';
import { Button } from '../../../components';
import OutOfComplianceAlert from '../../../components/Alert/OutOfComplianceAlert';
import Box from '../../../components/Box';
import HorizontalLineDivider from '../../../components/HorizontalLineDivider';
import VehicleFormModal from '../../../components/Modal/VehicleFormModal';
import BusinessVehicleList from '../../../components/shared-ui/BusinessVehicleList';
import SpaceSpinner from '../../../components/SpaceSpinner';
import Text from '../../../components/Text';
import Toast from '../../../components/Toast';
import { IMAGES } from '../../../enum';
import useDidUpdateEffect from '../../../hooks/useDidUpdateEffect';
import useModal from '../../../hooks/useModal';
import useVehicleCompliance from '../../../hooks/useVehicleCompliance';
import {
  emitAddUserVehicle,
  emitUpdateUserVehicleByID,
} from '../../../stores/actions/userVehicles';
import { formatPageTitle } from '../../../utils/common';
import { getMomentDateWithoutTimezone } from '../../../utils/datetime';
import { selectStoreCurrentAuthUser } from '../../../utils/storeSelectors';

const VinDecoderModal = React.lazy(() => import('../../../components/Modal/VinDecoderModal'));
const VehicleAddMethodSelectModal = React.lazy(() =>
  import('../../../components/Modal/VehicleAddMethodSelectModal'),
);

const PageHelmet = () => (
  <Helmet>
    <title>{formatPageTitle('Business Vehicle')}</title>
  </Helmet>
);

const initialValues = {
  isDefault: true,
  odometerDate: moment(),
  year: undefined,
  make: undefined,
  otherMake: undefined,
  model: undefined,
  otherModel: undefined,
  vehicleType: undefined,
  bodyClass: undefined,
  doors: undefined,
  engineNumberOfCylinders: undefined,
  displacementL: undefined,
  fuelTypePrimary: undefined,
  emissionModelId: null,
};

const BusinessVehicleView = props => {
  const {
    t,
    isFAVR,
    isVinDecoderEnabled,
    userDetails,
    isUserDeleted,
    userComplianceDetails,
    msrpAndSalesTax,
  } = props;

  const queryClient = useQueryClient();
  const [form] = Form.useForm();
  const [vehicleValues, setVehicleValues] = useState(initialValues);

  const dispatch = useDispatch();
  const authUser = selectStoreCurrentAuthUser();
  const userVehicles = useSelector(state => state.userVehicles);
  const isOutOfCompliance = useVehicleCompliance(userDetails?._id);
  const [updatingVehicleID, setUpdatingVehicleID] = useState();
  const [isVehicleModalOpen, openVehicleModal, closeVehicleModal] = useModal();
  const [isVinDecoderModalOpen, openVinDecoderModal, closeVinDecoderModal] = useModal();
  const [
    isVehicleAddMethodModalOpen,
    openVehicleAddMethodModal,
    closeVehicleAddMethodModal,
  ] = useModal();
  const [deletingVehicleIds, setDeletingVehicleIds] = useState([]);

  const userActiveVehicles = useMemo(() => {
    let vehicles = [];

    if (Array.isArray(userVehicles[userDetails._id]?.activeVehicles)) {
      vehicles = [...vehicles, ...userVehicles[userDetails._id]?.activeVehicles];
    }

    return vehicles;
  }, [userVehicles, userDetails]);

  const userDefaultVehicle = useMemo(() => {
    let vehicles = [];

    if (userVehicles[userDetails._id]?.defaultVehicle) {
      vehicles.push(userVehicles[userDetails._id]?.defaultVehicle);
    }

    return vehicles;
  }, [userVehicles, userDetails]);

  const allUserVehicles = useMemo(() => {
    return [...userDefaultVehicle, ...userActiveVehicles];
  }, [userActiveVehicles, userDefaultVehicle]);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleModalClose = useCallback(() => {
    closeVehicleModal();
    closeVinDecoderModal();
    setUpdatingVehicleID();
    setVehicleValues(initialValues);
    form.resetFields();
  }, [form, closeVehicleModal, closeVinDecoderModal]);

  const handleSelectedVehicleUpdate = useCallback(
    updatedState => {
      setVehicleValues(state => {
        const finalState = { ...state, ...updatedState };
        form.setFieldsValue(finalState);
        return finalState;
      });
    },
    [form],
  );

  const handleVehicleDelete = async vehicleID => {
    setDeletingVehicleIds(state => [...state, vehicleID]);

    try {
      await deleteVehicle(vehicleID);
      const filteredVehicles = allUserVehicles.filter(vehicle => vehicle._id !== vehicleID);
      dispatch(emitAddUserVehicle(userDetails._id, filteredVehicles));
      Toast({
        type: 'open',
        message: t('vehicleDeleteSuccess'),
      });
    } catch (error) {
      handleApiErrors(error.response, () => {
        Toast({
          type: 'error',
          message: t('vehicleDeleteError'),
        });
      });
    }

    setDeletingVehicleIds(state => state.filter(id => id !== vehicleID));
  };

  const handleVehicleSelect = vehicleID => {
    const vehicle = allUserVehicles.find(vehicle => vehicle._id === vehicleID);

    if (vehicle) {
      const newValues = {
        isDefault: vehicle.isDefault,
        year: vehicle.year,
        make: vehicle.otherMake ? 'other' : vehicle.make?.toUpperCase(),
        otherMake: vehicle.otherMake ? vehicle?.make.replace(/^_/, '') : undefined,
        model: vehicle.otherModel ? 'other' : vehicle?.model,
        otherModel: vehicle.otherModel ? vehicle?.model.replace(/^_/, '') : undefined,
        vin: vehicle.vin,
        licensePlate: vehicle.licensePlate,
        msrpConfirmed: vehicle.msrpConfirmed,
        favrDepreciationType: vehicle.favrDepreciationType,
        electronicSignatureConfirmed: vehicle.electronicSignatureConfirmed,
        vehicleType: vehicle.vehicleType,
        bodyClass: vehicle.bodyClass,
        fuelTypePrimary: vehicle.fuelTypePrimary,
        doors: vehicle.doors,
        engineNumberOfCylinders: vehicle.engineNumberOfCylinders,
        displacementL: vehicle.displacementL,
        electrificationLevel: vehicle.electrificationLevel,
        emissionModelId: vehicle.emissionModelId,
        vinDecoder: vehicle.vinDecoder,
        ...(Array.isArray(vehicle.odometer) && !!vehicle.odometer.length
          ? {
              odometer: vehicle.odometer[0].mileage,
              odometerDate: getMomentDateWithoutTimezone(vehicle.odometer[0].createdAt),
            }
          : { odometer: undefined, odometerDate: undefined }),
      };
      setVehicleValues(newValues);
      form.setFieldsValue(newValues);
    }

    setUpdatingVehicleID(vehicleID);
  };

  const _formatValuesForBE = useCallback(
    values => {
      let formattedValues = {
        year: values.year,
        otherMake: values.make === 'other',
        make: values.make === 'other' ? values.otherMake : values.make,
        otherModel: values.model === 'other',
        model: values.model === 'other' ? values.otherModel : values.model,
        isDefault: !!values.isDefault,
        licensePlate: values.licensePlate,
        vin: values.vin,
        vehicleType: values.vehicleType,
        bodyClass: values.bodyClass,
        doors: values.doors,
        engineNumberOfCylinders: values.engineNumberOfCylinders,
        displacementL: values.displacementL,
        fuelTypePrimary: values.fuelTypePrimary,
        electrificationLevel: values.electrificationLevel,
        city08: values.city08,
        co2TailpipeGpm: values.co2TailpipeGpm,
        ghgScore: values.ghgScore,
        highway08: values.highway08,
        emissionModelId: get(values, 'emissionModelId', null),
        vinDecoder: get(values, 'vinDecoder', false),
      };

      if (isFAVR) {
        formattedValues = {
          ...formattedValues,
          msrpConfirmed: values.msrpConfirmed,
          favrDepreciationType: values.favrDepreciationType,
          electronicSignatureConfirmed: values.electronicSignatureConfirmed,
          odometer: [
            {
              mileage: parseFloat(values.odometer),
              createdAt: getMomentDateWithoutTimezone(values.odometerDate).toISOString(),
            },
          ],
        };
      }

      return formattedValues;
    },
    [isFAVR],
  );

  const handleVehicleAdd = useCallback(
    async values => {
      form.validateFields().then(async () => {
        setIsSubmitting(true);
        try {
          const vehicles = await addVehicle(_formatValuesForBE(values));
          await confirmComplianceVehicleInformation.mutateAsync();
          dispatch(emitAddUserVehicle(userDetails._id, vehicles));
          Toast({
            type: 'open',
            message: t('addVehicleSuccess'),
          });
          handleModalClose();
          closeVinDecoderModal();
        } catch (error) {
          handleApiErrors(error.response, () => {
            Toast({
              type: 'error',
              message: t('addVehicleError'),
            });
          });
        }
        setIsSubmitting(false);
      });
      // eslint-disable-next-line
  }, [dispatch, form, handleModalClose, _formatValuesForBE, userDetails._id]);

  const handleVehicleUpdate = useCallback(
    async values => {
      form.validateFields().then(async () => {
        setIsSubmitting(true);
        try {
          const vehicle = await updateVehicle(updatingVehicleID, _formatValuesForBE(values));
          dispatch(emitUpdateUserVehicleByID(userDetails._id, updatingVehicleID, vehicle));
          Toast({
            type: 'open',
            message: t('updateVehicleSuccess'),
          });
          handleModalClose();
          closeVinDecoderModal();
        } catch (error) {
          handleApiErrors(error.response, () => {
            Toast({
              type: 'error',
              message: t('updateVehicleError'),
            });
          });
        }
        setIsSubmitting(false);
      });
    },
    // eslint-disable-next-line
    [dispatch, form, handleModalClose, _formatValuesForBE, updatingVehicleID, userDetails._id],
  );

  const handleMakeDefaultVehicle = async vehicleID => {
    setDeletingVehicleIds(state => [...state, vehicleID]);
    const selectedVehicle = allUserVehicles.find(vehicle => vehicle._id === vehicleID);
    const fieldsToOmit = [
      'city08',
      'co2TailpipeGpm',
      'ghgScore',
      'highway08',
      'outOfCompliance',
      'status',
      'vehicleMaintenanceInfo',
      'vehicleServiceInfo',
      'users',
      'updated',
      'created',
      '__v',
      '_id',
    ];

    try {
      const updatedVehicle = await updateVehicle(
        vehicleID,
        omit({ ...selectedVehicle, isDefault: true }, fieldsToOmit),
      );
      dispatch(emitUpdateUserVehicleByID(userDetails._id, vehicleID, updatedVehicle));
      Toast({
        type: 'open',
        message: t('updateVehicleSuccess'),
      });
    } catch (error) {
      handleApiErrors(error.response, () => {
        Toast({
          type: 'error',
          message: t('updateVehicleError'),
        });
      });
    }

    setDeletingVehicleIds(state => state.filter(id => id !== vehicleID));
  };

  const confirmComplianceVehicleInformation = useMutation(
    () => FAVR_COMPLIANCES_API.confirmComplianceInformation('business-vehicle'),
    {
      onSuccess: () =>
        queryClient.invalidateQueries(['fetchUserFavrComplianceStatuses', userDetails._id]),
    },
  );

  const handleAddVehicleModalOpen = () => {
    form.resetFields();
    form.setFieldsValue(initialValues);
    setVehicleValues(initialValues);

    if (isVinDecoderEnabled) {
      openVehicleAddMethodModal();
    } else {
      openVehicleModal();
    }
  };

  useDidUpdateEffect(() => {
    if (updatingVehicleID) {
      if (vehicleValues.vinDecoder) {
        openVinDecoderModal();
      } else {
        openVehicleModal();
      }
    }
  }, [updatingVehicleID, vehicleValues, openVehicleModal, openVinDecoderModal]);

  if (!userVehicles[userDetails._id]) {
    return (
      <Box>
        <PageHelmet />
        <SpaceSpinner />
      </Box>
    );
  }

  const IS_FORM_DISABLED = isUserDeleted || authUser.profile._id !== userDetails._id;

  return (
    <Box>
      <PageHelmet />

      {isOutOfCompliance && <OutOfComplianceAlert userComplianceDetails={userComplianceDetails} />}

      <section>
        <Row justify="space-between" align="middle" gutter={[16, 16]}>
          <Col>
            <Text variant="h5">Your Default Vehicle</Text>
          </Col>

          {!IS_FORM_DISABLED && (!!userDefaultVehicle.length || !!userActiveVehicles.length) && (
            <Col>
              <Button
                type="secondary"
                onClick={handleAddVehicleModalOpen}
                icon={
                  <img
                    alt="plus"
                    src={IMAGES.GREEN_PLUS_ICON}
                    width="16px"
                    style={{ marginRight: 5 }}
                  />
                }
              >
                {t('addVehicle')}
              </Button>
            </Col>
          )}
        </Row>

        {!IS_FORM_DISABLED && !userDefaultVehicle.length && !userActiveVehicles.length && (
          <>
            <Row justify="center">
              <Col>
                <Row justify="center">
                  <Col>
                    <img width="130px" src={IMAGES.CAR_CLOUD_ICON} alt="time off" />
                  </Col>
                </Row>

                <br />

                <Row justify="center">
                  <Col>
                    <Button
                      type="secondary"
                      onClick={handleAddVehicleModalOpen}
                      icon={
                        <img
                          alt="plus"
                          src={IMAGES.GREEN_PLUS_ICON}
                          width="16px"
                          style={{ marginRight: 5 }}
                        />
                      }
                    >
                      {t('addVehicle')}
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>

            <HorizontalLineDivider marginTop={30} marginBottom={30} />
          </>
        )}

        <BusinessVehicleList
          t={t}
          vehicles={userDefaultVehicle}
          onEdit={!IS_FORM_DISABLED ? handleVehicleSelect : undefined}
          requiresConfirmation={userComplianceDetails?.businessVehicleRequireConfirmation}
          isConfirming={confirmComplianceVehicleInformation.isLoading}
          onConfirm={
            !IS_FORM_DISABLED ? confirmComplianceVehicleInformation.mutateAsync : undefined
          }
        />
      </section>

      {!!userActiveVehicles.length && (
        <>
          <HorizontalLineDivider marginTop={30} marginBottom={30} />

          <section>
            <Text variant="h5">Other Vehicles</Text>

            <BusinessVehicleList
              t={t}
              vehicles={userActiveVehicles}
              deletingVehicleIds={deletingVehicleIds}
              onEdit={!IS_FORM_DISABLED ? handleVehicleSelect : undefined}
              onDelete={!IS_FORM_DISABLED ? handleVehicleDelete : undefined}
              onMakeDefault={!IS_FORM_DISABLED ? handleMakeDefaultVehicle : undefined}
            />
          </section>
        </>
      )}

      {isVinDecoderEnabled && (
        <VehicleAddMethodSelectModal
          t={t}
          visible={isVehicleAddMethodModalOpen}
          onCancel={closeVehicleAddMethodModal}
          onVinDecoderClick={() => {
            closeVehicleAddMethodModal();
            openVinDecoderModal();
          }}
          onManualTripClick={() => {
            closeVehicleAddMethodModal();
            openVehicleModal();
          }}
        />
      )}

      {isVinDecoderEnabled && (
        <VinDecoderModal
          t={t}
          isVisible={isVinDecoderModalOpen}
          isUpdate={!!updatingVehicleID}
          //
          isFAVR={isFAVR}
          isSubmitting={isSubmitting}
          selectedVehicle={vehicleValues}
          msrpAndSalesTax={msrpAndSalesTax}
          formInstance={form}
          initialValues={vehicleValues}
          onSubmit={updatingVehicleID ? handleVehicleUpdate : handleVehicleAdd}
          onChange={handleSelectedVehicleUpdate}
          onCancel={handleModalClose}
        />
      )}

      <VehicleFormModal
        isUpdate={!!updatingVehicleID}
        isVisible={isVehicleModalOpen}
        onCancel={handleModalClose}
        //
        t={t}
        isFAVR={isFAVR}
        isSubmitting={isSubmitting}
        selectedVehicle={vehicleValues}
        msrpAndSalesTax={msrpAndSalesTax}
        formInstance={form}
        initialValues={vehicleValues}
        onSubmit={updatingVehicleID ? handleVehicleUpdate : handleVehicleAdd}
        onChange={handleSelectedVehicleUpdate}
      />
    </Box>
  );
};

export default withNamespaces()(BusinessVehicleView);
