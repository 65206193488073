import moment from 'moment-timezone';

import { cleanQueryParams } from '../utils/queryParams';
import IMAGES from './Images';
import INTERNAL_LINKS from './InternalLinks';
import USER_ROLES from './UserRoles';

const MenuList = [
  {
    key: INTERNAL_LINKS.DASHBOARD,
    title: 'Dashboard',
    img: IMAGES.HOME_ICON,
    route: INTERNAL_LINKS.DASHBOARD,
    subnav: [],
    roles: [USER_ROLES.COMPANY_MANAGER, USER_ROLES.COMPANY_ADMIN, USER_ROLES.USER],
  },
  {
    key: INTERNAL_LINKS.TRIPS,
    title: 'Trips',
    img: IMAGES.CAR_RETRO_ICON,
    route: `${INTERNAL_LINKS.TRIPS}?${cleanQueryParams({
      startDateRange: moment().subtract(1, 'month').toISOString(),
      endDateRange: moment().toISOString(),
    })}`,
    subnav: [
      {
        title: 'View Trips',
        key: INTERNAL_LINKS.TRIPS,
        route: `${INTERNAL_LINKS.TRIPS}?${cleanQueryParams({
          startDateRange: moment().subtract(1, 'month').toISOString(),
          endDateRange: moment().toISOString(),
        })}`,
        companySettings: {
          settingToCheck: 'importTripsViaCsv',
          hideIfFalse: true,
        },
      },
      {
        title: 'Manage Uploaded Trips',
        key: INTERNAL_LINKS.MANAGE_UPLOADED_TRIPS,
        route: INTERNAL_LINKS.MANAGE_UPLOADED_TRIPS,
        companySettings: {
          settingToCheck: 'importTripsViaCsv',
          hideIfFalse: true,
        },
      },
    ],
    roles: [USER_ROLES.USER, USER_ROLES.COMPANY_MANAGER],
  },
  {
    key: 'manageTrips',
    title: 'Trips',
    img: IMAGES.CAR_RETRO_ICON,
    route: INTERNAL_LINKS.TRIPS,
    subnav: [
      {
        title: 'View Trips',
        key: INTERNAL_LINKS.TRIPS,
        route: INTERNAL_LINKS.TRIPS,
      },
      {
        title: 'Manage Uploaded Trips',
        key: INTERNAL_LINKS.MANAGE_UPLOADED_TRIPS,
        route: INTERNAL_LINKS.MANAGE_UPLOADED_TRIPS,
        companySettings: {
          settingToCheck: 'importTripsViaCsv',
          hideIfFalse: true,
        },
      },
    ],
    roles: [USER_ROLES.COMPANY_ADMIN],
  },
  {
    key: 'payments',
    title: 'Payments',
    img: IMAGES.MONEY_BAG_ICON,
    roles: [USER_ROLES.USER, USER_ROLES.COMPANY_ADMIN, USER_ROLES.COMPANY_MANAGER],
    subnav: [
      {
        key: INTERNAL_LINKS.RECEIPTS,
        title: 'Receipts',
        route: INTERNAL_LINKS.RECEIPTS,
        companySettings: {
          settingToCheck: 'hideReceipts',
          hideIfTrue: true,
        },
      },
      {
        key: INTERNAL_LINKS.REIMBURSEMENT,
        title: 'Reimbursements',
        route: INTERNAL_LINKS.REIMBURSEMENT,
        companySettings: {
          settingToCheck: 'tripApprovalWorkflow',
          renameIfFalse: 'Tax Vault',
        },
      },
      {
        key: INTERNAL_LINKS.PAYROLL_FILE_HISTORY,
        title: 'Payroll File',
        route: INTERNAL_LINKS.PAYROLL_FILE_HISTORY,
        roles: [USER_ROLES.COMPANY_ADMIN], // only visible in case of company admin
      },
      {
        key: INTERNAL_LINKS.ACCOUNT_BALANCE,
        title: 'Account Balance',
        route: INTERNAL_LINKS.ACCOUNT_BALANCE,
        roles: [USER_ROLES.COMPANY_ADMIN], // only visible in case of company admin
        companySettings: {
          settingToCheck: 'ach_enabled',
          hideIfFalse: true,
        },
      },
    ],
  },
  {
    key: 'user-management',
    title: 'Users and Groups',
    img: IMAGES.MULTIPLE_USERS_ICON,
    roles: [USER_ROLES.COMPANY_ADMIN, USER_ROLES.COMPANY_MANAGER],
    subnav: [
      {
        key: INTERNAL_LINKS.GROUP_MANAGEMENT,
        title: 'Manage Groups',
        route: INTERNAL_LINKS.GROUP_MANAGEMENT,
        roles: [USER_ROLES.COMPANY_ADMIN],
      },
      {
        key: INTERNAL_LINKS.USER_MANAGER,
        title: 'Manage Users',
        route: INTERNAL_LINKS.USER_MANAGER,
        roles: [USER_ROLES.COMPANY_ADMIN],
      },
      {
        key: INTERNAL_LINKS.MANAGED_USERS,
        title: 'Managed Users',
        route: INTERNAL_LINKS.MANAGED_USERS,
        roles: [USER_ROLES.COMPANY_MANAGER],
      },
      {
        key: INTERNAL_LINKS.CLIENTS_LIST,
        title: 'Manage Clients',
        route: INTERNAL_LINKS.CLIENTS_LIST,
        roles: [USER_ROLES.COMPANY_ADMIN],
        companySettings: {
          settingToCheck: 'realtorFeatures',
          hideIfFalse: true,
        },
      },
      {
        key: INTERNAL_LINKS.USER_SETTINGS,
        title: 'User Settings',
        route: INTERNAL_LINKS.USER_SETTINGS,
        roles: [USER_ROLES.COMPANY_ADMIN],
        companySettings: {
          settingToCheck: 'mileageCap',
          hideIfFalse: true,
        },
      },
    ],
  },
  {
    key: 'reports',
    title: 'Reports',
    img: IMAGES.REPORT_GRAPH_ICON,
    roles: [USER_ROLES.COMPANY_ADMIN, USER_ROLES.COMPANY_MANAGER, USER_ROLES.USER],
    subnav: [
      {
        key: INTERNAL_LINKS.REPORTS,
        title: 'Reports',
        route: INTERNAL_LINKS.REPORTS,
        roles: [USER_ROLES.COMPANY_ADMIN, USER_ROLES.COMPANY_MANAGER],
      },
      {
        key: INTERNAL_LINKS.COMPANY_TAX_REPORT,
        title: 'Tax Report',
        route: INTERNAL_LINKS.COMPANY_TAX_REPORT,
        roles: [USER_ROLES.COMPANY_ADMIN],
      },
      {
        key: INTERNAL_LINKS.DRIVERS_EGS_EMISSIONS,
        title: 'Driver Emissions',
        roles: [USER_ROLES.COMPANY_ADMIN],
        route: INTERNAL_LINKS.DRIVERS_EGS_EMISSIONS,
        companySettings: {
          settingToCheck: 'esgScope3',
          hideIfFalse: true,
        },
      },
      {
        key: INTERNAL_LINKS.USER_MOBILE_APP_SETTINGS_REPORT,
        title: 'User Mobile App Settings',
        roles: [USER_ROLES.COMPANY_ADMIN],
        route: INTERNAL_LINKS.USER_MOBILE_APP_SETTINGS_REPORT,
      },
      {
        key: INTERNAL_LINKS.ANOMALIES_DASHBOARD,
        title: 'Anomalies Dashboard',
        route: INTERNAL_LINKS.ANOMALIES_DASHBOARD,
        roles: [USER_ROLES.COMPANY_ADMIN, USER_ROLES.COMPANY_MANAGER],
        companySettings: {
          settingToCheck: 'alertsDashboard',
          hideIfFalse: true,
        },
      },
      {
        key: INTERNAL_LINKS.EXPORTS,
        title: 'Exports',
        route: INTERNAL_LINKS.EXPORTS,
      },
    ],
  },
  {
    key: INTERNAL_LINKS.SELECT_COMPANY,
    title: 'Companies',
    img: IMAGES.BUILDING_ICON,
    roles: [USER_ROLES.SYSTEM_ADMIN],
    route: INTERNAL_LINKS.SELECT_COMPANY,
    subnav: [],
  },
  {
    key: INTERNAL_LINKS.COUNTRY_RATES,
    title: 'Rates',
    img: IMAGES.MONEY_BAG_ICON,
    roles: [USER_ROLES.SYSTEM_ADMIN],
    route: INTERNAL_LINKS.COUNTRY_RATES,
    subnav: [],
  },
  {
    key: INTERNAL_LINKS.STANDARD_VEHICLES,
    title: 'Standard Vehicles',
    img: IMAGES.CAR_ICON,
    roles: [USER_ROLES.SYSTEM_ADMIN],
    route: INTERNAL_LINKS.STANDARD_VEHICLES,
    subnav: [],
  },
  {
    key: INTERNAL_LINKS.INSURANCE_COMPANIES,
    title: 'Insurance Companies',
    img: IMAGES.CAR_INSURANCE_ICON,
    roles: [USER_ROLES.SYSTEM_ADMIN],
    route: INTERNAL_LINKS.INSURANCE_COMPANIES,
    subnav: [],
  },
  {
    key: INTERNAL_LINKS.CUSTOM_PLANS,
    title: 'Custom Plans',
    img: IMAGES.CREDIT_CARD_ICON,
    roles: [USER_ROLES.SYSTEM_ADMIN],
    route: INTERNAL_LINKS.CUSTOM_PLANS,
    subnav: [],
  },
  {
    key: INTERNAL_LINKS.COMPLIANCE_WORKLIST,
    title: 'Compliance Worklist',
    img: IMAGES.LIST_TO_DO_WHITE_ICON,
    roles: [USER_ROLES.SYSTEM_ADMIN],
    route: INTERNAL_LINKS.COMPLIANCE_WORKLIST,
    subnav: [],
  },
  {
    key: INTERNAL_LINKS.SYSTEM_ADMINS,
    title: 'System Admins',
    img: IMAGES.MULTIPLE_USERS_ICON,
    roles: [USER_ROLES.SYSTEM_ADMIN],
    route: INTERNAL_LINKS.SYSTEM_ADMINS,
    subnav: [],
  },
];

const BottomSideMenuList = [
  {
    key: 'settings',
    title: 'Settings',
    img: IMAGES.SETTINGS_ICON,
    roles: [USER_ROLES.BACK_OFFICE_USER],
    subnav: [
      {
        key: INTERNAL_LINKS.INSURANCE_POLICY_VERIFICATION,
        title: 'Insurance Policy Verification',
        route: INTERNAL_LINKS.INSURANCE_POLICY_VERIFICATION,
      },
      {
        key: INTERNAL_LINKS.VEHICLE_POLICY_VERIFICATION,
        title: 'Vehicle Policy Verification',
        route: INTERNAL_LINKS.VEHICLE_POLICY_VERIFICATION,
      },
    ],
  },
  {
    key: 'services-settings',
    title: 'Company Settings',
    img: IMAGES.SETTINGS_ICON,
    roles: [USER_ROLES.COMPANY_ADMIN],
    subnav: [
      {
        key: INTERNAL_LINKS.COMPANY,
        title: 'Company',
        route: INTERNAL_LINKS.COMPANY,
      },
      {
        key: INTERNAL_LINKS.LOCATIONS,
        title: 'Locations',
        route: INTERNAL_LINKS.LOCATIONS,
        companySettings: {
          settingToCheck: 'importLocationsMethod',
          hideIfFalse: true,
        },
      },
      {
        key: INTERNAL_LINKS.RATES,
        title: 'Rates',
        route: INTERNAL_LINKS.RATES,
      },
      {
        key: INTERNAL_LINKS.USER_PROGRAM_COMPLIANCE,
        title: 'Compliance Status',
        route: INTERNAL_LINKS.USER_PROGRAM_COMPLIANCE,
        visible: ({ currentCompany }) => {
          return !!currentCompany?.hasFavrProducts;
        },
      },
      {
        key: INTERNAL_LINKS.PAYMENT_SCHEDULES,
        title: 'Payment Schedule',
        route: INTERNAL_LINKS.PAYMENT_SCHEDULES,
      },
      {
        key: 'gdpr',
        title: 'GDPR / CCPA',
        route: INTERNAL_LINKS.GDPR,
      },
    ],
  },
  {
    key: INTERNAL_LINKS.GLOBAL_SETTINGS,
    title: 'Global Settings',
    img: IMAGES.SETTINGS_ICON,
    roles: [USER_ROLES.SYSTEM_ADMIN],
    route: INTERNAL_LINKS.GLOBAL_SETTINGS,
    subnav: [],
  },
];

export default { BottomSideMenuList, MenuList };
