import { mergeWith } from 'lodash';
import queryString from 'query-string';
import { useMemo } from 'react';

/**
 * Parses the provided location.search using query-string.parse handling empty string values
 * @param {Location} location
 * @returns {object}
 */
const useLocationSearchQueryParser = location => {
  const parsedSearchQuery = useMemo(
    () =>
      mergeWith({}, queryString.parse(location.search), (objValue, srcValue) => {
        if (srcValue === 'true') return true;
        if (srcValue === 'false') return false;
        return srcValue === '' ? null : srcValue;
      }),
    [location],
  );

  return parsedSearchQuery;
};

export default useLocationSearchQueryParser;
