import moment from 'moment-timezone';

import { STATUS_LIST } from '../enum';
import { getMomentDateWithoutTimezone } from './datetime';

export const normalizeCountryRatesSchema = rate => {
  const flattenedRules = {};

  rate.rules.forEach(rule => {
    flattenedRules[rule.ruleType] = rule.unitRate;
  });

  const effectiveStartDate = getMomentDateWithoutTimezone(rate.effectiveStartDate);
  const effectiveEndDate = getMomentDateWithoutTimezone(rate.effectiveEndDate);
  const currentDate = getMomentDateWithoutTimezone(new Date());

  return {
    ...rate,
    status: moment(currentDate).isBetween(effectiveStartDate, effectiveEndDate, 'date', '[]')
      ? STATUS_LIST.Status.ACTIVE
      : STATUS_LIST.Status.INACTIVE,
    effectiveStartDate,
    effectiveEndDate,
    ...flattenedRules,
  };
};
