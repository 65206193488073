import { Col, Form, Row } from 'antd';
import { get } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import StepWizard from 'react-step-wizard';

import { DWOLLA_API, parseDwollaApiErrorMessage } from '../../../../api/dwolla';
import { Button } from '../../../../components';
import Box from '../../../../components/Box';
import SubmitCancelButtonGroup from '../../../../components/SubmitCancelButtonGroup';
import TabsContainer from '../../../../components/TabsContainer';
import Text from '../../../../components/Text';
import Toast from '../../../../components/Toast';
import { STATUS_LIST } from '../../../../enum';
import { DWOLLA_BUSINESS_TYPES } from '../../../../enum/Dwolla';
import useModal from '../../../../hooks/useModal';
import { saveCompany } from '../../../../stores/actions/common';
import { selectStoreCurrentCompany, useStoreSelector } from '../../../../utils/storeSelectors';
import {
  formatControllerInitialValuesFromBE,
  formatVerifiedAccountValuesToBE,
} from './ach-verification.utils';
import AdditionalDocumentsRequiredForVerificationModal from './AdditionalDocumentsRequiredForVerificationModal';
import BusinessInformationForm from './BusinessInformationForm';
import CollapsibleStepBox from './CollapsibleStepBox';
import ControllerInformationForm from './ControllerInformationForm';

const BUSINESS_INFO_STEP = '1';
const CONTROLLER_INFO_STEP = '2';

const BusinessAndControllerStepBox = props => {
  const { t, customerId, controllerInfo } = props;

  const [form] = Form.useForm();
  const currentCompany = useStoreSelector(selectStoreCurrentCompany);
  const [isDocumentsModalVisible, openDocumentsModal, closeDocumentsModal] = useModal();

  const dispatch = useDispatch();
  const [formValues, setFormValues] = useState({});
  const [wizardInstance, setWizardInstance] = useState({});
  const [lastCompletedStep, setLastCompletedStep] = useState(1);
  const { SW } = wizardInstance;

  const setInstance = SW => setWizardInstance({ ...wizardInstance, SW });
  const onStepChange = () => setWizardInstance({ ...wizardInstance });

  const customerCreateMutation = useMutation({
    mutationFn: async values => {
      const formattedValues = formatVerifiedAccountValuesToBE(values);
      const response = await DWOLLA_API.createVerifiedCustomer(formattedValues);
      if (get(formattedValues, 'businessType') === DWOLLA_BUSINESS_TYPES.SOLE_PROPRIETORSHIP) {
        return response;
      }
      return DWOLLA_API.certifyBusinessOwner();
    },
    onSuccess: response => {
      dispatch(saveCompany({ dwollaPaymentInfo: response.dwollaPaymentInfo }));

      if (
        response?.dwollaPaymentInfo?.controllerInfo?.customerStatus === STATUS_LIST.Status.DOCUMENT
      ) {
        openDocumentsModal();
      }

      Toast({
        type: 'open',
        message: t('businessAndControllerInfoSubmitSuccess'),
      });
    },
    onError: error => {
      parseDwollaApiErrorMessage(error.response, () => {
        Toast({
          type: 'error',
          message: t('submitInfoError'),
        });
      });
    },
  });

  const customerUpdateMutation = useMutation({
    mutationFn: async values => {
      const formattedValues = formatVerifiedAccountValuesToBE(values);
      return DWOLLA_API.updateCustomer(
        formattedValues,
        controllerInfo?.customerStatus === STATUS_LIST.Status.RETRY,
      );
    },
    onSuccess: response => {
      dispatch(
        saveCompany({
          dwollaPaymentInfo: {
            ...currentCompany.dwollaPaymentInfo,
            controllerInfo: response.controllerInfo,
          },
        }),
      );
      Toast({
        type: 'open',
        message: t(
          controllerInfo?.customerStatus === STATUS_LIST.Status.RETRY
            ? 'businessInfoRetrySuccess'
            : 'businessInfoUpdateSuccess',
        ),
      });
    },
    onError: error => {
      parseDwollaApiErrorMessage(error.response, () => {
        Toast({
          type: 'error',
          message: t('businessInfoUpdateError'),
        });
      });
    },
  });

  const handleFormValuesChange = (values = {}) => {
    setFormValues(state => ({ ...state, ...values }));
  };

  const validateNextStep = () => {
    const { nextStep, firstStep, currentStep } = SW;

    switch (currentStep) {
      // Business Info
      case 1: {
        form.validateFields().then(values => {
          handleFormValuesChange(values);
          setLastCompletedStep(2);

          if (values.businessType === DWOLLA_BUSINESS_TYPES.SOLE_PROPRIETORSHIP) {
            if (controllerInfo?.customerStatus === STATUS_LIST.Status.RETRY) {
              customerUpdateMutation.mutateAsync(values);
            } else if (canUpdateCustomer) {
              customerUpdateMutation.mutateAsync(values);
            } else {
              customerCreateMutation.mutateAsync(values);
            }
          } else if (canUpdateCustomer) {
            customerUpdateMutation.mutateAsync(values);
          } else {
            nextStep();
          }
        });
        break;
      }
      // Controller Info
      case 2: {
        form.validateFields().then(values => {
          if (controllerInfo?.customerStatus === STATUS_LIST.Status.RETRY) {
            customerUpdateMutation.mutateAsync({ ...formValues, ...values });
          } else {
            customerCreateMutation.mutateAsync({ ...formValues, ...values }).then(() => {
              firstStep();
            });
          }
          handleFormValuesChange(values);
        });
        break;
      }
      default:
        break;
    }
  };

  const isStepCompleted = useMemo(
    () =>
      [STATUS_LIST.Status.VERIFIED, STATUS_LIST.Status.DOCUMENT].includes(
        controllerInfo?.customerStatus,
      ),
    [controllerInfo],
  );

  const canUpdateCustomer = useMemo(
    () =>
      !!customerId &&
      wizardInstance?.SW?.currentStep === 1 &&
      ![
        STATUS_LIST.Status.SUSPENDED,
        STATUS_LIST.Status.DOCUMENT,
        STATUS_LIST.Status.RETRY,
      ].includes(controllerInfo?.customerStatus),
    [wizardInstance, customerId, controllerInfo],
  );

  useEffect(() => {
    if (isStepCompleted) {
      setLastCompletedStep(2);
    }
  }, [isStepCompleted]);

  useEffect(() => {
    if (controllerInfo) {
      const formData = formatControllerInitialValuesFromBE(controllerInfo);
      setFormValues(formData);
      form.setFieldsValue(formData);
    }
  }, [controllerInfo, form]);

  return (
    <Box>
      <CollapsibleStepBox
        stepNumber={1}
        completed={isStepCompleted}
        stepTitle={
          <Row
            wrap={false}
            align="middle"
            gutter={16}
            justify="space-between"
            style={{ width: '100%' }}
          >
            <Col flex={1}>
              <Text variant="b" size="sm">
                {t('businessAndControllerInformation')}
              </Text>
            </Col>
            {[STATUS_LIST.Status.DOCUMENT, STATUS_LIST.Status.RETRY].includes(
              controllerInfo?.customerStatus,
            ) && (
              <Col>
                <Button size="xs" onClick={openDocumentsModal}>
                  {t('uploadRequiredDocument')}
                </Button>
              </Col>
            )}
          </Row>
        }
      >
        <StepWizard
          isLazyMount
          onStepChange={onStepChange}
          instance={setInstance}
          initialStep={parseInt(BUSINESS_INFO_STEP)}
          transitions={{
            enterRight: '',
            enterLeft: '',
            exitRight: '',
            exitLeft: '',
          }}
          nav={
            <TabsContainer
              activeKey={`${SW?.currentStep || 1}`}
              onChange={stepNum => SW?.goToStep(stepNum)}
            >
              <TabsContainer.TabsPane
                key={BUSINESS_INFO_STEP}
                tab={t('businessInformation')}
                disabled={lastCompletedStep < parseInt(BUSINESS_INFO_STEP, 10)}
              />
              {formValues.businessType !== DWOLLA_BUSINESS_TYPES.SOLE_PROPRIETORSHIP && (
                <TabsContainer.TabsPane
                  key={CONTROLLER_INFO_STEP}
                  tab={t('controllerInformation')}
                  disabled={lastCompletedStep < parseInt(CONTROLLER_INFO_STEP, 10)}
                />
              )}
            </TabsContainer>
          }
        >
          <BusinessInformationForm
            t={t}
            form={form}
            isSubmitting={customerCreateMutation.isLoading || customerUpdateMutation.isLoading}
            isUpdate={canUpdateCustomer}
            initialValues={formValues}
            onSubmit={validateNextStep}
            formDisabled={isStepCompleted}
            onFieldChange={handleFormValuesChange}
            fullSsnRequired={controllerInfo?.customerStatus === STATUS_LIST.Status.RETRY}
          />
          <ControllerInformationForm
            t={t}
            form={form}
            isSubmitting={customerCreateMutation.isLoading || customerUpdateMutation.isLoading}
            initialValues={formValues}
            onSubmit={validateNextStep}
            formDisabled={isStepCompleted}
            onFieldChange={handleFormValuesChange}
            fullSsnRequired={controllerInfo?.customerStatus === STATUS_LIST.Status.RETRY}
          />
        </StepWizard>

        {(!isStepCompleted || canUpdateCustomer) && (
          <SubmitCancelButtonGroup
            loading={customerCreateMutation.isLoading || customerUpdateMutation.isLoading}
            disabled={customerCreateMutation.isLoading || customerUpdateMutation.isLoading}
            hideCancel={SW?.currentStep === 1}
            onCancel={SW?.previousStep}
            cancelText={t('Back')}
            submitText={
              formValues.businessType === DWOLLA_BUSINESS_TYPES.SOLE_PROPRIETORSHIP ||
              SW?.currentStep === SW?.props?.children?.length
                ? undefined
                : canUpdateCustomer
                ? t('Update')
                : t('Next')
            }
            onSubmit={validateNextStep}
          />
        )}
      </CollapsibleStepBox>

      <AdditionalDocumentsRequiredForVerificationModal
        t={t}
        customerId={customerId}
        documents={controllerInfo?.documents}
        businessType={controllerInfo?.businessType}
        visible={isDocumentsModalVisible}
        onCancel={closeDocumentsModal}
      />
    </Box>
  );
};

export default BusinessAndControllerStepBox;
