import { Form } from 'antd';
import { get, isEqual, merge } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';

import { handleApiCalls, handleApiErrors } from '../../../api/axiosInstance';
import { createGroup, updateGroupManager } from '../../../api/group';
import { USER_API } from '../../../api/user';
import Toast from '../../../components/Toast';
import { DAILY_MILEAGE_ENTRY_MODES, INTERNAL_LINKS } from '../../../enum';
import useDefaultRate from '../../../hooks/queries/useDefaultRate';
import usePaginatedAllCompanyUsersQuery from '../../../hooks/queries/usePaginatedAllCompanyUsersQuery';
import useAdminsAndManagersUsersLists from '../../../hooks/useAdminsAndManagersUsersLists';
import { updateGroupList } from '../../../stores/actions/group';
import { updateProfile } from '../../../stores/actions/profile';
import { momentEST } from '../../../utils/common';
import { convertDistance } from '../../../utils/numbers';
import {
  selectStoreCompanySettings,
  selectStoreCountryByCode,
  selectStoreCurrentAuthUser,
  selectStoreCurrentCompany,
} from '../../../utils/storeSelectors';
import useCommuteFormState from '../../company/company-settings/useCommuteFormState';

const filterAllUsersTable = (
  userList = [],
  groupManagerID = '',
  additionalManagers = [],
  usersInGroup = [],
) => {
  return userList.filter(
    user =>
      groupManagerID !== user._id &&
      !usersInGroup.includes(user._id) &&
      !additionalManagers?.includes(user._id),
  );
};

const useGroupDetails = ({ match, history, allUsersSearchTerm, groupUsersSearchTerm }) => {
  const dispatch = useDispatch();

  const [formInstance] = Form.useForm();
  const queryClient = useQueryClient();
  const groupsData = useSelector(store => store.group);
  const defaultRateQuery = useDefaultRate();
  const currentCompany = useSelector(selectStoreCurrentCompany);
  const companySettings = useSelector(selectStoreCompanySettings);
  const authUser = useSelector(selectStoreCurrentAuthUser);
  const countryDistanceUnit = useMemo(() => {
    const countryCode = get(currentCompany, 'address.country', 'US');
    return selectStoreCountryByCode(countryCode).distanceLong;
  }, [currentCompany]);

  const pageSubtitle = useMemo(() => {
    return match.params.id ? 'Edit Group' : 'Create New Group';
  }, [match.params.id]);

  const [currentGroup, setCurrentGroup] = useState(
    groupsData.groupList.find(g => g._id === match.params.id),
  );

  const [isEditMode] = useState(!!match.params.id);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [initialModel] = useState({
    name: currentGroup?.name,
    company: currentCompany.name || '',
    defaultGroup: currentGroup?.defaultGroup || false,
    commute: currentGroup?.commute
      ? merge(currentGroup?.commute, { level: currentCompany.companySettingId?.commute?.level })
      : currentCompany.companySettingId?.commute || {},
    groupManager: currentGroup?.groupManager?._id,
    paymentScheduleId: currentGroup?.paymentScheduleId?._id,
    mileageEntryMode: currentGroup?.mileageEntryMode
      ? currentGroup?.mileageEntryMode
      : companySettings.dailyMileageLog
      ? DAILY_MILEAGE_ENTRY_MODES.DAILY_MILEAGE_LOG
      : DAILY_MILEAGE_ENTRY_MODES.TRIP_BASED,
    mileageCapInMeters: convertDistance(
      currentGroup?.mileageCapInMeters,
      'meter',
      countryDistanceUnit,
    ),
    additionalManagers: Array.isArray(currentGroup?.additionalManagers)
      ? currentGroup.additionalManagers
      : [],
    productId: currentGroup?.productId
      ? currentGroup?.productId._id
      : !currentGroup
      ? defaultRateQuery.data?._id
      : undefined,
  });

  const commuteState = useCommuteFormState({
    formInstance,
    initialCommuteSettings: initialModel.commute,
  });

  const [model, setModel] = useState(initialModel);

  const [newItem, setNewItem] = useState({
    _id: '',
    firstName: '',
    lastName: '',
    email: '',
    role: 'user',
    status: '',
    group: currentGroup ? currentGroup.name : '',
    editable: true,
    removable: true,
  });

  const { groupManagersLists } = useAdminsAndManagersUsersLists();

  const {
    allUsersTablePagination,
    allUsersQuery,
    allUsersHandleTableSort,
  } = usePaginatedAllCompanyUsersQuery(
    currentCompany?._id,
    { searchTerm: allUsersSearchTerm },
    {
      select: ({ users, totalCount }) => ({
        totalCount,
        users: filterAllUsersTable(users),
      }),
    },
  );

  const {
    allUsersTablePagination: groupUsersTablePagination,
    allUsersQuery: groupUsersQuery,
    allUsersHandleTableSort: groupUsersQueryHandleTableSort,
  } = usePaginatedAllCompanyUsersQuery(
    currentCompany?._id,
    { groupId: get(match, 'params.id', '') },
    { enabled: !!get(match, 'params.id') },
  );

  const allUsersList = useMemo(() => {
    if (Array.isArray(allUsersQuery?.data?.users)) {
      return filterAllUsersTable(
        allUsersQuery.data.users,
        currentGroup?.groupManager?._id,
        currentGroup?.additionalManagers,
        currentGroup?.users,
      );
    }

    return [];
  }, [allUsersQuery, currentGroup]);

  const [invitedUserList, setInvitedUserList] = useState([]);

  const [showDuplicateModal, setShowDuplicateModal] = useState(false);
  const [isInviteUserModalVisible, setIsinviteUserModalVisible] = useState(false);
  const [showDefaultGroupSetModal, setShowDefaultGroupSetModal] = useState(false);

  const defaultGroup = useMemo(() => {
    if (Array.isArray(groupsData?.groupList)) {
      return groupsData.groupList.find(g => g.defaultGroup) || {};
    }
    return {};
  }, [groupsData]);

  const [changedDefaultGroup, setChangedDefaultGroup] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [groupToBeDefault, setGroupToBeDefault] = useState(null);

  const [selectedRowKeysInAllTable, setSelectedRowKeysInAllTable] = useState([]);

  const groupUserList = useMemo(() => {
    let list = [];

    if (Array.isArray(invitedUserList)) {
      list = list.concat(invitedUserList);
    }

    if (Array.isArray(groupUsersQuery?.data?.users)) {
      list = list.concat(groupUsersQuery.data.users);
    }

    return list;
  }, [groupUsersQuery, invitedUserList]);

  const goToGroupManagementPage = useCallback((stopRefetch = false) => {
    history.push({
      pathname: INTERNAL_LINKS.GROUP_MANAGEMENT,
      state: { stopRefetch },
    });
    // eslint-disable-next-line
  }, []);

  const openDuplicateModal = useCallback(() => {
    setShowDuplicateModal(true);
  }, []);

  const handleDefaultGroup = useCallback(
    async groupId => {
      await handleApiCalls(
        'patch',
        `${process.env.REACT_APP_HOST_API}company/${currentCompany._id}/default-group`,
        { groupId },
      );
    },
    [currentCompany],
  );

  const handleInviteUser = useCallback(
    async groupId => {
      let success = false;
      let flag = true;

      try {
        const usersToInvite = invitedUserList
          .filter(item => item.email && item.firstName && item.lastName && item.role)
          .map(item => {
            const userDataToInvite = {
              email: item.email,
              firstName: item.firstName,
              lastName: item.lastName,
              role: item.role,
              department: item.department,
              groupId,
              sendActivationEmailDate: momentEST(item.sendActivationEmailDate),
            };
            if (companySettings?.requireEmployeeNumber) {
              userDataToInvite.employeeId = item.employeeId;
            }

            if (item.streetOne) {
              userDataToInvite.homeAddress = {
                streetOne: item.streetOne,
                streetTwo: item.streetTwo,
                country: item.country,
                state: item.state,
                city: item.city,
                postalCode: item.postalCode,
              };
            }
            return userDataToInvite;
          });

        if (usersToInvite && usersToInvite.length) {
          try {
            await USER_API.inviteUsers(usersToInvite, currentCompany._id);
          } catch (error) {
            flag = false;
            handleApiErrors(error.response, () => {
              Toast({
                type: 'error',
                message: 'Error while inviting users',
              });
            });
          }
        }

        if (selectedRowKeysInAllTable && selectedRowKeysInAllTable.length) {
          // Handle user invitations sequentially
          await selectedRowKeysInAllTable.reduce(async (accumulatorPromise, key) => {
            await accumulatorPromise;

            const url = `${process.env.REACT_APP_HOST_API}group/${groupId}/users/link`;
            return handleApiCalls('patch', url, { userId: key }).catch(err => {
              flag = false;
              handleApiErrors(err.response, () => {
                Toast({
                  type: 'error',
                  duration: null,
                  message: `Error linking user to the group`,
                });
              });
            });
          }, Promise.resolve());
        }

        if (flag) {
          Toast({
            type: 'success',
            message: 'Success',
            description: `Successfully ${isEditMode ? 'updated' : 'created'} the group.`,
          });

          success = true;
        }
      } catch (err) {
        handleApiErrors(err.response, () => {
          Toast({
            type: 'error',
            message: 'Failed',
            description: 'Failed to invite users.',
          });
        });
      }

      return success;
    },
    // eslint-disable-next-line
    [
      currentGroup,
      currentCompany,
      isEditMode,
      selectedRowKeysInAllTable,
      invitedUserList,
    ],
  );

  const inviteUserChanged = useCallback(
    users => {
      const list = users.filter(user => user.status === 'new');

      setDirty(true);
      setInvitedUserList(list);
      groupUsersQuery.refetch();
    },
    [groupUsersQuery],
  );

  const changeDefaultGroup = useCallback(e => {
    setModel(state => ({
      ...state,
      defaultGroup: e.target.checked,
    }));
    setChangedDefaultGroup(true);
    setDirty(true);
  }, []);

  const updateCurrentUserGroup = useCallback(
    groupData => {
      if (
        // is current user a member of current group
        authUser.profile.group?._id === groupData?._id ||
        // was current user moved to the current group
        !!selectedRowKeysInAllTable.includes(authUser.profile._id)
      ) {
        dispatch(
          updateProfile({
            ...authUser.profile,
            group: groupData,
          }),
        );
      }
    },
    // eslint-disable-next-line
    [authUser, selectedRowKeysInAllTable],
  );

  const handleCreateGroup = useCallback(
    async latestModel => {
      let success = true;
      if (currentCompany._id) {
        setIsSubmitting(true);

        const newGroup = await createGroup(
          latestModel.name,
          currentCompany._id,
          latestModel.groupManager,
          latestModel.productId,
          latestModel.additionalManagers,
          latestModel.paymentScheduleId,
          latestModel.mileageEntryMode,
          companySettings.mileageCap
            ? convertDistance(latestModel.mileageCapInMeters, countryDistanceUnit, 'meter')
            : undefined,
          currentCompany.companySettingId?.commute?.level === 'group'
            ? commuteState.formattedCommuteSettings
            : undefined,
        );

        if (!newGroup) {
          success = false;
        } else {
          setCurrentGroup(newGroup);
          // update current user group if match
          updateCurrentUserGroup(newGroup);
        }

        if (newGroup && changedDefaultGroup && latestModel.defaultGroup) {
          try {
            await handleDefaultGroup(newGroup._id);
          } catch (error) {
            success = false;
            handleApiErrors(error.response, () => {
              Toast({
                type: 'error',
                message: 'Error setting default Group',
              });
            });
          }
        }

        if (success) {
          success = await handleInviteUser(newGroup._id);

          if (!success) {
            Toast({
              type: 'error',
              message: 'Error while inviting users to Group',
            });
          }

          // Link Payment Schedule
        }

        setIsSubmitting(false);
      }
      return success;
    },
    [
      currentCompany,
      countryDistanceUnit,
      commuteState.formattedCommuteSettings,
      companySettings,
      handleDefaultGroup,
      changedDefaultGroup,
      handleInviteUser,
      updateCurrentUserGroup,
    ],
  );

  const handleEditGroup = useCallback(
    async latestModel => {
      let success = false;
      if (currentCompany._id) {
        setIsSubmitting(true);
        let url = `${process.env.REACT_APP_HOST_API}group/${currentGroup._id}`;
        let param = {
          name: latestModel.name,
          defaultGroup: latestModel.defaultGroup,
          productId: latestModel.productId,
          paymentScheduleId: latestModel.paymentScheduleId,
          mileageEntryMode: latestModel.mileageEntryMode,
          mileageCapInMeters: companySettings.mileageCap
            ? convertDistance(latestModel.mileageCapInMeters, countryDistanceUnit, 'meter')
            : undefined,
        };

        if (currentCompany.companySettingId?.commute?.level === 'group') {
          param.commute = commuteState.formattedCommuteSettings;
        }

        try {
          const result = await handleApiCalls('put', url, param);
          if (result.status === 200) {
            // 1. assign group manager
            if (
              !currentGroup.groupManager ||
              (currentGroup.groupManager &&
                latestModel.groupManager !== currentGroup.groupManager._id) ||
              !isEqual(currentGroup.additionalManagers, latestModel.additionalManagers)
            ) {
              try {
                const managerResult = await updateGroupManager(
                  [currentGroup._id],
                  latestModel.groupManager,
                  latestModel.additionalManagers,
                );
                if (!managerResult) return success;
              } catch (error) {
                return success;
              }
            }
            success = await handleInviteUser(currentGroup._id);

            // Update groups in the Redux store
            const updatedGroupIndex = groupsData.groupList.findIndex(
              g => g._id === currentGroup._id,
            );
            const updatedStoreGroups = groupsData.groupList;
            updatedStoreGroups[updatedGroupIndex] = {
              ...result.data.data,
              groupManager: groupManagersLists.find(user => user._id === latestModel.groupManager),
            };
            dispatch(updateGroupList(updatedStoreGroups));
            // update current user group if match
            updateCurrentUserGroup(currentGroup);
          }
        } catch (err) {
          Toast({
            type: 'error',
            message: 'Failed',
            description: 'Failed to update group.',
          });
        }

        setIsSubmitting(false);
      }
      return success;
    },
    // eslint-disable-next-line
    [currentCompany, countryDistanceUnit, companySettings, commuteState.formattedCommuteSettings, currentGroup, groupsData, groupManagersLists, handleInviteUser, updateCurrentUserGroup],
  );

  const handleGroup = useCallback(
    async latestModel => {
      setIsSubmitting(true);

      let success = false;
      if (isEditMode) {
        if (currentGroup.defaultGroup && !latestModel.defaultGroup) {
          setShowDefaultGroupSetModal(true);
          success = false;
        } else {
          success = await handleEditGroup(latestModel);
        }
      } else {
        success = await handleCreateGroup(latestModel);
      }
      setDirty(false);
      setIsSubmitting(false);
      if (success) {
        queryClient.invalidateQueries({ exact: false, queryKey: ['fetchUserGroups'] });
        queryClient.invalidateQueries({ exact: false, queryKey: ['fetchPeriodsByGroup'] });
        goToGroupManagementPage();
      }
    },
    [
      handleEditGroup,
      handleCreateGroup,
      currentGroup,
      isEditMode,
      goToGroupManagementPage,
      queryClient,
    ],
  );

  const setGroupToDefault = useCallback(group => {
    setGroupToBeDefault(group);
  }, []);

  const setDefaultGroup = useCallback(async () => {
    await handleDefaultGroup(groupToBeDefault);
    const success = await handleEditGroup(model);
    if (success) {
      goToGroupManagementPage();
    }
  }, [groupToBeDefault, handleDefaultGroup, handleEditGroup, goToGroupManagementPage, model]);

  const cancelSetDefaultGroup = useCallback(() => {
    setShowDefaultGroupSetModal(false);
  }, []);

  const checkInviteUserList = useCallback(
    async latestModel => {
      setIsSubmitting(true);

      try {
        await handleGroup(latestModel);
      } catch (err) {
        Toast({
          type: 'error',
          message: 'Failed',
          description: 'Failed to invite users.',
        });
      }

      setIsSubmitting(false);
    },
    [handleGroup],
  );

  const updateInviteUsers = useCallback(() => {
    handleGroup(model);
    setShowDuplicateModal(false);
  }, [handleGroup, model]);

  const cancelInviteUsers = () => {
    setShowDuplicateModal(false);
  };

  const changeSelectedGroupsInCurrentTable = useCallback((_, selectedRows) => {
    const existingUsers = selectedRows.filter(user => user.status !== 'new');

    if (existingUsers.length) {
      Toast({
        type: 'warning',
        message: 'You can only select newly invited users',
      });
    } else {
      setInvitedUserList(selectedRows);
      setDirty(true);
    }
  }, []);

  const changeSelectedGroupsInAllTable = useCallback(selectedRowKeys => {
    setSelectedRowKeysInAllTable(selectedRowKeys);
    setDirty(true);
  }, []);

  const onFinish = useCallback(
    async values => {
      let updatedModel = {};
      setModel(state => {
        updatedModel = {
          ...state,
          name: values.name,
          groupManager: values.groupManager,
          mileageCapInMeters: values.mileageCapInMeters,
        };
        return updatedModel;
      });

      if (dirty || commuteState.isCommuteSettingsDirty) {
        await checkInviteUserList(updatedModel);
      }
    },
    [dirty, commuteState.isCommuteSettingsDirty, checkInviteUserList],
  );

  const formValuesChange = useCallback(value => {
    if (value.name !== undefined) {
      setModel(state => ({
        ...state,
        name: value.name,
      }));
    }

    setDirty(true);
  }, []);

  const openInviteUserModal = useCallback(() => {
    setIsinviteUserModalVisible(true);
  }, []);

  const closeInviteUserModal = useCallback(() => {
    setIsinviteUserModalVisible(false);
  }, []);

  const handleModelChange = useCallback(values => {
    setDirty(true);
    setModel(state => ({ ...state, ...values }));
  }, []);

  const handleGroupManagerChange = useCallback(managerId => {
    setDirty(true);
    setModel(state => ({ ...state, groupManager: managerId }));
  }, []);

  const handleAdditionalManagerAdd = useCallback(managerId => {
    setDirty(true);
    setModel(state => ({
      ...state,
      additionalManagers: [...state.additionalManagers, managerId],
    }));
  }, []);

  const handleAdditionalManagerRemove = useCallback(managerId => {
    setDirty(true);
    setModel(state => ({
      ...state,
      additionalManagers: state.additionalManagers.filter(id => id !== managerId),
    }));
  }, []);

  const handleGroupNameChanged = useCallback(() => {
    setNewItem(state => ({ ...state, group: model.name }));
  }, [model.name]);

  useEffect(() => {
    handleGroupNameChanged();
  }, [handleGroupNameChanged]);

  useEffect(() => {
    setSelectedRowKeysInAllTable(state =>
      state.filter(k => ![model.groupManager, ...model.additionalManagers].includes(k)),
    );
  }, [model.groupManager, model.additionalManagers]);

  useEffect(() => {
    if (match.params.id && !currentGroup) {
      history.push(INTERNAL_LINKS.GROUP_MANAGEMENT);
      Toast({
        type: 'error',
        message: 'Unable to find group on the company',
      });
    }
    // eslint-disable-next-line
  }, []);

  return {
    formInstance,
    commuteState,
    isSubmitting,
    currentGroup,
    isEditMode,
    model,
    initialModel,
    newItem,
    allUsersList,
    allUsersQuery,
    allUsersTablePagination,
    allUsersHandleTableSort,
    groupManagersLists,
    groupUsersQuery,
    groupUsersQueryHandleTableSort,
    groupUsersTablePagination,
    groupUserList,
    invitedUserList,
    showDuplicateModal,
    showDefaultGroupSetModal,
    defaultGroup,
    changedDefaultGroup,
    dirty,
    groupToBeDefault,
    selectedRowKeysInAllTable,
    pageSubtitle,
    newInvitedUsers: invitedUserList,
    isInviteUserModalVisible,

    // Handlers
    goToGroupManagementPage,
    openDuplicateModal,
    handleDefaultGroup,
    handleInviteUser,
    inviteUserChanged,
    changeDefaultGroup,
    setGroupToDefault,
    setDefaultGroup,
    cancelSetDefaultGroup,
    handleCreateGroup,
    handleEditGroup,
    handleGroup,
    checkInviteUserList,
    updateInviteUsers,
    cancelInviteUsers,
    changeSelectedGroupsInCurrentTable,
    changeSelectedGroupsInAllTable,
    openInviteUserModal,
    closeInviteUserModal,
    handleModelChange,
    handleGroupManagerChange,
    handleAdditionalManagerAdd,
    handleAdditionalManagerRemove,
    onFinish,
    formValuesChange,
  };
};

export default useGroupDetails;
