import React, { useMemo } from 'react';

import useDebouncedState from '../../../hooks/useDebouncedState';
import { checkIfStringContainsValue, getUserFullName, momentFormat } from '../../../utils/common';
import { sortColumnByDateString, sortColumnByMomentTime } from '../../../utils/tables';
import ProfileNamePicture from '../../shared-ui/ProfileNamePicture';
import CustomTable from '../../Table';

/**
 * Table with a list of Users that have downloaded a specific Payroll file
 *
 * @param {object} props Component props
 */
const PayrollDownloadHistoryUserTable = props => {
  const { t, dataSource, loading } = props;

  const [searchTerm, setSearchTerm] = useDebouncedState();

  const filteredDataSource = useMemo(() => {
    let array = [...dataSource];

    if (searchTerm) {
      array = array.filter(payroll => {
        return (
          checkIfStringContainsValue(getUserFullName(payroll.downloadedBy), searchTerm) ||
          checkIfStringContainsValue(
            momentFormat(new Date(payroll.created), 'hh:mm a'),
            searchTerm,
          ) ||
          checkIfStringContainsValue(
            momentFormat(new Date(payroll.created), 'MM-DD-YYYY'),
            searchTerm,
          )
        );
      });
    }

    return array;
  }, [dataSource, searchTerm]);

  const COLUMNS = [
    {
      title: 'Name',
      key: 'name',
      sorter: (a, b) => {
        const aFullName = a.user ? getUserFullName(a.user) : '-';
        const bFullName = a.user ? getUserFullName(b.user) : '-';

        return aFullName.toLowerCase().localeCompare(bFullName.toLowerCase());
      },
      render: (actions, data) => (data.user ? <ProfileNamePicture t={t} user={data.user} /> : '-'),
    },
    {
      defaultSortOrder: 'descend',
      title: 'Date Downloaded',
      key: 'dateDownloaded',
      dataIndex: 'timestamp',
      render: timestamp => momentFormat(new Date(timestamp), 'MM-DD-YYYY'),
      ...sortColumnByDateString('created'),
    },
    {
      defaultSortOrder: 'descend',
      title: 'Time Downloaded',
      key: 'timeDownloaded',
      dataIndex: 'timestamp',
      render: timestamp => momentFormat(new Date(timestamp), 'hh:mm a'),
      ...sortColumnByMomentTime('timestamp', 'h:mm a'),
    },
  ];

  return (
    <CustomTable
      // Search props
      onSearchTermChange={setSearchTerm}
      // Table props
      loading={loading}
      columns={COLUMNS}
      dataSource={filteredDataSource}
    />
  );
};

export default PayrollDownloadHistoryUserTable;
