import { Col, Form, Row } from 'antd';
import { get } from 'lodash';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { DAILY_MILEAGE_ENTRY_MODES } from '../../../enum';
import { getUserFullName } from '../../../utils/common';
import { ERROR_MESSAGE } from '../../../utils/constants';
import {
  selectStoreCompanySettings,
  selectStoreCurrentCompany,
} from '../../../utils/storeSelectors';
import { getDistanceUnitStringValue } from '../../../utils/trips';
import Button from '../../Button';
import FormItem from '../../Form/FormItem';
import Modal from '../../Modal/GenericModal';
import PaymentScheduleSelect from '../../PaymentScheduleSelect';
import Select from '../../Select';
import TextInput from '../../TextInput';

const GroupCreationModal = props => {
  const { t, visible, onGroupCreate, onCancel, groupManagerList, isCreatingGroup } = props;

  const [form] = Form.useForm();
  const companySettings = useSelector(selectStoreCompanySettings);
  const currentCompany = useSelector(selectStoreCurrentCompany);

  useEffect(() => {
    if (visible) {
      form.resetFields();
    }
    // eslint-disable-next-line
  }, [visible]);

  return (
    <Modal
      centered
      title={t('Create group')}
      visible={visible || isCreatingGroup}
      onCancel={onCancel}
      footer={
        <Row gutter={17} align="middle" justify="end">
          <Col>
            <Button
              text={t('Cancel')}
              type="secondary"
              onClick={onCancel}
              disabled={isCreatingGroup}
            />
          </Col>
          <Col>
            <Button
              key="ok"
              text={t(`OK`)}
              disabled={isCreatingGroup}
              loading={isCreatingGroup}
              onClick={() =>
                form.validateFields().then(values => {
                  onGroupCreate(values);
                })
              }
            />
          </Col>
        </Row>
      }
    >
      <div>
        <Form form={form} labelCol={{ span: 24 }}>
          <FormItem
            required
            label="Name"
            name="name"
            rules={[{ required: true, message: ERROR_MESSAGE.BLANK_FIELD }]}
          >
            <TextInput required disabled={isCreatingGroup} />
          </FormItem>

          <FormItem
            required
            label="Group Manager"
            name="managerID"
            rules={[{ required: true, message: ERROR_MESSAGE.BLANK_FIELD }]}
          >
            <Select
              placeholder="Select a Manager or Company Admin"
              disabled={isCreatingGroup}
              options={groupManagerList.map(user => ({
                value: user._id,
                label: getUserFullName(user),
              }))}
            />
          </FormItem>

          <FormItem
            required
            name="paymentScheduleId"
            label={t('paymentSchedule')}
            rules={[{ required: true, message: ERROR_MESSAGE.BLANK_FIELD }]}
          >
            <PaymentScheduleSelect disabled={isCreatingGroup} />
          </FormItem>

          {companySettings.dailyMileageLog && (
            <Form.Item
              label={t('mileageEntryMode')}
              name="mileageEntryMode"
              rules={[
                {
                  required: true,
                  message: ERROR_MESSAGE.BLANK_FIELD,
                },
              ]}
            >
              <Select
                placeholder="Select"
                disabled={isCreatingGroup}
                options={[
                  { label: t('tripBased'), value: DAILY_MILEAGE_ENTRY_MODES.TRIP_BASED },
                  {
                    label: t('dailyMileageLog'),
                    value: DAILY_MILEAGE_ENTRY_MODES.DAILY_MILEAGE_LOG,
                  },
                ]}
              />
            </Form.Item>
          )}

          {companySettings.mileageCap && (
            <Form.Item
              label={t('mileageCap')}
              name="mileageCapInMeters"
              rules={[
                { required: true, message: ERROR_MESSAGE.BLANK_FIELD },
                {
                  validateTrigger: 'onSubmit',
                  validator: (_, value) => {
                    if (!value) return Promise.resolve();
                    if (value < 0) return Promise.reject(ERROR_MESSAGE.MIN_NUMBER(0));
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <TextInput
                disabled={isCreatingGroup}
                type="number"
                step="any"
                suffix={getDistanceUnitStringValue(get(currentCompany, 'address.country'))}
              />
            </Form.Item>
          )}
        </Form>
      </div>
    </Modal>
  );
};

export default GroupCreationModal;
