import { Form } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { ERROR_MESSAGE } from 'utils/constants';

import TextInput from '../TextInput';

const PostalCodeInput = ({
  required,
  country,
  name,
  size,
  value,
  defaultValue,
  disabled,
  ...rest
}) => {
  return (
    <div>
      <Form.Item
        {...rest}
        name={name}
        rules={[
          { required: !!required, message: ERROR_MESSAGE.BLANK_FIELD },
          {
            pattern:
              country === 'US' ? new RegExp(/^[0-9]*$/) : new RegExp(/^(?=.*\d)[a-z\d\s-]*$/i),
            message: 'Please enter a valid zip code',
          },
        ]}
      >
        <TextInput
          disabled={disabled}
          defaultValue={defaultValue}
          value={value}
          style={{ textTransform: 'uppercase' }}
          size={size}
        />
      </Form.Item>
    </div>
  );
};

PostalCodeInput.propTypes = {
  country: PropTypes.string,
  name: PropTypes.string,
  size: PropTypes.oneOf(['small', 'large']),
  value: PropTypes.string,
  defaultValue: PropTypes.string,
};

PostalCodeInput.defaultProps = {
  name: 'postalCode',
  size: 'large',
  value: undefined,
  defaultValue: undefined,
};

export default PostalCodeInput;
