import { get } from 'lodash';
import React from 'react';

import ProfileNamePicture from '../components/shared-ui/ProfileNamePicture';
import Switch from '../components/Switch';
import StatusTag from '../components/Tag/StatusTag';

/**
 * @callback ColumnCreator
 * @param {object} params
 * @param {import('i18next').TFunction} params.t
 * @param {string?} params.key
 * @param {string | string[]?} params.dataIndex
 * @param {string | React.ReactNode?} params.title
 * @param {"descend" | "ascend"?} params.defaultSortOrder
 * @returns {import('antd/lib/table/Column').ColumnProps}
 */

const TABLE_COLUMNS = {
  /** @type {ColumnCreator} */
  USER_PROFILE_NAME: ({ t, ...rest }) => ({
    render: user => <ProfileNamePicture t={t} user={user} />,
    ...rest,
    key: get(rest, 'key', 'user.firstName,user.lastName'),
    title: get(rest, 'title', t('user')),
    dataIndex: get(rest, 'dataIndex', 'user'),
  }),

  /** @type {ColumnCreator} */
  EMAIL: ({ t, ...rest }) => ({
    ellipsis: true,
    ...rest,
    key: get(rest, 'key', 'user.email'),
    title: get(rest, 'title', t('email')),
    dataIndex: get(rest, 'dataIndex', ['user', 'email']),
  }),

  STATUS_TAG: ({ t, ...rest }) => ({
    render: status => <StatusTag status={status} />,
    ...rest,
    key: get(rest, 'key', 'status'),
    title: get(rest, 'title', t('status')),
    dataIndex: get(rest, 'dataIndex', 'status'),
  }),

  /** @type {ColumnCreator} */
  RADAR_SDK_TRIP_TRACKING: ({ t, ...rest }) => ({
    render: (tripTracking, data) => {
      return (
        <Switch
          checked={tripTracking === 'radarSDK'}
          onChange={checked => rest.onChange(checked, data)}
        />
      );
    },
    ...rest,
    key: get(rest, 'key', 'settings.tripTracking'),
    title: get(rest, 'title', t('useRadarSdk')),
    dataIndex: get(rest, 'dataIndex', ['settings', 'tripTracking']),
  }),
};

export default TABLE_COLUMNS;
