import { STATUS_LIST } from './index';

const SelectOptions = [
  {
    label: 'Paid',
    value: STATUS_LIST.Status.PAID,
  },
  {
    label: 'Awaiting Approval',
    value: STATUS_LIST.Status.PENDING,
  },
  {
    label: 'More Info',
    value: STATUS_LIST.Status.MORE_INFO,
  },
  {
    label: 'Denied',
    value: STATUS_LIST.Status.DENIED,
  },
  {
    label: 'Approved',
    value: STATUS_LIST.Status.APPROVED,
  },
  {
    label: 'Overdue',
    value: STATUS_LIST.Status.OVERDUE,
  },
];

const SelectionOptionsWithACH = [
  ...SelectOptions,
  {
    label: STATUS_LIST.StatusTagColorConfig[STATUS_LIST.Status.ACH_PENDING].label,
    value: STATUS_LIST.Status.ACH_PENDING,
  },
  {
    label: STATUS_LIST.StatusTagColorConfig[STATUS_LIST.Status.ACH_FAILED].label,
    value: STATUS_LIST.Status.ACH_FAILED,
  },
  {
    label: STATUS_LIST.StatusTagColorConfig[STATUS_LIST.Status.ACH_CANCELLED].label,
    value: STATUS_LIST.Status.ACH_CANCELLED,
  },
];

export default { SelectOptions, SelectionOptionsWithACH };
