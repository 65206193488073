import './style.scss';

import { Input, Select } from 'antd';
import { CustomCheckbox, CustomTable } from 'components';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';

class VehiclePolicyConfView extends React.Component {
  tableColumns = [
    {
      title: () => (
        <div className="d-flex justify-content-center align-items-center f-dir-column">
          <span className="mb-2">Required</span>
          <CustomCheckbox disabled={this.props.readOnly} type="secondary" />
        </div>
      ),
      dataIndex: ['required', 'key'],
      key: 'required',
      width: 50,
      render: (actions, data) => (
        <CustomCheckbox disabled={this.props.readOnly} type="secondary" checked={data.required} />
      ),
      align: 'center',
    },
    {
      title: () => <span className="d-flex justify-content-center">Field</span>,
      dataIndex: 'field',
      key: 'field',
    },
    {
      title: '',
      dataIndex: 'data',
      key: 'data',
      render: data => {
        let node = null;
        if (data) {
          if (data.type === 'year') {
            node = (
              <Select
                showSearch
                disabled={this.props.readOnly}
                defaultValue={data.value}
                style={{ width: '200px' }}
                onChange={e => this.changeVehicleInfo('Vehicle Year', e)}
                placeholder="Select max vehicle age"
              >
                {data.options().map((item, index) => (
                  <Select.Option key={index} value={item}>
                    {item}
                  </Select.Option>
                ))}
              </Select>
            );
          } else if (data.type === 'mileage') {
            node = (
              <div className="d-flex">
                <Input
                  disabled={this.props.readOnly}
                  className="mr-2"
                  type="number"
                  defaultValue={data.value}
                  style={{ maxWidth: '200px' }}
                  onChange={value => this.changeVehicleInfo('Vehicle Odometer', value, 'value')}
                  placeholder="Enter max odometer"
                />
                <Select
                  disabled={this.props.readOnly}
                  defaultValue={data.unit}
                  style={{ width: '100px' }}
                  onChange={e => this.changeVehicleInfo('Vehicle Odometer', e, 'unit')}
                  placeholder="Select miles or kilometers"
                >
                  {data.unitOptions.map((item, index) => (
                    <Select.Option key={index} value={item.value}>
                      {item.text}
                    </Select.Option>
                  ))}
                </Select>
              </div>
            );
          }
        }
        return node;
      },
      align: 'center',
    },
  ];

  tableData = [
    {
      key: 1,
      required: true,
      field: 'Vehicle Year',
      data: {
        type: 'year',
        value: '',
        options: () => {
          const currentYear = moment().year();
          let res = [];
          for (let i = 1970; i <= currentYear; i++) {
            res.push(i);
          }
          return res;
        },
      },
    },
    {
      key: 2,
      required: true,
      field: 'Vehicle Make',
      data: {},
    },
    {
      key: 3,
      required: true,
      field: 'Vehicle Model',
      data: {},
    },
    {
      key: 4,
      required: true,
      field: 'Vehicle Odometer',
      data: {
        type: 'mileage',
        value: '0',
        unit: 'mile',
        unitOptions: [
          {
            text: 'mile',
            value: 'mile',
          },
          {
            text: 'km',
            value: 'km',
          },
        ],
      },
    },
    {
      key: 5,
      required: true,
      field: 'Owned / Leased',
      data: {},
    },
    {
      key: 6,
      required: true,
      field: 'Vehicle License Plate',
      data: {},
    },
    {
      key: 7,
      required: true,
      field: 'State of Vehicle License Plate',
      data: {},
    },
  ];

  constructor(props) {
    super(props);

    const { creating } = this.props.policy;
    let data = this.tableData;
    if (creating && creating.data && creating.data.length) {
      data = creating.data;
    }

    this.state = {
      tableColumns: this.tableColumns,
      tableData: data,
    };
    this.props.onChange(data);
  }

  componentDidMount() {
    this.props.onChange(this.state.tableData);
  }

  changeRequired = key => {
    let { tableData } = this.state;
    const index = tableData.findIndex(item => item.key === key);
    if (index >= 0) {
      tableData[index].required = !tableData[index].required;
    }
    this.setState({ tableData });
    this.props.onChange(tableData);
  };

  selectAllRequired = e => {
    if (e) {
      const { checked } = e.target;
      let { tableData } = this.state;
      tableData.map(item => (item.required = checked));
      this.setState({ tableData });
      this.props.onChange(tableData);
    }
  };

  changeVehicleInfo = (field, value, subField = '') => {
    const { tableData } = this.state;
    const index = tableData.findIndex(item => item.field === field);
    if (index >= 0) {
      switch (field) {
        case 'Vehicle Year':
          tableData[index].data.value = value;
          break;
        case 'Vehicle Odometer':
          if (subField === 'value') {
            tableData[index].data.value = value;
          } else if (subField === 'unit') {
            tableData[index].data.unit = value;
          }
          break;
        default:
      }
      this.setState({ tableData });
      this.props.onChange(tableData);
    }
  };

  render() {
    const { tableData, tableColumns } = this.state;

    return (
      <div className="insurance-policy-view">
        <CustomTable pagination={false} bordered dataSource={tableData} columns={tableColumns} />
      </div>
    );
  }
}

VehiclePolicyConfView.propTypes = {
  data: PropTypes.array,
  readOnly: PropTypes.bool,
  onChange: PropTypes.func,
};

VehiclePolicyConfView.defaultProps = {
  data: [],
  readOnly: false,
  onChange: () => {},
};

const mapStateToProps = (state, ownProps) => {
  return {
    policy: state.policy,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withNamespaces()(VehiclePolicyConfView));
