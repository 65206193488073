import { Col, Form, Row } from 'antd';
import { range } from 'lodash';
import moment from 'moment-timezone';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';

import { handleApiErrors } from '../../../api/axiosInstance';
import { USER_API } from '../../../api/user';
import Box from '../../../components/Box';
import FormItem from '../../../components/Form/FormItem';
import TripRecordingDayEditModal from '../../../components/Modal/TripRecordingDayEditModal';
import Select from '../../../components/Select';
import SubmitCancelButtonGroup from '../../../components/SubmitCancelButtonGroup';
import Switch from '../../../components/Switch';
import Text from '../../../components/Text';
import FadedText from '../../../components/Text/FadedText';
import LinkText from '../../../components/Text/LinkText';
import Toast from '../../../components/Toast';
import useSupportedTimezones from '../../../hooks/useSupportedTimezones';
import { formatPageTitle } from '../../../utils/common';
import { ERROR_MESSAGE } from '../../../utils/constants';

const DAYS_ARRAY = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

const formatBusinessDayTime = data => {
  const bussinessDayTime = {};

  if (Array.isArray(data)) {
    data.forEach(dt => {
      bussinessDayTime[dt.day] = dt;
    });
  }

  return bussinessDayTime;
};

const extendBusinessDayTimeObject = (dts, day, newValues) => {
  return {
    ...dts,
    [day]: {
      day,
      ...(dts[day] || {}),
      ...newValues,
    },
  };
};

const formatNumberStringIntoHours = string => {
  if (!string) return '';
  return moment(string, 'HHmm').format('hh:mm A');
};

const TripRecordingPreferencesPage = props => {
  const { t, userDetails, isUserDeleted, onUserUpdate } = props;
  const { preferences } = userDetails;

  const [form] = Form.useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedDayToEdit, setSelectedDayToEdit] = useState();
  const [autoTripRecording, setAutoTripRecording] = useState(!!preferences?.businessHoursAutoStart);

  const timezones = useSupportedTimezones();

  const [businessDayTime, setBusinessDayTime] = useState(
    formatBusinessDayTime(preferences?.businessDayTime),
  );

  const handleSubmit = async v => {
    setIsSubmitting(true);

    try {
      const updatedData = await USER_API.updateUserTripRecordingPreferences(userDetails._id, {
        ...v,
        tripStopDurationUnit: 'minutes',
        businessDayTime: Object.keys(v.businessDayTime)
          .filter(day => v.businessDayTime[day]?.canEdit)
          .map(day => v.businessDayTime[day]),
      });
      onUserUpdate(updatedData);
      setSelectedDayToEdit();
      Toast({
        type: 'open',
        message: t('tripRecordingUpdateSuccess'),
      });
    } catch (error) {
      handleApiErrors(error.response);
    }

    setIsSubmitting(false);
  };

  const handleBusinessDayTimeChange = (day, newValues) => {
    setBusinessDayTime(dt => extendBusinessDayTimeObject(dt, day, newValues));
  };

  const handleModalSubmit = async ({ allDaysWithSameData, ...newValues }) => {
    setIsSubmitting(true);

    let businessDays = { ...businessDayTime };

    if (allDaysWithSameData) {
      DAYS_ARRAY.forEach(day => {
        handleBusinessDayTimeChange(day, newValues);
        businessDays = extendBusinessDayTimeObject(businessDays, day, newValues);
      });
    } else {
      handleBusinessDayTimeChange(selectedDayToEdit, newValues);
      businessDays = extendBusinessDayTimeObject(businessDays, selectedDayToEdit, newValues);
    }

    setTimeout(() => {
      handleSubmit({ ...form.getFieldsValue(true), businessDayTime: businessDays });
    }, 500);
  };

  const INITIAL_VALUES = {
    businessHoursAutoStart: preferences?.businessHoursAutoStart,
    preferredTimezone: preferences?.preferredTimezone,
    tripStopDurationMinimum: preferences?.tripStopDurationMinimum || 15,
  };

  return (
    <Box>
      <Helmet>
        <title>{formatPageTitle('Trip Recording')}</title>
      </Helmet>

      <Text variant="h5">{t('tripRecording')}</Text>

      <Form
        form={form}
        initialValues={INITIAL_VALUES}
        onFinish={v => handleSubmit({ ...v, businessDayTime })}
      >
        <FormItem>
          <Row gutter={16} wrap={false} align="middle">
            <Col>
              <FormItem name="businessHoursAutoStart" className="no-margin-bottom">
                <Switch
                  disabled={isSubmitting || isUserDeleted}
                  defaultChecked={INITIAL_VALUES?.businessHoursAutoStart}
                  onChange={checked => {
                    setAutoTripRecording(checked);

                    if (!checked) {
                      DAYS_ARRAY.forEach(day => {
                        handleBusinessDayTimeChange(day, { canEdit: false });
                      });
                    }
                  }}
                />
              </FormItem>
            </Col>

            <Col flex="200px">
              <FormItem
                className="no-margin-bottom"
                label={t('automaticTripRecording')}
                help="If enabled, Kliks will automatically record your trips by detecting when you start and stop driving."
              />
            </Col>
          </Row>
        </FormItem>

        <FormItem
          name="preferredTimezone"
          label={t('timezone')}
          rules={[{ required: true, message: ERROR_MESSAGE.BLANK_FIELD }]}
          help={
            <>
              <span style={{ marginBottom: 10, display: 'block' }}>
                {t('timezoneChangeByKliksMobile')}
              </span>
              <span>{t('timezoneChangeByKliksMobile_reason')}</span>
            </>
          }
        >
          <Select
            loading={!timezones.length}
            disabled={!timezones.length || isSubmitting || isUserDeleted}
            showSearch
            optionFilterProp="children"
            options={timezones}
          />
        </FormItem>

        <FormItem
          name="tripStopDurationMinimum"
          label={t('tripStopDurationMinimum')}
          rules={[{ required: true, message: ERROR_MESSAGE.BLANK_FIELD }]}
        >
          <Select
            showSearch
            disabled={isSubmitting || isUserDeleted}
            options={range(5, 26, 5).map(minutes => ({
              label: t('nMinutes', { count: minutes }),
              value: minutes,
            }))}
          />
        </FormItem>

        <div>
          {DAYS_ARRAY.map(day => {
            const dayData = businessDayTime?.[day];
            const startTime = formatNumberStringIntoHours(dayData?.startTime);
            const endTime = formatNumberStringIntoHours(dayData?.endTime);

            return (
              <Row key={day} gutter={20} wrap={false} align="middle" style={{ marginBottom: 10 }}>
                <Col flex="220px">
                  <Row gutter={16} wrap={false}>
                    <Col>
                      <Switch
                        disabled={isSubmitting || !autoTripRecording || isUserDeleted}
                        defaultChecked={dayData?.canEdit}
                        checked={dayData?.canEdit}
                        onChange={checked =>
                          handleBusinessDayTimeChange(day, {
                            canEdit: checked,
                            ...(!startTime && !endTime
                              ? { startTime: '0000', endTime: '2359', is24HourSelected: true }
                              : {}),
                          })
                        }
                      />
                    </Col>

                    <Col>
                      <Text style={{ lineHeight: '18px' }}>{day}</Text>
                      {dayData?.canEdit && startTime && endTime && (
                        <FadedText size="sm">
                          {startTime} - {endTime}
                        </FadedText>
                      )}
                    </Col>
                  </Row>
                </Col>

                {autoTripRecording && !isUserDeleted && (
                  <Col>
                    <LinkText
                      onClick={
                        !isSubmitting
                          ? () => {
                              form.validateFields().then(() => setSelectedDayToEdit(day));
                            }
                          : undefined
                      }
                    >
                      Edit
                    </LinkText>
                  </Col>
                )}
              </Row>
            );
          })}
        </div>

        <SubmitCancelButtonGroup
          isForm
          hideCancel
          submitText={t('Save')}
          loading={isSubmitting}
          disabled={isSubmitting || isUserDeleted}
        />
      </Form>

      <TripRecordingDayEditModal
        t={t}
        day={selectedDayToEdit}
        isSubmitting={isSubmitting}
        visible={!!selectedDayToEdit || (!!selectedDayToEdit && isSubmitting)}
        onCancel={() => setSelectedDayToEdit(undefined)}
        onOk={handleModalSubmit}
        initialValues={
          businessDayTime[selectedDayToEdit]
            ? {
                startTime: moment()
                  .set(
                    'hours',
                    parseInt(
                      moment(businessDayTime[selectedDayToEdit].startTime, 'HHmm').format('HH'),
                    ),
                  )
                  .set(
                    'minutes',
                    moment(businessDayTime[selectedDayToEdit].startTime, 'HHmm').format('mm'),
                  ),
                endTime: moment()
                  .set(
                    'hours',
                    moment(businessDayTime[selectedDayToEdit].endTime, 'HHmm').format('HH'),
                  )
                  .set(
                    'minutes',
                    moment(businessDayTime[selectedDayToEdit].endTime, 'HHmm').format('mm'),
                  ),
                is24HourSelected: businessDayTime[selectedDayToEdit].is24HourSelected,
              }
            : { is24HourSelected: false }
        }
      />
    </Box>
  );
};

export default TripRecordingPreferencesPage;
