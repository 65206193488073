import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '../Tooltip';
import { IMAGES } from '../../enum';
import { useTranslation } from 'react-i18next/hooks';

import classNames from './style.module.scss';

const TripSourceTrackIconsRow = props => {
  const [t] = useTranslation();
  const { onMobileIconClick, onVehicleIconClick, onManualIconClick } = props;

  return (
    <div className={classNames.iconsRow}>
      <Tooltip title={t('Tracked using mobile app')}>
        <img alt="mobile track" src={IMAGES.MOBILE_TRACK_ICON} onClick={onMobileIconClick} />
      </Tooltip>
      <Tooltip title={t('Tracked using vehicle device')}>
        <img alt="gps track" src={IMAGES.GPS_TRACK_ICON} onClick={onVehicleIconClick} />
      </Tooltip>
      <Tooltip title={t('Manually created trips')}>
        <img alt="manual trip track" src={IMAGES.MANUAL_TRACK_ICON} onClick={onManualIconClick} />
      </Tooltip>
    </div>
  );
};

TripSourceTrackIconsRow.propTypes = {
  onMobileIconClick: PropTypes.func,
  onVehicleIconClick: PropTypes.func,
  onManualIconClick: PropTypes.func,
};

TripSourceTrackIconsRow.defaultProps = {
  onMobileIconClick: () => undefined,
  onVehicleIconClick: () => undefined,
  onManualIconClick: () => undefined,
};

export default TripSourceTrackIconsRow;
