import React from 'react';
import { withNamespaces } from 'react-i18next';

import './style.scss';

class DriverPolicyConfView extends React.Component {
  render() {
    const { t } = this.props;

    return (
      <div className="dpv">
        <h6 className="dpv-title">{`${t(
          "All users will be required to submit the following driver's license information",
        )}:`}</h6>
        <ul className="dpv-ul">
          <li>{t('First Name')}</li>
          <li>{t('Middle Name')}</li>
          <li>{t('Last Name')}</li>
          <li>{t('License Number')}</li>
          <li>{t('License State / Province')}</li>
          <li>{t('Country')}</li>
          <li>{t('License Issue Date')}</li>
          <li>{t('License Expiration Date')}</li>
          <li>{t('License Vehicle Class(es)')}</li>
        </ul>
      </div>
    );
  }
}

export default withNamespaces()(DriverPolicyConfView);
