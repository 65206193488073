import React, { useState } from 'react';
import { Col, DatePicker, Empty, Row, Tabs } from 'antd';
import queryString from 'querystring';
import moment from 'moment-timezone';

import { Button } from '../../../../components';
import Toast from '../../../../components/Toast';
import Box from '../../../../components/Box';
import Text from '../../../../components/Text';
import TabsContainer from '../../../../components/TabsContainer';
import AnonymizeDataOptions from '../../../gdpr/components/AnonymizeDataOptions';
import StatusTab from '../../../gdpr/components/StatusTab';

import { handleApiErrors } from '../../../../api/axiosInstance';
import { validateDownloadDataRequest } from '../../../gdpr/DownloadUserDataPage/download-user-data-permissions';
import {
  createUserDataDownloadRequest,
  downloadUserDataFile,
} from '../../../../api/userDataActions';
import { momentFormat } from '../../../../utils/common';

const USER_INFO_TAB_KEY = 'userInfo';
const STATUS_TAB_KEY = 'status';

const DownloadDataTab = props => {
  const {
    t,
    history,
    location,
    loggedInUserID,
    currentCompany,
    downloadRequets,
    onDownload,
  } = props;

  const [isDownloadingData, setIsDownloadingData] = useState(false);
  const [downloadingRequestID, setDownloadingRequestID] = useState();

  const { activeTab, subTab } = queryString.parse(location.search.replace('?', ''));

  const [activePane, setActivePane] = useState(subTab || USER_INFO_TAB_KEY);

  const [dateRange, setDateRange] = useState([]);

  const [trips, setTrips] = useState(false);
  const [receipts, setReceipts] = useState(false);
  const [reimbursements, setReimbursements] = useState(false);
  const [profileInfo, setProfileInfo] = useState(false);

  const handleTabChange = tabKey => {
    setActivePane(tabKey);
    history.replace({ search: queryString.stringify({ activeTab, subTab: tabKey }) });
  };

  const handleDownloadDataRequest = async () => {
    const data = {
      trips,
      receipts,
      reimbursements,
      profile: profileInfo,
      entireDateRange: false,
      fromDate: dateRange[0] ? momentFormat(dateRange[0], 'YYYY-MM-DD') : undefined,
      toDate: dateRange[1] ? momentFormat(dateRange[1], 'YYYY-MM-DD') : undefined,
      userIds: [loggedInUserID],
    };

    setIsDownloadingData(true);

    if (validateDownloadDataRequest(data, t)) {
      try {
        await createUserDataDownloadRequest(data);
        if (typeof onDownload === 'function') await onDownload();

        handleTabChange(STATUS_TAB_KEY);
        Toast({
          type: 'success',
          message: t('userDataExportBeingGenerated'),
        });
      } catch (error) {
        handleApiErrors(error.response, () => {
          Toast({
            type: 'error',
            message: 'downloadDataRequestError',
          });
        });
      }
    }

    setIsDownloadingData(false);
  };

  const downloadFile = async (blob, filename) => {
    let url = window.URL.createObjectURL(blob);
    let a = document.createElement('a');
    a.href = url;
    a.download = `${filename}.csv`;
    a.click();
  };

  const handleDownload = async id => {
    setDownloadingRequestID(id);
    try {
      const blob = await downloadUserDataFile(id);
      await downloadFile(blob, 'user-data');

      Toast({
        type: 'success',
        message: t('requestDownloaded'),
      });
    } catch (error) {
      handleApiErrors(error.response, () => {
        Toast({
          type: 'error',
          message: t('errorDownloadingDataRequest'),
        });
      });
    }
    setDownloadingRequestID(undefined);
  };

  return (
    <Box>
      <TabsContainer activeKey={activePane} onChange={handleTabChange}>
        <Tabs.TabPane key={USER_INFO_TAB_KEY} tab={t('yourInformation')}>
          <AnonymizeDataOptions
            t={t}
            tripChecked={trips}
            reimbursementChecked={reimbursements}
            receiptsChecked={receipts}
            profileChecked={profileInfo}
            onTripsClick={setTrips}
            onReceiptClick={setReceipts}
            onReimbursmentClick={setReimbursements}
            onProfileClick={setProfileInfo}
            onSelectAllData={() => {
              setTrips(true);
              setReceipts(true);
              setReimbursements(true);
              setProfileInfo(true);
              setDateRange([moment(currentCompany.created), moment()]);
            }}
          />

          <br />

          <Row align="middle">
            <Col>
              <DatePicker.RangePicker
                allowClear={false}
                value={dateRange}
                onChange={setDateRange}
              />
            </Col>
            <Col flex={1} />
            <Col>
              <Button
                disabled={isDownloadingData}
                loading={isDownloadingData}
                onClick={handleDownloadDataRequest}
              >
                {t('Create File')}
              </Button>
            </Col>
          </Row>
        </Tabs.TabPane>

        <Tabs.TabPane key={STATUS_TAB_KEY} tab={t('availableFiles')}>
          {!!downloadRequets.length ? (
            <StatusTab
              t={t}
              downloadingRequestID={downloadingRequestID}
              requests={downloadRequets}
              loggedInUserID={loggedInUserID}
              onDownload={id => handleDownload(id)}
            />
          ) : (
            <Empty
              description={
                <div>
                  <Text variant="strong" size="sm" textAlign="center">
                    {t('noAvailableCopies')}
                  </Text>
                  <Text size="sm">{t('youDoNotHaveFilesYet')}</Text>
                </div>
              }
            />
          )}
        </Tabs.TabPane>
      </TabsContainer>
    </Box>
  );
};

DownloadDataTab.defaultProps = {
  downloadRequets: [],
};

export default DownloadDataTab;
