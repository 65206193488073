/* eslint-disable no-useless-escape */
import Toast from '../components/Toast';

export function isValidPassword(pw) {
  let result = 0;
  if (!pw) {
    result = 4;
  } else if (pw.length < 8) {
    result = 1;
  } else {
    let containNumber = false;
    let containCapical = false;
    let containUpperCase = false;
    for (let i = 0; i < pw.length; i++) {
      const c = pw.charAt(i);
      if (!isNaN(c)) {
        containNumber = true;
      }
      let characterPattern = /[~`@!#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/g;
      if (characterPattern.test(c)) {
        containCapical = true;
      }
      let upperCasePattern = /^[A-Z]*$/;
      let result = upperCasePattern.test(c);
      if (result) {
        containUpperCase = true;
      }
    }
    if (!containNumber) {
      result = 2;
    }
    if (!containCapical) {
      result = 3;
    }
    if (!containUpperCase) {
      result = 5;
    }
  }

  return result;
}

export function validateFields(field, value, value1 = '') {
  let result = {};
  switch (field) {
    case 'password':
      switch (isValidPassword(value)) {
        case 0:
          result = {
            validateStatus: 'success',
            errorMsg: null,
          };
          break;
        case 1:
          result = {
            validateStatus: 'error',
            errorMsg:
              'Your password needs to be at least 8 characters, contain at least one: upper case letter, special character, and number.',
          };
          break;
        case 2:
          result = {
            validateStatus: 'error',
            errorMsg:
              'Your password needs to be at least 8 characters, contain at least one: upper case letter, special character, and number.',
          };
          break;
        case 3:
          result = {
            validateStatus: 'error',
            errorMsg:
              'Your password needs to be at least 8 characters, contain at least one: upper case letter, special character, and number.',
          };
          break;
        case 4:
          result = {
            validateStatus: 'error',
            errorMsg: 'Please enter your password.',
          };
          break;
        case 5:
          result = {
            validateStatus: 'error',
            errorMsg:
              'Your password needs to be at least 8 characters, contain at least one: upper case letter, special character, and number.',
          };
          break;

        default:
          break;
      }
      break;
    case 'confirmPassword':
      if (value) {
        const isValid = isValidPassword(value);
        if (isValid === 0 && value === value1) {
          result = {
            validateStatus: 'success',
            errorMsg: null,
          };
        } else {
          result = {
            validateStatus: 'error',
            errorMsg: 'The passwords do not match. Please re-enter your password.',
          };
        }
      } else {
        result = {
          validateStatus: 'error',
          errorMsg: 'The passwords do not match. Please re-enter your password.',
        };
      }
      break;
    case 'acceptedTerm':
      if (value) {
        result = {
          validateStatus: 'success',
          errorMsg: null,
        };
      } else {
        result = {
          validateStatus: 'error',
          errorMsg: 'Please accept our terms and conditions.',
        };
      }
      break;
    default:
  }

  return result;
}

export function isAmericaPhoneNumber(number) {
  const americaPhonePattern = /^\D*([2-9]\d{2})(\D*)([2-9]\d{2})(\D*)(\d{4})\D*$/;
  return americaPhonePattern.test(number);
}

const MAX_IMAGE_FILE_SIZE = 2000000;

/**
 * Validates the provided image file to check if it matches the required dimensions
 *
 * @param {File} imageFile Image file
 * @param {Function} onSuccess Callback if image is valid. It takes the file and a base64 version of the image as params
 */
export const validateProfileImageSize = (imageFile, onSuccess = (file, base64) => undefined) => {
  if (imageFile) {
    let reader = new FileReader();
    reader.onload = e => {
      if (e.total > MAX_IMAGE_FILE_SIZE) {
        reader.abort();
        Toast({
          type: 'error',
          message: `Image upload failed. Your image is too large. The maximum image size is ${(
            MAX_IMAGE_FILE_SIZE / 1048576
          ).toFixed(0)}MB.`,
        });
        return;
      }

      let image = new Image();
      image.onload = () => {
        if (image.width > 180 || image.height > 180) {
          Toast({
            type: 'warning',
            duration: 10, // seconds
            message: `The image size needs to be 180px x 180px. Your image size is ${image.width} x ${image.height}. We will automatically resize your image or you can choose to upload a different image.`,
          });
          if (typeof onSuccess === 'function') {
            onSuccess(imageFile, reader.result);
          }
        } else if (image.width === 180 && image.height === 180 && typeof onSuccess === 'function') {
          onSuccess(imageFile, reader.result);
        } else {
          Toast({
            type: 'error',
            duration: 10, // seconds
            message: `The image size needs to be 180px x 180px. Your image size is ${image.width} x ${image.height}. Please upload an image with the correct size.`,
          });
        }
      };

      image.src = reader.result;
    };
    reader.readAsDataURL(imageFile);
  }
};

/**
 * Validates the provided image file to check if it matches the required dimensions
 *
 * @param {File} imageFile Image file
 * @param {Function} onSuccess Callback if image is valid. It takes the file and a base64 version of the image as params
 */
export const validateCompanyLogoImageSize = (
  imageFile,
  onSuccess = () => undefined,
  onError = () => undefined,
) => {
  const maxFileSize = 200000;

  if (imageFile) {
    let reader = new FileReader();
    reader.onload = event => {
      if (event.total > maxFileSize) {
        reader.abort();
        const maxFileSizeMessage = `Image upload failed. Your image is too large. The maximum image size is ${
          maxFileSize / 1000
        }KB.`;
        Toast({ type: 'error', message: maxFileSizeMessage });
        return onError(maxFileSizeMessage);
      }

      let image = new Image();
      image.onload = () => {
        if (image.width > 320 || image.height > 640) {
          const bigDimensionMessage = `The image size can't be more than 320 x 640. Your image size is ${image.width} x ${image.height}`;
          Toast({
            type: 'error',
            duration: 10, // seconds
            message: bigDimensionMessage,
          });
          onError(bigDimensionMessage);
        } else if (image.width < 160 || image.height < 320) {
          const smallDimensionMessage = `The image size must have a minimum size of 160 x 320. Your image size is ${image.width} x ${image.height}`;
          Toast({
            type: 'error',
            duration: 10, // seconds
            message: smallDimensionMessage,
          });
          onError(smallDimensionMessage);
        } else if (typeof onSuccess === 'function') {
          onSuccess(imageFile, reader.result);
        }
      };

      image.src = reader.result;
    };

    reader.readAsDataURL(imageFile);
  }
};

export const USER_NAME_VALIDATION_REGEXP = new RegExp(
  /^[^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{1,}$/,
);

export const EMAIL_SPECIAL_CHAR_VALIDATION_REGEXP = /[ `!#$%^&*()=\[\]{};':"\|,<>\/?]/;
