import { Col, Row } from 'antd';
import React, { useMemo } from 'react';

import { STATUS_LIST } from '../../../enum';
import {
  checkIfStringContainsValue,
  getUserFullName,
  momentFormat,
  momentTimezone,
} from '../../../utils/common';
import { CustomTable } from '../..';
import StatusTag from '../../Tag/StatusTag';
import FavrComplianceComponentsInfoTooltip from '../../Tooltips/FavrComplianceComponentsInfoTooltip';

const ComplianceUserHistoryTable = props => {
  const { t, dataSource, searchTerm, ...rest } = props;

  const filteredDataSource = useMemo(() => {
    let array = [...dataSource];

    if (searchTerm) {
      array = array.filter(item => {
        return checkIfStringContainsValue(getUserFullName(item), searchTerm);
      });
    }

    return array;
  }, [dataSource, searchTerm]);

  const COLUMNS = [
    {
      key: 'date',
      title: t('date'),
      dataIndex: 'created',
      render: created =>
        created
          ? `${momentFormat(created, 'DD MMM, YYYY')} (${momentTimezone(created).fromNow()})`
          : '-',
    },
    {
      key: 'event',
      title: t('event'),
      dataIndex: 'reason',
      render: (reason, data) => (reason ? t(`${data.type}-${reason}`) : '-'),
    },
    {
      key: 'complianceStatus',
      title: t('complianceStatus'),
      dataIndex: 'status',
      render: (status, data) => (
        <Row align="middle" gutter={5} wrap={false}>
          <Col>
            <StatusTag status={status} />
          </Col>
          {status === STATUS_LIST.Status.INVALID && (
            <Col>
              <FavrComplianceComponentsInfoTooltip
                t={t}
                type={data.type}
                complianceComponents={data.complianceComponents}
              />
            </Col>
          )}
        </Row>
      ),
    },
    {
      key: 'nonComplianceDetails',
      title: t('nonComplianceDetails'),
      dataIndex: 'nonComplianceDetails',
      render: () => '-',
    },
  ];

  return (
    <CustomTable
      {...rest}
      columns={COLUMNS}
      showSearchInput={false}
      dataSource={filteredDataSource}
      scroll={{ x: 1100 }}
    />
  );
};

export default ComplianceUserHistoryTable;
