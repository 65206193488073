import { Col, Row } from 'antd';
import React from 'react';

import { EXTERNAL_LINKS } from '../../enum';
import { Button } from '..';
import Alert from '../Alert';
import Text from '../Text';
import classNames from './styles.module.scss';

let INTENT_LINK = `intent://app.kliks.io/#Intent;scheme=https;package=${process.env.REACT_APP_ANDROID_PACKAGE_NAME};`;
INTENT_LINK += `S.browser_fallback_url=${encodeURIComponent(EXTERNAL_LINKS.KLIKS_PLAY_STORE)};end`;

const AndroidAppPrompt = props => {
  const { onClose } = props;
  return (
    <Alert
      banner
      closeable
      showIcon={false}
      className={classNames.androidAppPrompt}
      onClose={onClose}
      message={
        <Row gutter="10px" align="middle" justify="space-between" wrap={false}>
          <Col>
            <Text size="sm" variant="strong">
              Kliks - Mileage Tracking
            </Text>
            <Text size="xs">Open in the Kliks app</Text>
          </Col>
          <Col>
            <a href={INTENT_LINK}>
              <Button size="xs" text="Open" />
            </a>
          </Col>
        </Row>
      }
    ></Alert>
  );
};

export default AndroidAppPrompt;
