import { Checkbox, Col, Form, Row, Tag } from 'antd';
import { get } from 'lodash';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { isUserMemberInAnyGroup } from '../../../api/user';
import Box from '../../../components/Box';
import CompanyRatesLookupSelect from '../../../components/CompanyRatesLookupSelect';
import PaymentScheduleSelect from '../../../components/PaymentScheduleSelect';
import Select from '../../../components/Select';
import SpaceSpinner from '../../../components/SpaceSpinner';
import Text from '../../../components/Text';
import TextInput from '../../../components/TextInput';
import { DAILY_MILEAGE_ENTRY_MODES, STATUS_LIST } from '../../../enum';
import usePaymentSchedules from '../../../hooks/usePaymentSchedules';
import { getUserFullName } from '../../../utils/common';
import { ERROR_MESSAGE } from '../../../utils/constants';
import {
  selectStoreCompanyCommuteSettings,
  selectStoreCompanySettings,
  selectStoreCurrentCompany,
} from '../../../utils/storeSelectors';
import { getDistanceUnitStringValue } from '../../../utils/trips';
import CommuteDeductionSettingsSection from '../../company/company-settings/CommuteDeductionSettingsSection';
import CommuteSettingsToggleRow from '../../company/company-settings/CommuteSettingsToggleRow';

const ADDITIONAL_MANAGERS_LIMIT = 2;

const formatManagerOptions = (user, groupManager, additionalManagers, isMemberOfGroup) => ({
  value: user._id,
  label: `${user.firstName} ${user.lastName}`,
  disabled:
    [STATUS_LIST.Status.DELETED, STATUS_LIST.Status.INACTIVE].includes(user.status) ||
    user._id === groupManager ||
    additionalManagers?.includes(user._id) ||
    isMemberOfGroup,
});

const GroupDetailsFormBox = props => {
  const {
    t,
    group,
    initialGroupValues,
    isUpdate,
    isSubmitting,
    defaultGroup,
    groupMembers,
    groupManagerList,
    onManagerChange,
    onChangeDefaultGroup,
    onAddAdditionalManager,
    onRemoveAdditionalManager,
    onModelChange,

    commuteSettings,
    onCommuteSettingsChange,
    isCommuteSettingCustom,
    isCommuteSettingSingleLocation,
    isCommuteSettingNotHaveOffice,
    isNoOfficeMaxDeduction,
    isNoOfficeIgnoreFirstAndLast,
    isHomeAddressMaxCommuteDeduction,
    commuteDistanceUnit,
  } = props;

  const { isFetchingPaymentSchedules } = usePaymentSchedules();
  const companySettings = useSelector(selectStoreCompanySettings);
  const currentCompany = useSelector(selectStoreCurrentCompany);
  const companyCommuteSettings = useSelector(selectStoreCompanyCommuteSettings);

  const unselectedManagers = useMemo(() => {
    return groupManagerList.map(user => {
      const isMemberOfGroup = isUserMemberInAnyGroup(user, [{ users: groupMembers }]);

      return formatManagerOptions(
        user,
        group.groupManager,
        group.additionalManagers,
        isMemberOfGroup,
      );
    });
  }, [groupManagerList, group.groupManager, group.additionalManagers, groupMembers]);

  const unselectedManagersAdmins = useMemo(() => {
    return groupManagerList.map(user => {
      const isMemberOfGroup = isUserMemberInAnyGroup(user, [{ users: groupMembers }]);

      return formatManagerOptions(
        user,
        group.groupManager,
        group.additionalManagers,
        isMemberOfGroup,
      );
    });
  }, [groupManagerList, group.groupManager, group.additionalManagers, groupMembers]);

  const selectedAdditionalManagers = useMemo(() => {
    const managersArray = [];

    group.additionalManagers.forEach(managerId => {
      const user = groupManagerList.find(user => user._id === managerId);
      if (user) {
        return managersArray.push({
          id: user._id,
          name: getUserFullName(user),
        });
      }
    });

    return managersArray.sort((a, b) => a.name.localeCompare(b.name));
  }, [groupManagerList, group.additionalManagers]);

  if (isFetchingPaymentSchedules) {
    return (
      <Box style={{ maxWidth: 600, marginBottom: 40 }}>
        <SpaceSpinner />
      </Box>
    );
  }

  return (
    <Box style={{ maxWidth: 600, marginBottom: 40 }}>
      <Form.Item
        label="Name"
        name="name"
        rules={[
          {
            required: true,
            message: ERROR_MESSAGE.BLANK_FIELD,
          },
        ]}
      >
        <TextInput disabled={isSubmitting} />
      </Form.Item>
      <Row style={{ marginBottom: 24 }}>
        <Col flex={1}>
          <Row gutter={10} align="middle" justify="start">
            <Col flex="20px">
              <Checkbox
                size="small"
                className="default-group"
                checked={group.defaultGroup}
                disabled={isSubmitting}
                onChange={onChangeDefaultGroup}
              />
            </Col>
            <Col flex={1} style={{ height: 22 }}>
              <Form.Item
                className="faded-form-item"
                label="Default Group?"
                name="defaultGroup"
                valuePropName="checked"
                style={{ margin: 0, height: 20 }}
                onClick={() =>
                  !isSubmitting
                    ? onChangeDefaultGroup({ target: { checked: !group.defaultGroup } })
                    : undefined
                }
              />
            </Col>
          </Row>
          <Row gutter={10} align="middle" justify="start">
            <Col flex="26px" style={{ width: 26 }} />
            <Col flex={1}>
              {!group.defaultGroup && (
                <Text size="sm" variant="p" renderAs="h5" className="current-default-group-name">
                  Current default group:{' '}
                  <Text size="sm" variant="strong">
                    {defaultGroup.name}
                  </Text>
                </Text>
              )}
            </Col>
          </Row>
        </Col>
      </Row>

      <Form.Item
        label="Group Manager"
        name="groupManager"
        rules={[
          {
            required: true,
            message: ERROR_MESSAGE.BLANK_FIELD,
          },
        ]}
      >
        <Select
          placeholder="Select"
          disabled={isSubmitting}
          options={unselectedManagersAdmins}
          onChange={onManagerChange}
        />
      </Form.Item>

      <Form.Item
        className="no-margin-bottom"
        label="Additional Group Managers"
        extra={`You can select up to ${ADDITIONAL_MANAGERS_LIMIT} additional managers`}
      >
        <Select
          value={null}
          placeholder="Select"
          options={unselectedManagers}
          onChange={onAddAdditionalManager}
          disabled={
            isSubmitting ||
            !group.groupManager ||
            selectedAdditionalManagers.length === ADDITIONAL_MANAGERS_LIMIT
          }
        />
      </Form.Item>

      {!!selectedAdditionalManagers.length && (
        <Form.Item style={{ marginTop: 5 }}>
          <Row gutter={[16, 16]}>
            {selectedAdditionalManagers.map(({ id, name }) => {
              return (
                <Col xs={24} md={12} key={id}>
                  <Tag
                    closable
                    text={name}
                    onClose={() => onRemoveAdditionalManager(id)}
                    style={{
                      width: '100%',
                      height: 30,
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      display: 'flex',
                      fontSize: 14,
                    }}
                  >
                    {name}
                  </Tag>
                </Col>
              );
            })}
          </Row>
        </Form.Item>
      )}

      <Form.Item
        label="Company"
        name="company"
        rules={[
          {
            required: true,
            message: ERROR_MESSAGE.BLANK_FIELD,
          },
        ]}
      >
        <TextInput disabled readOnly={true} />
      </Form.Item>

      <Form.Item
        label="Rate"
        name="productId"
        rules={[
          {
            required: true,
            message: ERROR_MESSAGE.BLANK_FIELD,
          },
        ]}
      >
        <CompanyRatesLookupSelect
          t={t}
          disabled={isSubmitting}
          onChange={productId => onModelChange({ productId })}
        />
      </Form.Item>

      <Form.Item
        label="Payment Schedule"
        name="paymentScheduleId"
        rules={[
          {
            required: true,
            message: ERROR_MESSAGE.BLANK_FIELD,
          },
        ]}
      >
        <PaymentScheduleSelect
          disabled={isSubmitting}
          onChange={paymentScheduleId => onModelChange({ paymentScheduleId })}
        />
      </Form.Item>

      {companySettings.dailyMileageLog && (
        <Form.Item
          label={t('mileageEntryMode')}
          name="mileageEntryMode"
          rules={[
            {
              required: true,
              message: ERROR_MESSAGE.BLANK_FIELD,
            },
          ]}
        >
          <Select
            placeholder="Select"
            disabled={
              isSubmitting ||
              (isUpdate &&
                initialGroupValues.mileageEntryMode === DAILY_MILEAGE_ENTRY_MODES.DAILY_MILEAGE_LOG)
            }
            onChange={mileageEntryMode => onModelChange({ mileageEntryMode })}
            options={[
              { label: t('tripBased'), value: DAILY_MILEAGE_ENTRY_MODES.TRIP_BASED },
              { label: t('dailyMileageLog'), value: DAILY_MILEAGE_ENTRY_MODES.DAILY_MILEAGE_LOG },
            ]}
          />
        </Form.Item>
      )}

      {companySettings.mileageCap && (
        <Form.Item
          label={t('mileageCap')}
          name="mileageCapInMeters"
          rules={[
            { required: true, message: ERROR_MESSAGE.BLANK_FIELD },
            {
              validateTrigger: 'onSubmit',
              validator: (_, value) => {
                if (!value) return Promise.resolve();
                if (value < 0) return Promise.reject(ERROR_MESSAGE.MIN_NUMBER(0));
                return Promise.resolve();
              },
            },
          ]}
        >
          <TextInput
            type="number"
            step="any"
            disabled={isSubmitting}
            suffix={getDistanceUnitStringValue(get(currentCompany, 'address.country'))}
          />
        </Form.Item>
      )}

      {companyCommuteSettings?.enabled && (
        <>
          <CommuteSettingsToggleRow
            t={t}
            isSubmitting={isSubmitting}
            checked={commuteSettings?.enabled}
            disabledForm={companyCommuteSettings?.level === 'company'}
            onCommuteSettingsChange={onCommuteSettingsChange}
          />

          <CommuteDeductionSettingsSection
            t={t}
            hideStepNumber
            disabledForm={companyCommuteSettings?.level === 'company'}
            hideContent={!commuteSettings?.enabled}
            isSubmitting={isSubmitting}
            commuteSettings={commuteSettings}
            onCommuteSettingsChange={onCommuteSettingsChange}
            isCommuteSettingCustom={isCommuteSettingCustom}
            isCommuteSettingSingleLocation={isCommuteSettingSingleLocation}
            isCommuteSettingNotHaveOffice={isCommuteSettingNotHaveOffice}
            isNoOfficeMaxDeduction={isNoOfficeMaxDeduction}
            isNoOfficeIgnoreFirstAndLast={isNoOfficeIgnoreFirstAndLast}
            isHomeAddressMaxCommuteDeduction={isHomeAddressMaxCommuteDeduction}
            distanceUnit={commuteDistanceUnit}
          />
        </>
      )}
    </Box>
  );
};

export default GroupDetailsFormBox;
