import React from 'react';
import PropTypes from 'prop-types';
import { Popover } from 'antd';
// the hoc
import { withNamespaces } from 'react-i18next';
import { SettingFilled } from '@ant-design/icons';

import { CustomCheckbox, AddButton } from '../../components';


import './style.scss';

class SettingPopover extends React.Component {
  settingsContent = () => {
    const { t, columns, type } = this.props

    return (
      <div className="settings-columns">
        <h5 className="settings-columns-title">{t("Manage Columns")}</h5>
        <div className="settings-columns-list">
          {columns.map((s, index) =>
            <CustomCheckbox
              key={index}
              type={type}
              checked={s.checked}
              onChange={e => this.props.onColumnChange(index, e.target.checked)}
            >
              {t(s.title)}
            </CustomCheckbox>
          )}
        </div>
        <AddButton label="Add new column" />
      </div>
    )
  }

  render() {
    const { style, className, placement, trigger } = this.props

    return (
      <Popover
        className={className}
        placement={placement}
        content={this.settingsContent}
        trigger={trigger}
      >
        <SettingFilled className="caption-right-setting" style={style}/>
      </Popover>
    )
  }
}

SettingPopover.propTypes = {
  columns: PropTypes.array,
  onChange: PropTypes.func,
  type: PropTypes.string,
  style: PropTypes.object,
  className: PropTypes.string,
  placement: PropTypes.string,
  trigger: PropTypes.string, 
}

SettingPopover.defaultProps = {
  columns: () => [],
  onChange: () => { },
  type: 'primary',
  style: () => {},
  className: '',
  placement: 'bottom',
  trigger: 'hover'
}

export default withNamespaces()(SettingPopover);