import { Col, DatePicker, Form, Row } from 'antd';
import { isEqual, omit } from 'lodash';
import React, { useRef, useState } from 'react';

import Checkbox from '../../../components/Checkbox';
import FormItem from '../../../components/Form/FormItem';
import VehicleMakeSelect from '../../../components/Select/VehicleMakeSelect';
import VehicleModelSelect from '../../../components/Select/VehicleModelSelect';
import VehicleYearSelect from '../../../components/Select/VehicleYearSelect';
import TextInput from '../../../components/TextInput';
import useDebouncedState from '../../../hooks/useDebouncedState';
import useDidUpdateEffect from '../../../hooks/useDidUpdateEffect';
import { ERROR_MESSAGE } from '../../../utils/constants';
import { getMomentDateWithoutTimezone } from '../../../utils/datetime';

const VehicleEmissionsDescriptions = React.lazy(() =>
  import('../../../components/VehicleEmissionsDescriptions'),
);

const VehicleForm = props => {
  const {
    t,
    isFAVR,
    isSubmitting,
    selectedVehicle,
    formInstance,
    initialValues,
    onSubmit,
    onChange,
    isVisible,
    isVinDecoder,
  } = props;

  const initialValuesRef = useRef();
  const [odometerDate, setOdometerDate] = useState(initialValues?.odometerDate);
  const [odometer, setOdometer] = useDebouncedState(initialValues?.odometer);
  const [licensePlate, setLicensePlate] = useDebouncedState(initialValues?.licensePlate);
  const [otherMake, setOtherMake] = useDebouncedState(initialValues?.otherMake);
  const [otherModel, setOtherModel] = useDebouncedState(initialValues?.otherModel);

  useDidUpdateEffect(() => {
    onChange({ odometer, licensePlate, odometerDate, otherMake, otherModel });
  }, [odometer, licensePlate, odometerDate, otherMake, otherModel]);

  useDidUpdateEffect(() => {
    const fieldsToOmit = ['odometer', 'odometerDate', 'licensePlate'];
    if (!isEqual(omit(initialValues, fieldsToOmit), omit(initialValuesRef.current, fieldsToOmit))) {
      const previousValues = formInstance.getFieldsValue();
      const updatedValues = { ...(previousValues || {}), ...initialValues };
      setOdometer(updatedValues.odometer);
      setOdometerDate(updatedValues.odometerDate);
      setLicensePlate(updatedValues.licensePlate);
    }
  }, [initialValues]);

  return (
    <Form
      form={formInstance}
      labelCol={{ span: 24 }}
      onFinish={onSubmit}
      initialValues={selectedVehicle}
    >
      <Row gutter={10} align="middle">
        <Col flex={1}>
          <Form.Item
            name="year"
            label={t('Vehicle Year')}
            rules={[{ required: isVisible, message: ERROR_MESSAGE.BLANK_FIELD }]}
            hidden={!isVisible || isVinDecoder}
          >
            <VehicleYearSelect
              t={t}
              defaultValue={initialValues?.year}
              disabled={isSubmitting}
              onChange={year => {
                setOtherMake();
                setOtherModel();
                onChange({
                  year,
                  make: undefined,
                  otherMake: undefined,
                  model: undefined,
                  otherModel: undefined,
                });
              }}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={10} align="middle">
        <Col flex={1}>
          <Form.Item
            name="make"
            label={t('Vehicle Make')}
            rules={[{ required: isVisible, message: ERROR_MESSAGE.BLANK_FIELD }]}
            hidden={!isVisible || isVinDecoder}
          >
            {!(!isVisible || isVinDecoder) && (
              <VehicleMakeSelect
                t={t}
                year={selectedVehicle.year}
                defaultValue={initialValues?.make}
                disabled={isSubmitting}
                onChange={make => {
                  setOtherMake();
                  setOtherModel();
                  if (make === 'other') {
                    onChange({ make, otherMake: undefined, model: 'other', otherModel: undefined });
                  } else {
                    onChange({
                      make,
                      otherMake: undefined,
                      model: undefined,
                      otherModel: undefined,
                    });
                  }
                }}
              />
            )}
          </Form.Item>
        </Col>
      </Row>

      <Form.Item
        name="otherMake"
        label={t('Custom Vehicle Make')}
        rules={[
          {
            required: isVisible && selectedVehicle?.make === 'other',
            message: ERROR_MESSAGE.BLANK_FIELD,
          },
        ]}
        hidden={!isVisible || isVinDecoder || selectedVehicle?.make !== 'other'}
      >
        <TextInput t={t} disabled={isSubmitting} onChange={e => setOtherMake(e.target.value)} />
      </Form.Item>

      <Row gutter={10} align="middle">
        <Col flex={1}>
          <Form.Item
            name="model"
            label={t('Vehicle Model')}
            rules={[{ required: !!isVisible, message: ERROR_MESSAGE.BLANK_FIELD }]}
            hidden={!isVisible || isVinDecoder}
          >
            {!(!isVisible || isVinDecoder) && (
              <VehicleModelSelect
                t={t}
                year={selectedVehicle?.year}
                make={selectedVehicle?.make}
                defaultValue={initialValues?.model}
                disabled={isSubmitting}
                onChange={model => {
                  setOtherModel();
                  onChange({ model, otherModel: undefined });
                }}
              />
            )}
          </Form.Item>
        </Col>
      </Row>

      <Form.Item
        name="otherModel"
        label={t('Custom Vehicle Model')}
        rules={[
          {
            required: isVisible && selectedVehicle?.model === 'other',
            message: ERROR_MESSAGE.BLANK_FIELD,
          },
        ]}
        hidden={!isVisible || isVinDecoder || selectedVehicle?.model !== 'other'}
      >
        <TextInput t={t} disabled={isSubmitting} onChange={e => setOtherModel(e.target.value)} />
      </Form.Item>

      {isFAVR && (
        <>
          <Form.Item
            name="odometerDate"
            label={t('odometerDate')}
            rules={[{ required: isVisible, message: ERROR_MESSAGE.BLANK_FIELD }]}
            hidden={!isVisible}
          >
            <DatePicker
              defaultValue={odometerDate}
              allowClear={false}
              disabled={isSubmitting}
              style={{ width: '100%' }}
              onChange={setOdometerDate}
            />
          </Form.Item>

          <Form.Item
            name="odometer"
            label={t('odometerOnDate', {
              date: getMomentDateWithoutTimezone(odometerDate).format('YYYY-MM-DD'),
            })}
            rules={[{ required: isVisible, message: ERROR_MESSAGE.BLANK_FIELD }]}
            hidden={!isVisible}
          >
            <TextInput
              step="any"
              type="number"
              disabled={isSubmitting}
              onChange={setOdometer}
              defaultValue={odometer}
            />
          </Form.Item>
        </>
      )}

      <Form.Item name="licensePlate" label={t('licensePlate')} hidden={!isVisible}>
        <Row gutter={10} align="middle">
          <Col flex={1}>
            <TextInput
              defaultValue={initialValues?.licensePlate}
              disabled={isSubmitting}
              onChange={e => setLicensePlate(e.target.value)}
            />
          </Col>
        </Row>
      </Form.Item>

      <FormItem name="vin" label={t('VIN')} hidden>
        <Row gutter={10} align="middle" wrap={false}>
          <Col flex={1}>
            <TextInput defaultValue={initialValues?.vin} disabled />
          </Col>
        </Row>
      </FormItem>

      <Form.Item name="vehicleType" label={t('vehicleType')} hidden={!isVisible || isVinDecoder}>
        <Row gutter={10} align="middle">
          <Col flex={1}>
            <TextInput defaultValue={initialValues?.vehicleType} disabled={isSubmitting} />
          </Col>
        </Row>
      </Form.Item>

      <Form.Item name="bodyClass" label={t('bodyClass')} hidden={!isVisible || isVinDecoder}>
        <Row gutter={10} align="middle">
          <Col flex={1}>
            <TextInput defaultValue={initialValues?.bodyClass} disabled={isSubmitting} />
          </Col>
        </Row>
      </Form.Item>

      <Form.Item name="doors" label={t('doors')} hidden={!isVisible || isVinDecoder}>
        <Row gutter={10} align="middle">
          <Col flex={1}>
            <TextInput type="number" defaultValue={initialValues?.doors} disabled={isSubmitting} />
          </Col>
        </Row>
      </Form.Item>

      <Form.Item
        name="engineNumberOfCylinders"
        label={t('engineNumberOfCylinders')}
        hidden={!isVisible || isVinDecoder}
      >
        <Row gutter={10} align="middle">
          <Col flex={1}>
            <TextInput
              type="number"
              defaultValue={initialValues?.engineNumberOfCylinders}
              disabled={isSubmitting}
            />
          </Col>
        </Row>
      </Form.Item>

      <Form.Item
        name="displacementL"
        label={t('displacementL')}
        hidden={!isVisible || isVinDecoder}
      >
        <Row gutter={10} align="middle">
          <Col flex={1}>
            <TextInput
              type="number"
              defaultValue={initialValues?.displacementL}
              disabled={isSubmitting}
            />
          </Col>
        </Row>
      </Form.Item>

      <Form.Item
        name="fuelTypePrimary"
        label={t('fuelTypePrimary')}
        hidden={!isVisible || isVinDecoder}
      >
        <Row gutter={10} align="middle">
          <Col flex={1}>
            <TextInput defaultValue={initialValues?.fuelTypePrimary} disabled={isSubmitting} />
          </Col>
        </Row>
      </Form.Item>

      <Form.Item name="electrificationLevel" hidden>
        <TextInput defaultValue={initialValues?.electrificationLevel} disabled />
      </Form.Item>

      <Row gutter={10} align="middle">
        <Col flex={1}>
          <Form.Item
            name="isDefault"
            valuePropName="checked"
            className="no-margin-bottom"
            hidden={!isVisible}
          >
            <Checkbox
              disabled={isSubmitting}
              onChange={e => onChange({ isDefault: e.target.checked })}
            >
              {t('setAsDefault')}
            </Checkbox>
          </Form.Item>
        </Col>
      </Row>

      {isVinDecoder && isVisible && (
        <>
          <br />
          <VehicleEmissionsDescriptions
            t={t}
            title=" "
            size="small"
            vehicle={selectedVehicle}
            withExtraFields={
              !!selectedVehicle.city08 ||
              !!selectedVehicle.highway08 ||
              !!selectedVehicle.ghgScore ||
              !!selectedVehicle.co2TailpipeGpm
            }
          />
        </>
      )}
    </Form>
  );
};

VehicleForm.defaultProps = {
  isVisible: true,
};

export default VehicleForm;
