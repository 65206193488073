import React, { useMemo } from 'react';

import useDebouncedState from '../../../hooks/useDebouncedState';

import { STATUS_LIST } from '../../../enum';
import { checkIfStringContainsValue, getUserFullName } from '../../../utils/common';
import { sortColumnByStatus, sortColumnByStringField } from '../../../utils/tables';

import { CustomTable } from '../..';
import StatusTag from '../../Tag/StatusTag';

const DownloadUserDataUsersTable = props => {
  const { dataSource, ...rest } = props;

  const [debouncedSearchTerm, setSearchTerm] = useDebouncedState();

  const filteredTable = useMemo(() => {
    let array = dataSource;

    if (debouncedSearchTerm) {
      array = array.filter(user => {
        return (
          checkIfStringContainsValue(user.firstName, debouncedSearchTerm) ||
          checkIfStringContainsValue(user.lastName, debouncedSearchTerm) ||
          checkIfStringContainsValue(getUserFullName(user), debouncedSearchTerm) ||
          checkIfStringContainsValue(
            STATUS_LIST.StatusTagColorConfig[user.status].label,
            debouncedSearchTerm,
          )
        );
      });
    }

    return array;
  }, [debouncedSearchTerm, dataSource]);

  const COLUMNS = [
    {
      width: 200,
      key: 'firstName',
      title: 'First Name',
      dataIndex: 'firstName',
      ...sortColumnByStringField('firstName'),
    },
    {
      width: 200,
      key: 'lastName',
      title: 'Last Name',
      dataIndex: 'lastName',
      ...sortColumnByStringField('lastName'),
    },
    {
      key: 'status',
      title: 'Status',
      render: (actions, data) => <StatusTag status={data.status} />,
      ...sortColumnByStatus('status'),
    },
  ];

  return (
    <CustomTable
      pagination={false}
      showSearchInput={false}
      {...rest}
      onSearchTermChange={setSearchTerm}
      rowKey={data => data._id}
      columns={COLUMNS}
      dataSource={filteredTable}
    />
  );
};

DownloadUserDataUsersTable.defaultProps = {
  dataSource: [],
};

export default DownloadUserDataUsersTable;
