import { Col, Form, Row } from 'antd';
import { get } from 'lodash';
import React from 'react';
import { Helmet } from 'react-helmet';

import CompanyCommuteSettingConfirmModal from '../../../components/Modal/CompanyCommuteSettingConfirmModal';
import CustomPlanSysAdminConfirmModal from '../../../components/Modal/CustomPlanSysAdminConfirmModal';
import Select from '../../../components/Select';
import SpaceSpinner from '../../../components/SpaceSpinner';
import SubmitCancelButtonGroup from '../../../components/SubmitCancelButtonGroup';
import Text from '../../../components/Text';
import usePricePlans from '../../../hooks/usePricePlans';
import { formatPageTitle } from '../../../utils/common';
import { hasSystemAdminRole } from '../../../utils/roles';
import ApprovalWorkflowSettingsFormItems from './ApprovalWorkflowSettingsFormItems';
import BillingSettingsFormItems from './BillingSettingsFormItems';
import CommuteSettingsToggleRow from './CommuteSettingsToggleRow';
import { REIMBURSEMENT_PURPOSE, TAX_RETURN_PURPOSE } from './company-settings-constants';
import CompanyCommuteSettingsSection from './CompanyCommuteSettingsSection';
import InsuranceManagementSettingsFormItems from './InsuranceManagementSettingsFormItems';
import MiscellaneousSettingsFormItems from './MiscellaneousSettingsFormItems';
import NotificationSettingsFormItems from './NotificationSettingsFormItems';
import ReimbursementSettingsFormItems from './ReimbursementSettingsFormItems';
import ReportsSettingsFormItems from './ReportsSettingsFormItems';
import StripeInvoiceSettingsFormItems from './StripeInvoiceSettingsFormItems';
import TripsAndTripsRecordingSettingsFormItems from './TripsAndTripsRecordingSettingsFormItems';
import useCompanySettingsState from './useCompanySettingsState';
import UserManagementSettingsFormItems from './UserManagementSettingsFormItems';
import VehicleManagementSettingsFormItems from './VehicleManagementSettingsFormItems';

const CompanySettings = props => {
  const { t } = props;

  const [form] = Form.useForm();
  const {
    // Boolean
    isSubmitting,
    isCommuteConfirmModalVisible,
    isCustomPlanConfirmModalVisible,
    isInitialPlanCustom,
    isCustomPlanDirty,
    isCommuteSettingsDirty,

    // General
    authUser,
    planSettings,
    currentCompany,
    currentCompanySettings,
    companyCommuteSettings,
    currentCompanySettingValues,
    isCommuteSettingCustom,
    isCommuteSettingSingleLocation,
    isCommuteSettingNotHaveOffice,
    isNoOfficeMaxDeduction,
    isHomeAddressMaxCommuteDeduction,
    isNoOfficeIgnoreFirstAndLast,
    commuteDistanceUnit,

    // Handlers
    handleFormSave,
    handleActiveFeatureChange,
    handleSettingValueChange,
    handleCommuteSettingChange,
    handlePlanChangeChange,
    openCustomPlanConfirmModal,
    closeCustomPlanConfirmModal,
    openCommuteConfirmModal,
    closeCommuteConfirmModal,
  } = useCompanySettingsState({ t, form });

  const { standardPlansOptions, customPlansOptions, pricesStore } = usePricePlans(t, true);

  const handleSubmitCommute = () => {
    closeCustomPlanConfirmModal();

    if (companyCommuteSettings?.enabled && isCommuteSettingsDirty) {
      openCommuteConfirmModal();
    } else {
      handleFormSave();
    }
  };

  const handleSubmit = () => {
    if (
      hasSystemAdminRole(authUser) &&
      currentCompanySettings?.customPricePlan &&
      isCustomPlanDirty
    ) {
      openCustomPlanConfirmModal();
    } else {
      handleSubmitCommute();
    }
  };

  return (
    <Row gutter={[30, 30]}>
      <Helmet>
        <title>{formatPageTitle('Company Settings')}</title>
      </Helmet>

      <React.Suspense fallback={<SpaceSpinner />}>
        <ApprovalWorkflowSettingsFormItems
          t={t}
          authUser={authUser}
          disabled={isSubmitting}
          companySettings={currentCompanySettings}
          onFeatureChange={handleActiveFeatureChange}
          companySettingValues={currentCompanySettingValues}
        />

        <TripsAndTripsRecordingSettingsFormItems
          t={t}
          authUser={authUser}
          disabled={isSubmitting}
          companySettings={currentCompanySettings}
          onFeatureChange={handleActiveFeatureChange}
        />

        <ReimbursementSettingsFormItems
          t={t}
          authUser={authUser}
          disabled={isSubmitting}
          companySettings={currentCompanySettings}
          onFeatureChange={handleActiveFeatureChange}
        />

        <ReportsSettingsFormItems
          t={t}
          authUser={authUser}
          disabled={isSubmitting}
          companySettings={currentCompanySettings}
          onFeatureChange={handleActiveFeatureChange}
        />

        <UserManagementSettingsFormItems
          t={t}
          authUser={authUser}
          disabled={isSubmitting}
          companySettings={currentCompanySettings}
          onFeatureChange={handleActiveFeatureChange}
        />

        <VehicleManagementSettingsFormItems
          t={t}
          authUser={authUser}
          disabled={isSubmitting}
          companySettings={currentCompanySettings}
          onFeatureChange={handleActiveFeatureChange}
        />

        <InsuranceManagementSettingsFormItems
          t={t}
          authUser={authUser}
          disabled={isSubmitting}
          companySettings={currentCompanySettings}
          onFeatureChange={handleActiveFeatureChange}
        />

        <NotificationSettingsFormItems
          t={t}
          authUser={authUser}
          disabled={isSubmitting}
          companySettings={currentCompanySettings}
          onFeatureChange={handleActiveFeatureChange}
        />

        <MiscellaneousSettingsFormItems
          t={t}
          authUser={authUser}
          disabled={isSubmitting}
          companySettings={currentCompanySettings}
          onFeatureChange={handleActiveFeatureChange}
        />

        <BillingSettingsFormItems
          t={t}
          disabled={isSubmitting}
          authUser={authUser}
          companySettings={currentCompanySettings}
          planSettings={planSettings}
          onFeatureChange={handleActiveFeatureChange}
          standardPlanOptions={standardPlansOptions}
          customPlansOptions={customPlansOptions}
          isLoadingPlans={pricesStore.isLoadingData}
          onPlanChange={handlePlanChangeChange}
          isInitialPlanCustom={isInitialPlanCustom}
          // activePlan={currentCompany.currentPlan}
        />

        <StripeInvoiceSettingsFormItems
          t={t}
          authUser={authUser}
          stripeCustomerId={get(currentCompany, 'stripeCustomerId')}
          companySettingValues={currentCompanySettingValues}
          handleSettingValueChange={handleSettingValueChange}
        />
      </React.Suspense>

      <Col flex="736px">
        <Text renderAs="p" variant="b" size="sm" style={{ marginBottom: 16 }}>
          {t('Settings')}
        </Text>

        <Form
          form={form}
          labelCol={{ span: 24 }}
          onFinish={handleSubmit}
          initialValues={{
            ...companyCommuteSettings,
            importLocationsMethod: currentCompanySettingValues.importLocationsMethod
              ? currentCompanySettingValues.importLocationsMethod
              : !currentCompanySettings.importLocationsMethod
              ? ''
              : undefined,
            mileagePurpose: currentCompany.mileagePurpose,
            fixedReceiptPaymentMethod: currentCompanySettingValues.fixedReceiptPaymentMethod,
          }}
        >
          <Form.Item label={t('importLocations')} name="importLocationsMethod">
            <Select
              value={
                currentCompanySettingValues.importLocationsMethod
                  ? currentCompanySettingValues.importLocationsMethod
                  : !currentCompanySettings.importLocationsMethod
                  ? ''
                  : undefined
              }
              disabled={isSubmitting || !currentCompanySettings.importLocationsMethod}
              onChange={value => handleSettingValueChange({ importLocationsMethod: value })}
              options={[
                ...(!currentCompanySettings.importLocationsMethod
                  ? [
                      {
                        value: '',
                        label: t('disabled'),
                        disabled: !hasSystemAdminRole(authUser),
                      },
                    ]
                  : []),
                {
                  value: 'csv',
                  label: t('CSV'),
                },
                {
                  value: 'api',
                  label: t('API'),
                },
              ]}
            />
          </Form.Item>

          <Form.Item label={t('fixedReceiptPaymentMethod')} name="fixedReceiptPaymentMethod">
            <Select
              value={currentCompanySettingValues.fixedReceiptPaymentMethod}
              disabled={isSubmitting}
              onChange={value => handleSettingValueChange({ fixedReceiptPaymentMethod: value })}
              options={[
                {
                  value: 'in-arrears',
                  label: t('inArrears'),
                },
                {
                  value: 'up-front',
                  label: t('upFront'),
                },
              ]}
            />
          </Form.Item>

          <Form.Item label={t('mileagePurpose')} name="mileagePurpose">
            <Select
              value={currentCompany.mileagePurpose}
              disabled={isSubmitting || !hasSystemAdminRole(authUser)}
              onChange={value => handleSettingValueChange({ mileagePurpose: value })}
              options={[
                {
                  value: REIMBURSEMENT_PURPOSE,
                  label: t('Reimbursement'),
                },
                {
                  value: TAX_RETURN_PURPOSE,
                  label: t('taxReturn'),
                },
              ]}
            />
          </Form.Item>

          <CommuteSettingsToggleRow
            t={t}
            isSubmitting={isSubmitting}
            checked={companyCommuteSettings?.enabled}
            onCommuteSettingsChange={handleCommuteSettingChange}
          />

          {companyCommuteSettings?.enabled && (
            <CompanyCommuteSettingsSection
              t={t}
              isSubmitting={isSubmitting}
              companyCommuteSettings={companyCommuteSettings}
              onCommuteSettingsChange={handleCommuteSettingChange}
              isCommuteSettingCustom={isCommuteSettingCustom}
              isCommuteSettingSingleLocation={isCommuteSettingSingleLocation}
              isCommuteSettingNotHaveOffice={isCommuteSettingNotHaveOffice}
              isNoOfficeMaxDeduction={isNoOfficeMaxDeduction}
              isNoOfficeIgnoreFirstAndLast={isNoOfficeIgnoreFirstAndLast}
              isHomeAddressMaxCommuteDeduction={isHomeAddressMaxCommuteDeduction}
              distanceUnit={commuteDistanceUnit}
            />
          )}

          <SubmitCancelButtonGroup
            isForm
            hideCancel
            loading={isSubmitting}
            submitText={t('Save')}
          />
        </Form>
      </Col>

      <CustomPlanSysAdminConfirmModal
        t={t}
        visible={isCustomPlanConfirmModalVisible}
        onCancel={closeCustomPlanConfirmModal}
        onConfirm={handleSubmitCommute}
      />

      <CompanyCommuteSettingConfirmModal
        t={t}
        visible={isCommuteConfirmModalVisible}
        onCancel={closeCommuteConfirmModal}
        commuteSettingLevel={companyCommuteSettings?.level}
        onConfirm={handleFormSave}
      />
    </Row>
  );
};

export default CompanySettings;
