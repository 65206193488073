import StatusList from './StatusList';

const SelectOptions = [
  {
    label: 'Paid',
    value: StatusList.Status.PAID,
  },
  {
    label: 'Not Paid',
    value: StatusList.Status.NOT_PAID,
  },
];

export default { SelectOptions };
