import React from 'react';

import AdjustmentHistoryTable from '../../Table/AdjustmentHistoryTable';
import GenericModal from '../GenericModal';

const AdjustmentHistoryModal = props => {
  const { t, defaultSearchTerm, visible, adjustmentsList, currency, onCancel } = props;

  const WINDOW_WIDTH = window.innerWidth - 50;
  const MODAL_WIDTH = WINDOW_WIDTH > 1400 ? 1400 : WINDOW_WIDTH;

  return (
    <GenericModal
      title="Adjustment History"
      width={MODAL_WIDTH}
      footer={null}
      visible={visible}
      onCancel={onCancel}
    >
      {visible && (
        <AdjustmentHistoryTable
          t={t}
          currency={currency}
          defaultSearchTerm={defaultSearchTerm}
          dataSource={adjustmentsList}
        />
      )}
    </GenericModal>
  );
};

export default AdjustmentHistoryModal;
