import React from 'react';
import { Link } from 'react-router-dom';

import { INTERNAL_LINKS } from '../../../enum';
import { ReactComponent as CreditCardCheckSVG } from '../../../images/credit-card-check.svg';
import { ReactComponent as CreditCardGiveSVG } from '../../../images/credit-card-give.svg';
import { ReactComponent as LogoutSVG } from '../../../images/logout.svg';
import { ReactComponent as ProfilePictureSVG } from '../../../images/single-neutral-profile-picture.svg';
import { ReactComponent as TaskListSVG } from '../../../images/task-list-multiple.svg';
import { hasCompanyAdminRole } from '../../../utils/roles';

const MenuItemWrapper = ({ children, onClick }) => {
  return (
    <div className="profile-menu-item" onClick={onClick}>
      {children}
    </div>
  );
};

const ProfileDropdownMenu = ({ t, user, regCompleted, logoutUser }) => {
  /** @type {import('antd/lib/menu/index').MenuProps}*/
  let menu = {
    items: [],
  };

  if (regCompleted) {
    menu.items.push({
      key: 'profile',
      label: (
        <Link to={INTERNAL_LINKS.USER_DETAILS + `/${user.profile._id}/profile`}>
          <MenuItemWrapper>
            <ProfilePictureSVG />
            {t('My Profile')}
          </MenuItemWrapper>
        </Link>
      ),
    });
  }

  if (regCompleted && hasCompanyAdminRole(user)) {
    menu.items.push({
      key: 'company-admin-menu',
      type: 'group',
      label: t('Invoices and Billing'),
      children: [
        {
          key: 'billing-method',
          label: (
            <Link to={INTERNAL_LINKS.BILLING_METHOD}>
              <MenuItemWrapper>
                <CreditCardGiveSVG />
                {t('Billing Method')}
              </MenuItemWrapper>
            </Link>
          ),
        },
        {
          key: 'invoices',
          label: (
            <Link to={INTERNAL_LINKS.INVOICES}>
              <MenuItemWrapper>
                <TaskListSVG />
                {t('Invoices')}
              </MenuItemWrapper>
            </Link>
          ),
        },
        {
          key: 'price-plan',
          label: (
            <Link to={INTERNAL_LINKS.PRICE_PLANS}>
              <MenuItemWrapper>
                <CreditCardCheckSVG />
                {t('Price Plan')}
              </MenuItemWrapper>
            </Link>
          ),
        },
      ],
    });
  }

  menu.items.push({
    key: 'logout',
    label: (
      <MenuItemWrapper onClick={logoutUser}>
        <LogoutSVG />
        {t('Log out')}
      </MenuItemWrapper>
    ),
  });

  return menu;
};

export default ProfileDropdownMenu;
