import { Col, Form, Row } from 'antd';
import React, { useEffect } from 'react';

import { ReactComponent as EditSVG } from '../../images/pencil-write.svg';
import { ERROR_MESSAGE } from '../../utils/constants';
import Button from '../Button';
import Text from '../Text';
import TextInput from '../TextInput';

const RateConfigurationFormSection = props => {
  const {
    isGovernmentFlatRate,
    onRateChange,
    countryRate,
    onToggleEditRate,
    rate,
    isSubmitting,
  } = props;

  useEffect(() => {
    if (typeof rate === 'undefined') {
      onRateChange(isGovernmentFlatRate ? rate || countryRate?.defaultRate : rate);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div style={{ marginBottom: 15, lineHeight: 14 }}>
        <Text variant="p" size="sm" renderAs="div">
          {isGovernmentFlatRate &&
            'The flat rate is set to adhere to the government recommended rate.'}

          {!isGovernmentFlatRate && 'The flat rate will be set to your own custom rate.'}
        </Text>
      </div>

      <Row gutter={10} align="middle" wrap={false}>
        <Col flex={1}>
          <Form.Item
            required
            name="rateAmount"
            rules={[{ required: true, message: ERROR_MESSAGE.BLANK_FIELD }]}
          >
            <TextInput
              key={isGovernmentFlatRate}
              disabled={isSubmitting || isGovernmentFlatRate}
              type={isGovernmentFlatRate ? 'text' : 'number'}
              value={rate}
              name="rateAmount"
              onChange={value => onRateChange(value)}
            />
          </Form.Item>
        </Col>
        {isGovernmentFlatRate && (
          <Col>
            <Form.Item>
              <Button
                size="xs"
                type="secondary"
                disabled={isSubmitting}
                icon={<EditSVG width="18px" alt="arrow" />}
                onClick={onToggleEditRate}
              />
            </Form.Item>
          </Col>
        )}
      </Row>
    </>
  );
};

RateConfigurationFormSection.propTypes = {};

export default RateConfigurationFormSection;
