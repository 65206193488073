import { get } from 'lodash';
import storage from 'store';

import { STATUS_LIST, USER_ROLES } from '../../enum';
import {
  checkUserRole,
  hasCompanyAdminRole,
  hasCompanyManagerRole,
  hasSystemAdminRole,
} from '../../utils/roles';
import { selectStoreCurrentAuthUser, selectStoreCurrentCompany } from '../../utils/storeSelectors';

export const canViewBreadcrumb = userData => {
  return checkUserRole(userData, [USER_ROLES.COMPANY_ADMIN, USER_ROLES.SYSTEM_ADMIN]);
};

export const canImpersonateUser = (loggedInUser, userToImpersonate) => {
  const previousAccessToken = storage.get('previousAccessToken');
  if (previousAccessToken) return false;

  if (!loggedInUser || !userToImpersonate) return false;
  if (loggedInUser.profile._id === userToImpersonate._id) return false;

  return (
    hasCompanyAdminRole(loggedInUser) ||
    hasSystemAdminRole(loggedInUser) ||
    (hasCompanyManagerRole(loggedInUser) && userToImpersonate.role === USER_ROLES.USER)
  );
};

export const canStopImpersonation = () => {
  return !!storage.get('previousAccessToken');
};

export const canSoftDeleteUser = (loggedInUser, userToDelete) => {
  if (!loggedInUser || !userToDelete) return false;
  if (loggedInUser.profile._id === userToDelete._id) return false;

  return hasCompanyAdminRole(loggedInUser) || hasSystemAdminRole(loggedInUser);
};

export const canViewUserAchBankInfo = userId => {
  const currentCompany = selectStoreCurrentCompany();
  const authUser = selectStoreCurrentAuthUser();

  return (
    // Is same user
    userId === authUser.profile._id &&
    // ach enabled
    !!currentCompany?.companySettingId?.settings?.ach_enabled &&
    // dwolla customer is verified
    currentCompany?.dwollaPaymentInfo?.controllerInfo?.customerStatus ===
      STATUS_LIST.Status.VERIFIED
  );
};

class UserPermissions {
  #currentUser;

  __setCurrentUser() {
    this.#currentUser = selectStoreCurrentAuthUser();
  }

  canChangeActivationEmailDate(userToUpdate) {
    this.__setCurrentUser();

    const isCompanyAdmin = hasCompanyAdminRole(this.#currentUser);

    if (userToUpdate) {
      return isCompanyAdmin && get(userToUpdate, 'status') === STATUS_LIST.Status.PENDING;
    }

    return isCompanyAdmin;
  }
}

export const USER_PERMISSIONS = new UserPermissions();
