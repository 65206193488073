import { isBoolean, isEmpty } from 'lodash';
import moment from 'moment-timezone';
import React from 'react';

import { INSURANCE_FIELDS } from '../components/InsurancePerPersonPerAccident/InsurancePerPersonPerAccident.constants';
import Tooltip from '../components/Tooltip';
import { IMAGES, STATUS_LIST } from '../enum';
import { RATE_FORM_WIZARD_STEPS, RATE_TYPES } from '../enum/Rates';

/**
 * Returns the value (limit) of specific approval setting type
 *
 * @param {string} approvalType Approval setting type
 * @param {{ approvalType: string, limit: number | string }[]} approvalSetting Rate approval settings
 */
export const getApprovalSettingValue = (approvalType, approvalSetting) => {
  if (Array.isArray(approvalSetting)) {
    const approval = approvalSetting.find(s => s.approvalType === approvalType);
    return approval ? parseFloat(approval.limit) : undefined;
  }

  return undefined;
};

export const formatInsuranceComplianceInitialValues = (
  activeCompliance,
  insuranceComplianceInsuranceSettings,
  insuranceComplianceVehicleSettings,
) => {
  if (!activeCompliance) return {};

  let vehicleInitialValues = {
    umbrellaPolicy: insuranceComplianceVehicleSettings?.umbrellaPolicy?.enabled,
    makeModelYearInfo: insuranceComplianceVehicleSettings?.makeModelYearInfo?.enabled,
    makeModelYearInfo_value: insuranceComplianceVehicleSettings?.makeModelYearInfo?.value,
    odometerReading: insuranceComplianceVehicleSettings?.odometerReading?.enabled,
    odometerReading_value: insuranceComplianceVehicleSettings?.odometerReading?.value,
    originalPurchasePrice: insuranceComplianceVehicleSettings?.originalPurchasePrice?.enabled,
    originalPurchasePrice_value: insuranceComplianceVehicleSettings?.originalPurchasePrice?.value,
    depreciationClaimedUsingIRS:
      insuranceComplianceVehicleSettings?.depreciationClaimedUsingIRS?.enabled,
    depreciationClaimedUsingIRS_value:
      insuranceComplianceVehicleSettings?.depreciationClaimedUsingIRS?.value,
  };

  const insurancePerAccidentPerPerson = {};

  INSURANCE_FIELDS.forEach(field => {
    const insurance = insuranceComplianceInsuranceSettings[field];
    insurancePerAccidentPerPerson[`${field}_person`] = insurance?.perPerson;
    insurancePerAccidentPerPerson[`${field}_accident`] = insurance?.perAccident;
  });

  const insuranceInitialValues = {
    ...insurancePerAccidentPerPerson,
    insurancePolicyNotAvailable:
      insuranceComplianceInsuranceSettings?.insurancePolicyNotAvailable?.enabled,
    driverVehicleCoverageNotListed:
      insuranceComplianceInsuranceSettings?.driverVehicleCoverageNotListed?.enabled,
    policyCoverageDatesIncorrect:
      insuranceComplianceInsuranceSettings?.policyCoverageDatesIncorrect?.enabled,
    umbrellaPolicyRequired: insuranceComplianceInsuranceSettings?.umbrellaPolicyRequired?.enabled,
    stateMinimumCoverage: insuranceComplianceInsuranceSettings?.stateMinimumCoverage?.enabled,

    bodilyInjuryToOthers: insuranceComplianceInsuranceSettings?.bodilyInjuryToOthers?.enabled,
    damageToProperty: insuranceComplianceInsuranceSettings?.damageToProperty?.enabled,
    personalInjuryProtection:
      insuranceComplianceInsuranceSettings?.personalInjuryProtection?.enabled,
    bodilyInjuryByUninsuredAuto:
      insuranceComplianceInsuranceSettings?.bodilyInjuryByUninsuredAuto?.enabled,
    medicalPayments: insuranceComplianceInsuranceSettings?.medicalPayments?.enabled,
    optionalBodilyInjuryToOthers:
      insuranceComplianceInsuranceSettings?.optionalBodilyInjuryToOthers?.enabled,
    comprehensive: insuranceComplianceInsuranceSettings?.comprehensive?.enabled,
    bodilyInjuryByUnderinsuredAuto:
      insuranceComplianceInsuranceSettings?.bodilyInjuryByUnderinsuredAuto?.enabled,
  };

  if (activeCompliance === 'vehicleAndInsurance') {
    return {
      ...vehicleInitialValues,
      ...insuranceInitialValues,
    };
  }

  return activeCompliance === 'vehicle' ? vehicleInitialValues : insuranceInitialValues;
};

export const renderFavrComplianceStatusSymbol = status => {
  let itemStatus = status || STATUS_LIST.Status.DENIED;
  let icon = IMAGES.CIRCLE_REMOVE_ICON;

  if (itemStatus === STATUS_LIST.Status.PENDING) {
    icon = IMAGES.ALERT_WARNING_TRIANGLE_ICON;
  } else if (itemStatus === STATUS_LIST.Status.APPROVED) {
    icon = IMAGES.CIRCLE_CHECK_MARK_ICON;
  }

  const statusLabel = STATUS_LIST.StatusTagColorConfig[itemStatus]?.label;

  return (
    <Tooltip enabled={!!statusLabel} title={statusLabel}>
      <img width="24px" height="24px" src={icon} alt={itemStatus} />
    </Tooltip>
  );
};

export const getFinalCompletedStep = values => {
  if (
    isBoolean(values.insuranceCompliance) ||
    isBoolean(values.vehicleCompliance) ||
    !isEmpty(values.annualComplianceSubmission)
  ) {
    return RATE_FORM_WIZARD_STEPS.REVIEW;
  } else if (
    !isEmpty(values.annualBusinessMiles) &&
    !isEmpty(values.businessUse) &&
    !isEmpty(values.retentionYears) &&
    !isEmpty(values.vehicleMake) &&
    !isEmpty(values.vehicleModel)
  ) {
    return RATE_FORM_WIZARD_STEPS.COMPLIANCE;
  } else if (
    !isEmpty(values.name) &&
    !isEmpty(values.country) &&
    !isEmpty(values.rateType) &&
    !isEmpty(values.effectiveDate) &&
    !isEmpty(values.approvalConfig)
  ) {
    return RATE_FORM_WIZARD_STEPS.SELECT_STANDARD_VEHICLE;
  }

  return RATE_FORM_WIZARD_STEPS.RATE_FORM;
};

export const normalizeRateSchema = rate => {
  const groups = rate.groups.join(', ');
  return {
    ...rate,
    originalGroups: rate.groups,
    key: rate._id,
    name: rate.name || '',
    active: rate.active ? 'Yes' : 'No',
    effective_date: moment(
      new Date(
        moment.parseZone(rate.effectiveDate).year(),
        moment.parseZone(rate.effectiveDate).month(),
        moment.parseZone(rate.effectiveDate).date(),
      ),
    ).format('MM/DD/YYYY'),
    end_date: '',
    groups,
    default: rate.defaultRate ? 'Yes' : 'No',
    payouts: rate.payout,
    _id: rate._id,
  };
};

export const isTieredRate = (rateType, countryCode) => {
  switch (countryCode) {
    case 'CA': // Canada
      return [RATE_TYPES.GOVT, RATE_TYPES.TIERED].includes(rateType);
    default:
      return rateType === RATE_TYPES.TIERED;
  }
};

export const isGovernmentFlatRate = (rateType, countryCode) => {
  switch (countryCode) {
    case 'CA': // Canada
      return false;
    default:
      return rateType === RATE_TYPES.GOVT;
  }
};
