import React, { useMemo } from 'react';

import useDebouncedState from '../../../hooks/useDebouncedState';
import { checkIfStringContainsValue } from '../../../utils/common';
import { CustomTable } from '../..';

const StandardVehiclesTable = props => {
  const { t, dataSource, ...rest } = props;

  const [searchTerm, setSearchTerm] = useDebouncedState();

  const filteredDataSource = useMemo(() => {
    let array = [...dataSource];

    if (searchTerm) {
      array = array.filter(item => {
        return (
          checkIfStringContainsValue(item.year, searchTerm) ||
          checkIfStringContainsValue(item.make, searchTerm) ||
          checkIfStringContainsValue(item.model, searchTerm) ||
          checkIfStringContainsValue(item.trim, searchTerm)
        );
      });
    }

    return array;
    // eslint-disable-next-line
  }, [dataSource, searchTerm]);

  const COLUMNS = [
    {
      title: t('year'),
      dataIndex: 'year',
    },
    {
      title: t('make'),
      dataIndex: 'make',
    },
    {
      title: t('model'),
      dataIndex: 'model',
    },
    {
      title: t('trim'),
      dataIndex: 'trim',
    },
  ];

  return (
    <CustomTable
      {...rest}
      onSearchTermChange={setSearchTerm}
      columns={COLUMNS}
      dataSource={filteredDataSource}
    />
  );
};

StandardVehiclesTable.defaultProps = {
  dataSource: [],
};

export default StandardVehiclesTable;
