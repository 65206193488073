import { Col, Row, Tabs } from 'antd';
import queryString from 'querystring';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Trans, withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';

import { handleApiErrors } from '../../api/axiosInstance';
import {
  approveUserDataRequest,
  cancelUserDataRequest,
  denyUserDataRequest,
  downloadUserDataFile,
  fetchUserDataActions,
} from '../../api/userDataActions';
import Box from '../../components/Box';
import GenericModal from '../../components/Modal/GenericModal';
import Spinner from '../../components/Spinner';
import DownloadUserDataUsersTable from '../../components/Table/DownloadUserDataUsersTable';
import TabsContainer from '../../components/TabsContainer';
import Text from '../../components/Text';
import Toast from '../../components/Toast';
import PageContainer from '../../containers/PageContainer';
import withAuthentication from '../../hocs/withAuthentication';
import { formatPageTitle, getUserFullName } from '../../utils/common';
import RequestsFromUsersTab from './components/RequestsFromUsersTab';
import StatusTab from './components/StatusTab';
import UserInformationTab from './components/UserInformationTab';

const USER_INFO_TAB_KEY = 'userInfo';
const STATUS_TAB_KEY = 'status';
const REQUEST_FROM_USERS_TAB_KEY = 'requests';

const GDPRPage = props => {
  const { t, history, location, myProfile } = props;

  const { activeTab } = queryString.parse(location.search.replace('?', ''));

  const [activePane, setActivePane] = useState(activeTab || USER_INFO_TAB_KEY);

  const [isLoadingRequests, setIsLoadingRequests] = useState(false);
  const [userDataRequests, setUserDataRequests] = useState();

  const [downloadingRequestID, setDownloadingRequestID] = useState();
  const [approvingRequestID, setApprovingRequestID] = useState();
  const [denyingRequestID, setDenyingRequestID] = useState();

  const [isUserModalVisible, setIsUserModalVisible] = useState(false);
  const [usersToView, setUsersToView] = useState([]);

  const loadAnonymizeRequests = async () => {
    setIsLoadingRequests(true);
    try {
      const data = await fetchUserDataActions({
        type: ['anonymize', 'download', 'soft-delete'],
        subtype: 'gdpr',
      });
      setUserDataRequests(data);
      setIsLoadingRequests(false);
    } catch (error) {
      handleApiErrors(error.response, () => {
        Toast({
          type: 'error',
          message: t('errorLoadingSubmittedRequests'),
        });
      });
    }
  };

  const handleTabChange = tabKey => {
    setActivePane(tabKey);
    history.replace({ search: `activeTab=${tabKey}` });
  };

  const _updateAnonymizeRequestState = (id, data) => {
    const requestIndex = userDataRequests.findIndex(r => r._id === id);
    const updatedRequests = [...userDataRequests];
    updatedRequests[requestIndex] = { ...updatedRequests[requestIndex], ...data };
    setUserDataRequests(updatedRequests);
  };

  const handleRequestApproval = async id => {
    setApprovingRequestID(id);
    try {
      const data = await approveUserDataRequest(id);
      _updateAnonymizeRequestState(id, data);

      Toast({
        type: 'success',
        message: t('requestApproved'),
      });
    } catch (error) {
      handleApiErrors(error.response, () => {
        Toast({
          type: 'error',
          message: t('errorApprovingRequest'),
        });
      });
    }
    setApprovingRequestID(undefined);
  };

  const handleRequestDenial = async id => {
    setDenyingRequestID(id);
    try {
      const data = await denyUserDataRequest(id);
      _updateAnonymizeRequestState(id, data);

      Toast({
        type: 'success',
        message: t('requestDenied'),
      });
    } catch (error) {
      handleApiErrors(error.response, () => {
        Toast({
          type: 'error',
          message: t('errorDenyingRequest'),
        });
      });
    }
    setDenyingRequestID(undefined);
  };

  const handleRequestCancellation = async id => {
    setApprovingRequestID(id);
    try {
      const data = await cancelUserDataRequest(id);
      _updateAnonymizeRequestState(id, data);

      Toast({
        type: 'success',
        message: t('requestCancelled'),
      });
    } catch (error) {
      handleApiErrors(error.response, () => {
        Toast({
          type: 'error',
          message: t('errorCancellingRequest'),
        });
      });
    }
    setApprovingRequestID(undefined);
  };

  const downloadFile = async (blob, filename) => {
    let url = window.URL.createObjectURL(blob);
    let a = document.createElement('a');
    a.href = url;
    a.download = `${filename}.csv`;
    a.click();
  };

  const handleDownload = async id => {
    setDownloadingRequestID(id);
    try {
      const blob = await downloadUserDataFile(id);
      await downloadFile(blob, 'user-data');

      Toast({
        type: 'success',
        message: t('requestDownloaded'),
      });
    } catch (error) {
      handleApiErrors(error.response, () => {
        Toast({
          type: 'error',
          message: t('errorDownloadingDataRequest'),
        });
      });
    }
    setDownloadingRequestID(undefined);
  };

  const handleViewUsersModal = users => {
    setIsUserModalVisible(true);
    setUsersToView(users.sort((a, b) => getUserFullName(a).localeCompare(getUserFullName(b))));
  };

  useEffect(() => {
    loadAnonymizeRequests();
    // eslint-disable-next-line
  }, []);

  return (
    <PageContainer title={t('gdpr_ccpa')}>
      <Helmet>
        <title>{formatPageTitle('GDPR / CCPA')}</title>
      </Helmet>

      <div>
        <Text>
          <Trans
            t={t}
            i18nKey="gdrpDescription_1"
            components={[<Text variant="strong">DummyText</Text>]}
          />
        </Text>
        <Text>{t('gdrpDescription_2')}</Text>

        <br />

        <Text>
          <Trans
            t={t}
            i18nKey="ccpaDescription"
            components={[<Text variant="strong">DummyText</Text>]}
          />
        </Text>
      </div>

      <br />

      <Text variant="h5" style={{ marginBottom: 20 }}>
        {t('whatToDoUserData')}
      </Text>

      <Box>
        <TabsContainer activeKey={activePane} onChange={handleTabChange}>
          <Tabs.TabPane key={USER_INFO_TAB_KEY} tab={t('userInformation')}>
            <UserInformationTab t={t} />

            <div>
              <Text size="sm">{t('gdprUserInfoNote')}</Text>
              <Text size="sm">{t('gdprUserInfoContactAdmin')}</Text>
            </div>
          </Tabs.TabPane>

          <Tabs.TabPane key={STATUS_TAB_KEY} tab={t('status')}>
            {isLoadingRequests || !userDataRequests ? (
              <Row justify="center" align="middle">
                <Col style={{ minHeight: 300 }}>
                  <Spinner />
                </Col>
              </Row>
            ) : (
              <StatusTab
                isAdmin
                t={t}
                loggedInUserID={myProfile.profile._id}
                downloadingRequestID={downloadingRequestID}
                denyingRequestID={denyingRequestID}
                approvingRequestID={approvingRequestID}
                requests={userDataRequests.filter(
                  request => request.requestingUser._id === myProfile.profile._id,
                )}
                onDeny={id => handleRequestDenial(id)}
                onCancel={id => handleRequestCancellation(id)}
                onApprove={id => handleRequestApproval(id)}
                onDownload={id => handleDownload(id)}
                onViewUsers={userIds => handleViewUsersModal(userIds)}
              />
            )}
          </Tabs.TabPane>

          <Tabs.TabPane key={REQUEST_FROM_USERS_TAB_KEY} tab={t('requestFromUsers')}>
            {isLoadingRequests || !userDataRequests ? (
              <Row justify="center" align="middle">
                <Col style={{ minHeight: 300 }}>
                  <Spinner />
                </Col>
              </Row>
            ) : (
              <RequestsFromUsersTab
                t={t}
                loggedInUserID={myProfile.profile._id}
                denyingRequestID={denyingRequestID}
                approvingRequestID={approvingRequestID}
                requests={userDataRequests.filter(
                  request =>
                    request.requestingUser._id !== myProfile.profile._id &&
                    request.requestType === 'anonymize',
                )}
                onDeny={id => handleRequestDenial(id)}
                onCancel={id => handleRequestCancellation(id)}
                onApprove={id => handleRequestApproval(id)}
              />
            )}
          </Tabs.TabPane>
        </TabsContainer>
      </Box>

      <GenericModal
        centered
        footer={null}
        visible={isUserModalVisible}
        onCancel={() => {
          setIsUserModalVisible(false);
          setTimeout(() => {
            setUsersToView([]);
          }, 200);
        }}
      >
        <DownloadUserDataUsersTable
          showSearchInput
          dataSource={usersToView}
          pagination={{
            pageSize: 10,
            hideOnSinglePage: true,
            showSizeChanger: false,
          }}
        />
      </GenericModal>
    </PageContainer>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    myProfile: state.profile,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withNamespaces()(withAuthentication(GDPRPage)));
