import { Col, Row } from 'antd';
import moment from 'moment-timezone';
import queryString from 'querystring';
import React, { useLayoutEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Trans, withNamespaces } from 'react-i18next';
import { useMutation } from 'react-query';
import { useSelector } from 'react-redux';

import { handleApiErrors } from '../../api/axiosInstance';
import ReceiptAPI, { approveReceipt, RECEIPT_API, rejectReceipt } from '../../api/receipt';
import { resubmitReceiptForApproval } from '../../api/trip-receipt-reviews';
import { Button } from '../../components';
import OverdueReceiptAlert from '../../components/Alert/OverdueReceiptAlert';
import DownloadIcon from '../../components/DownloadIcon';
import ReceiptApproveConfirmation from '../../components/Modal/ReceiptApproveConfirmation';
import ReceiptDeleteConfirmationModal from '../../components/Modal/ReceiptDeleteConfirmationModal';
import ReceiptRejectConfirmation from '../../components/Modal/ReceiptRejectConfirmation';
import SubmitReceiptsForApprovalConfirmation from '../../components/Modal/SubmitReceiptsForApprovalConfirmation';
import CommonTableFilters from '../../components/shared-ui/CommonTableFilters';
import ReceiptsTable from '../../components/Table/ReceiptsTable';
import LinkText from '../../components/Text/LinkText';
import Toast from '../../components/Toast';
import PageContainer from '../../containers/PageContainer';
import { INTERNAL_LINKS, RECEIPT_STATUS, STATUS_LIST } from '../../enum';
import withAuthentication from '../../hocs/withAuthentication';
import usePaginatedFiltersQuery from '../../hooks/queries/usePaginatedFiltersQuery';
import useModal from '../../hooks/useModal';
import useTableSort from '../../hooks/useTableSort';
import { formatPageTitle, momentFormat } from '../../utils/common';
import { hasCompanyAdminRole, hasCompanyManagerOrAdminRole } from '../../utils/roles';
import { selectStoreCompanySettings, selectStoreCurrentAuthUser } from '../../utils/storeSelectors';
import { findReceiptsWithTripsInMoreInfoStatus } from '../../utils/trip-receipts';
import { canResubmitReceiptForApproval, canUpdateReceiptStatus } from './receipt-permissions';

const ReceiptPage = props => {
  const { t, history } = props;

  const authUser = useSelector(selectStoreCurrentAuthUser);
  const companySettings = useSelector(selectStoreCompanySettings);

  const {
    page,
    pageSize,
    status,
    user,
    searchTerm,
    startDateRange,
    endDateRange,
  } = queryString.parse(props.location.search.replace('?', ''));

  const [isApprovingReceipt, setIsApprovingReceipt] = useState(false);
  const [isRejectingReceipt, setIsRejectingReceipt] = useState(false);
  const [isResubmittingReceiptsForApproval, setIsResubmittingReceiptsForApproval] = useState(false);
  const [overdueReceiptsCount, setOverdueReceiptsCount] = useState();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [receiptData, setReceiptData] = useState([]);
  const [receiptsWithMoreInfoTrips, setReceiptsWithMoreInfoTrips] = useState([]);
  const [isApprovalConfirmationVisible, setIsApprovalConfirmationVisible] = useState(false);
  const [isRejectionConfirmationVisible, setIsRejectionConfirmationVisible] = useState(false);
  const [isResubmitConfirmationVisible, setIsResubmitConfirmationVisible] = useState(false);
  const [filters, setFilters] = useState({
    userId: user || null,
    status: status || null,
    searchTerm: searchTerm || undefined,
    dateRange: [
      startDateRange ? moment(startDateRange) : moment().subtract(30, 'days').startOf('day'),
      endDateRange ? moment(endDateRange) : moment(),
    ],
  });

  useLayoutEffect(() => {
    if (companySettings?.hideReceipts) {
      history.replace(INTERNAL_LINKS.REIMBURSEMENT);
    }
    // eslint-disable-next-line
  }, []);

  const { stringTableSort, handleTableSort } = useTableSort({ seqId: -1 });

  const receiptsQueryParams = useMemo(() => {
    return {
      fromDate:
        Array.isArray(filters.dateRange) && filters.dateRange[0]
          ? momentFormat(filters.dateRange[0], 'YYYY-MM-DD')
          : '',
      toDate:
        Array.isArray(filters.dateRange) && filters.dateRange[1]
          ? momentFormat(filters.dateRange[1], 'YYYY-MM-DD')
          : '',
      status: filters.status,
      userId: filters.userId,
      searchTerm: filters.searchTerm,
    };
    // eslint-disable-next-line
  }, [filters]);

  const {
    query: receiptsQuery,
    paginationConfig,
    handlePageChange,
    handlePageSizeChange,
  } = usePaginatedFiltersQuery(
    {
      placeholderData: [],
      enabled: !selectStoreCompanySettings()?.hideReceipts,
      queryKey: [
        'fetchCompanyTripReceipts',
        ...Object.values(receiptsQueryParams),
        stringTableSort,
      ],
      queryFn: () =>
        RECEIPT_API.fetchCompanyTripReceipts(
          { ...receiptsQueryParams, sort: stringTableSort },
          paginationConfig.current,
          paginationConfig.pageSize,
        ),
      onSuccess: response => {
        let receiptData = [];

        response.data.forEach(receipt => {
          receiptData.push({ ...receipt });
        });

        setOverdueReceiptsCount(response.overdueCount || 0);
        setSelectedRowKeys([]);
        setReceiptData(receiptData);
      },
      onError: error => {
        setSelectedRowKeys([]);
        handleApiErrors(error.response, () => {
          Toast({
            type: 'error',
            message: 'Error while loading receipts',
          });
        });
      },
    },
    {
      resetPageQueryKey: Object.values(receiptsQueryParams),
      initialPage: page,
      initialPageSize: pageSize,
      useQueryParams: true,
    },
  );

  const exportReceiptMutation = useMutation(
    () =>
      RECEIPT_API.exportTripReceipt({
        ...receiptsQueryParams,
        sort: stringTableSort,
        hideReceipts: false,
      }),
    {
      onSuccess: () => {
        Toast({
          type: 'open',
          duration: 10, // seconds
          message: (
            <Trans
              t={t}
              i18nKey="exportBeingProcessed"
              components={[
                <LinkText variant="b" onClick={() => history.push(INTERNAL_LINKS.EXPORTS)}>
                  Dummy
                </LinkText>,
              ]}
            />
          ),
        });
      },
      onError: error => handleApiErrors(error.response),
    },
  );

  const onSelectedRowKeysChange = selectedRowKeys => {
    const receiptsWithMoreInfoTrips = findReceiptsWithTripsInMoreInfoStatus(
      receiptData,
      selectedRowKeys,
    );
    setSelectedRowKeys(selectedRowKeys);
    setReceiptsWithMoreInfoTrips(receiptsWithMoreInfoTrips);
  };

  const checkIfUserIsAllowedToResubmitSelectedReceiptsForApproval = (receiptIDs = []) => {
    let canUpdate = true;

    for (let i = 0; i < receiptIDs.length; i++) {
      const id = receiptIDs[i];
      const receipt = receiptData.find(r => r._id === id);
      if (receipt) {
        if (!canResubmitReceiptForApproval(authUser, receipt)) {
          canUpdate = false;
          break;
        }
      }
    }

    if (!canUpdate) {
      Toast({
        type: 'error',
        message: t('receiptAlreadySubmittedForApproval'),
        description: t('selectedReceiptAlreadySubmittedForApproval'),
      });
    }

    return canUpdate;
  };

  const openApprovalConfirmation = () => setIsApprovalConfirmationVisible(true);
  const closeApprovalConfirmation = () => setIsApprovalConfirmationVisible(false);

  const openRejectionConfirmation = () => setIsRejectionConfirmationVisible(true);
  const closeRejectionConfirmation = () => setIsRejectionConfirmationVisible(false);

  const openResubmitConfirmation = () => setIsResubmitConfirmationVisible(true);
  const closeResubmitConfirmation = () => setIsResubmitConfirmationVisible(false);

  const [receiptIdToDelete, setReceiptIdToDelete] = useState();
  const [isDeleteReceiptModalVisible, openDeleteReceiptModal, closeDeleteReceiptModal] = useModal();

  const deleteReceiptMutation = useMutation({
    mutationFn: () => new ReceiptAPI().deleteReceipt(receiptIdToDelete),
    onSuccess: () => {
      receiptsQuery.refetch();
      setReceiptIdToDelete();
      closeDeleteReceiptModal();
    },
    onError: error => handleApiErrors(error.response),
  });

  const handleResubmitForApproval = async () => {
    if (checkIfUserIsAllowedToResubmitSelectedReceiptsForApproval(selectedRowKeys)) {
      setIsResubmittingReceiptsForApproval(true);

      try {
        await resubmitReceiptForApproval(selectedRowKeys);
        receiptsQuery.refetch();
        closeResubmitConfirmation();
      } catch (error) {
        handleApiErrors(error.response);
      }

      setIsResubmittingReceiptsForApproval(false);
    }
  };

  const handleApprove = async id => {
    const receiptsToUpdate = id ? [id] : selectedRowKeys;

    setIsApprovingReceipt(true);

    try {
      await approveReceipt(receiptsToUpdate, 'approveAll');
      receiptsQuery.refetch();
      closeApprovalConfirmation();
    } catch (error) {
      handleApiErrors(error.response);
    }
    setIsApprovingReceipt(false);
  };

  const handleDeny = async id => {
    const receiptsToUpdate = id ? [id] : selectedRowKeys;

    setIsRejectingReceipt(true);

    try {
      await rejectReceipt(receiptsToUpdate);
      receiptsQuery.refetch();
      closeRejectionConfirmation();
    } catch (error) {
      handleApiErrors(error.response);
    }
    setIsRejectingReceipt(false);
  };

  /**
   * Handle table filter state update
   */
  const updateFilters = (filters = {}, callback) => {
    setFilters(state => ({ ...state, ...filters }), callback);
  };

  const handleOverdueAlertClick = () => {
    updateFilters({
      dateRange: null,
      status: STATUS_LIST.Status.OVERDUE,
    });
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectedRowKeysChange,
  };

  return (
    <PageContainer
      title={
        <Row gutter={[16, 16]} align="middle">
          <Col>Receipts</Col>

          <Col>
            <OverdueReceiptAlert
              t={t}
              overdueCount={overdueReceiptsCount}
              onClick={handleOverdueAlertClick}
            />
          </Col>
        </Row>
      }
      stickyHeader={canUpdateReceiptStatus(authUser, undefined, true)}
      sideActionComponent={
        <Row gutter={[16, 16]} justify="start">
          {canUpdateReceiptStatus(authUser, undefined, true) && (
            <>
              <Col>
                <Button
                  size="sm"
                  type="secondary"
                  disabled={!selectedRowKeys.length}
                  onClick={openRejectionConfirmation}
                >
                  <Trans
                    t={t}
                    values={{ number: selectedRowKeys.length }}
                    defaults={'Reject {{number}} Selected'}
                  />
                </Button>
              </Col>
              <Col>
                <Button
                  size="sm"
                  type="primary"
                  disabled={!selectedRowKeys.length}
                  onClick={openApprovalConfirmation}
                >
                  <Trans
                    t={t}
                    values={{ number: selectedRowKeys.length }}
                    defaults={'Approve {{number}} Selected'}
                  />
                </Button>
              </Col>
            </>
          )}

          <Col>
            <Button
              size="sm"
              type="primary"
              disabled={!selectedRowKeys.length}
              onClick={openResubmitConfirmation}
            >
              <Trans t={t} i18nKey="submitApproval" />
            </Button>
          </Col>
        </Row>
      }
    >
      <Helmet>
        <title>{formatPageTitle('Receipts')}</title>
      </Helmet>

      <Row gutter={16} align="middle" justify="space-between" wrap={false}>
        <Col flex={1}>
          <CommonTableFilters
            hiddenFilters={['group', 'type', 'crmMatch']}
            loggedInUserProfile={authUser}
            disabledFilters={receiptsQuery.isFetching}
            dateRangeValue={filters.dateRange}
            onDateRangeChange={dateRange => updateFilters({ dateRange })}
            userValue={filters.userId}
            onUserChange={userId => updateFilters({ userId })}
            statusValue={filters.status}
            statusOptions={
              companySettings.ach_enabled
                ? RECEIPT_STATUS.SelectionOptionsWithACH
                : RECEIPT_STATUS.SelectOptions
            }
            onStatusChange={status => updateFilters({ status })}
            searchTermValue={filters.searchTerm}
            onSearchTermChange={searchTerm => updateFilters({ searchTerm })}
          />
        </Col>

        <DownloadIcon
          t={t}
          colWidth="65px"
          text={t('exportToCSV')}
          loading={exportReceiptMutation.isLoading || receiptsQuery.isFetching}
          onClick={exportReceiptMutation.mutateAsync}
          disabled={!paginationConfig.total || paginationConfig.total === 0}
        />
      </Row>

      <Row style={{ marginBottom: 24 }}>
        <ReceiptsTable
          t={t}
          asyncSort
          searchTerm={filters.searchTerm}
          showSearchInput={false}
          loading={receiptsQuery.isFetching}
          rowSelection={rowSelection}
          dataSource={receiptData}
          pagination={{
            pageSize: paginationConfig.pageSize,
            total: paginationConfig.total,
            current: paginationConfig.current,
            onShowSizeChange: handlePageSizeChange,
          }}
          onDeleteReceipt={
            hasCompanyAdminRole(authUser)
              ? receiptId => {
                  openDeleteReceiptModal();
                  setReceiptIdToDelete(receiptId);
                }
              : undefined
          }
          onChange={({ current }, _, sorters) => {
            handlePageChange(current);
            onSelectedRowKeysChange([]);
            handleTableSort(sorters?.columnKey, sorters?.order);
          }}
        />
      </Row>

      {hasCompanyManagerOrAdminRole(authUser) && (
        <ReceiptApproveConfirmation
          t={t}
          visible={isApprovalConfirmationVisible}
          isApproving={isApprovingReceipt}
          onCancel={closeApprovalConfirmation}
          onConfirm={() => handleApprove()}
          receiptCount={selectedRowKeys.length}
          receiptsWithMoreInfoTrips={receiptsWithMoreInfoTrips}
        />
      )}

      {hasCompanyManagerOrAdminRole(authUser) && (
        <ReceiptRejectConfirmation
          t={t}
          visible={isRejectionConfirmationVisible}
          isRejecting={isRejectingReceipt}
          onCancel={closeRejectionConfirmation}
          onConfirm={() => handleDeny()}
        />
      )}

      <SubmitReceiptsForApprovalConfirmation
        t={t}
        count={selectedRowKeys.length}
        visible={isResubmitConfirmationVisible}
        isSubmitting={isResubmittingReceiptsForApproval}
        onCancel={closeResubmitConfirmation}
        onConfirm={() => handleResubmitForApproval()}
      />

      {hasCompanyAdminRole(authUser) && (
        <ReceiptDeleteConfirmationModal
          t={t}
          visible={isDeleteReceiptModalVisible}
          loading={deleteReceiptMutation.isLoading}
          onOk={deleteReceiptMutation.mutateAsync}
          onCancel={() => {
            closeDeleteReceiptModal();
            setReceiptIdToDelete();
          }}
        />
      )}
    </PageContainer>
  );
};

ReceiptPage.propTypes = {};

ReceiptPage.defaultProps = {};

export default withNamespaces()(withAuthentication(ReceiptPage));
