import React, { useState, useEffect, useMemo } from 'react';
import { Form, Input, Modal, Select } from 'antd';
import { useSelector } from 'react-redux';
import { selectStoreCountryByCode } from '../../utils/storeSelectors';
import './style.scss';

const { Option } = Select;
const horizontalSpan = { span: 24 };

const AddressModal = ({ address, currentAddress, show, visible }) => {
  const [addressModalVisibility, setAddressModalVisibility] = useState(show);
  const [countryCode, setCountryCode] = useState(
    currentAddress ? currentAddress.country : undefined,
  );
  const [form] = Form.useForm();

  const { supportedCountries } = useSelector(state => state.common);

  const COUNTRY_STATES = useMemo(() => {
    if (countryCode) {
      const country = selectStoreCountryByCode(countryCode);

      if (country) {
        return country.states;
      }
    }
  }, [countryCode]);

  const onSubmitAddress = values => {
    address(values);
    visible(false);
  };

  useEffect(() => {
    setAddressModalVisibility(show);
    visible(show);
    // eslint-disable-next-line
  }, [show]);

  const onSubmitAddressFailed = () => {
    return;
  };

  const handleCountrySelectorChange = value => {
    setCountryCode(value);
  };

  return (
    <Modal
      className="address-modal"
      title="Edit Company Address"
      visible={addressModalVisibility}
      okText="Save Address"
      onCancel={() => visible(false)}
      onOk={() => {
        form
          .validateFields(['streetOne', 'streetTwo', 'state', 'city', 'postalCode', 'country'])
          .then(values => {
            onSubmitAddress(values);
          });
      }}
    >
      <Form
        onFinish={onSubmitAddress}
        onFinishFailed={onSubmitAddressFailed}
        name="address"
        form={form}
        labelCol={horizontalSpan}
        initialValues={{
          streetOne: currentAddress ? currentAddress.streetOne : '',
          streetTwo: currentAddress ? currentAddress.streetTwo : '',
          state: currentAddress ? currentAddress.state : '',
          city: currentAddress ? currentAddress.city : '',
          postalCode: currentAddress ? currentAddress.postalCode : '',
          country: currentAddress ? currentAddress.country : '',
        }}
      >
        <Form.Item
          name="country"
          rules={[{ required: true }]}
          label="Country"
          labelcol={horizontalSpan}
        >
          <Select
            labelcol={horizontalSpan}
            placeholder="* Select your country"
            onChange={handleCountrySelectorChange}
          >
            {supportedCountries.map((c, index) => (
              <Option key={index} value={c.code} disabled={c.status !== 'active'}>
                {c.name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="streetOne"
          rules={[{ required: true }]}
          label="Address 1"
          labelcol={horizontalSpan}
        >
          <Input placeholder={`Address 1 (e.g: S Shore Rd &)`} />
        </Form.Item>

        <Form.Item name="streetTwo" label="Address 2" labelcol={horizontalSpan}>
          <Input placeholder={`Address 2 (e.g: Ohio Ave)`} />
        </Form.Item>

        <Form.Item name="city" rules={[{ required: true }]} label="City" labelcol={horizontalSpan}>
          <Input />
        </Form.Item>

        <Form.Item
          name="state"
          rules={[{ required: true }]}
          label="State"
          labelcol={horizontalSpan}
        >
          {Array.isArray(COUNTRY_STATES) && !!COUNTRY_STATES.length ? (
            <Select labelcol={horizontalSpan} placeholder="* Select your country">
              {COUNTRY_STATES.map((state, key) => (
                <Option key={key} value={state.value}>
                  {state.value}
                </Option>
              ))}
            </Select>
          ) : (
            <Input />
          )}
        </Form.Item>

        <Form.Item
          name="postalCode"
          rules={[{ required: true }]}
          label="Zip Code"
          labelcol={horizontalSpan}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddressModal;
