import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { STATUS_LIST } from '../enum';
import { hasCompanyAdminRole, hasSystemAdminRole } from '../utils/roles';
import {
  selectStoreCompanySettings,
  selectStoreCurrentAuthUser,
  selectStoreCurrentCompany,
} from '../utils/storeSelectors';

const useFeatureToggle = () => {
  const authUser = useSelector(selectStoreCurrentAuthUser);
  const currentCompany = useSelector(selectStoreCurrentCompany);
  const companySettings = useSelector(selectStoreCompanySettings);

  const isUserDataReadOnly = useMemo(() => {
    if (!currentCompany || !authUser) return true;
    if (hasCompanyAdminRole(authUser) || hasSystemAdminRole(authUser)) return false;

    return [STATUS_LIST.Status.TRIAL_END, STATUS_LIST.Status.CANCELLED].includes(
      currentCompany.status,
    );
  }, [currentCompany, authUser]);

  return { companySettings, isUserDataReadOnly };
};

export default useFeatureToggle;
