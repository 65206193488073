import { Col, DatePicker, Descriptions, Dropdown, Form, Row } from 'antd';
import { get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';

import { STATUS_LIST } from '../../enum';
import { getUserInitials, momentEST, momentFormat } from '../../utils/common';
import { validateProfileImageSize } from '../../utils/validate';
import { USER_PERMISSIONS } from '../../views/userdetails/userdetails-permissions';
import Avatar from '../Avatar';
import Button from '../Button';
import FormItem from '../Form/FormItem';
import HelpIcon from '../HelpIcon';
import PhoneTextInput from '../PhoneTextInput';
import Select from '../Select';
import Switch from '../Switch';
import StatusTag from '../Tag/StatusTag';
import TextInput from '../TextInput';
import classNames from './style.module.scss';

const STATUS_DATE_FORMAT = 'MMM DD, YYYY - hh:MMa zz';

/**
 * Renders with fields to update User data like
 * first name, last name, phone number, email, timezone, etc.
 */
const UserProfileForm = props => {
  const {
    t,
    userDetails,
    isSubmitting,
    isSendingActivationLink,
    formDisabled,
    values,
    profilePicture,
    timezoneOptions,
    canUpdateUserStatus,
    onFieldChange,
    onImageUpload,
    currentStatus,
    onUserStatusChange,
    sendActivationLink,
    canSendActivationLink,
    onChangePassword,
    canChangePassword,
    canEditUserIdentity,
  } = props;

  const fileInput = useRef(null);
  const [imageBase64, setImageBase64] = useState();

  const [status, setStatus] = useState(currentStatus === 'active');

  const handleStatusUpdate = async status => {
    await onUserStatusChange(status ? STATUS_LIST.Status.ACTIVE : STATUS_LIST.Status.INACTIVE);
    setStatus(status);
  };

  /**
   * Open the image file picker on the default OS window
   */
  const openImagePickerDlg = () => {
    if (fileInput.current) {
      fileInput.current.click();
    }
  };

  /**
   * Handles the file input value change
   *
   * @param {object} event File input change event
   */
  const uploadProfilePic = async event => {
    try {
      const file = event.target.files[0];
      validateProfileImageSize(file, (imageFile, base64) => {
        setImageBase64(base64);
        onImageUpload(imageFile);
      });
    } catch (err) {}
  };

  const userCreatedOn = get(userDetails, 'created');
  const userActivatedOn = get(userDetails, 'activatedOn');
  const userTerminatedOn = get(userDetails, 'terminatedOn');

  return (
    <Form labelCol={{ span: 24 }} initialValues={values}>
      <Row gutter={[16, 20]} style={{ marginBottom: 57 }}>
        <Col>
          <div>
            <Dropdown
              trigger="hover"
              disabled={isSubmitting || formDisabled}
              menu={{
                className: classNames.avatarMenu,
                items: [
                  {
                    key: 'open-image-picker',
                    label: profilePicture ? t('changeImage') : t('uploadAnImage'),
                    onClick: openImagePickerDlg,
                  },
                ],
              }}
            >
              <div>
                <input
                  type="file"
                  ref={fileInput}
                  style={{ display: 'none' }}
                  accept="image/png, image/jpeg"
                  onChange={uploadProfilePic}
                />
                <Avatar
                  size={96}
                  src={imageBase64 || profilePicture}
                  initials={getUserInitials({
                    firstName: values.firstName,
                    lastName: values.lastName,
                  })}
                />
              </div>
            </Dropdown>

            <br />

            <Row gutter={10} justify="center" wrap={false}>
              <Col>
                <StatusTag status={currentStatus} />
              </Col>
              {userDetails.accountLocked && (
                <Col>
                  <StatusTag status={STATUS_LIST.Status.ACCOUNT_LOCKED} />
                </Col>
              )}
              <Col>
                <HelpIcon
                  width={300}
                  hint={
                    <Descriptions column={1} size="small">
                      <Descriptions.Item label={t('Created')}>
                        {isEmpty(userCreatedOn)
                          ? 'N/A'
                          : momentFormat(userCreatedOn, STATUS_DATE_FORMAT)}
                      </Descriptions.Item>
                      <Descriptions.Item label={t('Activated')}>
                        {isEmpty(userActivatedOn)
                          ? 'N/A'
                          : momentFormat(userActivatedOn, STATUS_DATE_FORMAT)}
                      </Descriptions.Item>
                      {!status && (
                        <Descriptions.Item label={t('Deactivated')}>
                          {isEmpty(userTerminatedOn)
                            ? 'N/A'
                            : momentFormat(userTerminatedOn, STATUS_DATE_FORMAT)}
                        </Descriptions.Item>
                      )}
                    </Descriptions>
                  }
                />
              </Col>
            </Row>
          </div>
        </Col>

        <Col flex={1} align="end">
          {canSendActivationLink && (
            <Row align="bottom" style={{ height: '100%' }}>
              <Col>
                <Button
                  size="sm"
                  type="secondary"
                  loading={isSendingActivationLink}
                  disabled={isSendingActivationLink || formDisabled}
                  onClick={() => sendActivationLink(values?.email)}
                  style={{ transform: 'translateY(5px)' }}
                >
                  Resend Activation Email
                </Button>
              </Col>
            </Row>
          )}
        </Col>

        <Col flex="175px">
          <Form.Item>
            <Switch
              size="medium"
              name="status"
              label={t('Account {{ status }}', { status: status ? 'Active' : 'Inactive' })}
              defaultChecked={status}
              disabled={!canUpdateUserStatus || formDisabled}
              onChange={canUpdateUserStatus ? st => handleStatusUpdate(st) : undefined}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col xs={24} lg={12}>
          <Form.Item required label={t('First Name')}>
            <TextInput
              disabled={!canEditUserIdentity}
              name="firstName"
              autoComplete="given-name"
              value={values.firstName}
              onChange={e => onFieldChange({ firstName: e.target.value })}
            />
          </Form.Item>
        </Col>
        <Col xs={24} lg={12}>
          <Form.Item required label={t('Last Name')}>
            <TextInput
              disabled={!canEditUserIdentity}
              name="lastName"
              autoComplete="family-name"
              value={values.lastName}
              onChange={e => onFieldChange({ lastName: e.target.value })}
            />
          </Form.Item>
        </Col>
        <Col xs={24} lg={12}>
          <Form.Item required label={t('Email')}>
            <TextInput
              disabled={!canEditUserIdentity}
              name="email"
              autoComplete="email"
              value={values.email}
              onChange={e => onFieldChange({ email: e.target.value })}
            />
          </Form.Item>
        </Col>
        <Col xs={24} lg={12}>
          <Form.Item label={t('Phone')}>
            <PhoneTextInput
              disabled={isSubmitting || formDisabled}
              defaultValue={values.phone}
              onChange={phone => onFieldChange({ phone })}
            />
          </Form.Item>
        </Col>
        <Col xs={24} lg={12}>
          <FormItem label={t('Timezone')} help={t('timezone_helpText')}>
            <Select
              placeholder={t('Select a Timezone')}
              loading={!timezoneOptions.length}
              disabled={!timezoneOptions.length || isSubmitting || formDisabled}
              value={values.preferences.preferredTimezone}
              options={timezoneOptions}
              onChange={timezone =>
                onFieldChange({
                  preferences: {
                    ...values.preferences,
                    preferredTimezone: timezone,
                  },
                })
              }
            />
          </FormItem>
        </Col>
        <Col xs={24} lg={12}>
          <Form.Item label={t('Cost Center')}>
            <TextInput
              name="department"
              value={values.department}
              disabled={!canEditUserIdentity || isSubmitting}
              onChange={e => onFieldChange({ department: e.target.value })}
            />
          </Form.Item>
        </Col>
        <Col xs={24} lg={12}>
          <Form.Item label={t('Employee Number')}>
            <TextInput
              name="employeeId"
              value={values.employeeId}
              disabled={!canEditUserIdentity || isSubmitting || formDisabled}
              onChange={e => onFieldChange({ employeeId: e.target.value })}
            />
          </Form.Item>
        </Col>
        <Col xs={24} lg={12}>
          <Form.Item
            label={t('sendActivationEmailDate')}
            name="sendActivationEmailDate"
            hidden={
              !USER_PERMISSIONS.canChangeActivationEmailDate(userDetails) || canSendActivationLink
            }
            extra={
              values.sendActivationEmailDate
                ? t('toSendActivationEmailImmediatelyRemoveThisValue')
                : undefined
            }
            rules={[
              {
                validateTrigger: ['onChange', 'onSubmit'],
                validator: (_, value) => {
                  if (!value) return Promise.resolve();

                  if (momentEST().isAfter(momentEST(value))) {
                    return Promise.reject('Date must be in the future');
                  }

                  return Promise.resolve();
                },
              },
            ]}
          >
            <DatePicker
              showTime
              use12Hours
              showNow={false}
              autoComplete="off"
              format={
                values.sendActivationEmailDate ? 'YYYY-MM-DD hh:mm a zz' : 'YYYY-MM-DD hh:mm a'
              }
              value={values.sendActivationEmailDate}
              onChange={date => {
                onFieldChange({
                  sendActivationEmailDate: date ? date.tz('America/New_York', true) : date,
                });
              }}
              disabled={isSubmitting || formDisabled}
            />
          </Form.Item>
        </Col>
      </Row>

      {canChangePassword && (
        <Row justify="end">
          <Col>
            <Button onClick={onChangePassword}>{t('changePassword')}</Button>
          </Col>
        </Row>
      )}
    </Form>
  );
};

UserProfileForm.propTypes = {
  t: PropTypes.func.isRequired,
  // Original Profile picture from User's profile data
  profilePicture: PropTypes.string,
  onFieldChange: PropTypes.func.isRequired,
  values: PropTypes.shape({}),
  disableUserNames: PropTypes.bool,
  canUpdateUserStatus: PropTypes.bool,
  timezoneOptions: PropTypes.arrayOf(PropTypes.shape({})),
};

UserProfileForm.defaultProps = {
  values: {},
  profilePicture: undefined,
  isSubmitting: false,
  timezoneOptions: [],
  disableUserNames: false,
  canUpdateUserStatus: false,
};

export default UserProfileForm;
