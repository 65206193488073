import './style.scss';

import { Col, Row, Table } from 'antd';
import { Form } from 'antd';
import { debounce } from 'lodash';
import React, { useMemo, useState } from 'react';
import { withNamespaces } from 'react-i18next';

import { IMAGES } from '../../enum';
import Text from '../Text';
import TextInput from '../TextInput';
import classNameModule from './style.module.scss';

/**
 * Expand Icon for Table.
 *
 * @param {object} props - Table props
 */
const TableExpandIcon = props => {
  const { expanded, onExpand, ...iconProps } = props;

  const toggleExpand = e => {
    e.preventDefault();
    e.stopPropagation();
    if (typeof onExpand === 'function') onExpand(iconProps.record, e);
  };

  return (
    iconProps.record.expandable && (
      <div
        onClick={toggleExpand}
        className={expanded ? 'expand-icon expanded' : 'expand-icon collapsed'}
      >
        <img src={IMAGES.ARROW_CIRCLE_DOWN} alt="expand icon" />
      </div>
    )
  );
};

/**
 * Custom Table component based on the Ant Design Table
 * but following Kliks style guide and branding
 */

const CustomTable = props => {
  const {
    onRow,
    columns,
    cursorOnRowHover,
    withExpandableRows,
    expandedRowRender,
    dataSource,
    pagination,
    showSearchInput,
    customTitle,
    longTitle,
    hiddenColumns,
    defaultSearchTerm,
    className,
    loading,
    onExpand,
    expandedRowClassName,
  } = props;

  const [debouncedFn, setDebouncedFn] = useState({});

  const handleSearchTermChange = event => {
    const { value } = event.target;

    let func = debouncedFn.fn;
    if (!func) {
      func = debounce(v => {
        if (typeof props.onSearchTermChange === 'function') {
          props.onSearchTermChange(v);
        }
      }, 300);

      setDebouncedFn({ fn: func });
    }

    func(value);
  };

  const tableClassNames = [
    'custom-table',
    dataSource.length > 0 ? 'custom-table-show-scroll-on-hover' : 'custom-table-hidden-scroll',
    cursorOnRowHover ? 'cursor-on-row-hover' : '',
    className,
  ]
    .filter(Boolean)
    .join(' ');

  const extendOnRowConfig = rowData => {
    let config = {};

    if (onRow) {
      config = onRow(rowData);
    }

    const { leftBorderColor, ...restConfig } = config;
    restConfig.id = rowData?._id;

    if (leftBorderColor) {
      const borderClass = [
        classNameModule.leftBoder,
        classNameModule[`${config.leftBorderColor}Border`],
      ].join(' ');

      if (restConfig.className) {
        restConfig.className += ' ' + borderClass;
      } else {
        restConfig.className = borderClass;
      }
    }

    return restConfig;
  };

  const COLUMNS = useMemo(() => {
    const arrayOfColumns = columns.filter(column => !hiddenColumns.includes(column.key));

    if (withExpandableRows) {
      arrayOfColumns.push(Table.EXPAND_COLUMN);
    }

    return arrayOfColumns;
  }, [columns, hiddenColumns, withExpandableRows]);

  return (
    <div className={tableClassNames}>
      {showSearchInput && (
        <Row
          align="middle"
          justify={customTitle ? 'space-between' : 'end'}
          style={{ width: '100%' }}
        >
          {customTitle && (
            <Col sm={24} lg={longTitle ? 12 : 10} xl={longTitle ? 14 : 8}>
              <Text size="large" variant="h5" style={{ marginBottom: 0 }}>
                {customTitle}
              </Text>
            </Col>
          )}
          <Col sm={24} md={12} lg={10} xl={8}>
            <Form.Item labelCol={{ span: 24 }} label="Search" style={{ marginBottom: 10 }}>
              <TextInput
                allowClear
                placeholder="Search"
                defaultValue={defaultSearchTerm}
                onChange={handleSearchTermChange}
                disabled={loading}
              />
            </Form.Item>
          </Col>
        </Row>
      )}

      <Table
        rowKey={data => data?._id}
        {...props}
        onRow={extendOnRowConfig}
        loading={loading}
        dataSource={dataSource}
        pagination={
          typeof pagination === 'boolean'
            ? pagination
            : {
                hideOnSinglePage: false,
                defaultPageSize: 25,
                showSizeChanger: true,
                pageSizeOptions: ['25', '50', '100'],
                ...(pagination || {}),
                position: ['topRight', 'bottomRight'],
              }
        }
        size="large"
        columns={COLUMNS}
        showSorterTooltip={false}
        expandable={
          !withExpandableRows
            ? {}
            : {
                onExpand,
                expandRowByClick: true,
                defaultExpandAllRows: false,
                expandedRowClassName,
                expandIcon: TableExpandIcon,
                expandedRowRender: (record, index, indent, expanded) => {
                  if (!record.expandable) return null;
                  if (expanded && typeof expandedRowRender === 'function') {
                    return (
                      <div className="expanded-row-content" style={props.expandedRowStyle}>
                        {expandedRowRender(record)}
                      </div>
                    );
                  }
                  return null;
                },
                rowExpandable: record =>
                  record.expandable && typeof expandedRowRender === 'function',
              }
        }
      />
    </div>
  );
};

CustomTable.defaultProps = {
  renderOnExpand: undefined,
  withExpandableRows: false,
  showSearchInput: true,
  hiddenColumns: [],
  dataSource: [],
  expandedRowStyle: {},
};

export default withNamespaces()(CustomTable);
