import { Col, Row } from 'antd';
import { isNull } from 'lodash';
import React from 'react';
import { Link } from 'react-router-dom';

import { INTERNAL_LINKS } from '../../../enum';
import { momentCompanyFormat } from '../../../utils/common';
import { formatCurrencyToCompanyLocale } from '../../../utils/numbers';
import { Button, CustomTable, HelpIcon } from '../..';
import StatusTag from '../../Tag/StatusTag';
import LinkText from '../../Text/LinkText';
import UnpaidDueToInvalidACHInfoSummary from '../../UnpaidDueToInvalidACHInfoSummary';

const AccountReimbursementHistoryTable = props => {
  const { t, dataSource, onTotalPaidInfoClick, ...rest } = props;

  const COLUMNS = [
    {
      width: 270,
      key: 'reimbursementPeriod',
      title: t('reimbursementPeriod'),
      dataIndex: 'period',
      render: (period, data) =>
        period ? (
          <Link to={INTERNAL_LINKS.DWOLLA_MASS_PAYMENT_DETAILS(data._id)}>
            <LinkText variant="b">
              {[
                momentCompanyFormat(period.paymentPeriodStart, 'MMMM DD'),
                momentCompanyFormat(period.paymentPeriodEnd, 'MMMM DD'),
              ].join(' - ')}
            </LinkText>
          </Link>
        ) : (
          'N/A'
        ),
    },
    {
      align: 'right',
      key: 'totalApprovedAmount',
      title: t('totalApprovedReceipts'),
      dataIndex: 'totalApprovedAmount',
      render: totalApprovedAmount =>
        formatCurrencyToCompanyLocale(isNull(totalApprovedAmount) ? 0 : totalApprovedAmount),
    },
    {
      align: 'right',
      key: 'totalPaidAmount',
      title: t('totalPaid'),
      dataIndex: 'totalPaidAmount',
      render: (totalPaidAmount, data) => {
        return (
          <Row gutter={[8, 8]} align="middle" wrap={false}>
            <Col>{formatCurrencyToCompanyLocale(totalPaidAmount)}</Col>
            {typeof totalPaidAmount === 'number' &&
              typeof data.totalApprovedAmount === 'number' &&
              data.totalPaidAmount < data.totalApprovedAmount && (
                <Col>
                  <HelpIcon
                    placement="top"
                    hint={
                      <UnpaidDueToInvalidACHInfoSummary
                        t={t}
                        massPaymentId={data._id}
                        totalApprovedAmount={data.totalApprovedAmount}
                        totalPaidAmount={totalPaidAmount}
                        onTotalPaidInfoClick={onTotalPaidInfoClick}
                      />
                    }
                  />
                </Col>
              )}
          </Row>
        );
      },
    },
    {
      align: 'right',
      key: 'fundingSourceBalance',
      title: t('achFundBalance'),
      dataIndex: 'fundingSourceBalance',
      render: fundingSourceBalance => formatCurrencyToCompanyLocale(fundingSourceBalance || 0),
    },
    {
      key: 'achFundingDeadline',
      title: t('achFundingDeadline'),
      dataIndex: 'achFundingDeadlineDate',
      render: achFundingDeadlineDate =>
        achFundingDeadlineDate
          ? momentCompanyFormat(achFundingDeadlineDate, 'MMMM DD, YYYY, hh:mm a zz')
          : '-',
    },
    {
      align: 'right',
      key: 'achFeesIncludingPenalites',
      title: t('achFeesIncludingPenalites'),
      dataIndex: 'feesAndPenalties',
      render: feesAndPenalties => formatCurrencyToCompanyLocale(feesAndPenalties),
    },
    {
      key: 'status',
      title: t('status'),
      dataIndex: 'status',
      render: status => <StatusTag status={status} />,
    },
    {
      width: 150,
      align: 'right',
      key: 'failedTransactionsCount',
      title: t('numberOfAchErrors'),
      dataIndex: 'failedTransactionsCount',
    },
    {
      width: 200,
      key: '',
      title: t('reimbursementsNotPaidByACH'),
      render: data => {
        return (
          <Link
            to={{
              pathname: INTERNAL_LINKS.REIMBURSEMENT,
              search: new URLSearchParams({
                groupId: '',
                fromDate: momentCompanyFormat(data.period.paymentPeriodStart, 'YYYY-MM-DD'),
                toDate: momentCompanyFormat(data.period.paymentPeriodEnd, 'YYYY-MM-DD'),
                canBeUpdatedManually: false,
              }).toString(),
            }}
          >
            <Button size="xs">{t('viewReimbursements')}</Button>
          </Link>
        );
      },
    },
  ];

  return (
    <CustomTable {...rest} showSearchInput={false} columns={COLUMNS} dataSource={dataSource} />
  );
};

export default AccountReimbursementHistoryTable;
