import { Col, Form, Row } from 'antd';
import React from 'react';

import { ERROR_MESSAGE } from '../../../utils/constants';
import Text from '../../Text';
import TextInput from '../../TextInput';

const RateFormSegmentCA = props => {
  const { t, isSubmitting } = props;

  return (
    <>
      <Text>{t('standardKilometerRateCRA')}</Text>

      <br />

      <Row gutter={16}>
        <Col xs={24} md={12}>
          <Form.Item
            required
            name="mileage-band-1"
            label={t('first5000Km')}
            rules={[{ required: true, message: ERROR_MESSAGE.BLANK_FIELD }]}
          >
            <TextInput
              disabled={isSubmitting}
              step="any"
              type="number"
              placeholder={t('enterAmountPerKilometer')}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col xs={24} md={12}>
          <Form.Item
            required
            name="mileage-band-2"
            label={t('after5000Km')}
            rules={[{ required: true, message: ERROR_MESSAGE.BLANK_FIELD }]}
          >
            <TextInput
              disabled={isSubmitting}
              step="any"
              type="number"
              placeholder={t('enterAmountPerKilometer')}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col xs={24} md={12}>
          <Form.Item
            required
            name="additional-rate-North-West-Territory"
            label={t('additionalRateNorthwestTerritory')}
            rules={[{ required: true, message: ERROR_MESSAGE.BLANK_FIELD }]}
          >
            <TextInput
              disabled={isSubmitting}
              step="any"
              type="number"
              placeholder={t('enterAmountPerKilometer')}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col xs={24} md={12}>
          <Form.Item
            required
            name="additional-rate-Yukon-Territory"
            label={t('additionalRateYukonTerritory')}
            rules={[{ required: true, message: ERROR_MESSAGE.BLANK_FIELD }]}
          >
            <TextInput
              disabled={isSubmitting}
              step="any"
              type="number"
              placeholder={t('enterAmountPerKilometer')}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col xs={24} md={12}>
          <Form.Item
            required
            name="additional-rate-Nunavet-Territory"
            label={t('additionalRateNunavetTerritory')}
            rules={[{ required: true, message: ERROR_MESSAGE.BLANK_FIELD }]}
          >
            <TextInput
              disabled={isSubmitting}
              step="any"
              type="number"
              placeholder={t('enterAmountPerKilometer')}
            />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

export default RateFormSegmentCA;
