import React from 'react';

import BusinessVehicleList from '../../shared-ui/BusinessVehicleList';
import GenericModal from '../GenericModal';

const BusinessVehicleHistoryModal = props => {
  const { t, visible, onCancel } = props;

  const WINDOW_WIDTH = window.innerWidth - 50;
  const MODAL_WIDTH = WINDOW_WIDTH > 505 ? 505 : WINDOW_WIDTH;

  return (
    <GenericModal
      title={t('Business Vehicle History')}
      width={MODAL_WIDTH}
      footer={null}
      visible={visible}
      onCancel={onCancel}
    >
      <BusinessVehicleList t={t} vehicles={[]} />
    </GenericModal>
  );
};

export default BusinessVehicleHistoryModal;
