import { handleApiCalls } from './axiosInstance';

export const CARS_API = {
  /**
   * Performs an API call to fetch the min and max year scope available for vehicle APIs
   *
   * @returns {{ min_year: number, max_year: number}}
   */
  fetchCarYears: async () => {
    const url = `${process.env.REACT_APP_HOST_API}cars/informations?cmd=getYears`;
    const result = await handleApiCalls('get', url);

    return result.data.data;
  },
  /**
   * Peforms an API call to fetch a list of call Makes based on a year.
   *
   * @param {number} year Year of car make
   * @returns {string[]}
   */
  fetchCarMakes: async year => {
    const url = `${process.env.REACT_APP_HOST_API}cars/informations?cmd=getMakes&year=${year}`;
    const result = await handleApiCalls('get', url);

    return result.data.data;
  },
  /**
   * Performs an API call to fetch a list of car models.
   *
   * @param {number} year Year of model
   * @param {string} carMake Name of Make
   * @returns {string[]}
   */
  fetchCarModels: async (year, carMake) => {
    const url = `${process.env.REACT_APP_HOST_API}cars/informations?cmd=getModels&year=${year}&make=${carMake}`;
    const result = await handleApiCalls('get', url);

    return result.data.data;
  },
};

export const STANDARD_VEHICLES_API = {
  fetchFavrCarYears: async () => {
    const url = `${process.env.REACT_APP_HOST_API}standard-vehicles?year=&make=&mode=years`;
    const result = await handleApiCalls('get', url);

    return result.data;
  },
  fetchAllStandardVehicles: async year => {
    const url = `${process.env.REACT_APP_HOST_API}standard-vehicles?year=${year}&make=0&mode=0`;
    const result = await handleApiCalls('get', url);

    return result.data;
  },
  /**
   * Peforms an API call to fetch a list of call Makes based on a year.
   *
   * @param {number} year Year of car make
   * @returns {string[]}
   */
  fetchFavrCarMakes: async year => {
    const url = `${process.env.REACT_APP_HOST_API}standard-vehicles?year=${year}&make=0&mode=makes`;
    const result = await handleApiCalls('get', url);

    return result.data;
  },
  /**
   * Performs an API call to fetch a list of car models.
   *
   * @param {number} year Year of model
   * @param {string} carMake Name of Make
   * @returns {string[]}
   */
  fetchFavrCarModels: async (year, carMake) => {
    const url = `${process.env.REACT_APP_HOST_API}standard-vehicles?year=${year}&make=${carMake}&mode=models`;
    const result = await handleApiCalls('get', url);

    return result.data;
  },
};
