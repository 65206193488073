import { Col, Row, Tabs } from 'antd';
import queryString from 'querystring';
import React, { useMemo, useState } from 'react';

import Box from '../../../../components/Box';
import Spinner from '../../../../components/Spinner';
import SubmitCancelButtonGroup from '../../../../components/SubmitCancelButtonGroup';
import TabsContainer from '../../../../components/TabsContainer';
import Text from '../../../../components/Text';
import { STATUS_LIST } from '../../../../enum';
import AnonymizeDataOptions from '../../../gdpr/components/AnonymizeDataOptions';
import StatusTab from '../../../gdpr/components/StatusTab';

const USER_INFO_TAB_KEY = 'userInfo';
const STATUS_TAB_KEY = 'status';

const AnonymizeDataTab = props => {
  const {
    t,
    loggedInUserID,
    onSubmitRequest,
    anonymizeRequests,
    history,
    location,
    onCancelRequest,
    loading,
    showRemoveAccount,
  } = props;

  const { activeTab, subTab } = queryString.parse(location.search.replace('?', ''));

  const [activePane, setActivePane] = useState(subTab || USER_INFO_TAB_KEY);

  const handleTabChange = tabKey => {
    setActivePane(tabKey);
    history.replace({ search: queryString.stringify({ activeTab, subTab: tabKey }) });
  };

  const requestsAwaitingApproval = useMemo(() => {
    if (Array.isArray(anonymizeRequests)) {
      return anonymizeRequests.filter(r => r.status === STATUS_LIST.Status.AWAITING_APPROVAL);
    }

    return [];
  }, [anonymizeRequests]);

  if (loading) {
    return (
      <Box>
        <Row justify="center" align="middle">
          <Col style={{ minHeight: 300 }}>
            <Spinner />
          </Col>
        </Row>
      </Box>
    );
  }

  return (
    <Box>
      <TabsContainer activeKey={activePane} onChange={handleTabChange}>
        <Tabs.TabPane key={USER_INFO_TAB_KEY} tab={t('yourInformation')}>
          <AnonymizeDataOptions
            t={t}
            disabled
            tripChecked
            reimbursementChecked
            receiptsChecked
            profileChecked
            showRemoveAccount={showRemoveAccount}
          />
          <br />
          <Text size="sm">{t('ifRequestApprovedWithUnpaidItems')}</Text>
          <br />
          <SubmitCancelButtonGroup
            hideCancel
            disabled={!!requestsAwaitingApproval.length}
            submitText={
              !!requestsAwaitingApproval.length ? t('requestInProgress') : t('submitRequest')
            }
            onSubmit={onSubmitRequest}
          />
        </Tabs.TabPane>

        <Tabs.TabPane key={STATUS_TAB_KEY} tab={t('status')}>
          <StatusTab
            t={t}
            loggedInUserID={loggedInUserID}
            requests={anonymizeRequests}
            onCancel={onCancelRequest}
          />
        </Tabs.TabPane>
      </TabsContainer>
    </Box>
  );
};

export default AnonymizeDataTab;
