import { Col, Row, Spin } from 'antd';
import React, { useMemo, useState } from 'react';

import { COMPANY_API } from '../../api/company';
import useInfiniteQuery from '../../hooks/queries/useInfiniteQuery';
import useDebouncedState from '../../hooks/useDebouncedState';
import Select from '../Select';

const CompanyLookupSelect = props => {
  const { t, showAllOption, onChange, notFoundContent, ...rest } = props;

  const [searchTerm, setSearchTerm] = useDebouncedState(null, 500);
  const [initialCount, setInitialCount] = useState();

  const { query: companiesQuery, flatPages } = useInfiniteQuery(
    {
      queryKey: ['getAllCompanies', searchTerm],
      queryFn: ({ pageParam = 1 }) =>
        COMPANY_API.getAllCompanies({ searchTerm, pageNumber: pageParam, pageSize: 25 }),
      onSuccess: ({ pages }) => {
        if (
          Array.isArray(pages) &&
          typeof pages[0]?.totalCount !== 'undefined' &&
          typeof initialCount === 'undefined'
        ) {
          setInitialCount(pages[0]?.totalCount);
        }
      },
    },
    'totalCount',
    'documents',
  );

  const options = useMemo(() => {
    let optionsArray = flatPages.map(company => ({
      value: company._id,
      label: company.name,
    }));

    if (showAllOption) {
      optionsArray = [{ value: null, label: t('All') }, ...optionsArray];
    }

    return optionsArray;
  }, [t, showAllOption, flatPages]);

  const handleChange = val => {
    if (typeof onChange === 'function') {
      onChange(
        val,
        flatPages.find(user => user._id === val),
      );
    }
    setSearchTerm();
  };

  const handleOptionsScroll = e => {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;

    if (bottom && !companiesQuery.isFetchingNextPage && companiesQuery.hasNextPage) {
      companiesQuery.fetchNextPage();
    }
  };

  return (
    <Select
      {...rest}
      onChange={handleChange}
      fullWidth
      showSearch
      allowClear={false}
      loading={companiesQuery.isFetchingNextPage}
      options={options}
      onSearch={setSearchTerm}
      placeholder={t('user')}
      onPopupScroll={handleOptionsScroll}
      notFoundContent={
        companiesQuery.isFetching ? (
          <Row justify="center" align="middle">
            <Col>
              <Spin size="default" />
            </Col>
          </Row>
        ) : initialCount === 0 ? (
          /* message to display only if the initial search has not results,
           hence any other search term queries will be return the same */
          notFoundContent
        ) : undefined
      }
    />
  );
};

export default CompanyLookupSelect;
