import React, { useState } from 'react';

import { setFullscreenMaxHeight } from '../../../utils/common';
import VehicleFavrFormWizard from '../../../views/userdetails/reimbursement-rate/VehicleFavrFormWizard';
import VehicleForm from '../../../views/userdetails/reimbursement-rate/VehicleForm';
import SubmitCancelButtonGroup from '../../SubmitCancelButtonGroup';
import GenericModal from '../GenericModal';

const VehicleFormModal = props => {
  const {
    isVisible,
    isUpdate,
    onCancel,
    //
    t,
    isFAVR,
    isSubmitting,
    selectedVehicle,
    formInstance,
    initialValues,
    onSubmit,
    onChange,
    msrpAndSalesTax,
  } = props;

  const [modalFooter, setModalFooter] = useState(null);

  return (
    <GenericModal
      centered
      destroyOnClose
      visible={isVisible}
      onCancel={onCancel}
      height={setFullscreenMaxHeight(762)}
      bodyStyle={{
        overflowY: 'auto',
        height: '100%',
        paddingBottom: 12,
        paddingTop: 0,
        marginTop: 30,
      }}
      footer={
        isFAVR ? (
          modalFooter
        ) : (
          <SubmitCancelButtonGroup
            submitText={isUpdate ? 'Update' : 'Add'}
            loading={isSubmitting}
            disabled={isSubmitting}
            onCancel={onCancel}
            onSubmit={() => onSubmit(selectedVehicle)}
          />
        )
      }
    >
      {isFAVR ? (
        <VehicleFavrFormWizard
          t={t}
          isUpdate={isUpdate}
          isSubmitting={isSubmitting}
          msrpAndSalesTax={msrpAndSalesTax}
          selectedVehicle={selectedVehicle}
          formInstance={formInstance}
          initialValues={initialValues}
          onChange={onChange}
          onFooterUpdate={setModalFooter}
          onSubmit={onSubmit}
        />
      ) : (
        <VehicleForm
          t={t}
          isUpdate={isUpdate}
          isSubmitting={isSubmitting}
          selectedVehicle={selectedVehicle}
          formInstance={formInstance}
          initialValues={initialValues}
          onChange={onChange}
        />
      )}
    </GenericModal>
  );
};

export default VehicleFormModal;
