import { Col, Row } from 'antd';
import React, { useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { withNamespaces } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';

import { handleApiErrors } from '../../api/axiosInstance';
import {
  confirmImportRequest,
  fetchImportRequestByID,
  IMPORT_REQUEST_API,
} from '../../api/import-requests';
import Box from '../../components/Box';
import Button from '../../components/Button';
import PageBreadcrumbs from '../../components/PageBreadcrumbs';
import Steps from '../../components/Steps';
import LocationsTable from '../../components/Table/LocationsTable';
import StatusTag from '../../components/Tag/StatusTag';
import Text from '../../components/Text';
import Toast from '../../components/Toast';
import PageContainer from '../../containers/PageContainer';
import { INTERNAL_LINKS, STATUS_LIST } from '../../enum';
import withAuthentication from '../../hocs/withAuthentication';
import useTablePagination from '../../hooks/useTablePagination';
import { formatPageTitle } from '../../utils/common';
import { checkForCreateErrorsOnImportRequest } from '../../utils/importRequest';
import ImportRequestSummaryCard from '../locations/ImportRequestSummaryCard';

const REVIEW_VALID_STEP = 0;
const REVIEW_INVALID_STEP = 1;
const REVIEW_DUPLICATE_STEP = 2;
const REVIEW_MISSING_STEP = 3;
const REVIEW_CONFIRM_STEP = 4;

const CompanyLocationsDetails = props => {
  const { t, match, history } = props;

  const [locationIDsToDelete, setLocationIDsToDelete] = useState([]);

  const [currentStep, setCurrentStep] = useState(REVIEW_VALID_STEP);

  const importRequestDetailsQuery = useQuery({
    queryKey: ['fetchImportRequestByID', match.params.id],
    queryFn: () => fetchImportRequestByID(match.params.id),
    select: data => ({
      ...data,
      validation: data.validation.map(item => ({
        errors: item.errors,
        expandable: Array.isArray(item.errors) && !!item.errors.length,
        label: item.label,
        result: item.result,
        address: {
          street: item.street,
          streetOne: item.streetOne,
          number: item.streetNumber,
          streetTwo: item.streetTwo,
          city: item.city,
          state: item.state,
          postalCode: item.postalCode,
          country: item.country,
          latitude: item.latitude,
          longitude: item.longitude,
        },
      })),
    }),
  });

  const [validLocations, invalidLocations, duplicateLocations] = useMemo(() => {
    let valid = [];
    let invalid = [];
    let duplicate = [];

    if (
      importRequestDetailsQuery.data &&
      Array.isArray(importRequestDetailsQuery.data?.validation)
    ) {
      importRequestDetailsQuery.data.validation.forEach(location => {
        if (location.result === 'valid' || location.result === 'update') valid.push(location);
        if (location.result === 'invalid') invalid.push(location);
        if (location.result === 'duplicate' || location.result === 'ignored')
          duplicate.push(location);
      });
    }

    return [valid, invalid, duplicate];
  }, [importRequestDetailsQuery]);

  const missingLocationsPagination = useTablePagination();
  const missingLocationsQuery = useQuery({
    queryKey: [
      'fetchImportRequestMissingLocations',
      match.params.id,
      missingLocationsPagination.paginationConfig.current,
      missingLocationsPagination.paginationConfig.pageSize,
    ],
    placeholderData: { documents: [], totalCount: 0 },
    onSuccess: ({ totalCount }) => missingLocationsPagination.handleTotalUpdate(totalCount),
    queryFn: () =>
      IMPORT_REQUEST_API.fetchImportRequestMissingLocations(
        match.params.id,
        missingLocationsPagination.paginationConfig.current,
        missingLocationsPagination.paginationConfig.pageSize,
      ),
  });

  const AVAILABLE_STEPS = useMemo(() => {
    const steps = [REVIEW_VALID_STEP];

    if (invalidLocations.length !== 0) {
      steps.push(REVIEW_INVALID_STEP);
    }

    if (duplicateLocations.length !== 0) {
      steps.push(REVIEW_DUPLICATE_STEP);
    }

    if (
      Array.isArray(missingLocationsQuery.data?.documents) &&
      missingLocationsQuery.data?.documents?.length !== 0
    ) {
      steps.push(REVIEW_MISSING_STEP);
    }

    steps.push(REVIEW_CONFIRM_STEP);

    return steps;
  }, [invalidLocations, duplicateLocations, missingLocationsQuery]);

  const confirmLocationImportMutation = useMutation(
    () => confirmImportRequest(match.params.id, locationIDsToDelete),
    {
      onSuccess: () => {
        Toast({
          type: 'success',
          message: t('locationsConfirmSuccess'),
        });
        history.push(INTERNAL_LINKS.LOCATIONS);
      },
      onError: error => handleApiErrors(error.response),
    },
  );

  const handleLocationToDeleteUpdate = locationID => {
    if (locationIDsToDelete.includes(locationID)) {
      const updatedLocations = locationIDsToDelete.filter(locID => locID !== locationID);
      setLocationIDsToDelete(updatedLocations);
    } else {
      setLocationIDsToDelete([...locationIDsToDelete, locationID]);
    }
  };

  const handleNextStep = () => {
    const currentStepIndex = AVAILABLE_STEPS.findIndex(stepNumber => stepNumber === currentStep);

    if (currentStep !== REVIEW_CONFIRM_STEP) {
      setCurrentStep(AVAILABLE_STEPS[currentStepIndex + 1]);
    } else {
      confirmLocationImportMutation.mutateAsync();
    }
  };

  const handleBackStep = () => {
    const currentStepIndex = AVAILABLE_STEPS.findIndex(stepNumber => stepNumber === currentStep);
    setCurrentStep(AVAILABLE_STEPS[currentStepIndex - 1]);
  };

  const handleGoBackToLocations = () =>
    props.history.push({
      pathname: INTERNAL_LINKS.LOCATIONS,
      search: 'activeTab=import-csv',
    });

  const LOCATION_COLUMNS_TO_HIDE = ['street', 'addedBy', 'dateAdded', 'latitude', 'longitude'];

  return (
    <PageContainer
      loading={importRequestDetailsQuery.isFetching}
      title={
        <PageBreadcrumbs
          items={[
            {
              label: t('manageLocations'),
              onClick: handleGoBackToLocations,
            },
            {
              label: (
                <Row align="middle" gutter={10} style={{ display: 'inline-flex' }}>
                  <Col>{t('csvImportDetails')}</Col>
                  {importRequestDetailsQuery.data && (
                    <Col>
                      <StatusTag
                        status={
                          checkForCreateErrorsOnImportRequest(importRequestDetailsQuery.data)
                            ? STATUS_LIST.Status.COMPLETED_WITH_ERRORS
                            : importRequestDetailsQuery.data.status
                        }
                      />
                    </Col>
                  )}
                </Row>
              ),
            },
          ]}
        />
      }
    >
      <Helmet>
        <title>{formatPageTitle('Company Location Details')}</title>
      </Helmet>

      <Row style={{ marginBottom: 20 }}>
        <Col flex={1}>
          <ImportRequestSummaryCard t={t} importRequest={importRequestDetailsQuery.data} />
        </Col>
      </Row>

      <Row>
        <Col flex={1}>
          <Box>
            <Steps size="small" current={currentStep}>
              <Steps.Step
                title={t('reviewValidLocationsCount', {
                  count: validLocations.length,
                })}
                description={t('newOrUpdatedValidLocations')}
              />
              <Steps.Step
                title={t('reviewInvalidLocationsCount', {
                  count: invalidLocations.length,
                })}
                description={t('locationsNotVerifiedNotImported')}
              />
              <Steps.Step
                title={t('reviewLocationsDuplicateCount', {
                  count: duplicateLocations.length,
                })}
                description={t('duplicateLocationWillNoImport')}
              />
              <Steps.Step
                title={t('reviewLocationsMissingCount', {
                  count: missingLocationsQuery.data?.totalCount || 0,
                })}
                description={t('locationsExistKliksButNotCSV')}
              />
              <Steps.Step title={t('Confirm')} />
            </Steps>

            <br />

            <Row>
              <Col flex={1}>
                {currentStep === REVIEW_VALID_STEP && (
                  <LocationsTable
                    t={t}
                    loading={importRequestDetailsQuery.isFetching}
                    dataSource={validLocations}
                    hiddenColumns={LOCATION_COLUMNS_TO_HIDE}
                  />
                )}

                {currentStep === REVIEW_INVALID_STEP && (
                  <LocationsTable
                    t={t}
                    dataSource={invalidLocations}
                    hiddenColumns={LOCATION_COLUMNS_TO_HIDE}
                  />
                )}

                {currentStep === REVIEW_DUPLICATE_STEP && (
                  <LocationsTable
                    t={t}
                    dataSource={duplicateLocations}
                    hiddenColumns={LOCATION_COLUMNS_TO_HIDE}
                  />
                )}

                {currentStep === REVIEW_MISSING_STEP && (
                  <LocationsTable
                    isDeleteTable
                    t={t}
                    dataSource={missingLocationsQuery.data?.documents}
                    locationsToDelete={locationIDsToDelete}
                    onDeleteLocation={handleLocationToDeleteUpdate}
                    hiddenColumns={['streetOne', 'streetTwo']}
                    onChange={({ current }) => missingLocationsPagination.handlePageChange(current)}
                    pagination={{
                      pageSize: missingLocationsPagination.paginationConfig.pageSize,
                      total: missingLocationsPagination.paginationConfig.total,
                      current: missingLocationsPagination.paginationConfig.current,
                      onShowSizeChange: missingLocationsPagination.handlePageSizeChange,
                    }}
                  />
                )}

                {currentStep === REVIEW_CONFIRM_STEP && (
                  <Row justify="center" style={{ minHeight: 300, alignItems: 'center' }}>
                    <Col flex="300px">
                      <br />
                      <Text>
                        {t('locationsToBeImported')}: {validLocations.length}
                      </Text>
                      <br />
                      <Text>
                        {t('locationsNotImported')}:{' '}
                        {invalidLocations.length + duplicateLocations.length}
                      </Text>
                      <br />
                      <Text>
                        {t('locationsToBeDeleted')}: {locationIDsToDelete.length}
                      </Text>
                      <br />
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>

            <br />

            <Row justify="space-between">
              <Col>
                <Button
                  onClick={handleGoBackToLocations}
                  disabled={confirmLocationImportMutation.isLoading}
                >
                  {t('Cancel')}
                </Button>
              </Col>

              <Col>
                <Row gutter={17} justify="end" align="middle">
                  {currentStep !== REVIEW_VALID_STEP && (
                    <Col>
                      <Button
                        type="secondary"
                        onClick={handleBackStep}
                        disabled={confirmLocationImportMutation.isLoading}
                      >
                        {t('Back')}
                      </Button>
                    </Col>
                  )}

                  {!(
                    importRequestDetailsQuery.data &&
                    currentStep === REVIEW_CONFIRM_STEP &&
                    checkForCreateErrorsOnImportRequest(importRequestDetailsQuery.data)
                  ) && (
                    <Col>
                      <Button
                        onClick={handleNextStep}
                        loading={confirmLocationImportMutation.isLoading}
                        disabled={confirmLocationImportMutation.isLoading}
                      >
                        {currentStep !== REVIEW_CONFIRM_STEP ? t('Next') : t('Confirm')}
                      </Button>
                    </Col>
                  )}
                </Row>
              </Col>
            </Row>
          </Box>
        </Col>
      </Row>
    </PageContainer>
  );
};

export default withNamespaces()(withAuthentication(CompanyLocationsDetails));
