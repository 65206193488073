import { Col, Row } from 'antd';
import React from 'react';

import FormItem from '../../../components/Form/FormItem';
import Select from '../../../components/Select';
import { ERROR_MESSAGE } from '../../../utils/constants';
import { hasSystemAdminRole } from '../../../utils/roles';
import FeatureFormItem from './FeatureFormItem';
import SettingSectionWrapper from './SettingSectionWrapper';

const BillingSettingsFormItems = props => {
  const {
    t,
    authUser,
    companySettings,
    planSettings,
    onFeatureChange,
    disabled,
    standardPlanOptions,
    customPlansOptions,
    isLoadingPlans,
    isInitialPlanCustom,
    onPlanChange,
  } = props;

  if (!hasSystemAdminRole(authUser)) return null;

  return (
    <SettingSectionWrapper title={t('billingSettings')}>
      <FeatureFormItem
        label={t('billAllActiveUsers')}
        value={companySettings.bill_all_active_users}
        disabled={disabled}
        onChange={checked => {
          if (checked) {
            onFeatureChange({
              bill_all_active_users: checked,
              bill_active_trip_users: false,
              billAllForRecordedTrip: false,
            });
          } else {
            onFeatureChange({ bill_all_active_users: checked });
          }
        }}
        helpText={t('billAllActiveUsers_SysAdminHelpText')}
      />

      <FeatureFormItem
        label={t('billUsersWhoRecordTrips_OnlyUsers')}
        helpText={t('billUsersWhoRecordTrips_OnlyUsers_SysAdminHelpText')}
        value={companySettings.bill_active_trip_users}
        disabled={disabled}
        onChange={checked => {
          if (checked) {
            onFeatureChange({
              bill_active_trip_users: checked,
              bill_all_active_users: false,
              billAllForRecordedTrip: false,
            });
          } else {
            onFeatureChange({ bill_active_trip_users: checked });
          }
        }}
      />

      <FeatureFormItem
        label={t('billUsersWhoRecordTrips_AllUsers')}
        helpText={t('billUsersWhoRecordTrips_AllUsers_SysAdminHelpText')}
        value={companySettings.billAllForRecordedTrip}
        disabled={disabled}
        onChange={checked => {
          if (checked) {
            onFeatureChange({
              billAllForRecordedTrip: checked,
              bill_all_active_users: false,
              bill_active_trip_users: false,
            });
          } else {
            onFeatureChange({ billAllForRecordedTrip: checked });
          }
        }}
      />

      <FeatureFormItem
        label={t('allowInvoiceForBilling')}
        value={companySettings.allow_invoice_for_billing}
        onChange={checked => onFeatureChange({ allow_invoice_for_billing: checked })}
        helpText={t('allowInvoiceForBilling_SysAdminHelpText')}
        disabled={disabled}
      />

      <FeatureFormItem
        label={t('customPricePlan')}
        value={companySettings.customPricePlan}
        onChange={checked => onFeatureChange({ customPricePlan: checked })}
        helpText={t('selectCustomPlan_SysAdminHelpText')}
        disabled={disabled}
      />

      <FeatureFormItem
        label={t('generateSeparatePayrollFiles')}
        value={companySettings.generateSeparatePayrollFiles}
        onChange={checked => onFeatureChange({ generateSeparatePayrollFiles: checked })}
        disabled={disabled}
      />

      {companySettings?.customPricePlan && (
        <Row align="middle" gutter={[16, 16]} wrap={false}>
          <Col flex={1}>
            <FormItem
              required
              label={t('customPlanName')}
              rules={[{ required: true, message: ERROR_MESSAGE.BLANK_FIELD }]}
            >
              <Select
                fullWidth
                showSearch
                name="customPlanId"
                defaultValue={planSettings?.customPlanId}
                optionFilterProp="children"
                disabled={disabled}
                options={customPlansOptions}
                loading={isLoadingPlans}
                placeholder={t('selectCustomPlan')}
                onChange={customPlanId => {
                  onPlanChange({ customPlanId });
                }}
              />
            </FormItem>
          </Col>
        </Row>
      )}

      {isInitialPlanCustom && !companySettings?.customPricePlan && (
        <FormItem
          required
          label={t('standardPlanName')}
          rules={[{ required: true, message: ERROR_MESSAGE.BLANK_FIELD }]}
        >
          <Select
            fullWidth
            showSearch
            name="standardPlanId"
            defaultValue={planSettings?.standardPlanId}
            optionFilterProp="children"
            disabled={disabled}
            options={standardPlanOptions}
            loading={isLoadingPlans}
            placeholder={t('selectStandardPlan')}
            onChange={standardPlanId => onPlanChange({ standardPlanId })}
          />
        </FormItem>
      )}
    </SettingSectionWrapper>
  );
};

export default BillingSettingsFormItems;
