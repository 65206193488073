import { DeleteFilled } from '@ant-design/icons';
import { Col, Row } from 'antd';
import React from 'react';

import { momentFormat } from '../../../utils/common';
import { CustomTable } from '../..';
import Button from '../../Button';

const UploadedFilesTable = props => {
  const { t, dataSource, actions, ...rest } = props;

  const COLUMNS = [
    {
      width: '250px',
      title: t('dateUploaded'),
      dataIndex: 'timestamp',
      render: timestamp => momentFormat(timestamp, 'MMMM DD, YYYY'),
    },
    {
      width: '900px',
      title: t('fileName'),
      dataIndex: 'name',
    },
  ];

  if (actions.canDownload || actions.canRemove) {
    COLUMNS.push({
      title: '',
      render: data => (
        <Row align="middle" gutter={16} wrap={false}>
          {actions?.canDownload && (
            <Col>
              <Button size="xs" type="primary" onClick={() => actions?.download(data)}>
                Download
              </Button>
            </Col>
          )}

          {actions?.canRemove && (
            <Col>
              <DeleteFilled
                className="item-container-trash"
                onClick={() => actions?.remove(data)}
              />
            </Col>
          )}
        </Row>
      ),
    });
  }

  return (
    <CustomTable
      pagination={false}
      {...rest}
      showSearchInput={false}
      rowKey={data => data.uid || data.fileKey}
      columns={COLUMNS}
      dataSource={dataSource}
      scroll={{ x: 500 }}
    />
  );
};

UploadedFilesTable.defaultProps = {
  actions: {
    canDownload: false,
    download: () => undefined,
    canRemove: false,
    remove: () => undefined,
  },
};

export default UploadedFilesTable;
