import React from 'react';
// the hoc
import { withNamespaces } from 'react-i18next';

import './style.scss';

const initialData = [
  {
    title: 'Create your account',
    desc: 'Fill in main information',
  },
  {
    title: 'Company',
    desc: 'Select your company or create new',
  },
  {
    title: 'Price',
    desc: 'Select your plan according to your needs',
  },
  {
    title: 'Features',
    desc: 'Select features which you will need',
  },
  {
    title: 'Payment',
    desc: 'Select the payment method',
  },
  {
    title: 'Success',
    desc: 'Start working with new possibilities',
  },
];

const HistoryStep = ({ className = "", data = initialData, position = 0, t }) => {
  const newClassName = `history-step ${className}`;
  const getStepClassName = index => `history-step-item ${index < position ? 'complete' : (index === position ? 'inprogress' : '')}`;
  const getBar = index => `bar ${index < position ? 'complete' : ''}`
  const getCircle = index => `circle ${index < position ? 'complete' : (index === position ? 'inprogress' : '')}`
  const stepCount = data.length;

  return (
    <div className={newClassName}>
      {
        data.map((step, index) => (
          <div className={getStepClassName(index)} key={index}>
            <h5>{t(step.title)}</h5>
            <h6>{t(step.desc)}</h6>
            {stepCount > index + 1 && <div className={getBar(index)} />}
            <div className={getCircle(index)} />
          </div>
        ))
      }
    </div>
  )
}

export default withNamespaces()(HistoryStep);