import { Col, DatePicker, Progress, Row, Space, Statistic } from 'antd';
import { get, omit } from 'lodash';
import moment from 'moment-timezone';
import React, { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { withNamespaces } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { handleApiErrors } from '../../api/axiosInstance';
import { TRIP_API } from '../../api/trips';
import { USER_API } from '../../api/user';
import { Button } from '../../components';
import Box from '../../components/Box';
import FormItem from '../../components/Form/FormItem';
import PageBreadcrumbs from '../../components/PageBreadcrumbs';
import SpaceSpinner from '../../components/SpaceSpinner';
import Text from '../../components/Text';
import LinkText from '../../components/Text/LinkText';
import Toast from '../../components/Toast';
import PageContainer from '../../containers/PageContainer';
import { INTERNAL_LINKS } from '../../enum';
import withAuthentication from '../../hocs/withAuthentication';
import useDidUpdateEffect from '../../hooks/useDidUpdateEffect';
import { formatPageTitle, replaceValueInArrayByID } from '../../utils/common';
import { formatNumberToLocale } from '../../utils/numbers';
import {
  selectStoreCompanySettings,
  selectStoreCountryByCode,
  selectStoreCurrentAuthUser,
  useStoreSelector,
} from '../../utils/storeSelectors';
import MileageItemCard from './MileageItemCard';
import classNames from './styles.module.scss';

const DailyMileageLogView = props => {
  const { t, history } = props;

  const authUser = useStoreSelector(selectStoreCurrentAuthUser);
  const companySettings = useStoreSelector(selectStoreCompanySettings);

  const [isMonthlyView, setIsMonthlyView] = useState(false);
  const [selectedDate, setSelectedDate] = useState(moment());
  const [dateRange, setDateRange] = useState([]);

  const [mileageItemsToCreate, setMileageItemsToCreate] = useState({});
  const [mileageItemsToUpdate, setMileageItemsToUpdate] = useState({});
  const [mileageItemsToDelete, setMileageItemsToDelete] = useState({});

  const toggleMonthlyView = () => setIsMonthlyView(state => !state);

  const _setMileageItemsState = ({ tripId, journeyDate, journeyDistance }, setStateCb) => {
    setStateCb(state => ({
      ...state,
      [journeyDate]: { tripId, journeyDate, journeyDistance },
    }));
  };

  const handleMileageCreate = values => {
    if (parseFloat(values?.journeyDistance) > 0) {
      _setMileageItemsState(values, setMileageItemsToCreate);
    } else {
      setMileageItemsToCreate(tripsToCreate => {
        return omit(tripsToCreate, [values.journeyDate]);
      });
    }
  };
  const handleMileageDelete = values => _setMileageItemsState(values, setMileageItemsToDelete);
  const handleMileageDeleteRevert = date => setMileageItemsToDelete(state => omit(state, [date]));
  const handleMileageUpdate = values => {
    _setMileageItemsState(values, setMileageItemsToUpdate);
    handleMileageDeleteRevert(values.journeyDate);
  };

  const handleDateChange = momentDate => {
    const year = momentDate.year();

    let dateYear = moment().year(year);
    let startDate;
    let endDate;

    if (isMonthlyView) {
      const month = momentDate.month();
      startDate = dateYear.clone().month(month).startOf('month');
      endDate = dateYear.clone().month(month).endOf('month');
    } else {
      const week = momentDate.week();
      startDate = dateYear.clone().week(week).startOf('week');
      endDate = dateYear.clone().week(week).endOf('week');
    }

    const cleanStartOfWeekDate = startDate.format('YYYY-MM-DD');
    const cleanEndOfWeekDate = endDate.format('YYYY-MM-DD');
    setSelectedDate(momentDate);
    setDateRange([cleanStartOfWeekDate, cleanEndOfWeekDate]);
  };

  useEffect(() => {
    handleDateChange(moment());
    // eslint-disable-next-line
  }, []);

  useDidUpdateEffect(() => {
    handleDateChange(selectedDate);
  }, [isMonthlyView]);

  const queryClient = useQueryClient();

  const dailyMileageQuery = useQuery({
    placeholderData: [],
    queryKey: ['daily-mileage', dateRange[0], dateRange[1]],
    queryFn: () => TRIP_API.getAllMileages(dateRange[0], dateRange[1]),
    enabled: !!dateRange[0] && !!dateRange[1],
    onError: error =>
      handleApiErrors(error.response, () => {
        Toast({
          type: 'error',
          message: t('dailyMileageQueryError'),
        });
      }),
  });

  const _updateDailyMileageQueryData = (trips, variables) => {
    const queryKey = ['daily-mileage', dateRange[0], dateRange[1]];
    let updatedQueryData = queryClient.getQueryData(queryKey);

    trips.forEach((trip, i) => {
      updatedQueryData = replaceValueInArrayByID(
        updatedQueryData,
        {
          date: variables.trips[i].journeyDate,
          trip: {
            _id: trip._id,
            journeyStatus: trip.journeyStatus,
            journeyDistance: trip.journeyDistance,
          },
        },
        'date',
      );
    });

    queryClient.setQueryData(queryKey, updatedQueryData);
  };

  const createMileageMutation = useMutation(TRIP_API.createMileage, {
    onSuccess: (trips, variables) => {
      _updateDailyMileageQueryData(trips, variables);
    },
    onError: error =>
      handleApiErrors(error.response, () => {
        Toast({
          type: 'error',
          message: t('createMileageMutationError'),
        });
      }),
  });

  const updateMileageMutation = useMutation(TRIP_API.updateMileage, {
    onSuccess: (trips, variables) => {
      _updateDailyMileageQueryData(trips, variables);
    },
    onError: error =>
      handleApiErrors(error.response, () => {
        Toast({
          type: 'error',
          message: t('updateMileageMutationError'),
        });
      }),
  });

  const deleteTripsMutation = useMutation(TRIP_API.deleteTrip, {
    onSuccess: (_, tripIds) => {
      const queryKey = ['daily-mileage', dateRange[0], dateRange[1]];
      let updatedQueryData = queryClient.getQueryData(queryKey);

      tripIds.forEach(tripId => {
        const updateIndex = updatedQueryData.findIndex(data => data?.trip?._id === tripId);
        updatedQueryData[updateIndex].trip = null;
      });

      queryClient.setQueryData(queryKey, updatedQueryData);
    },
    onError: error =>
      handleApiErrors(error.response, () => {
        Toast({
          type: 'error',
          message: t('deleteTripsMutationMutationError'),
        });
      }),
  });

  const handleDailyMileageSave = async () => {
    const hasMileageToCreate = !!Object.keys(mileageItemsToCreate).length;
    const hasMileageToUpdate = !!Object.keys(mileageItemsToUpdate).length;
    const hasTripsToDelete = !!Object.keys(mileageItemsToDelete).length;

    if (hasTripsToDelete) {
      try {
        await deleteTripsMutation.mutateAsync(
          Object.values(mileageItemsToDelete).map(trip => trip.tripId),
        );
      } catch (error) {
        return;
      }
    }

    if (hasMileageToCreate) {
      try {
        await createMileageMutation.mutateAsync({ trips: Object.values(mileageItemsToCreate) });
      } catch (error) {
        return;
      }
    }

    if (hasMileageToUpdate) {
      try {
        await updateMileageMutation.mutateAsync({ trips: Object.values(mileageItemsToUpdate) });
      } catch (error) {
        return;
      }
    }

    if (hasMileageToCreate || hasMileageToUpdate || hasTripsToDelete) {
      Toast({
        type: 'open',
        message: t('mileageSaveSucess'),
      });

      setMileageItemsToCreate({});
      setMileageItemsToUpdate({});
      setMileageItemsToDelete({});
    }
  };

  const country = useMemo(
    () => selectStoreCountryByCode(get(authUser, 'profile.group.productId.country', 'US')),
    [authUser],
  );

  const userMileageCapQuery = useQuery({
    enabled: !!companySettings?.mileageCap,
    staleTime: Infinity,
    cacheTime: 0,
    placeholderData: {
      currentMileageInPeriod: 0,
      mileageCap: 0,
      unit: 'mi',
    },
    queryKey: ['fetchUserMileageCap', authUser.profile._id],
    queryFn: () => USER_API.fetchUserMileageCap(authUser.profile._id),
    onError: error => handleApiErrors(error.response),
  });

  const DMLCapProgressPercent = useMemo(() => {
    if (
      typeof userMileageCapQuery.data.currentMileageInPeriod !== 'number' ||
      typeof userMileageCapQuery.data.mileageCap !== 'number'
    )
      return 0;

    return (
      (userMileageCapQuery.data.currentMileageInPeriod * 100) / userMileageCapQuery.data.mileageCap
    );
  }, [userMileageCapQuery.data]);

  if (userMileageCapQuery.isFetching) {
    return <SpaceSpinner />;
  }

  return (
    <PageContainer
      title={
        <PageBreadcrumbs
          items={[
            {
              label: t('trips'),
              onClick: () => history.push(INTERNAL_LINKS.TRIPS),
            },
            { label: t('dailyMileageLog') },
          ]}
        />
      }
      sideActionComponent={
        <Button
          loading={
            updateMileageMutation.isLoading ||
            createMileageMutation.isLoading ||
            deleteTripsMutation.isLoading
          }
          disabled={
            updateMileageMutation.isLoading ||
            createMileageMutation.isLoading ||
            deleteTripsMutation.isLoading
          }
          onClick={handleDailyMileageSave}
        >
          {t('saveMileage')}
        </Button>
      }
    >
      <Helmet>
        <title>{formatPageTitle('Daily Mileage Log')}</title>
      </Helmet>

      <Space direction="vertical" size="large">
        {!userMileageCapQuery.isFetching && userMileageCapQuery.data && (
          <Row align="middle" justify={{ xs: 'end', md: 'space-between' }} gutter={[16, 16]}>
            <Col xs={24} md={12} lg={6}>
              <FormItem
                label={
                  <Row gutter={6} wrap={false}>
                    <Col>{t(isMonthlyView ? 'selectMonth' : 'selectWeek')}</Col>
                    <Col>
                      <LinkText size="xs" onClick={toggleMonthlyView}>
                        ({t(isMonthlyView ? 'weeklyView' : 'monthlyView')})
                      </LinkText>
                    </Col>
                  </Row>
                }
              >
                <DatePicker
                  allowClear={false}
                  value={selectedDate}
                  picker={isMonthlyView ? 'month' : 'week'}
                  onChange={handleDateChange}
                  disabledDate={currentDate => {
                    const currentDateTimezone = moment(currentDate);
                    const isNextYear = currentDateTimezone.year() > moment().year();
                    const isSameYear = currentDateTimezone.year() === moment().year();
                    const isHigherWeekNumber = currentDateTimezone.week() > moment().week();
                    return isNextYear || (isSameYear && isHigherWeekNumber);
                  }}
                />
              </FormItem>
            </Col>
            <Col xs={24} lg={12} xl={6}>
              <Box className={classNames.mileageCapCard}>
                <Text variant="h5">{t('yourMileageCap')}</Text>

                <Statistic
                  title={
                    <Text>
                      {t('x_of_x_unit', {
                        countA: formatNumberToLocale(
                          userMileageCapQuery.data.currentMileageInPeriod,
                        ),
                        countB: formatNumberToLocale(userMileageCapQuery.data.mileageCap),
                        unit: userMileageCapQuery.data.unit,
                      })}
                    </Text>
                  }
                  valueRender={() => (
                    <Progress
                      percent={DMLCapProgressPercent}
                      strokeColor="#16b296"
                      trailColor="#DADADA"
                      strokeWidth={15}
                      type="line"
                      format={() => ''}
                      style={{ display: 'block', marginRight: '-8px' }}
                    />
                  )}
                />
              </Box>
            </Col>
          </Row>
        )}

        {dailyMileageQuery.isFetching || (userMileageCapQuery.isFetching && <SpaceSpinner />)}

        <Row gutter={[10, 10]}>
          {!dailyMileageQuery.isFetching &&
            !userMileageCapQuery.isFetching &&
            dailyMileageQuery.data?.map(mileageItem => {
              return (
                <MileageItemCard
                  t={t}
                  item={mileageItem}
                  onMileageCreate={handleMileageCreate}
                  onMileageUpdate={handleMileageUpdate}
                  onMileageDelete={handleMileageDelete}
                  distanceUnit={country.distanceShort}
                  disabled={
                    updateMileageMutation.isLoading ||
                    createMileageMutation.isLoading ||
                    deleteTripsMutation.isLoading
                  }
                />
              );
            })}
        </Row>
      </Space>
    </PageContainer>
  );
};

export default withNamespaces()(withAuthentication(DailyMileageLogView));
