import React from 'react';

import { Modal } from '../..';
import InviteMultipleUsersForm from '../../Form/InviteMultipleUsersForm';

const InviteNewUsersModal = props => {
  const {
    visible,
    onCancel,
    onFinish,
    existingUsers,
    defaultValues,
    requireEmployeeID,
    companyAddress,
  } = props;

  const WINDOW_WIDTH = Math.min(window.innerWidth, 1080) - 50;
  const MODAL_WIDTH = WINDOW_WIDTH > 1400 ? 1400 : WINDOW_WIDTH;

  return (
    <Modal
      width={MODAL_WIDTH}
      title="Invite new users"
      visible={visible}
      onCancel={onCancel}
      footer={null}
    >
      <InviteMultipleUsersForm
        existingUsers={existingUsers}
        defaultValues={defaultValues}
        onCancel={onCancel}
        onFinish={onFinish}
        requireEmployeeID={requireEmployeeID}
        requireGroupID={false}
        companyAddress={companyAddress}
      />
    </Modal>
  );
};

export default InviteNewUsersModal;
