import { Col, Row } from 'antd';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';

import { INTERNAL_LINKS, STATUS_LIST } from '../../enum';
import StatusTag from '../Tag/StatusTag';
import Text from '../Text';
// import LinkText from '../Text/LinkText';
import ErrorAlert from './ErrorAlert';

const OutOfComplianceAlert = props => {
  const { userComplianceDetails } = props;

  const licenseOutOfCompliance = useMemo(() => {
    return [userComplianceDetails?.driverLicenseComplianceStatus].includes(
      STATUS_LIST.Status.PENDING,
      STATUS_LIST.Status.DENIED,
      STATUS_LIST.Status.NON_COMPLIANT,
    );
  }, [userComplianceDetails]);

  const insuranceOutOfCompliance = useMemo(() => {
    return [userComplianceDetails?.insuranceComplianceStatus].includes(
      STATUS_LIST.Status.PENDING,
      STATUS_LIST.Status.DENIED,
      STATUS_LIST.Status.NON_COMPLIANT,
    );
  }, [userComplianceDetails]);

  if (!(licenseOutOfCompliance || insuranceOutOfCompliance)) return null;

  return (
    <ErrorAlert
      center
      closable={false}
      title={
        <Row gutter={16} align="middle">
          <Col>
            <Text variant="strong">Out of Compliance Alert</Text>
          </Col>
          {/* <Col>
            <LinkText size="xs">How will this affect me?</LinkText>
          </Col> */}
        </Row>
      }
      message={
        <>
          {licenseOutOfCompliance && (
            <Row
              wrap={false}
              gutter={16}
              justify="space-between"
              align="middle"
              style={{ marginBottom: 10 }}
            >
              <Col>
                <Text size="sm">
                  Please enter or update your driver license information. You can update this
                  information <Link to={INTERNAL_LINKS.USER_DRIVERS_LICENSE}>here</Link>.
                </Text>
              </Col>
              <Col>
                <StatusTag status="fix" />
              </Col>
            </Row>
          )}

          {insuranceOutOfCompliance && (
            <Row wrap={false} gutter={16} justify="space-between" align="middle">
              <Col>
                <Text size="sm">
                  Please enter or update your insurance information. You can update this information{' '}
                  <Link to={INTERNAL_LINKS.USER_INSURANCE_INFO}>here</Link>.
                </Text>
              </Col>
              <Col>
                <StatusTag status="fix" />
              </Col>
            </Row>
          )}
        </>
      }
    />
  );
};

export default OutOfComplianceAlert;
