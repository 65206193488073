import { STATUS_LIST } from './index';

const TripActions = [
  {
    text: 'Approve',
    value: 'approve',
  },
  {
    text: 'Reject',
    value: 'reject',
  },
];

const SelectOptions = [
  {
    label: 'Awaiting Approval',
    value: STATUS_LIST.Status.SUBMITTED,
  },
  {
    label: 'Approved',
    value: STATUS_LIST.Status.APPROVED,
  },
  // {
  //   label: 'In Progress',
  //   value: STATUS_LIST.Status.IN_PROGRESS,
  // },
  {
    label: 'Pending',
    value: STATUS_LIST.Status.PENDING,
  },
  {
    label: 'More Info',
    value: STATUS_LIST.Status.MORE_INFO,
  },
  {
    label: 'Denied',
    value: STATUS_LIST.Status.DENIED,
  },
  {
    label: 'Paid',
    value: STATUS_LIST.Status.PAID,
  },
];

const SelectionOptionsWithACH = [
  ...SelectOptions,
  {
    label: STATUS_LIST.StatusTagColorConfig[STATUS_LIST.Status.ACH_PENDING].label,
    value: STATUS_LIST.Status.ACH_PENDING,
  },
  {
    label: STATUS_LIST.StatusTagColorConfig[STATUS_LIST.Status.ACH_FAILED].label,
    value: STATUS_LIST.Status.ACH_FAILED,
  },
  {
    label: STATUS_LIST.StatusTagColorConfig[STATUS_LIST.Status.ACH_CANCELLED].label,
    value: STATUS_LIST.Status.ACH_CANCELLED,
  },
];

export default {
  TripActions,
  SelectOptions,
  SelectionOptionsWithACH,
};
