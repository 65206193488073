import moment from 'moment-timezone';
import React from 'react';

import Toast from '../../components/Toast';

/**
 * Validation function for the Trips creation
 *
 * @param {object} tripData
 */
export function validateManualTrip(tripData) {
  let isValid = true;

  const CREATE_TRIP_ERROR_TITLE = 'Create Trip Error';

  if (!tripData) {
    isValid = false;
    Toast({
      type: 'error',
      message: CREATE_TRIP_ERROR_TITLE,
      description: "Can't create without verified locations",
    });
  } else if (!tripData.purpose) {
    isValid = false;
    Toast({
      type: 'error',
      message: CREATE_TRIP_ERROR_TITLE,
      description: 'Trip purpose is required',
    });
  } else if (!tripData.startDate) {
    isValid = false;
    Toast({
      type: 'error',
      message: CREATE_TRIP_ERROR_TITLE,
      description: 'Please select a start date',
    });
  } else if (!tripData.startTime) {
    isValid = false;
    Toast({
      type: 'error',
      message: CREATE_TRIP_ERROR_TITLE,
      description: 'Please select a start time',
    });
  } else if (!tripData.endDate) {
    isValid = false;
    Toast({
      type: 'error',
      message: CREATE_TRIP_ERROR_TITLE,
      description: 'Please select an end date',
    });
  } else if (!tripData.isKliksCalculatingEndTime && !tripData.endTime) {
    isValid = false;
    Toast({
      type: 'error',
      message: CREATE_TRIP_ERROR_TITLE,
      description: 'Please select an end time',
    });
  } else if (tripData.startDate.isAfter(moment(), 'date')) {
    isValid = false;
    Toast({
      type: 'error',
      message: CREATE_TRIP_ERROR_TITLE,
      description: (
        <React.Fragment>
          A trip cannot have a <b>start</b> date set in the future.
        </React.Fragment>
      ),
    });
  } else if (!tripData.isKliksCalculatingEndTime && tripData.endDate.isAfter(moment(), 'date')) {
    isValid = false;
    Toast({
      type: 'error',
      message: CREATE_TRIP_ERROR_TITLE,
      description: (
        <React.Fragment>
          A trip cannot have an <b>end</b> date set in the future.
        </React.Fragment>
      ),
    });
  } else if (
    !tripData.isKliksCalculatingEndTime &&
    tripData.startDate.isAfter(tripData.endDate, 'date')
  ) {
    isValid = false;
    Toast({
      type: 'error',
      message: CREATE_TRIP_ERROR_TITLE,
      description: (
        <React.Fragment>
          The <b>start date</b> cannot be after the <b>end date</b>
        </React.Fragment>
      ),
    });
  } else if (
    moment(
      `${tripData.startDate.format('MM/DD/YYYY')} ${tripData.startTime.format('HH:mm:ss')}`,
      'MM/DD/YYYY HH:mm:ss',
    ).isAfter(moment())
  ) {
    isValid = false;
    Toast({
      type: 'error',
      message: CREATE_TRIP_ERROR_TITLE,
      description: (
        <React.Fragment>
          <b>Start</b> time cannot be in the future.
        </React.Fragment>
      ),
    });
  } else if (
    !tripData.isKliksCalculatingEndTime &&
    moment(
      `${tripData.endDate.format('MM/DD/YYYY')} ${tripData.endTime.format('HH:mm:ss')}`,
      'MM/DD/YYYY HH:mm:ss',
    ).isAfter(moment())
  ) {
    isValid = false;
    Toast({
      type: 'error',
      message: CREATE_TRIP_ERROR_TITLE,
      description: (
        <React.Fragment>
          <b>End</b> time cannot be in the future.
        </React.Fragment>
      ),
    });
  } else if (
    !tripData.isKliksCalculatingEndTime &&
    // If a trip's start DateTime is AFTER its end
    moment(
      `${tripData.startDate.format('MM/DD/YYYY')} ${tripData.startTime.format('HH:mm:ss')}`,
      'MM/DD/YYYY HH:mm:ss',
    ).isAfter(
      moment(
        `${tripData.endDate.format('MM/DD/YYYY')} ${tripData.endTime.format('HH:mm:ss')}`,
        'MM/DD/YYYY HH:mm:ss',
      ),
    )
  ) {
    isValid = false;
    Toast({
      type: 'error',
      message: 'Validation error',
      description: (
        <React.Fragment>
          A trip's <b>start</b> cannot be before it's <b>end</b>.
        </React.Fragment>
      ),
    });
  }

  return isValid;
}
