import React from 'react';

import ConfirmModal from '../ConfirmModal';

const CustomPlanSysAdminConfirmModal = props => {
  const { t, visible, onCancel, onConfirm } = props;

  return (
    <ConfirmModal
      visible={visible}
      title={t('sureYouWantToChangeActiveFeaturesForCompany')}
      message={t('thisCompanyIsOnContractCustomPlan')}
      onCancel={onCancel}
      onOk={onConfirm}
      okText={t('yes')}
    />
  );
};

export default CustomPlanSysAdminConfirmModal;
