import './style.scss';

import { Layout, Row } from 'antd';
import FullLogo from 'components/FullLogo';
import Spinner from 'components/Spinner';
import Toast from 'components/Toast';
import AuthContainer from 'containers/Auth';
import { INTERNAL_LINKS } from 'enum';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { withNamespaces } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';
import { ERROR_MESSAGE } from 'utils/constants';

import { handleApiCalls } from '../../../api/axiosInstance';
import AnimatedMailSent from '../../../components/Lottie/AnimatedMailSent';
import { emitNavigateTo } from '../../../stores/actions/router';
import { formatPageTitle } from '../../../utils/common';

const { Content } = Layout;

const SignupSuccess = ({ t, ...props }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(true);

  const resendActivationLink = async () => {
    try {
      setLoading(true);
      await handleApiCalls('post', `${process.env.REACT_APP_HOST_API}auth/confirm-email-resend`, {
        email,
      });
      Toast({
        type: 'success',
        message: t('newAccountActivationEmailSent'),
      });
    } catch (error) {
      Toast({
        type: 'error',
        message: ERROR_MESSAGE.CONFIRM_EMAIL,
        description: error.msg,
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    if (history.location.state) {
      setEmail(history.location.state.email);
      setLoading(false);
    }

    if (!history.location.state) {
      setLoading(false);
      dispatch(emitNavigateTo(INTERNAL_LINKS.LOGIN));
    } else if (!history.location.state.email) {
      setLoading(false);
      dispatch(emitNavigateTo(INTERNAL_LINKS.LOGIN));
    }
    // eslint-disable-next-line
  }, [email]);

  return (
    <Spinner spinning={loading}>
      <Helmet>
        <title>{formatPageTitle('Signup Success')}</title>
      </Helmet>

      <Layout style={{ minHeight: '100vh' }}>
        <div className="logo">
          <FullLogo />
        </div>
        <div className="content-wrapper">
          <Content>
            <Row className="kliks-row mail-sending">
              <AnimatedMailSent />
            </Row>
            <Row className="kliks-row text-heading">{t('Activate Your Trial')}</Row>
            <Row className="kliks-row form-subhead">
              {t(`Click the activation link in the message from
              Kliks, at the following email address`)}
              :
            </Row>
            <Row className="kliks-row form-subhead email">{email || ''}</Row>
            <Row className="kliks-row">
              <div className="reactivation-link email">
                {t(`Need us to resend the email? `)}
                <NavLink to="#" onClick={() => resendActivationLink()}>
                  {t('Resend activation link.')}
                </NavLink>
              </div>
            </Row>
          </Content>
        </div>
      </Layout>
    </Spinner>
  );
};

export default AuthContainer(withNamespaces()(SignupSuccess));
